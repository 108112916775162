import 'babel-polyfill'; // babel-polyfill must be the first import!
import EventBus from 'tdsAppRoot/library/EventBus.js';
//import "tdsAppRoot/testtheme.scss"; // ONLY IMPORT A THEME HERE FOR TESTING PURPOSES
import "tdsAppRoot/GlobalStyles.scss";
//import 'es6-promise/auto';
import 'tdsAppRoot/library/node-contains-polyfill.js';
import 'tdsAppRoot/PublicPath';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import { hasNativeCssVarsSupport, RememberCssVar } from "tdsAppRoot/library/CssVars.js";

//import { InitializePromiseTracking } from "tdsAppRoot/library/PromiseTrace.js";
//InitializePromiseTracking(); // This InitializePromiseTracking call can be commented/not as needed to provide possible stack traces in unhandled promise rejections.  Promise tracking causes CPU overhead at every Promise.then() call, and is only useful in rare circumstances.

/**
 * Bootstraps the vue app
 * @param {any} CreateApp App initialization function specific to the vue app being bootstrapped.  These are the default exports of the files in ../vue-root
 */
export default function AppInitialize(CreateApp)
{
	let { myApp, router, store } = CreateApp(
		appContext // This is a global from _Layout.cshtml
	);

	try
	{
		if ('serviceWorker' in navigator)
		{
			window.addEventListener("beforeinstallprompt", (event) =>
			{
				// Prevents the default mini-infobar or install dialog from appearing on mobile
				EventBus.pwaInstallAvailable = true;
				EventBus.pwaInstallEvent = event;
				try
				{
					event.preventDefault();
				}
				catch (ex)
				{
					console.error("PWA beforeinstallprompt event.preventDefault() failed.", ex);
				}
			});
			window.addEventListener("appinstalled", (event) =>
			{
				EventBus.pwaInstalled = true;
			});
			window.addEventListener('load', () =>
			{
				navigator.serviceWorker.register(appContext.appPath + 'serviceworker.js?v=' + appContext.build)
					.then(function (registration)
					{
						console.log('Service Worker registration successful with scope: ', registration.scope);
					})
					.catch(function (err)
					{
						console.log('Service Worker registration failed: ', err);
					});
			});
		}
	}
	catch (ex)
	{
		console.error("PWA setup failed.", ex);
	}

	if (typeof window !== 'undefined')
		window.router = router;
	//store.replaceState(__INITIAL_STATE__); // this probably shouldn't be done here, as it would replace the appPath stuff we just set.  Perhaps pass this in, or just ensure it has the appPath data?

	// Run css-vars-ponyfill
	if (!hasNativeCssVarsSupport)
		cssVars({
			watch: true,
			updateURLs: false, // updateURLs: false to work around an error we get 1-2 times a day from IE11.
			onComplete(cssText, styleElms, cssVariables, benchmark)
			{
				if (cssVariables)
					for (let key in cssVariables)
						if (cssVariables.hasOwnProperty(key))
							RememberCssVar(key, cssVariables[key]);
			}
		});

	if (router !== null)
	{
		router.onReady(() =>
		{
			const matched_init = router.getMatchedComponents();

			if (!matched_init.length)
			{
				// ******************************************************
				// As of 2019-06-21 there is a catch-all 404 route 
				// in the vue router configuration for all 3 vue 
				// apps. The following code should not be reached 
				// under normal circumstances.
				// ******************************************************
				document.body.innerHTML = "404 Not Found"; // TODO: This is currently like this for debugging purposes.  This should be changed back to the redirect before going live.
				return;
				//window.location.replace(appContext.appPath + "NotFound");
			}

			router.beforeResolve((to, from, next) =>
			{
				const matched = router.getMatchedComponents(to);
				const prevMatched = router.getMatchedComponents(from);


				let diffed = false;
				const activated = matched.filter((c, i) =>
				{
					return diffed || (diffed = (prevMatched[i] !== c));
				});

				if (!activated.length)
				{
					return next();
				}

				NProgress.start();

				Promise.all(activated.map(c =>
				{
					if (c.asyncData)
					{
						return c.asyncData({ store, route: to });
					}
				}))
					.then(() =>
					{
						NProgress.done();
						next();
					})
					.catch(next);

			});

			myApp.$mount('#app');
		});
	}
	else
		myApp.$mount('#app');
}