<template>
	<div class="dialogContainer">
		<transition-group :name="transitionName">
			<component ref="childModalDialogComponent" v-for="(c, idx) in components"
					   :is="c.component"
					   v-bind="c.props"
					   :key="c.key"
					   :modalDialogIndex="idx"
					   :modalDialogCount="components.length"
					   @close="function(dialogResult) { closeRequested(c.key, dialogResult) }" />
		</transition-group>
	</div>
</template>

<script>
	// One instance of this component should exist near the end of the root vue component.  All modal dialogs will be children of this.
	import { RegisterModalDialogContainer, UnregisterModalDialogContainer } from 'tdsAppRoot/library/ModalDialog.js';

	let uniqueComponentCounter = 0;
	export default {
		props:
		{
			name: { // IMPORTANT: If we ever create multiple Modal Dialog Containers, the `CloseTopModalDialog` method will need to be updated to handle it.
				type: String,
				required: true
			},
			transitionName: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				components: [] // Does not contain component instances.  Contains component DEFINITIONS.
			};
		},
		created()
		{
			RegisterModalDialogContainer(this);
		},
		beforeDestroy()
		{
			while (this.components.length > 0)
				this.components.pop().onDialogClose(false);
			UnregisterModalDialogContainer(this);
		},
		computed:
		{
			numComponents()
			{
				return this.components.length;
			}
		},
		watch:
		{
			numComponents(val)
			{
				var root = document.getElementById("appContentRoot");
				if (root)
				{
					if (val > 0)
					{
						// make main content inert.
						root.setAttribute("aria-hidden", "true");
					}
					else
					{
						// Make main content not inert.
						root.removeAttribute("aria-hidden");
					}
				}
			}
		},
		methods:
		{
			CreateDialog(component, props, onDialogClose)
			{
				let c = { component, props, onDialogClose, key: uniqueComponentCounter++ };
				this.components.push(c);
				return c.key;
			},
			closeRequested(key, dialogResult)
			{
				for (let i = 0; i < this.components.length; i++)
				{
					if (this.components[i].key === key)
					{
						let c = this.components[i];

						this.components.splice(i, 1);
						c.onDialogClose(dialogResult);
						return;
					}
				}
			},
			CloseTopDialog()
			{
				if (this.$refs.childModalDialogComponent && this.$refs.childModalDialogComponent.length > 0)
				{
					let idx = this.$refs.childModalDialogComponent.length - 1;
					let c = this.$refs.childModalDialogComponent[idx];
					c.defaultClose();
					return true;
				}
				return false;
			}
		}
	}
</script>