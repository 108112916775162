import Vue from 'vue';
import { EscapeHTML, quoteattr } from 'tdsAppRoot/library/TDSUtil.js';

var ThemeMixin = {
	methods: {
		/**
		 * Gets data from the themeMetadata javascript object based on an array of keys.
		 * Returns [defaultValue] if any key doesn't exist.
		 * @param {any} paramArray Array of object keys, for example, ["linkWiz", "loginLogoMedium", "width"] accesses themeMetadata.linkWiz.loginLogoMedium.width
		 * @param {any} defaultValue If the targeted path doesn't exist, this is returned instead.
		 */
		GetThemeMetadata(paramArray, defaultValue)
		{
			if (!this.themeMetadata)
				return defaultValue;

			let node = this.themeMetadata;
			for (let i = 0; i < paramArray.length; i++)
			{
				node = node[paramArray[i]];
				if (!node)
					return defaultValue;
			}
			return node;
		}
	},
	computed: {
		conditionalSubProduct()
		{
			if (themeMetadata.key == "tds_health")
				return this.subProduct + " ";
			else
				return "";
		},
		conditionalSubProductPhonetic()
		{
			if (themeMetadata.key == "tds_health")
				return this.subProductPhonetic + " ";
			else
				return "";
		},
		themeMetadata()
		{
			return window.themeMetadata;
		},
		appContext()
		{
			return window.appContext;
		},
		appName()
		{
			return window.appContext.appName;
		},
		appNameHtml()
		{
			return EscapeHTML(window.appContext.appName); // There is a field called appNameHtml which for some reason isn't used here.
		},
		subProduct()
		{
			if (window.themeMetadata.key === "tds_health")
				return "STAT!Ref";
			else
				return window.appContext.appName;
		},
		subProductScreenReaderSafe()
		{
			return this.subProduct.replace(/!/, '<span aria-hidden=\"true\">!</span>');
		},
		subProductHtml()
		{
			return EscapeHTML(this.subProduct);
		},
		subProductAttribute()
		{
			return quoteattr(this.subProduct);
		},
		subProductPhonetic()
		{
			if (window.themeMetadata.key === "tds_health")
				return "STAT REF";
			else
				return this.subProductAttribute;
		},
		socialMediaLinks()
		{
			return window.themeMetadata.socialLinks;
		}
	}
};

export default ThemeMixin;