import { ShowCornerLoader, HideCornerLoader } from 'tdsAppRoot/library/ModalDialog.js';

/**
 * Calls an asynchronous import function.  If it fails, it is automatically retried after a short delay until 5 attempts have been made.
 * @param {String} type The general type of thing being loaded (to be used in error messages). E.g. "script" or "resource"
 * @param {String} name The specific name of the thing being loaded (to be used in error messages). E.g. "EULA" or the file name.
 * @param {Function} importFn A function that simply calls import() with some argument and returns the value.
 * @returns {Promise} Returns a promise that resolves when the import is complete, or rejects if it fails after running out of retries.
 */
export function RobustImport(type, name, importFn)
{
	function TryImport(retries)
	{
		return importFn()
			.then(result =>
			{
				HideCornerLoader();
				return result;
			})
			.catch(err =>
			{
				if (retries <= 5)
				{
					ShowCornerLoader();
					return new Promise((resolve, reject) =>
					{
						setTimeout(() =>
						{
							TryImport(retries + 1).then(resolve).catch(reject);
						}, 500 * retries);
					});
				}
				HideCornerLoader();
				return Promise.reject(new Error('Unable to load ' + type + ' "' + name + '": ' + err));
			});
	}
	return TryImport(1);
}
export function EULALoader()
{
	return RobustImport("resource", "EULA.html", () => import(/* webpackChunkName: "[request]" */"tdsAppRoot/themes/" + themeMetadata.key + "/EULA.html"));
}
export function VideoLoader()
{
	return RobustImport("resource", "videotds.js", () => import(/* webpackChunkName: "videotds" */'tdsAppRoot/library/videotds.js'));
}