import { CallServer } from "tdsAppRoot/API/apibase.js";

var DrugCompletionRequest = function ()
{
	var query;
};
Object.seal(DrugCompletionRequest);

var DrugLookupRequest = function ()
{
	var name;
};
Object.seal(DrugLookupRequest);

var DrugInteractionsRequest = function ()
{
	var names; // string[]
	var sortby = "None"; // string ["Significance", "DrugNames", "None"]
	var includeMonographs; // bool
};
Object.seal(DrugInteractionsRequest);

var DrugMonographLookupRequest = function ()
{
	var id;
};
Object.seal(DrugMonographLookupRequest);

var DrugInteractionsGetResourceRequest = function () // Obfuscated log event args object. The "Brave" browser otherwise blocks it.
{
	var name; // The name of the event to log. "MonographView" or "DrugAdd".
	var id; // The id of the monograph or name of the drug.
};
Object.seal(DrugInteractionsGetResourceRequest);

export function Acquire(store)
{
	try
	{
		return CallServer("DrugInteractionsData", "Acquire", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function Completion(store, query)
{
	try
	{
		var args = new DrugCompletionRequest();
		args.query = query;
		return CallServer("DrugInteractionsData", "Completion", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function DrugLookup(store, name)
{
	try
	{
		var args = new DrugLookupRequest();
		args.name = name;
		return CallServer("DrugInteractionsData", "DrugLookup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function Interactions(store, names, sortby, includeMonographs)
{
	try
	{
		var args = new DrugInteractionsRequest();
		args.names = names;
		args.sortby = sortby;
		args.includeMonographs = includeMonographs;
		return CallServer("DrugInteractionsData", "Interactions", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function MonographLookup(store, id)
{
	try
	{
		var args = new DrugMonographLookupRequest();
		args.id = id;
		return CallServer("DrugInteractionsData", "MonographLookup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function GetResource(store, name, id) // Obfuscated LogEvent. The "Brave" browser otherwise blocks it.
{
	try
	{
		var args = new DrugInteractionsGetResourceRequest();
		args.name = name;
		args.id = id;
		return CallServer("DrugInteractionsData", "GetResource", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}