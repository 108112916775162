var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        role: "alert",
        "aria-label":
          (_vm.title ? _vm.title + ". " : "") +
          _vm.message +
          ". Click anywhere outside this box or press escape to dismiss this message.",
        "aria-describedby": "errMsgLbl"
      }
    },
    [
      _c("div", { staticClass: "errorWindow" }, [
        _vm.title
          ? _c("div", { staticClass: "title" }, [
              _vm._v("\n\t\t\t" + _vm._s(_vm.title) + "\n\t\t")
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { class: _vm.errorMessageClass, attrs: { id: "errMsgLbl" } },
          [_vm._v("\n\t\t\t" + _vm._s(_vm.message) + "\n\t\t")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "help",
            attrs: { "aria-hidden": "true", role: "presentation" }
          },
          [
            _vm._v(
              "\n\t\t\tClick anywhere outside this box or press escape to dismiss this message.\n\t\t"
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("input", {
        ref: "closeBtn",
        staticClass: "offscreenForScreenReader",
        attrs: { type: "button", value: "Close", tabindex: "0" },
        on: { click: _vm.DefaultClose }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }