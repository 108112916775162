let toastContainer;
export function RegisterToastContainer(containerComponent)
{
	if (toastContainer !== containerComponent)
		toastContainer = containerComponent;
}
export function UnregisterToastContainer(containerComponent)
{
	if (toastContainer === containerComponent)
		toastContainer = null;
}
/**
 * Creates a toast notification and returns a promise which resolves when the notification is closed. The promise does not reject.
 * @param {any} args A string message or an arguments object containing a string message and other arguments. See `args` prop in "components/Controls/Toast/Toast.vue".
 * @returns {Promise} Returns a promise which resolves when the notification is closed. The promise does not reject.
 */
export function MakeToast(args)
{
	if (toastContainer)
	{
		return new Promise((resolve, reject) =>
		{
			toastContainer.CreateToast(args, () => { resolve(); });
		});
	}
	else
	{
		console.error("Toast container does not exist. Make sure you have added <ToastContainer /> somewhere in the project.");
		return new Promise((resolve, reject) => { resolve(); });
	}
}