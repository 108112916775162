<template>
	<div role="alert" :aria-label="(title ? title + '. ' : '') + message + '. Click anywhere outside this box or press escape to dismiss this message.'" aria-describedby="errMsgLbl">
		<div class="errorWindow">
			<div v-if="title" class="title">
				{{ title }}
			</div>
			<div id="errMsgLbl" :class="errorMessageClass">
				{{ message }}
			</div>
			<div class="help" aria-hidden="true" role="presentation">
				Click anywhere outside this box or press escape to dismiss this message.
			</div>
		</div>
		<input ref="closeBtn" type="button" value="Close" class="offscreenForScreenReader" tabindex="0" @click="DefaultClose" />
	</div>
</template>

<script>
	export default {
		components: {},
		props:
		{
			message: {
				type: String,
				default: "An error has occurred.  Technical support has been notified."
			},
			colorScheme: {
				type: String,
				default: "ERROR" // or TURNAWAY
			}
		},
		methods:
		{
			DefaultClose()
			{
				this.$emit("close", false);
			},
			SetFocus()
			{
				this.$refs.closeBtn.focus();
			}
		},
		computed:
		{
			errorMessageClass()
			{
				if (this.$props.colorScheme == "TURNAWAY")
					return "turnawayMessage";
				else
					return "errorMessage";
			},
			title()
			{
				if (this.$props.colorScheme == "TURNAWAY")
					return "Turnaway Notice";
				return null;
			}
		}
	}
</script>

<style scoped>
	.turnawayMessage, .errorMessage
	{
		text-align: center;
	}

	.turnawayMessage
	{
		color: #6699cc;
	}

	.offscreenForScreenReader
	{
		position: absolute;
		left: -9999px;
		width: 0px;
		height: 0px;
		border: none;
		padding: 0px;
		margin: 0px;
	}

	.title
	{
		color: #6699cc;
		font-weight: bold;
		font-size: 16pt;
		margin-bottom: 10px;
	}

	.errorMessage
	{
		width: 100%;
		color: #D40202;
	}

	.errorWindow
	{
		max-width: 500px;
		padding: 20px 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

	@media (min-width: 550px)
	{
		.errorWindow
		{
			width: 500px;
		}
	}

	.help
	{
		text-align: center;
		margin-top: 20px;
	}
</style>