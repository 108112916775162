<template>
	<div v-if="loading" class="loading">
		<ScaleLoader class="animation" />
		<div class="loadingtxt">Loading…</div>
	</div>
	<div v-else>
		<div v-if="category" class="summary">
			<b>{{totalMatches}}</b> Hits in <b>{{totalResults}}</b> Matching Results found in
			<a role="button" tabindex="0" @click="showZeroHitTitles = false" @keypress.enter.space.stop.prevent="showZeroHitTitles = false" :class="{ alwaysUnvisited: true, bold: !showZeroHitTitles }">{{titlesWithResults}}</a>
			titles of
			<a role="button" tabindex="0" @click="showZeroHitTitles = true" @keypress.enter.space.stop.prevent="showZeroHitTitles = true" :class="{ alwaysUnvisited: true, bold: showZeroHitTitles }">{{bookHits.length}}</a>
			Titles Searched, requiring <b>{{searchTimeSeconds}}</b> seconds
		</div>
		<table v-if="category" class="resultCountsTbl">
			<thead>
				<tr>
					<th class="results">Results</th>
					<th class="title">{{ subProduct }} Title</th>
				</tr>
			</thead>
			<tbody>
				<tr class="item" v-for="item in bookHits" :key="item.id" v-show="showZeroHitTitles || item.results > 0">
					<td class="results">{{item.results}}</td>
					<td class="title" v-html="item.name"></td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";

	export default {
		mixins: [ThemeMixin],
		components: { ScaleLoader },
		props:
		{
			searchid: {
				type: String,
				required: true
			}
		},
		data()
		{
			return {
				showZeroHitTitles: true
			};
		},
		created()
		{
			this.$store.dispatch("GetBookshelfTitles").then(() => { }).catch(err => { });
		},
		computed:
		{
			books()
			{
				return this.$store.state.bookshelf.bookshelfTitles;
			},
			bookHits()
			{
				if (this.books && this.titleResultCounts)
					return this.books.map(b =>
					{
						let results = this.titleResultCounts[b.Id];
						return { id: b.Id, name: b.Name, results: results ? results : 0 };
					});
				return [];
			},
			category()
			{
				return this.$store.getters.GetCategorySearchResults(this.searchid, "All");
			},
			titleResultCounts()
			{
				return this.category && this.category.debug && this.category.debug.titleResultCounts ? this.category.debug.titleResultCounts : null;
			},
			loading()
			{
				return !this.category || !this.books;
			},
			totalResults()
			{
				let total = 0;
				let counts = this.titleResultCounts;
				for (let key in counts)
				{
					let v = counts[key];
					if (v)
						total += v;
				}
				if (this.category)
				{
					if (total !== this.category.totalResults)
						return total + " (<- computed sum. reported total did not match: " + this.category.totalResults + ")";
				}
				return total;
			},
			totalMatches()
			{
				if (this.category)
					return this.category.totalMatches;
				return 0;
			},
			titlesWithResults()
			{
				let total = 0;
				let counts = this.titleResultCounts;
				for (let key in counts)
					if (counts[key])
						total++;
				return total;
			},
			searchTimeSeconds()
			{
				let time = "unknown";
				if (this.category && this.category.metadata)
					time = this.category.metadata.searchDuration / 1000;
				return time;
			}
		}
	};
</script>
<style scoped>
	.summary
	{
		margin-bottom: 1em;
	}

	.resultCountsTbl
	{
		border-collapse: collapse;
	}

		.resultCountsTbl th
		{
			text-align: left;
			border-bottom: 2px solid #AAAAAA;
		}

		.resultCountsTbl td
		{
			vertical-align: top;
			border-bottom: 1px solid #E6E6E6;
		}

	.results
	{
		text-align: right;
		border-right: 2px solid #AAAAAA;
		padding-right: 5px;
	}

	.title
	{
		padding-left: 5px;
	}

	.loading
	{
		padding: 3px;
		text-align: center;
	}

	a.alwaysUnvisited
	{
		text-decoration: underline;
	}

	a.bold
	{
		font-weight: bold;
		text-decoration: none;
		color: var(--default-text-color);
		cursor: text;
	}
</style>
