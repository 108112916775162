/*
TitleListCompressor.js
Author: Brian Pearce / Teton Data Systems
2011
Requres no additional javascript files.
*/
let encodeHelper = new Array(1, 2, 4, 8, 16, 32);
let CompressMap = CreateCompressMap();
let DecompressMap = CreateDecompressMap();
/**
 * Compresses an uncompressed title list string. If the argument is already compressed, it is returned unaltered.
 * Example compressed: "c040002"
 * Example uncompressed: "31,8"
 * @param {String} plainText uncompressed title list
 * @returns {String} compressed title list
 */
export function FxListCompress(plainText)
{
	if (typeof plainText === "undefined" || plainText === null)
		plainText = "";
	if (plainText.length > 0 && plainText.charAt(0) === "c")
		return plainText; // Already has the marker indicating it is compressed!
	let ids = plainText.toString().split(',');
	let includedIds = new Array();
	let highestId = 0;
	for (let i = 0; i < ids.length; i++)
	{
		let id = parseInt(ids[i]);
		if (!isNaN(id) && id > -1 && id < 10000)
		{
			includedIds[id] = true;
			if (highestId < id)
				highestId = id;
		}
	}
	let highestIndex = highestId + 1;

	let sbCompText = new Array();
	sbCompText.length = Math.ceil(highestIndex / 6) + 1;
	sbCompText.push("c"); // This marks the string as a compressed title list.
	for (let i = 0; i < highestIndex; i += 6)
	{
		sbCompText.push(Encode(includedIds, i));
	}
	return sbCompText.join("");
}
/**
 * Decompresses a compressed title list string. If the argument is already uncompressed, it is returned unaltered.
 * Example compressed: "c040002"
 * Example uncompressed: "31,8"
 * @param {String} compText compressed title list
 * @returns {String} decompressed title list
 */
export function FxListDecompress(compText)
{
	if (typeof compText === "undefined" || compText === null)
		compText = "";
	if (compText.length > 0 && compText.charAt(0) === "c")
		compText = compText.substr(1); // Remove the letter c that marks this string as a compressed title list.
	else
		return compText; // Invalid string or already decompressed.
	let includedIds = new Array();
	includedIds.length = compText.length * 6;
	for (let i = 0; i < compText.length; i++)
	{
		Decode(includedIds, i * 6, compText.charAt(i));
	}
	return GetFxListFromArray(includedIds);
}
/**
 * Converts a title list string (compressed or not) to an array of integer title IDs.
 * Example argument: "c040002"
 * Example argument: "31,8"
 * Example return value: [8,31]
 * @param {String} compText compressed title list. Or uncompressed title list.
 * @returns {Array} array of integer title IDs
 */
export function TitleListStringToArray(compText)
{
	let str = FxListDecompress(compText);
	return str.split(',').map(s => parseInt(s));
}
function Encode(idArray, startIndex)
{
	let charIndex = 0;
	let i = 0;
	for (let j = startIndex; j < idArray.length && i < 6; j++)
	{
		if (idArray[j])
			charIndex |= encodeHelper[i];
		i++;
	}
	return CompressMap[charIndex];
}
function Decode(idArray, startIndex, charToDecode)
{
	let nCharToDecode = DecompressMap[atoi(charToDecode)];
	let i = 0;
	for (let j = startIndex; j < idArray.length && i < 6; j++)
	{
		idArray[j] = (nCharToDecode & encodeHelper[i]) > 0;
		i++;
	}
}
function GetFxListFromArray(fxArray)
{
	let fxList = new Array();
	for (let i = 0; i < fxArray.length; i++)
	{
		if (fxArray[i])
			fxList.push(i);
	}
	return fxList.join(",");
}
function CreateCompressMap()
{
	let chars = new Array();
	chars.length = 64;
	let index = 0;
	for (let asciiValue = 48; asciiValue < 58; asciiValue++)
		chars[index++] = itoa(asciiValue);
	for (let asciiValue = 65; asciiValue < 91; asciiValue++)
		chars[index++] = itoa(asciiValue);
	for (let asciiValue = 97; asciiValue < 123; asciiValue++)
		chars[index++] = itoa(asciiValue);
	chars[index++] = itoa(46);
	chars[index] = itoa(95);
	return chars;
}
function CreateDecompressMap()
{
	let shorts = new Array();
	shorts.length = 123;
	let index = 0;
	for (let asciiValue = 48; asciiValue < 58; asciiValue++)
		shorts[asciiValue] = index++;
	for (let asciiValue = 65; asciiValue < 91; asciiValue++)
		shorts[asciiValue] = index++;
	for (let asciiValue = 97; asciiValue < 123; asciiValue++)
		shorts[asciiValue] = index++;
	shorts[46] = index++;
	shorts[95] = index;
	return shorts;
}
function itoa(i)
{
	return String.fromCharCode(i);
}
function atoi(a)
{
	return a.charCodeAt();
}