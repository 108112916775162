/// This mixin is designed to keep the input focus inside the dialog box (for example, when the user presses tab).

/// To use this mixin, you must implement the method "SetFocus()", which should call
/// focus() on the first DOM element in your dialog box that is capable of receiving focus.


export default { /* ModalDialogAccessibilityMixin */
	data()
	{
		return {
			oldFocus: null,
			destroying: false,
			oldFocusId: null,
			oldFocusScrollPos: -1
		};
	},
	methods:
	{
		LostFocus(event)
		{
			if (!this.destroying)
				this.CallSetFocus();
		},
		CallSetFocus()
		{
			if (typeof this.SetFocus === "function")
			{
				this.SetFocus();
			}
			else if (process.env.NODE_ENV !== 'production')
			{
				console.error("Component implementing ModalDialogAccessibilityMixin does not implement SetFocus method.", this);
				alert("Component implementing ModalDialogAccessibilityMixin does not implement SetFocus method.");
			}
		},
		PreventDefault(event)
		{
			event.preventDefault();
			return false;
		}
	},
	mounted()
	{
		if (!this.oldFocus && !this.oldFocusId)
		{
			if (document.activeElement && document.activeElement.id)
			{
				this.oldFocusId = document.activeElement.id;
				this.oldFocusScrollPos = window.pageYOffset || document.documentElement.scrollTop;
			}
			else
				this.$data.oldFocus = document.activeElement;
		}

		this.CallSetFocus();
		// Disabling this because it just doesn't work as well as the focus traps in ModalDialog.vue
		//if (typeof window !== 'undefined')
		//{
		//	let mainLayout = window.mainLayoutRef || document.getElementById("appContentRoot");
		//	if (mainLayout)
		//		mainLayout.addEventListener("focusin", this.LostFocus, true);
		//}
	},
	beforeDestroy()
	{
		this.destroying = true;
		
		if (typeof window !== 'undefined')
		{
			let mainLayout = window.mainLayoutRef || document.getElementById("appContentRoot");
			if (mainLayout)
				window.mainLayoutRef.removeEventListener("focusin", this.LostFocus, true);
		}
		if (this.$data.oldFocus && this.$data.oldFocus.focus)
		{
			console.log("ModalDialogAccessibilityMixin focus restore: " + this.$data.oldFocus.tagName);
			this.$data.oldFocus.focus();
		}
		else if (this.oldFocusId && this.oldFocusScrollPos >= 0)
		{
			var oldFocus = document.getElementById(this.oldFocusId);
			if (oldFocus)
				oldFocus.focus();
			else
			{
				window.scrollTo(0, this.oldFocusScrollPos);
				setTimeout(() =>
				{
					oldFocus = document.getElementById(this.oldFocusId);
					if (oldFocus)
						oldFocus.focus();
				}, 200);
			}
		}
	}
};