import { render, staticRenderFns } from "./MetadataPanel.vue?vue&type=template&id=15a90bea&scoped=true&"
import script from "./MetadataPanel.vue?vue&type=script&lang=js&"
export * from "./MetadataPanel.vue?vue&type=script&lang=js&"
import style0 from "./MetadataPanel.vue?vue&type=style&index=0&id=15a90bea&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15a90bea",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\TFS2010\\WebApps\\Kent\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('15a90bea')) {
      api.createRecord('15a90bea', component.options)
    } else {
      api.reload('15a90bea', component.options)
    }
    module.hot.accept("./MetadataPanel.vue?vue&type=template&id=15a90bea&scoped=true&", function () {
      api.rerender('15a90bea', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "ClientApp/components/Search/Metadata/MetadataPanel.vue"
export default component.exports