import URLParse from 'url-parse';
import aesjs from 'aes-js';
import { ReportError } from 'tdsAppRoot/library/ErrorReporter.js';
import { Queue } from 'tdsAppRoot/library/Queue.js';
import { MakeToast } from 'tdsAppRoot/library/Toast.js';
import { base64url } from 'rfc4648'; // Many Base64 implementations in JS only work for valid UTF8 binary data. This one is supposedly correct.

var escape = document.createElement('textarea');
var _htmlToText = document.createElement('div');
export function EscapeHTML(html)
{
	escape.textContent = html;
	return escape.innerHTML;
}
export function UnescapeHTML(html)
{
	escape.innerHTML = html;
	return escape.textContent;
}
export function HtmlAttributeEncode(str)
{
	if (typeof str !== "string")
		return "";
	var sb = new Array("");
	for (var i = 0; i < str.length; i++)
	{
		var c = str.charAt(i);
		switch (c)
		{
			case '"':
				sb.push("&quot;");
				break;
			case '\'':
				sb.push("&#39;");
				break;
			case '&':
				sb.push("&amp;");
				break;
			case '<':
				sb.push("&lt;");
				break;
			case '>':
				sb.push("&gt;");
				break;
			default:
				sb.push(c);
				break;
		}
	}
	return sb.join("");
}
export function quoteattr(s, preserveCR)
{ // From https://stackoverflow.com/questions/7753448/how-do-i-escape-quotes-in-html-attribute-values
	preserveCR = preserveCR ? '&#13;' : '\n';
	return ('' + s) /* Forces the conversion to string. */
		.replace(/&/g, '&amp;') /* This MUST be the 1st replacement. */
		.replace(/'/g, '&apos;') /* The 4 other predefined entities, required. */
		.replace(/"/g, '&quot;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		/*
		You may add other replacements here for HTML only 
		(but it's not necessary).
		Or for XML, only if the named entities are defined in its DTD.
		*/
		.replace(/\r\n/g, preserveCR) /* Must be before the next replacement. */
		.replace(/[\r\n]/g, preserveCR);
}
export function HTMLToText(html)
{
	_htmlToText.innerHTML = html;
	return _htmlToText.innerText;
}
/**
 * Gets a fuzzy time string accurate within 1 year.
 * @param {Number} ms milliseconds
 * @returns {String} A string roughly describing a time period.
 */
export function GetFuzzyTime(ms)
{

	var years = Math.round(ms / 31536000000);
	if (years > 0)
		return years + " year" + (years === 1 ? "" : "s");
	var months = Math.round(ms / 2628002880);
	if (months > 0)
		return months + " month" + (months === 1 ? "" : "s");
	var weeks = Math.round(ms / 604800000);
	if (weeks > 0)
		return weeks + " week" + (weeks === 1 ? "" : "s");
	return GetFuzzyTime_Days(ms);
}
/**
 * Gets a fuzzy time string accurate within 1 day.
 * @param {Number} ms milliseconds
 * @returns {String} A string roughly describing a time period.
 */
export function GetFuzzyTime_Days(ms)
{
	var days = Math.round(ms / 86400000);
	if (days > 0)
		return days + " day" + (days === 1 ? "" : "s");
	var hours = Math.round(ms / 3600000);
	if (hours > 0)
		return hours + " hour" + (hours === 1 ? "" : "s");
	var minutes = Math.round(ms / 60000);
	if (minutes > 0)
		return minutes + " minute" + (minutes === 1 ? "" : "s");
	return "less than 1 minute";
}
export function msToTime(totalMs, includeMs)
{
	var ms = totalMs % 1000;
	var totalS = totalMs / 1000;
	var totalM = totalS / 60;
	var totalH = totalM / 60;
	var s = Math.floor(totalS) % 60;
	var m = Math.floor(totalM) % 60;
	var h = Math.floor(totalH);

	var retVal;
	if (h !== 0)
		retVal = h + ":" + m.toString().padLeft(2, "0");
	else
		retVal = m;

	retVal += ":" + s.toString().padLeft(2, "0");

	if (includeMs)
		retVal += '<span style="opacity:0.6;">.' + ms.toString().padLeft(3, "0") + "</span>";

	return retVal;
}
export function GetElementObjOffset(ele)
{
	if (typeof document === 'undefined')
		return -1;
	if (!ele)
		return -2;
	if (ele.offsetParent === null)
		return -3;
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	if (BrowserIsIE() && ele.getBoundingClientRect().top === ele.getBoundingClientRect().bottom && ele.parentElement) // fix for IE, which otherwise aligns the top of the window to the bottom of the target line, making that line invisible.
		ele = ele.parentElement;
	return ele.getBoundingClientRect().top + scrollTop;
}
export function GetElementOffset(elementid)
{
	if (typeof document === 'undefined')
		return -1;
	let ele = document.getElementById(elementid);
	if (!ele && elementid.length > 1 && elementid[0] == 'p')
		ele = document.getElementById("cell" + elementid.substr(1));
	return GetElementObjOffset(ele);
}


export function SupportsSticky()
{
	if (BrowserIsLegacyEdge())
		return false; // Edge supports sticky, but it flickers on scroll.
	var el = document.createElement('a'),
		mStyle = el.style;
	mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
	return mStyle.position.indexOf('sticky') !== -1;
}

export function range(min, max)
{ // because v-for doesn't let us start at an arbitrary number, we have to generate a list for it to iterate over.  If vue adds this feature later, we can remove this.
	var array = [],
		j = 0;
	for (var i = min; i <= max; i++)
	{
		array[j] = i;
		j++;
	}
	return array;
}

export function Clamp(i, min, max)
{
	/// <summary>Clamps number [i] between [min] and [max].</summary>
	if (i < min)
		return min;
	if (i > max)
		return max;
	return i;
}

export function getCSSRule(sheetId, ruleName, deleteFlag)
{
	if (document.styleSheets)
	{
		ruleName = ruleName.toLowerCase();
		for (let i = 0; i < document.styleSheets.length; i++)
		{
			let styleSheet = document.styleSheets[i];
			if (sheetId && sheetId !== styleSheet.id)
				continue;
			let ii = 0;
			let cssRule = false;
			do
			{
				if (styleSheet.cssRules)
					cssRule = styleSheet.cssRules[ii];
				else
					cssRule = styleSheet.rules[ii];
				if (cssRule)
				{
					if (cssRule.selectorText.toLowerCase() === ruleName)
					{
						if (deleteFlag === 'delete')
						{
							if (styleSheet.deleteRule)
								styleSheet.deleteRule(ii);
							else
								styleSheet.removeRule(ii);
							return true;
						}
						else
							return cssRule;
					}
				}
				ii++;
			} while (cssRule)
		}
	}
	return false;
}

export function deleteCSSRule(sheetId, ruleName)
{
	return getCSSRule(sheetId, ruleName, 'delete');
}

export function getOrAddCSSRule(sheetId, ruleName)
{
	let rule = false;
	if (document.styleSheets)
	{
		rule = getCSSRule(sheetId, ruleName);
		if (!rule)
		{
			if (document.styleSheets.length > 0)
			{
				let styleSheet = document.styleSheets[0];
				if (sheetId)
				{
					for (let i = 0; i < document.styleSheets.length; i++)
					{
						if (document.styleSheets[i].id === sheetId)
						{
							styleSheet = document.styleSheets[i];
							break;
						}
					}
				}
				if (styleSheet.addRule)
					styleSheet.addRule(ruleName, null, 0);
				else
					styleSheet.insertRule(ruleName + ' { }', 0);
				rule = getCSSRule(sheetId, ruleName);
			}
		}
	}
	return rule;
}
/**
 * Given a route object from vue-router, determines if any of the matched routes contains a route with the specified path. E.g. for the route "/Kent/history/searches", the paths "Kent", "history", and "searches" are contained.  Uses case-insensitive comparison, so "HISTORY" would also match.
 * @param {Object} route A route object from vue-router.
 * @param {String} path A string representing fragment of the path.
 * @returns {Boolean} true if the route contains the path fragment.
 */
export function RouteContainsPath(route, path)
{
	if (route && route.matched && route.matched.length > 0 && path)
	{
		path = path.toLowerCase();
		for (let i = 0; i < route.matched.length; i++)
			if (route.matched[i].path.split('/').some(m => m.toLowerCase() === path))
				return true;
	}
	return false;
}
/**
 * Given a route object from vue-router, returns the first matched route which causes the provided test function to return true.
 * @param {Object} route A route object from vue-router.
 * @param {Function} testFunc A function accepting a route.matched[…] object as an argument, returning either true or false.
 * @returns {Object} A route.matched[…] object or null.
 */
export function GetRouteMatched(route, testFunc)
{
	if (route && route.matched && route.matched.length > 0 && typeof testFunc === "function")
	{
		for (let i = 0; i < route.matched.length; i++)
			if (testFunc(route.matched[i]))
				return route.matched[i];
	}
	return null;
}
/**
 * TDSUtil.js contains several String extension methods, which are available as long as any function from TDSUtil has been imported.  This function exists to make it more clear why an unused function might be imported.
 */
export function ImportingForTheStringExtensions()
{
}

export function CleanCurrentUrl(loginAppVersion)
{
	var uAddr = URLParse(window.location.href, true);
	let qsChanged = false;
	if (uAddr.query && Object.keys(uAddr.query).length > 0)
	{
		// These are case sensitive
		let startCount = Object.keys(uAddr.query).length;
		delete uAddr.query.un;
		delete uAddr.query.pw;
		delete uAddr.query.grpalias;
		delete uAddr.query.productTypeInt;
		if (!loginAppVersion)
		{
			delete uAddr.query.path;
			delete uAddr.query.suppressBrowserWarning;
			delete uAddr.query.target;
			delete uAddr.query.SessionID;
		}
		delete uAddr.query.entityid;
		delete uAddr.query.product;
		delete uAddr.query.sessionid;
		if (!loginAppVersion && uAddr.pathname.indexOf("/error") === -1)
			delete uAddr.query.errMsg;
		delete uAddr.query.fromPending;
		delete uAddr.query.SessionId;
		delete uAddr.query.isAutoLogon;
		let endCount = Object.keys(uAddr.query).length;
		if (endCount !== startCount)
		{
			uAddr.set("query", uAddr.query);
			qsChanged = true;
		}
	}
	if (qsChanged && typeof history !== 'undefined')
	{
		try
		{
			history.replaceState(history.state, document.title, uAddr.href);
		}
		catch (ex)
		{
			throw new Error("Exception thrown when replacing history state: " + ex.toString());
		}
	}
}
/**
 * Returns the value of the specified query string parameter using case-insensitive matching of the key. If the parameter is not found, returns empty string.
 * @param {String} key case-insensitive query string parameter name
 * @returns {String} query string parameter value or empty string
 */
export function QueryStrGetParam(key)
{
	let params = {};
	window.location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, paramKey, value) =>
	{
		params[paramKey.toLowerCase()] = decodeURIComponent(value);
	});
	if (typeof params[key.toLowerCase()] !== 'undefined')
		return params[key.toLowerCase()];
	return "";
}
/**
 * Returns the value of the specified query string parameter using case-insensitive matching of the key. If the parameter is not found, returns empty string.
 * @param {Object} route The route object.
 * @param {String} key case-insensitive query string parameter name
 * @returns {String} query string parameter value or empty string
 */
export function RouteGetQueryParam(route, key)
{
	let keyLower = key.toLowerCase();
	for (let queryKey in route.query)
	{
		if (route.query.hasOwnProperty(queryKey) && queryKey.toLowerCase() === keyLower)
			return route.query[queryKey];
	}
	return "";
}
if (!String.prototype.endsWith)
{
	String.prototype.endsWith = function (search, this_len)
	{
		if (this_len === undefined || this_len > this.length)
		{
			this_len = this.length;
		}
		return this.substring(this_len - search.length, this_len) === search;
	};
}
if (!String.prototype.startsWith)
{
	String.prototype.startsWith = function (search)
	{
		if (!search || !search.length)
			return false;
		if (this.length < search.length)
			return false;
		return this.substring(0, search.length) === search;
	};
}
if (!String.prototype.endsWithCaseInsensitive)
{
	String.prototype.endsWithCaseInsensitive = function (search, this_len)
	{
		if (this_len === undefined || this_len > this.length)
		{
			this_len = this.length;
		}
		return this.substring(this_len - search.length, this_len).toLowerCase() === search.toLowerCase();
	};
}
if (!String.prototype.padLeft)
	String.prototype.padLeft = function (len, c)
	{
		var pads = len - this.length;
		if (pads > 0)
		{
			var sb = [];
			var pad = c || "&nbsp;";
			for (var i = 0; i < pads; i++)
				sb.push(pad);
			sb.push(this);
			return sb.join("");
		}
		return this;

	};
if (!String.prototype.padRight)
	String.prototype.padRight = function (len, c)
	{
		var pads = len - this.length;
		if (pads > 0)
		{
			var sb = [];
			sb.push(this);
			var pad = c || "&nbsp;";
			for (var i = 0; i < pads; i++)
				sb.push(pad);
			return sb.join("");
		}
		return this;
	};
/**
 * Returns a new string that has removed any occurrences of the specified characters from the beginning and end of this string.
 * @param {String} chars A string containing the char(s) to trim.
 * @returns {String} A new string with chars trimmed.
 */
String.prototype.cstrim = function (chars)
{
	chars = escapeRegExp(chars);
	return this.replace(new RegExp("^[" + chars + "]+|[" + chars + "]+$", "g"), "");
};
if (!Number.prototype.padLeft)
	Number.prototype.padLeft = function (len, c)
	{
		return this.toString().padLeft(len, c);
	};
if (!Number.prototype.padRight)
	Number.prototype.padRight = function (len, c)
	{
		return this.toString().padRight(len, c);
	};

/**
 * Gets document relative offsets for the given dom node.
 * @param {any} node The dom element
 * @returns {object} object with left, top, right, bottom, width, and height
 */
export function FindOffsets(node)
{
	//var element = $(node);
	//var offset = element.offset();
	let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
	let scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
	let rect = node.getBoundingClientRect();
	return {
		left: rect.left + scrollLeft, top: rect.top + scrollTop,
		width: rect.width, height: rect.height,
		right: rect.left + scrollLeft + rect.width, bottom: rect.top + scrollTop + rect.height,
		centerX: rect.left + scrollLeft + (rect.width / 2), centerY: rect.top + scrollTop + (rect.height / 2)
	};
}

export function PointInsideObject(pleft, ptop, object)
{
	if (!object)
		return false;
	var ofsts = FindOffsets(object);
	ofsts.top = Math.floor(ofsts.top);
	ofsts.left = Math.floor(ofsts.left);
	if (pleft >= ofsts.left && ptop >= ofsts.top)
	{
		if (pleft < ofsts.left + object.offsetWidth)
			if (ptop < ofsts.top + object.offsetHeight)
				return true;
	}
	return false;
}
export function PointInsideAnyOf(pleft, ptop, objects)
{
	if (!objects || objects.length < 1)
		return false;
	for (var i = 0; i < objects.length; i++)
	{
		if (PointInsideObject(pleft, ptop, objects[i]))
			return true;
	}
	return false;
}
export function PointInsideClass(pleft, ptop, className)
{
	if (!className)
		return false;
	let objects = document.getElementsByClassName(className);
	return PointInsideAnyOf(pleft, ptop, objects);
}

function FindOffsetRoot(ele) // Finds the first paragraph ancestor of the given element.
{
	if (!ele)
		return null;
	if (ele.getAttribute && ele.getAttribute("offsetWithoutTags"))
		return ele;
	return FindOffsetRoot(ele.parentElement);
}
export function FindParagraph(ele) // Finds the first paragraph ancestor of the given element.
{
	if (!ele)
		return null;
	if (ele.className && ele.className.indexOf("para") != -1)
		return ele;
	return FindParagraph(ele.parentElement);
}
//function FindAncestor(ele, searchForAncestor)
//{
//	if (!ele)
//		return null;
//	if (ele == searchForAncestor)
//		return searchForAncestor;
//	return FindAncestor(ele.parentElement);
//}

export function TruncateString(str, maxLength)
{
	if (!str)
		return str;
	let truncatedStr = str;
	if (truncatedStr.length > maxLength) // Max number of characters before truncation is applied.
	{
		let i = maxLength - 16; // Where to try to truncate.  Actual truncation point will be first space before this, or index 50, whichever is found first.
		while (i > 50 && truncatedStr[i] !== ' ')
			i--;
		truncatedStr = truncatedStr.substr(0, i) + " …";
	}
	return truncatedStr;
}

export function ClearSelection()
{
	if (window.getSelection)
	{
		if (window.getSelection().empty)
		{  // Chrome
			window.getSelection().empty();
		} else if (window.getSelection().removeAllRanges)
		{  // Firefox
			window.getSelection().removeAllRanges();
		}
	} else if (document.selection)
	{  // IE?
		document.selection.empty();
	}
}


/**
 * Removes user highlight markup from the dom.  Used when deleting a highlight, in order to make the deletion immediately take effect
 * without reloading the page.
 * @param {any} favid Id of the highlight / favorite.
 */
export function RemoveHighlightMarkup(favid)
{
	var annotation = document.getElementById("annotation" + favid);
	if (annotation && annotation.comp)
		annotation.comp.DisableTeleport();

	setTimeout(() =>
	{
		let focusNode = document.getElementById("highlightFocus" + favid);
		if (focusNode)
			focusNode.parentNode.removeChild(focusNode);
		var nodes = document.getElementsByName("userHighlight" + favid);
		var allNodesToBeRemoved = [];
		for (var i = 0; i < nodes.length; i++)
		{
			var nodeToBeRemoved = nodes[i];
			allNodesToBeRemoved.push(nodeToBeRemoved);
		}
		for (var i = 0; i < allNodesToBeRemoved.length; i++)
		{
			var nodeToBeRemoved = allNodesToBeRemoved[i];
			while (nodeToBeRemoved.firstChild)
			{
				nodeToBeRemoved.parentNode.insertBefore(nodeToBeRemoved.firstChild,
					nodeToBeRemoved);
			}

			nodeToBeRemoved.parentNode.removeChild(nodeToBeRemoved);
		}
		var imgs = document.querySelectorAll(".userHighlightImg" + favid);
		for (var i = 0; i < imgs.length; i++)
		{
			imgs[i].classList.remove("userHighlightImg" + favid);
			imgs[i].classList.remove("imgHighlightColorBlue");
			imgs[i].classList.remove("imgHighlightColorOrange");
			imgs[i].classList.remove("imgHighlightColorPurple");
			imgs[i].classList.remove("imgHighlightColorRed");
		}
	}, 0);
}


export function GetOffsetRootOffsets(startingNode)
{
	let node = startingNode.offsetParent;
	let offsetRoot = null;
	//let offsetRootTop = null, offsetRootLeft = null;
	while (node)
	{
		let parentPosStyle = window.getComputedStyle(node).position;
		if (parentPosStyle == "relative" || parentPosStyle == "absolute")
		{
			offsetRoot = node;
			break;
		}
		node = node.offsetParent;
	}
	if (offsetRoot)
	{
		let offsetRootOffsets = FindOffsets(offsetRoot);
		//offsetRootTop = offsetRootOffsets.top;
		//offsetRootLeft = offsetRootOffsets.left;
		return offsetRootOffsets;
	}
	return { top: null, left: null, bottom: null, right: null, width: null, height: null };
}

/// range: A javascript range object.
export function InjectHighlightMarkup(range, id, color)
{
	var highlightRanges = [];
	var highlightImages = [];
	IterateNodes(range.startContainer, range.endContainer, range.startOffset, range.endOffset, (currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail) =>
	{

	}, (currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail) =>
	{
		if (inInvisibleElement || documentChanged || inGeneratedContent || inVideoPage || inAnnotation)
			return;
		if (state.active && currentNode.tagName == "IMG")
		{
			highlightImages.push({ ele: currentNode, isEntity: currentNode.parentNode.tagName == "E" });
		}
		//else if (state.active && state.foundStopNode && currentNode.childNodes.length > 0 && currentNode.childNodes[0].tagName == "IMG")
		//{
		//	//if (currentNode === range.endContainer)
		//	//{
		//	//	for (var i = 0; i < currentNode.childNodes.length && i < range.endOffset; i++)
		//	//	{
		//	//		let ele = currentNode.childNodes[i];
		//	//		if (ele.tagName == "IMG")
		//	//			highlightImages.push({ ele: ele, isEntity: currentNode.tagName == "E" });
		//	//	}
		//	//}
		//}
		else if (state.active && currentNode.nodeType == TEXT_NODE_TYPE)
		{
			var newRange = document.createRange();
			if (currentNode == range.startContainer && currentNode == range.endContainer)
			{
				newRange.setStart(currentNode, range.startOffset);
				newRange.setEnd(currentNode, range.endOffset);
			}
			else if (currentNode == range.startContainer)
			{
				newRange.setStart(currentNode, range.startOffset);
				newRange.setEnd(currentNode, currentNode.textContent.length);
			}
			else if (currentNode == range.endContainer)
			{
				newRange.setStart(currentNode, 0);
				newRange.setEnd(currentNode, range.endOffset);
			}
			else
			{
				newRange.setStart(currentNode, 0);
				newRange.setEnd(currentNode, currentNode.textContent.length); // Using wholeText here is an error and causes the algorithm to break when encountering the leftover empty text nodes that happen after a client side highlight removal.
			}
			highlightRanges.push(newRange);
		}

	});

	var i = 0;
	let focusSpan = document.createElement("span");
	focusSpan.setAttribute("tabindex", 0);
	focusSpan.setAttribute("highlightId", id);
	focusSpan.id = "highlightFocus" + id;
	focusSpan.setAttribute("onfocusin", "OnHighlightFocusIn('" + id + "')");
	focusSpan.setAttribute("onfocusout", "OnHighlightFocusOut('" + id + "')");
	focusSpan.setAttribute("onkeypress", "HandleHighlightKeypress('" + id + "', event)");
	while (i < highlightRanges.length)
	{
		let highlightSpan = document.createElement("span");
		highlightSpan.className = "userHighlight highlightColor" + color;
		highlightSpan.setAttribute("name", "userHighlight" + id);
		//highlightSpan.setAttribute("onclick", "HandleHighlightClick('" + id + "')");
		highlightSpan.setAttribute("onmouseover", "OnHighlightMouseOver('" + id + "')");
		highlightSpan.setAttribute("onmouseleave", "OnHighlightMouseLeave()");
		//highlightSpan.setAttribute("ontouchend", "HandleHighlightClick('" + id + "', event)");
		highlightRanges[i].surroundContents(highlightSpan);
		if (focusSpan)
			highlightSpan.appendChild(focusSpan);
		focusSpan = null;
		i++;
	}
	i = 0;
	while (i < highlightImages.length)
	{
		/// img.  Might actually be an E tag with and IMG inside it.
		var img = highlightImages[i].ele;



		if (highlightImages[i].isEntity)
		{
			/// wrapper
			let highlightSpan = document.createElement("span");
			highlightSpan.className = "userHighlight highlightColor" + color;
			highlightSpan.setAttribute("name", "userHighlight" + id);
			highlightSpan.style.backgroundColor = color;
			//highlightSpan.setAttribute("onclick", "HandleHighlightClick('" + id + "')");
			//highlightSpan.setAttribute("ontouchend", "HandleHighlightClick('" + id + "', event)");

			// insert wrapper before img in the DOM tree
			img.parentNode.insertBefore(highlightSpan, img);

			if (focusSpan)
				highlightSpan.appendChild(focusSpan);
			focusSpan = null;

			// move img into wrapper
			highlightSpan.appendChild(img);
		}
		else if (!img.classList.contains("userHighlightImg" + id))
		{
			img.classList.add("userHighlightImg" + id);
			img.classList.add("imgHighlightColor" + color);
		}

		i++;
	}
}

export function CombineClientRectOffsets(clientRects)
{
	let top = -1;
	let bottom = -1;
	let left = -1;
	let right = -1;
	for (let i = 0; i < clientRects.length; i++)
	{
		let offsets = clientRects[i];
		if (top == -1 || top > offsets.top)
			top = offsets.top;
		if (bottom == -1 || bottom < offsets.bottom)
			bottom = offsets.bottom;
		if (left == -1 || left > offsets.left)
			left = offsets.left;
		if (right == -1 || right < offsets.right)
			right = offsets.right;
	}
	return {
		left: left, top: top,
		width: right - left, height: bottom - top,
		right: right, bottom: bottom,
		centerX: left + ((right - left) / 2), centerY: top + ((bottom - top) / 2)
	};
}

/// This gets X, Y, width, height, etc coordinates for a highlight dom object.
export function GetOffsetsForHighlightMarkup(highlightId)
{

	let highlightDomObjs = document.getElementsByName("userHighlight" + highlightId);
	if (highlightDomObjs && highlightDomObjs.length > 0)
	{
		let top = -1;
		let bottom = -1;
		let left = -1;
		let right = -1;
		for (let i = 0; i < highlightDomObjs.length; i++)
		{
			let offsets = FindOffsets(highlightDomObjs[i]);

			// Thanks to filter tabs, it is possible for highlight content to actually be invisible at the moment and it's location not relevant.  We have to exclude such content from the calculation.
			if (offsets.height == 0)
				continue;

			if (top == -1 || top > offsets.top)
				top = offsets.top;
			if (bottom == -1 || bottom < offsets.bottom)
				bottom = offsets.bottom;
			if (left == -1 || left > offsets.left)
				left = offsets.left;
			if (right == -1 || right < offsets.right)
				right = offsets.right;
		}
		return {
			left: left, top: top,
			width: right - left, height: bottom - top,
			right: right, bottom: bottom,
			centerX: left + ((right - left) / 2), centerY: top + ((bottom - top) / 2)
		};
	}
}

function GetFFSelCoords()
{
	let sel = window.getSelection();
	if (!sel.getRangeAt)
		return;
	var sText = sel.toString();
	if (sel.rangeCount > 0)
	{
		var result = null;
		var range = sel.getRangeAt(0);
		var s, e;
		s = range.startOffset;
		e = range.endOffset;

		var leadIn = 0;
		var totalHighlightLengthInTvmlOffset = 0; // "without tags" offset version.
		var startFullOffsetWithoutTags = -1;
		var subDocAddress = null;
		var spansDocuments = false;
		var foundStopNode = false;
		var selectionIncludesThumbnailCaption = false; // True if the selection includes, but is not entirely within, a thumbnail caption.
		var selectionIncludesEditableTextbox = false; // If the selection is around an annotation, this gets set to true and the highlight attempt is invalid.

		if (appContext.enableHighlightFeature)
		{
			IterateNodes(range.startContainer, range.endContainer, s, e, (currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail) =>
			{
				if (state.foundStopNode)
					foundStopNode = true;
				subDocAddress = state.subDocAddress;
				if (documentChanged && state.active)
					spansDocuments = true;
				if (foundThumbnail && state.active)
					selectionIncludesThumbnailCaption = true;
				if (inInvisibleElement || documentChanged || inGeneratedContent || inVideoPage)
					return;
				if (inAnnotation)
				{
					if (state.active)
						selectionIncludesEditableTextbox = true;
					return;
				}
				var offsetWithoutTags = currentNode.getAttribute && currentNode.getAttribute("offsetwithouttags") ? parseInt(currentNode.getAttribute("offsetwithouttags")) : -1;
				if (startFullOffsetWithoutTags < 0)
					startFullOffsetWithoutTags = offsetWithoutTags;
				if (offsetWithoutTags >= 0 && typeof (state.offsetWithoutTags) == 'undefined')
					state.offsetWithoutTags = offsetWithoutTags;
				var delta = 0;
				if (offsetWithoutTags >= 0 && state.offsetWithoutTags != offsetWithoutTags)
					delta = offsetWithoutTags - state.offsetWithoutTags;

				var cntThisTag = 0;

				// Currently, if the end node is not a text node, it does not get counted at all.  Unfortunately, this CAN happen.
				if (currentNode.nodeType == TEXT_NODE_TYPE)
				{
					var bytes;
					if (currentNode.parentElement.tagName == "E")
						cntThisTag += currentNode.textContent.length;
					else
					{
						let textWithinBounds = null;
						if (currentNode == range.startContainer && currentNode == range.endContainer)
							textWithinBounds = currentNode.textContent.substr(s, e - s);
						else if (currentNode == range.startContainer)
							textWithinBounds = currentNode.textContent.substr(s, currentNode.textContent.length - s);
						else if (currentNode == range.endContainer)
							textWithinBounds = currentNode.textContent.substr(0, e);
						else
							textWithinBounds = currentNode.textContent;
						cntThisTag += ByteSize(textWithinBounds);
					}
				}
				else if (currentNode.tagName == "IMG")
				{

					if (currentNode.getAttribute("additionalByte") && currentNode.getAttribute("additionalByte") == "true")
						cntThisTag += 2;
					else
						cntThisTag += 1;
				}
				else if (currentNode == range.endContainer) // Non text-node end tags.
				{
					// In this specific circumstance, [currentNode] is likely a container containing images and/or textNodes, and [e] is the number of child nodes until the end of the selection.
					// In this case, a textNode child would be counted as 1 regardless of how long it actually is.

					// The startNode may or may not be this node or a child of this node.  There may be multiple levels of child nodes.
					//cntThisTag += e;
				}

				if (state.active)
				{
					if (currentNode == range.startContainer && currentNode == range.endContainer)
					{
						if (delta > 0)
							throw Error("Unexpectedly found an offset delta on the range endContainer");
						leadIn += s + delta;
					}
					else if (currentNode == range.startContainer)
					{
						leadIn += s + delta;
						state.offsetWithoutTags += s;
					}

					// delta is only nonzero if the current node had an offsetwithouttags attribute, but if the current node is the start node, then the delta is not part of the highlight length.
					totalHighlightLengthInTvmlOffset += cntThisTag + (currentNode == range.startContainer ? 0 : delta);
					if (typeof (state.offsetWithoutTags) !== 'undefined')
						state.offsetWithoutTags += cntThisTag + delta;
				}
				else
				{
					leadIn += cntThisTag + delta;
					if (typeof (state.offsetWithoutTags) !== 'undefined')
						state.offsetWithoutTags += cntThisTag + delta;
				}
			}, (currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail) =>
			{
				if (state.foundStopNode)
					foundStopNode = true;
				if (inInvisibleElement || documentChanged || inGeneratedContent || inVideoPage || inAnnotation)
					return;
				if (!state.foundStopNode && currentNode.getAttribute && currentNode.getAttribute("returnCnt"))
				{
					if (state.active)
						totalHighlightLengthInTvmlOffset += parseInt(currentNode.getAttribute("returnCnt"));
					if (typeof (state.offsetWithoutTags) !== 'undefined')
						state.offsetWithoutTags += parseInt(currentNode.getAttribute("returnCnt"));

					if (!state.active)
						leadIn += parseInt(currentNode.getAttribute("returnCnt"));
				}

			});
		}

		var startRoot = FindOffsetRoot(range.startContainer); // The first paragraph ancestor of the given element.
		var startRootOffset = startRoot && startRoot.getAttribute ? parseInt(startRoot.getAttribute("offsetWithoutTags")) : null;
		//var leadInOffset;
		//leadInOffset = GetNodeTextSizeFromTo(startRoot, 0, -1, range.startContainer, s);
		//var highlightStartOffsetWithoutTags = startRootOffset + leadInOffset;
		//var textSize = GetNodeTextSizeFromTo(range.startContainer, s, highlightStartOffsetWithoutTags, range.endContainer, e);


		if (startRootOffset !== null && isNaN(startRootOffset))
		{
			ReportError(appRoot.$store.getters.urlRoot, "Failed to parse paragraph offset from paragraph id: " + startRoot.id, undefined, undefined, appRoot.$store.state.sid);
		}
		else if (appContext.enableHighlightFeature)
		{
			console.log("NEW METHOD: highlight start offset: " + (startFullOffsetWithoutTags + leadIn) + "  length: " + totalHighlightLengthInTvmlOffset);
		}

		if (!foundStopNode)
			spansDocuments = true; // if a selection starts in a subdoc or thumbnail and ends after it, the end node will never be reached.  This is expected to be the only case this line can be hit.

		if (typeof range.getClientRects !== "undefined")
		{ // This section is required by IE11
			result = CombineClientRectOffsets(range.getClientRects());

			var scrolledTo = window.document.body.scrollTop;
			result.top += scrolledTo;
		}
		if (!result)
		{
			// Fallback.  Doesn't account for the size of the range at all.  This results in the selection menu appearing directly under the top line of the selection no matter how big it is.
			var newRange = range.cloneRange();
			newRange.collapse(false);
			var dummy = document.createElement("span");
			dummy.style.display = "inline-block";
			dummy.innerHTML = "&nbsp;";
			dummy.style.width = "0px";
			newRange.insertNode(dummy);
			var offsets = FindOffsets(dummy);
			var parent = dummy.parentNode;
			dummy.parentNode.removeChild(dummy);
			parent.normalize();
		}

		return {
			result: result,
			highlight: !appContext.enableHighlightFeature || selectionIncludesEditableTextbox ? null : {
				highlightStartOffsetWithoutTags: startFullOffsetWithoutTags + leadIn,
				textSize: totalHighlightLengthInTvmlOffset,
				startRoot: startRoot,
				subDocAddress: subDocAddress,
				range: range.cloneRange(),
				highlightText: sText,
				spansDocuments: spansDocuments,
				selectionIncludesThumbnailCaption: selectionIncludesThumbnailCaption,
				sel: { anchorNode: sel.anchorNode, anchorOffset: sel.anchorOffset, focusNode: sel.focusNode, focusOffset: sel.focusOffset }
			}
		};
	}
	return null;
}

const cloneSelection = (sel = document.getSelection()) =>
{
	const cloned = {}

	for (const p in sel)
		cloned[p] = sel[p]

	return cloned
}

export function RestoreSelection(selection)
{
	window.getSelection().setBaseAndExtent(selection.anchorNode, selection.anchorOffset, selection.focusNode, selection.focusOffset);
}

const TEXT_NODE_TYPE = 3; // Shouldn't there be an enum somewhere with this?

/// Get size of string in bytes instead of characters.
const ByteSize = str => new Blob([str]).size;


/// startNode and stopNode are both visited, as well as every node in between.
/// visitIn:  A callback function, called when first entering the node, before processing its children.
/// visitOut:  A callback function, called after processing the node's children.  The exception is the stopNode, for which the children are not processed at all.  visitOut is called directly after visitIn for that node.
function IterateNodes(startNode, stopNode, startOffset, stopOffset, visitIn, visitOut)
{
	// Climb up to paragraph level first from startNode.
	// Recursively navigate children, then next sibling.  Keep track of offsetWithoutTags.  Set state.active = true once startNode is encountered.
	// Abort all iteration once stopNode is visited.
	// Skips thumbnail boxes.

	var node = startNode;

	while (node
		&& !(node.className
			&& (node.className.indexOf("para") != -1
				|| node.className.indexOf("ppThumbnailCaption") != -1)))
		node = node.parentElement;

	if (!node)
	{
		console.log("Couldn't find paragraph or thumbnail caption element above selection.");
		return;
	}

	var subDocAddress = node.getAttribute("subdocaddress");
	var rootDocAddress = subDocAddress;
	if (!rootDocAddress)
		rootDocAddress = "";
	IterateNodesRecursive(node, startNode, stopNode, startOffset, stopOffset, visitIn, visitOut, rootDocAddress, { active: false, foundStopNode: false, subDocAddress: subDocAddress }, true, false, false, false, false, false, false);

}

/// Do not call this one directly.  Must be called from IterateNodes to work properly.
function IterateNodesRecursive(currentNode, startNode, stopNode, startOffset, stopOffset, visitIn, visitOut, rootDocAddress, state, rootLevel, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail)
{
	if (currentNode == startNode)
		state.active = true;

	let startIdx = 0;
	let endIdx = typeof (currentNode.childNodes) !== 'undefined' ? currentNode.childNodes.length : 0;
	if (currentNode == startNode && currentNode.nodeType !== TEXT_NODE_TYPE)
		startIdx = startOffset;
	if (currentNode == stopNode)
	{
		if (currentNode.nodeType !== TEXT_NODE_TYPE)
		{
			endIdx = stopOffset;
		}
	}

	// inGeneratedContent should turn on if we hit a ppAllThumbs, but should turn off if we hit a ppThumbnailCaption.
	if (currentNode.className && currentNode.className.toLowerCase
		&& (currentNode.className.toLowerCase().indexOf("ppthumbnailbox") != -1 || currentNode.className.toLowerCase().indexOf("ppallthumbs") != -1 || currentNode.className.toLowerCase().indexOf("ffall") != -1))
	{
		foundThumbnail = true;
		inGeneratedContent = true;
	}
	else if (currentNode.classList && currentNode.classList.contains("ppThumbnailCaption"))
	{
		inGeneratedContent = false;
	}
	else if (currentNode.classList && currentNode.classList.contains("ppThumbnailImageCell"))
		inGeneratedContent = true;
	else if (currentNode.classList && currentNode.classList.contains("toolsMenu"))
		inGeneratedContent = true;

	if (currentNode.id == "videoPage")
		inVideoPage = true;

	if (currentNode.classList && currentNode.classList.contains("annotationRoot"))
		inAnnotation = true;

	if (currentNode.style && currentNode.style.display == "none")
		inInvisibleElement = true;

	if (currentNode.getAttribute && currentNode.getAttribute("subdocaddress"))
	{
		var subDocAddress = currentNode.getAttribute("subdocaddress");
		if (!subDocAddress)
			subDocAddress = "";
		if (subDocAddress != rootDocAddress)
			documentChanged = true;
	}


	visitIn(currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail);

	for (var i = startIdx; i < endIdx; i++)
	{
		IterateNodesRecursive(currentNode.childNodes[i], startNode, stopNode, startOffset, stopOffset, visitIn, visitOut, rootDocAddress, state, false, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail);
		if (state.foundStopNode && currentNode != stopNode)
			break;
	}
	if (currentNode == stopNode)
		state.foundStopNode = true;

	visitOut(currentNode, state, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail);

	if (rootLevel && currentNode.nextSibling && !state.foundStopNode)
	{
		IterateNodesRecursive(currentNode.nextSibling, startNode, stopNode, startOffset, stopOffset, visitIn, visitOut, rootDocAddress, state, rootLevel, inGeneratedContent, inVideoPage, inAnnotation, inInvisibleElement, documentChanged, foundThumbnail);
	}

}



export function GetSelectedText(requiredDomAncestorList)
{
	if (typeof document === 'undefined' || typeof window === 'undefined')
		return null;
	let selTxt = "";
	let validDomAncestor = null;
	if (document.selection)
		selTxt = document.selection.createRange().text;
	else
	{
		selTxt = (window.getSelection ? window.getSelection() : document.getSelection());
		if (selTxt.focusNode && requiredDomAncestorList)
		{
			// Check ancestors
			let n = selTxt.focusNode;
			while (n && !NodeIsPrivate(n))
			{
				for (var i = 0; i < requiredDomAncestorList.length; i++)
				{
					if (n.classList && n.classList.contains(requiredDomAncestorList[i]))
					{
						validDomAncestor = n;
						break;
					}
				}
				if (validDomAncestor)
					break;
				n = n.parentNode;
			}
			if (!n || !validDomAncestor)
				return null;
		}
		if (selTxt)
			selTxt = selTxt.toString();
	}



	if (!selTxt)
		return null;

	if (selTxt)
		selTxt = selTxt.trim();
	//if (selTxt != oldSelectedText) {
	var coords = null;
	var highlightData = null;
	if (selTxt && selTxt.length > 0)
	{
		if (document.selection)
		{
			var range = document.selection.createRange();
			coords = { left: range.boundingLeft, top: range.boundingTop + getScrollY(), width: range.boundingWidth, height: range.boundingHeight };
		}
		else
		{
			var result = GetFFSelCoords();
			if (result)
			{
				coords = result.result;
				highlightData = result.highlight;
			}
		}
		if (coords == null)
			return null;
		if (!PointInsideClass(coords.left, coords.top, "documentContentRoot")
			&& !PointInsideClass(coords.left, coords.top, "dictPopupRoot")
			&& !PointInsideClass(coords.left, coords.top, "asset"))
			return null; // Don't try to define terms that aren't in the document.
		if (PointInsideClass(coords.left, coords.top, "tdsnote")) // Don't try to define terms that are inside stickynotes.  TODO: As of this time stickynotes aren't implemented, so this class is probably going to be wrong.
			return null;
	}
	return { selTxt: selTxt, coords: coords, highlight: highlightData, validDomAncestor: validDomAncestor };
	//}
}

/**
 * If true, Firefox may throw an exception if you try to access certain things like "parentNode". E.g. 'Permission denied to access property "parentNode"'
 * @param {any} node The node to test.
 * @returns {Boolean} True if the node is private.
 */
function NodeIsPrivate(node)
{
	try
	{
		return !Object.getPrototypeOf(node);
	}
	catch (ex)
	{
		return false;
	}
}
/**
 * DocAddress Transform
 * @param {String} da untransformed docAddress
 * @returns {String} transformed docAddress
 */
export function DAT(da)
{
	var a = ""; for (var b = 0; b < da.length; b++) { var c = da.charAt(b), d = c.charCodeAt(0), e = !isNaN(parseInt(c, 10)), f = !1; c.match(/[a-zA-Z]/i) ? (90 >= d && (f = !0), d += 13, f && 90 < d ? d = 64 + (d - 90) : 122 < d && (d = 96 + (d - 122))) : e && (d += 5, 57 < d && (d = 47 + (d - 57))); a += String.fromCharCode(d) };
	return a;
}

// When generating router links, if you have a key in your query that is null or empty, you still get the key name in your query string without a value.
// Run your query object through this to fix.
export function RemoveEmptyKeys(obj)
{
	for (const key of Object.keys(obj))
	{
		if (obj.hasOwnProperty(key) && (obj[key] === null || obj[key] === '' || typeof obj[key] === 'undefined'))
		{
			delete obj[key];
		}
	}
	return obj;
}

/**
 * Creates a route that can be passed to vue router.
 * @param {any} docAddress docAddress (param)
 * @param {any} searchid searchid (query)
 * @param {any} categoryType categoryType (query)
 * @param {any} filterType filterType (query)
 * @param {any} noScroll noScroll (param)
 * @param {any} anchorId anchor (query)
 * @param {any} targetAnnotation targetAnnotation (param)
 * @param {any} tocfxidlist Optional.  Array of fxids to show in the toc panel, if it is necessary to show more than one.
 * @param {any} silent If true, router will be instructed not to announce the navigation to screen readers.
 * @returns {Object} A route that can be passed to vue router.
 */
export function CreateDocLinkRoute(docAddress, searchid, categoryType, filterType, noScroll, anchorId, targetAnnotation, tocfxidlist, silent, disableIndexScroll)
{
	let query = { searchid: searchid, categoryType: categoryType, filterType: filterType, anchor: anchorId };
	query = RemoveEmptyKeys(query);
	if (typeof (query.searchid) !== 'undefined' && query.searchid === 'none')
		delete query.searchid;
	if (typeof (query.filterType) !== 'undefined' && query.filterType === 'all')
		delete query.filterType;
	if (tocfxidlist)
		query.fxidlist = tocfxidlist;
	let route = {
		name: 'document', params: { docAddress: docAddress, noScroll: noScroll, disableIndexScroll: disableIndexScroll, targetAnnotation: targetAnnotation, silent: silent }, query: query
	};
	return route;
}
// This StringBuilder is from http://www.codeproject.com/KB/scripting/stringbuilder.aspx
// ***************************
// Initializes a new instance of the StringBuilder class
// and appends the given value if supplied
export function StringBuilder(value)
{
	this.strings = new Array("");
	this.Append(value);
}

// Appends the given value to the end of this instance.
StringBuilder.prototype.Append = function (value)
{
	if (value)
	{
		this.strings.push(value);
	}
};

// Clears the string buffer
StringBuilder.prototype.Clear = function ()
{
	this.strings.length = 1;
};

StringBuilder.prototype.StrCount = function ()
{
	return this.strings.length;
};

// Converts this instance to a String.
StringBuilder.prototype.ToString = function ()
{
	return this.strings.join("");
};


export function EncodedHost()
{
	if (typeof window === "undefined")
		return "";
	var port = window.location.port;
	var hostName = window.location.hostname;
	if (port && port.length > 0 && port !== 80)
		hostName = hostName + ":" + port;
	var encoded = new StringBuilder("");
	for (var i = 0; i < hostName.length; i++)
	{
		var charCode = hostName.charCodeAt(i);
		charCode++;
		encoded.Append(String.fromCharCode(charCode));
	}
	return encoded.ToString();
}


export function DecodeHost(hstNm)
{
	var newStr = "";
	for (var i = 0; i < hstNm.length; i++)
		newStr += String.fromCharCode(hstNm.charCodeAt(i) - 1);
	return newStr;
}

// opt is a single AuthOption from GetAuthOptions in LinkGenAPI.js
export function BuildLink(urlRoot, host, path, query, opt)
{
	if (opt.AuthType === "Shib")
		host = DecodeHost(appContext.encodedHost);
	urlRoot = urlRoot + host + "/";

	let hasQuery = query && query.length > 0;
	if (urlRoot[urlRoot.length - 1] === '/' && path.length > 0 && path[0] === '/')
		path = path.substr(1, path.length - 1);
	let linkTxt = urlRoot + path;
	if (linkTxt[linkTxt.length - 1] === '/')
		linkTxt = linkTxt.substr(0, linkTxt.length - 1);
	if (hasQuery)
		linkTxt += "?" + query;



	if (opt.AuthType === "IP")
	{
		linkTxt += (hasQuery ? "&" : "?") + "grpalias=" + opt.grpalias;
	}
	else if (opt.AuthType === "Notset")
	{
		// Intentionally left blank
	}
	else if (opt.AuthType === "Shib")
	{
		let entrypointLink = urlRoot.replace(/http:/, "https:") + opt.shibEntrypointPath.substr(1, opt.shibEntrypointPath.length - 1) + "ShibEntrypoint?target=" + encodeURIComponent(linkTxt.replace(/http:/, "https:")) + (hasQuery ? "&" : "?") + "grpalias=" + opt.grpalias;
		linkTxt = urlRoot + opt.shibAppPath.substr(1, opt.shibAppPath.length - 1) + "Shibboleth.sso/Login?target=" + encodeURIComponent(entrypointLink) + "&entityID=" + opt.entityID;
	}
	else if (opt.AuthType === "Proxy")
	{
		if (opt.proxyUrl.indexOf("?") === -1)
			linkTxt = opt.proxyUrl + "/login?url=" + linkTxt + "&grpalias=" + opt.grpalias;
		else
			linkTxt = opt.proxyUrl + linkTxt + "&grpalias=" + opt.grpalias;
	}
	else 
	{
		if (typeof appRoot === "object")
			ReportError(appRoot.$store.getters.urlRoot, "Unhandled auth type in Link to Page options: " + opt.AuthType, undefined, undefined, appRoot.$store.state.sid);
	}
	return linkTxt;
}

/**
 * Returns true if the arrays are the same length and contain the same items in the same order.
 * @param {Array} a An array
 * @param {Array} b An array
 * @returns {Boolean} True if they arrays match.
 */
export function ArraysMatch(a, b)
{
	if (a === b)
		return true;
	else if (a === null && b === null)
		return true;
	else if (a === null || b === null)
		return false;
	else if (a.length !== b.length)
		return false;
	else
	{
		for (let i = 0; i < a.length; i++)
			if (a[i] !== b[i])
				return false;
	}
	return true;
}
/**
 * Returns true if all items in each array also exist in the other array.  Duplicate items do not count, and therefore the length of each array may be different. e.g. [1, 1, 2] is considered similar to [2, 1], but neither is similar to [1, 1, 3].
 * @param {Array} a An array
 * @param {Array} b An array
 * @returns {Boolean} True if the arrays are considered similar.
 */
export function ArraysSimilar(a, b)
{
	if (a === b)
		return true;
	else if (a === null && b === null)
		return true;
	else if (a === null && b.length === 0)
		return true;
	else if (b === null && a.length === 0)
		return true;
	else if (a === null || b === null)
		return false;
	else
	{
		// Order does not matter.  For the purposes of this function, length does not matter either.
		// All which matters is that all items in each array also exist in the other.
		// e.g. [1, 1, 2] is considered equal to [2, 1]
		let objA = {};
		for (let i = 0; i < a.length; i++)
			objA[a[i]] = true;
		for (let i = 0; i < b.length; i++)
			if (!objA[b[i]])
				return false; // An item found in `b` does not exist in `a`.
		let objB = {};
		for (let i = 0; i < b.length; i++)
			objB[b[i]] = true;
		for (let i = 0; i < a.length; i++)
			if (!objB[a[i]])
				return false; // An item found in `a` does not exist in `b`.
	}
	return true;
}
export function GetScrollbarWidth()
{ // from https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript
	var outer = document.createElement("div");
	outer.style.visibility = "hidden";
	outer.style.width = "100px";
	outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps

	document.body.appendChild(outer);

	var widthNoScroll = outer.offsetWidth;
	// force scrollbars
	outer.style.overflow = "scroll";

	// add innerdiv
	var inner = document.createElement("div");
	inner.style.width = "100%";
	outer.appendChild(inner);

	var widthWithScroll = inner.offsetWidth;

	// remove divs
	outer.parentNode.removeChild(outer);

	return widthNoScroll - widthWithScroll;
}
export function Encrypt(key, data)
{
	let keyBytes = [];
	for (let i = 0; i < 16; i++)
		keyBytes.push(key.charCodeAt(i % key.length));

	let dataBytes = aesjs.utils.utf8.toBytes(data);

	let aesCtr = new aesjs.ModeOfOperation.ctr(keyBytes, new aesjs.Counter(1));
	let encryptedBytes = aesCtr.encrypt(dataBytes);

	let encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);

	return encryptedHex;
}
export function Decrypt(key, data)
{
	let keyBytes = [];
	for (let i = 0; i < 16; i++)
		keyBytes.push(key.charCodeAt(i % key.length));

	let encryptedBytes = aesjs.utils.hex.toBytes(data);

	let aesCtr = new aesjs.ModeOfOperation.ctr(keyBytes, new aesjs.Counter(1));
	let decryptedBytes = aesCtr.decrypt(encryptedBytes);

	let decryptedText = aesjs.utils.utf8.fromBytes(decryptedBytes);

	return decryptedText;
}
/**
 * Decrypts a byte array using weak AES-128 encryption with key weakly derived from [key].
 * @param {String} key A string from which the encryption key is derived.  For best security, it should be 16 characters long. There is no reason to have it be longer than that.
 * @param {Array} encryptedBytes Array of encrypted bytes.
 * @returns {Array} Array of decrypted bytes.
 */
export function DecryptBytes(key, encryptedBytes)
{
	let keyBytes = [];
	for (let i = 0; i < 16; i++)
		keyBytes.push(key.charCodeAt(i % key.length));

	let aesCtr = new aesjs.ModeOfOperation.ctr(keyBytes, new aesjs.Counter(1));
	let decryptedBytes = aesCtr.decrypt(encryptedBytes);

	return decryptedBytes;
}
/**
 * Decodes a byte array containing UTF8 data, returning a string.
 * @param {Array} bytes Array of bytes containing UTF8 data.
 * @returns {String} The string form of the data.
 */
export function Utf8GetString(bytes)
{
	return aesjs.utils.utf8.fromBytes(bytes);
}
let _localStorageEnabled = -1;
export function isLocalStorageEnabled()
{
	if (_localStorageEnabled === 0)
		return false;
	else if (_localStorageEnabled === 1)
		return true;
	try // May throw exception if local storage is disabled by browser settings!
	{
		var key = "local_storage_test_item";
		localStorage.setItem(key, key);
		localStorage.removeItem(key);
		_localStorageEnabled = 1;
		return true;
	} catch (e)
	{
		_localStorageEnabled = 0;
		return false;
	}
}


let _sessionStorageEnabled = -1;
export function IsSessionStorageEnabled()
{
	if (_sessionStorageEnabled === 0)
		return false;
	else if (_sessionStorageEnabled === 1)
		return true;
	try // May throw exception if local storage is disabled by browser settings!
	{
		if (!sessionStorage)
			return false;
		var key = "local_storage_test_item";
		sessionStorage.setItem(key, key);
		sessionStorage.removeItem(key);
		_sessionStorageEnabled = 1;
		return true;
	} catch (e)
	{
		_sessionStorageEnabled = 0;
		return false;
	}
}
/**
 * Gets a string of the specified length consisting of random non-whitespace ASCII characters between values 33 and 126 inclusively.
 * @param {Number} length Length of the string in characters.
 * @returns {String} String with the specified length.
 */
export function GenerateRandomAsciiPrintableString(length)
{
	let arr = [];
	for (let i = 0; i < length; i++)
	{
		arr.push(String.fromCharCode(GetRandomInt(33, 126)));
	}
	return arr.join("");
}
/**
 * Gets a random integer between min and max inclusively.
 * @param {Number} min Minimum value possible for the returned number.
 * @param {Number} max Maximum value possible for the returned number.
 * @returns {Number} A random number between min and max inclusively.
 */
export function GetRandomInt(min, max)
{
	if (max < min)
	{
		let tmp = max;
		max = min;
		min = tmp;
	}
	min = min >> 0;
	max = ((max >> 0) - min) + 1;
	return Math.floor(min + (Math.random() * max));
}
const mfaGuidId = "tds_duid";
const mfaGuidKey = "43Wj2KSPclzlhcfn";
/**
 * Saves the given GUID for MFA to localStorage, if localStorage is available.  If guid is null, the currently saved value will be deleted instead.
 * @param {String} guid GUID string to save.
 * @returns {String} Encrypted GUID string.
 */
export function SaveDeviceGuidForMFA(guid)
{
	try
	{
		if (isLocalStorageEnabled())
		{
			// Encrypt GUID using random key, put both in localStorage so the GUID can be reloaded later.
			if (guid)
			{
				// Use a few rounds of encryption just to make the GUID a little harder to steal.
				let key2 = GenerateRandomAsciiPrintableString(16);
				let payload = key2 + Encrypt(key2, guid);
				let encryptedPayload = Encrypt(mfaGuidKey, payload);
				localStorage.setItem(mfaGuidId, encryptedPayload);
			}
			else
			{
				localStorage.removeItem(mfaGuidId);
			}
		}
	}
	catch (ex)
	{
		console.error(ex);
		if (window && window.appContext)
			ReportError(appContext.urlRoot, ex.message, null, ex, sid);
	}
}
/**
* Loads the GUID for MFA from localStorage. If not stored, returns null.
 * @returns {String} GUID string.
 */
export function LoadDeviceGuidForMFA()
{
	try
	{
		// Decrypt GUID from localStorage
		if (isLocalStorageEnabled())
		{
			let encryptedPayload = localStorage.getItem(mfaGuidId);
			if (encryptedPayload)
			{
				try
				{
					let payload = Decrypt(mfaGuidKey, encryptedPayload);
					let key2 = payload.substr(0, 16);
					let encryptedGuid = payload.substr(16);
					let guid = Decrypt(key2, encryptedGuid);
					return guid;
				}
				catch (err)
				{
					console.error(err);
				}
			}
		}
	}
	catch (ex)
	{
		console.error(ex);
		if (window && window.appContext)
			ReportError(appContext.urlRoot, ex.message, null, ex, sid);
	}
	return null;
}
/**
 * Given a URL, returns the substring beginning at the first "#" through the end of the string.  Or "" if no "#" is found.
 * @param {String} href URL to parse
 * @returns {String} substring starting at first "#"
 */
export function GetHash(href)
{
	let idxHash = href.indexOf('#');
	if (idxHash > -1)
		return href.substr(idxHash);
	else
		return "";
}
/**
 * Given a URL, returns the substring ending at the first "#".  If no "#" is found, returns the entire string.
 * @param {any} href URL to parse
 * @returns {String} substring before first "#"
 */
export function GetBeforeHash(href)
{
	let idxHash = href.indexOf('#');
	if (idxHash > -1)
		return href.substr(0, idxHash);
	return href;
}
/**
 * Performs an action when a specific condition is met.
 * @param {Function} action The function to call when the condition is met.
 * @param {Function} when A function which returns true when the condition is met.
 * @param {Number} interval Number of milliseconds to wait between attempts to check the condition.
 * @param {Number} max Maximum number of milliseconds to delay before giving up.
 * @param {Function} giveup Optional function to call if [max] is exceeded before [when] returns true.
 */
export function PerformActionWhen(action, when, interval, max, giveup)
{
	if (when())
		action();
	else if (max > 0)
	{
		if (interval < 1)
			interval = 1;
		let delay = Math.min(interval, max);
		setTimeout(() =>
		{
			PerformActionWhen(action, when, interval, max - delay);
		}, delay);
	}
	else if (typeof giveup === "function")
		giveup();
}
/**
 * Returns the number of pixels this element is offset from the top of the document, using offsetTop and offsetParent properties to perform the calculation. This specifically does not use the getBoundingClientRect() function.
 * @param {HTMLElement} ele element to measure distance to
 * @returns {Number} the number of pixels this element is offset from the top of the document.
 */
export function GetPositionTop(ele)
{
	let top = 0;
	while (ele)
	{
		top += ele.offsetTop;
		ele = ele.offsetParent;
	}
	return top;
}

export function PreventDoubleTapZoom(ele)
{
	ele.addEventListener("touchstart", (e) =>
	{
		var t2 = e.timeStamp
			, t1 = ele.lastTouch || t2
			, dt = t2 - t1
			, fingers = e.touches.length;
		ele.lastTouch = t2;
		if (!dt || dt > 500 || fingers > 1) return; // not double-tap

		e.preventDefault(); // double tap - prevent the zoom
		// also synthesize click events we just swallowed up
		if (document.createEvent)
		{
			let trigger = document.createEvent("HTMLEvents");
			trigger.initEvent("click", true, true);
			ele.dispatchEvent(trigger);
		}
	});
}

var verge = require("verge");
//let viewportAttachedToWindowResize = false;
let resetViewportTimeout = null;
export function FixViewport()
{
	// Note:  I have observed a glitch in early versions of iOS 10 where with wide documents, stickied toolbars scroll with the page but slower, when zoomed in.  Not trying 
	// to fix it as barely anyone is using that OS.
	if (BrowserIsIOS())
	{
		//console.log("Setting wide viewport. " + window.screen.availWidth);
		document.getElementById("viewport").setAttribute('content', 'width=' + window.screen.availWidth + ', initial-scale=1.0, minimum-scale=1.0, user-scalable=yes'); // maximum-scale of 1.0 here prevents automatic zoom on input focus, but does not prevent manual user zoom.  4-4-24: The maximum-scale=1.0 had been taken out as an "accessibility" fix, but I put it back in as it causes iOS to auto zoom in on text inputs, breaking layouts.
		clearTimeout(resetViewportTimeout);
		resetViewportTimeout = setTimeout(() =>
		{
			document.getElementById("viewport").setAttribute('content', 'width=' + window.screen.availWidth + ', initial-scale=1.0, minimum-scale=' + GetMinimumScale() + ', maximum-scale=1.0, user-scalable=yes');
		}, 10);
	}
	else if (BrowserIsAndroid())
	{
		document.getElementById("viewport").setAttribute('content', 'width=device-width, minimum-scale=' + GetMinimumScale() + ', user-scalable=yes');
	}
	//else if (BrowserIsAndroid())
	//{
	//	// For Android: This disables the ability to zoom out, but keeps the toolbars and text wrapping in good shape.
	//	document.getElementById("viewport").setAttribute('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=4.0, user-scalable=yes');
	//}
	//if (!viewportAttachedToWindowResize)
	//{
	//	viewportAttachedToWindowResize = true;
	//	window.addEventListener("resize", FixViewport);
	//}
}
export function GetMinimumScale()
{
	if (BrowserIsIOS())
		return 0.2;
	else
		return 0.67;
}
export function GetMonthAbbreviation(monthIndex)
{
	if (monthIndex === 0)
		return "Jan";
	if (monthIndex === 1)
		return "Feb";
	if (monthIndex === 2)
		return "Mar";
	if (monthIndex === 3)
		return "Apr";
	if (monthIndex === 4)
		return "May";
	if (monthIndex === 5)
		return "Jun";
	if (monthIndex === 6)
		return "Jul";
	if (monthIndex === 7)
		return "Aug";
	if (monthIndex === 8)
		return "Sep";
	if (monthIndex === 9)
		return "Oct";
	if (monthIndex === 10)
		return "Nov";
	if (monthIndex === 11)
		return "Dec";
	return monthIndex;
}
export function InsertCommasInNumber(n)
{
	let s = n.toString();
	if (s.length < 4)
		return s;
	let ca = [];
	for (let i = 0; i < s.length; i++)
	{
		if (i > 0 && (s.length - i) % 3 === 0)
			ca.push(',');
		ca.push(s.charAt(i));
	}
	return ca.join('');
}

export function LtpAppPath()
{
	let appPath = appContext.appPath;
	if (appPath)
	{
		if (appPath.length > 0 && appPath[0] === '/')
			appPath = appPath.substr(1, appPath.length - 1);
		if (appPath.length > 0 && appPath[appPath.length - 1] !== '/')
			appPath = appPath + '/';
	}
	else
		appPath = '';
	return appPath;
}
export function BrowserIsIOS()
{
	return !!navigator.userAgent.match(/iPad|iPhone|iPod/) && !window.MSStream; // There reportedly exists a version of IE 11 that pretends to be iOS
}
export function BrowserIsAndroid()
{
	return !!navigator.userAgent.match(/ Android /);
}
export function BrowserIsIE()
{
	return /MSIE \d|Trident.*rv:/.test(navigator.userAgent) ? 1 : 0;
}
/**
 * Returns true if the user agent says this browser is pre-chromium Edge.
 * @returns {Boolean} Returns true if the user agent says this browser is pre-chromium Edge.
 */
export function BrowserIsLegacyEdge()
{
	return window.navigator.userAgent.indexOf(" Edge/") > -1;
}
/**
 * Returns the Edge version number if this is the Edge browser (otherwise null). Supports both pre-chromium (version < 19) and chromium-based Edge.
 * @returns {Number} Returns the Edge version number if this is the Edge browser (otherwise null).
 */
export function BrowserEdgeVersion()
{
	var m = window.navigator.userAgent.match(/ Edge\/([0-9\.,]+)/);
	if (!m)
		m = window.navigator.userAgent.match(/ Edg\/([0-9\.,]+)/);
	if (m)
		return m[1];
	return null;
}
export function GetWidthNoPadding(ele)
{
	if (window)
	{
		var cs = window.getComputedStyle(ele);
		var paddingX = parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
		var borderX = parseFloat(cs.borderLeftWidth) + parseFloat(cs.borderRightWidth);

		// clientWidth includes only padding, but is not available for inline elements.
		// offsetWidth includes padding and border, and is available for inline elements.
		return ele.offsetWidth - paddingX - borderX;
	}
	return 0;
}
export function GetPaddingX(ele)
{
	if (window)
	{
		var cs = window.getComputedStyle(ele);
		return parseFloat(cs.paddingLeft) + parseFloat(cs.paddingRight);
	}
	return 0;
}
if (window.NodeList && !NodeList.prototype.forEach)
{
	NodeList.prototype.forEach = Array.prototype.forEach;
}
export function AutoResetFlag(autoResetTimeMs)
{
	let flag = false;
	let autoResetTimeout = null;
	this.Set = function ()
	{
		flag = true;
		clearTimeout(autoResetTimeout);
		autoResetTimeout = setTimeout(() =>
		{
			flag = false;
		}, autoResetTimeMs);
	};
	this.UnSet = function ()
	{
		flag = false;
		clearTimeout(autoResetTimeout);
	};
	this.IsSet = function ()
	{
		return flag;
	};
}
export function overlapping1D(min1, size1, min2, size2)
{
	return min1 + size1 >= min2
		&& min2 + size2 >= min1;
}
export function overlapping2D(box1, box2)
{
	return overlapping1D(box1.top, box1.height, box2.top, box2.height)
		&& overlapping1D(box1.left, box1.width, box2.left, box2.width);
}

/**
 * Returns true if [child] is a descendent of [ancestor]
 * @param {any} child Child node
 * @param {any} ancestor Node that is possibly an ancestor of [child]
 * @returns {Boolean} true if [child] is a descendent of [ancestor]
 */
export function FindAncestor(child, ancestor)
{
	let node = child;
	while (node)
	{
		if (node === ancestor)
			return true;
		node = node.parentElement;
	}
	return false;
}

//for IE
if (!Element.prototype.matches)
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;

export function closest(el, selector)		//walk up elements until find matching
{
	while (el && !el.matches(selector))
	{
		if (el.tagName === 'HTML')
			return null;
		el = el.parentNode;
	}
	return el;
}

/**
 * Injects the specified javascript text into a new script element and inserts it at the end of document.body.
 * @param {String} scriptText Javascript text
 */
export function InjectScript(scriptText)
{
	let attachPoint = document.body;
	if (!attachPoint)
	{
		console.error("unable to inject script due to missing document.body");
		return;
	}
	let sTag = document.createElement("script");
	sTag.type = "text/javascript";
	sTag.text = scriptText;
	attachPoint.appendChild(sTag);
}

export function GetTopBarOffset(store)
{
	let topBarContent = document.getElementById("topBarContent");
	let topBarOffset = 0;
	if (!topBarContent)
		ReportError("Can't find topBarContent.  Can't calculate top bar offset.");
	else if (window.getComputedStyle(topBarContent).position === "fixed")
		topBarOffset = topBarContent.offsetTop + topBarContent.offsetHeight;
	else
	{
		// Handle the case where we're in desktop layout and scrolled to the top, so the toolbar isn't fixed position yet, but it will be once we scroll down.
		if (!store && typeof appRoot === "object")
			store = appRoot.$store;
		if (store && store.state && store.state.topbar.expanded)
			topBarOffset = 54;
	}
	return topBarOffset;
}
/**
 * Call this after setting document.title. It sets document.ariaLabel.
 */
export function SetDocumentAriaLabel()
{
	document.ariaLabel = document.title.replace('TDS Health', 'T D S Health').replace('STAT!Ref', 'STAT Ref');
}
/**
 * Sets the document title based on metadata from the specified route.
 * @param {Object} to The route being navigated to.
 */
export function RouterSetDocumentTitle(to)
{
	let titleArr = [];
	let routeSetsOwnTitle = false;
	for (let i = to.matched.length - 1; i >= 0; i--)
	{
		let r = to.matched[i];
		if (typeof r.meta !== 'undefined' && r.meta)
		{
			if (!routeSetsOwnTitle)
				routeSetsOwnTitle = typeof r.meta.setsOwnTitle !== 'undefined' && r.meta.setsOwnTitle;
			if (typeof r.meta.title !== 'undefined')
			{
				if (typeof r.meta.title === 'function')
					titleArr.push(r.meta.title(r));
				else
					titleArr.push(r.meta.title);
			}
		}
	}
	if (titleArr.length === 0 || titleArr[titleArr.length - 1].toLowerCase() !== appContext.appName.toLowerCase())
		titleArr.push(appContext.appName);
	if (!routeSetsOwnTitle)
	{
		document.title = titleArr.join(" - ");
		SetDocumentAriaLabel();
	}
}
/**
 * Calls the getTitle function in the route's vue component instance.
 * @param {Object} route The route to call getTitle in.
 * @returns {String} The title or null.
 */
export function GetTitleFromRoute(route)
{
	if (route && route.instances && route.instances.default)
		return route.instances.default.getTitle();
	else
		return null;
}
/**
 * Focuses one of the descendant elements of the parent, or failing that, the parent itself.
 * @param {any} parent DOM element which is the parent of elements to consider for focusing.
 * @param {Boolean} focusFirstItem If true, the function will prefer to focus the first descendant.  If false, the last.
 */
export function FocusDescendant(parent, focusFirstItem)
{
	console.log("FocusDescendant (" + (focusFirstItem ? "true" : "false") + ")");
	let focusable = parent.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]):not(.FocusCatcher)');
	if (focusable.length > 0)
	{
		if (focusFirstItem)
			focusable[0].focus();
		else
			focusable[focusable.length - 1].focus();
	}
	else
		parent.focus();
}

export function FocusPrevElement()
{
	FocusNextElement(-1);
}

export function FocusNextElement(direction = 1)
{
	console.log("FocusNextElement");
	//add all elements we want to include in our selection
	var focussableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
	if (document.activeElement && document.body)
	{
		var focussable = Array.prototype.filter.call(document.body.querySelectorAll(focussableElements),
			function (element)
			{
				//check for visibility while always include the current activeElement 
				return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement;
			});
		var index = focussable.indexOf(document.activeElement);
		if (index > -1)
		{
			if (direction > 0)
			{
				let nextElement = focussable[index + 1] || focussable[0];
				nextElement.focus();
			}
			else
			{
				let nextElement = focussable[index - 1] || focussable[focussable.length - 1];
				nextElement.focus();
			}
		}
	}
}
/**
 * Create via (`new FPSCounter`). Counts the exact number of frames that arrived within the last 1000 ms.
 */
export function FPSCounter()
{
	let queue = new Queue();
	/**
	 * Returns the current FPS.
	 * @param {Boolean} suppressAdd If true, no new frame is added to the counter, but the current FPS is still returned.
	 * @returns {Number} Returns the exact number of frames that were added within the last 1000 ms.
	 */
	this.getFPS = function ()
	{
		let now = performance.now();
		// Trim times older than 1 second
		while (!queue.isEmpty() && now - queue.peek() >= 1000)
			queue.dequeue();
		return queue.getLength();
	};
	/**
	 * Adds a frame and returns the current FPS.
	 * @returns {Number} Returns the exact number of frames that were added within the last 1000 ms.
	 */
	this.addFrame = function ()
	{
		let now = performance.now();
		// Trim times older than 1 second
		while (!queue.isEmpty() && now - queue.peek() >= 1000)
			queue.dequeue();
		queue.enqueue(now);
		return queue.getLength();
	};
}
/**
 * Returns true if the device supports vibration.
 * @returns {Boolean} true if the device supports vibration
 */
export function DetectVibrateSupport()
{
	try
	{
		return typeof window.navigator.vibrate === "function";
	}
	catch (ex)
	{
		return false;
	}
}

/**
 * This function makes assumptions about the tooltip's width, but it dynamically calculates appropriate coordinates for that size
 * of tooltip based on available viewport space.
 * @param {any} parentElement Element relative to which this tooltip is to be positioned [not needed if coords provided]
 * @param {any} offsetParent The offsetParent relative to which this tooltip is to be positioned.  This is typically the first ancestor with special positioning (such as position: relative).
 * @param {any} coords Object with top and left [not needed if parentElement provided]
 * @returns {Object} object with left, top, and arrowClass
 */
export function CalcTooltipPosition(parentElement, offsetParent, coords)
{
	let result = {
		left: 0,
		top: 0,
		arrowClass: ''
	};
	if ((!parentElement && !coords) || !offsetParent)
		return result;

	let viewport = {
		top: window.pageYOffset || document.documentElement.scrollTop,
		left: window.pageXOffset || document.documentElement.scrollLeft,
		width: document.documentElement.clientWidth,
		height: document.documentElement.clientHeight,
		right: 0,
		bottom: 0
	};
	viewport.right = viewport.left + viewport.width;
	viewport.bottom = viewport.top + viewport.height;

	let triggerVPRelOffset = null;// = FindOffsets(parentElement);

	let trigger = null;
	if (coords)
	{
		trigger = {
			left: coords.left,
			top: coords.top,
			width: coords.width,
			height: coords.height,
			right: 0,
			bottom: 0
		};
	}
	else
	{
		trigger = {
			left: parentElement.offsetLeft,
			top: parentElement.offsetTop,
			width: parentElement.offsetWidth,
			height: parentElement.offsetHeight,
			right: 0,
			bottom: 0
		};
		triggerVPRelOffset = FindOffsets(parentElement);
	}
	trigger.right = trigger.left + trigger.width;
	trigger.bottom = trigger.top + trigger.height;
	if (!triggerVPRelOffset)
		triggerVPRelOffset = trigger;

	let offsetParentRect = offsetParent.getBoundingClientRect();

	// Determine the best tooltip location
	let tooltipWidth = 300 + 29 + 25;
	let xDirection = 0;
	if (triggerVPRelOffset.right + tooltipWidth <= viewport.right)
	{
		// There is room to the right (preferred).
		xDirection = 1;
		result.left = trigger.right + 29;
	}
	else if (triggerVPRelOffset.left - tooltipWidth > 0)
	{
		// There is room to the left.
		xDirection = -1;
		result.left = trigger.left - tooltipWidth;
	}
	if (xDirection !== 0)
	{
		// Tooltip is 272px tall, and its arrow is 20px away from its edge
		let yMiddle = trigger.top + (trigger.height / 2);
		let yMiddleVPRel = triggerVPRelOffset.top + (triggerVPRelOffset.height / 2);
		if (yMiddleVPRel + 252 < viewport.bottom)
		{
			// Tooltip can extend downward (preferred).
			result.arrowClass = "arrowTop" + (xDirection === -1 ? "Right" : "Left");
			result.top = yMiddle - 20;
		}
		else
		{
			// Tooltip can extend upward.
			result.arrowClass = "arrowBottom" + (xDirection === -1 ? "Right" : "Left");
			result.top = yMiddle - 252;

			if ((yMiddleVPRel.top - 252) < viewport.top)
			{
				// Tooltip is vertically constrained.
				result.arrowClass = "";
				// this.top needs to be relative to it's offset parent, not relative to the viewport.  But we want it to line up
				// exactly with the viewport.  So we figure out the difference between the offset parent's top and the viewport's top, and
				// set this.top to that difference.

				result.top = viewport.top - offsetParentRect.top;
				if (xDirection === 1)
					result.left -= 27;
				else if (xDirection === -1)
					result.left += 27;
			}
		}
	}
	else
	{
		// Position above or below with arrow in center.
		result.left = (trigger.left + (trigger.width / 2)) - 160;

		let distToTop = triggerVPRelOffset.top - viewport.top;
		let distToBottom = viewport.bottom - triggerVPRelOffset.bottom;
		let tooltipHeight = 250 + 20 + 2 + 27;
		if (distToBottom >= tooltipHeight)
		{
			result.arrowClass = "arrowAboveCenter";
			result.top = trigger.bottom + 27;
		}
		else if (distToTop >= tooltipHeight)
		{
			result.arrowClass = "arrowBelowCenter";
			result.top = trigger.top - tooltipHeight - 1;
		}
		else
		{
			result.arrowClass = "";
			let eleCenter = trigger.top + (trigger.height / 2);
			result.top = eleCenter - 136;
			result.left = viewport.right - offsetParentRect.left; // will be auto-adjusted to the left
		}
	}
	if (result.left < 0)
		result.left = 0;
	if (coords === null)
	{
		if ((result.left + offsetParentRect.left > viewport.right - 322))
			result.left = (viewport.right - offsetParentRect.left) - 322;
	}
	else
	{
		if (result.left > viewport.right - 322)
			result.left = viewport.right - 322;
	}
	return result;
}

/**
 * Escapes a string to be used in a regular expression. From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
 * @param {String} string A string that may contain reserved characters.
 * @return {String} A string that can be used in a regular expression to literally match itself.
 */
export function escapeRegExp(string)
{
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}
/**
 * Returns a string with the first letter of each word capitalized, and all other letters lower-case.
 * @param {String} str string to change to title case
 * @returns {String} title-case string
 */
export function TitleCase(str)
{
	str = str.toLowerCase().split(' ');
	for (let i = 0; i < str.length; i++)
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	return str.join(' ');
}
/**
 * Returns a string with the first letter of each word capitalized, and other characters unchanged.
 * @param {String} str string to change to title case
 * @returns {String} title-case string
 */
export function TitleCase2(str)
{
	str = str.split(' ');
	for (let i = 0; i < str.length; i++)
		str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
	return str.join(' ');
}
// Date.now() and performance.now() polyfills
if (typeof window !== "undefined")
{
	window.Date.now = (Date.now || function () { return new Date().getTime(); });
	if ("performance" in window === false)
		window.performance = {};
	if ("now" in window.performance === false)
	{
		var start = Date.now();
		window.performance.now = function () { return Date.now() - start; };
	}
}
/**
 * Returns a stack trace leading up to but not including this function call.
 * @param {Number} offset Number of additional stack trace lines to remove off the top.
 * @returns {String} Stack trace
 */
export function getStackTrace(offset)
{
	if (!offset)
		offset = 0;

	let stack = new Error().stack;
	if (!stack)
		try
		{
			throw new Error('');
		}
		catch (error)
		{
			stack = error.stack || '';
		}

	stack = stack.split('\n')/*.map(line => line.trim())*/;
	return stack.splice((stack[0] === 'Error' ? 2 : 1) + offset).join("\n");
}

/**
 * Returns true if the user agent is not connected directly to online.statref.com. (not Platform aware)
 * @returns {Boolean} true if the user is not connected through online.statref.com. (not Platform aware)
 */
function DetectProxyServer()
{
	if (typeof window !== 'undefined' && window.location.host !== "online.statref.com")
		return true;
	return false;
}

/**
 * Displays a warning about connecting using insecure (http) if the conditions are right. (not Platform aware)
 */
export function HttpsWarning()
{
	if (DetectProxyServer() && window.location.protocol === "http:" && window.location.host !== "127.0.0.1" && window.location.host !== "[::1]" && window.location.host !== "localhost")
	{
		MakeToast({ message: "You appear to be connecting using an insecure (http) connection through a proxy server.  In the future, TDS Health will only accept secure (https) connections.  Please contact your proxy server administrator to ensure your proxy server is ready to support https connections to TDS Health.  Connect securely (https) to avoid seeing this message.", type: "info" });
	}
}

var screenReaderDebounce = null;
/// This is designed to trick a screen reader in to reading arbitrary text by using an offscreen alert dialog.
export function ScreenReaderReadText(text)
{
	clearTimeout(screenReaderDebounce);
	screenReaderDebounce = setTimeout(() =>
	{
		var screenReaderAlertWrapper = document.getElementById("screenReaderMessageContainer");
		if (!screenReaderAlertWrapper)
			return;
		//var alertbox = document.getElementById("screenReaderAlertBox");
		//if (alertbox)
		//	alertbox.parentElement.removeChild(alertbox);
		//alertbox = document.createElement("div");
		//alertbox.className = "visuallyhidden";
		//alertbox.id = "screenReaderAlertBox";
		//alertbox.setAttribute("tabindex", "-1");
		//alertbox.setAttribute("role", "alert");
		//alertbox.setAttribute("aria-atomic", "true");
		//alertbox.setAttribute("aria-hidden", "false");
		//alertbox.setAttribute("aria-live", "assertive");
		//screenReaderAlertWrapper.appendChild(alertbox);
		//alertbox.setAttribute("aria-label", text);
		var textNode = document.createTextNode(text);
		screenReaderAlertWrapper.appendChild(textNode);
		console.log("Sent to screen reader: " + text);
		screenReaderDebounce = setTimeout(() =>
		{
			screenReaderAlertWrapper.innerHTML = "";
			//alertbox.setAttribute("aria-hidden", "true");
		}, 5000);

	}, 500);
}

/// array: used in place of ids
export function MoveFocus(event, direction, length, idPrefix, array)
{
	let myidx = parseInt(event.target.getAttribute("index"));
	myidx = myidx + direction;
	if (myidx < 0)
		myidx = 0;
	if (myidx >= length)
		myidx = length - 1;
	let trgt;
	if (array)
		trgt = array[myidx];
	else
		trgt = document.getElementById(idPrefix + myidx);
	if (!trgt)
		return;
	if (trgt.getAttribute("type") == "radio")
	{
		//trgt.checked = true;

		if (trgt.component && trgt.component.ToggleMe)
			trgt.component.ToggleMe(event);

	}
	if (trgt.$el)
		trgt.$el.focus();
	else
		trgt.focus();
}


// Modified from https://stackoverflow.com/questions/49043684/how-to-calculate-the-amount-of-flexbox-items-in-a-row
// This function is capable of assisting the navigation of a wrapped flex grid or layouts using css columns using arrow keys.
// autofocus: If true, focus the next element.  If false, just return it.
export function NavigateGrid(gridSelector, direction, focusGrandchild = false, autofocus = true, currentElement = null, ignoreFirstElement = false)
{
	const grid = document.querySelector(gridSelector);
	var columns = false;
	let columnWidth = window.getComputedStyle(grid).columnWidth;
	let columnsCount = window.getComputedStyle(grid).columns;
	let nColumnsCount = 1;
	if (columnsCount && columnsCount.length > 0)
	{
		nColumnsCount = parseInt(columnsCount);
		if (isNaN(nColumnsCount))
			nColumnsCount = parseInt(columnsCount.replace(/auto /g, ''));
		if (isNaN(nColumnsCount))
			nColumnsCount = 1;
	}
	if (currentElement == null)
		currentElement = document.activeElement;
	if ((typeof columnWidth !== 'undefined' && columnWidth && columnWidth !== "" && columnWidth !== "auto")
		|| nColumnsCount > 1)
		columns = true;
	const active = focusGrandchild ? currentElement.parentNode : currentElement;
	const gridChildren = Array.from(grid.children);
	if (ignoreFirstElement)
		gridChildren.splice(0, 1);
	const activeIndex = gridChildren.indexOf(active);

	const gridNum = gridChildren.length;
	const baseOffset = columns ? gridChildren[0].offsetLeft : gridChildren[0].offsetTop;
	let breakIndex;
	if (columns)
		breakIndex = gridChildren.findIndex(item => item.offsetLeft > baseOffset);
	else
		breakIndex = gridChildren.findIndex(item => item.offsetTop > baseOffset);
	const numPerRow = (breakIndex === -1 ? gridNum : breakIndex);

	const updateActiveItem = (active, next) =>
	{
		if (next !== null)
		{
			if (focusGrandchild)
				next.children[Array.from(active).indexOf(currentElement)];
			if (autofocus)
				next.focus();
			return next;
		}
		return null;
	}

	const isTopRow = columns ? activeIndex % numPerRow === 0 : activeIndex <= numPerRow - 1;
	const isBottomRow = columns ? activeIndex % numPerRow === numPerRow - 1 || activeIndex === gridNum - 1 : activeIndex >= gridNum - numPerRow;
	const isLeftColumn = columns ? activeIndex <= numPerRow - 1 : activeIndex % numPerRow === 0;
	const isRightColumn = columns ? activeIndex >= gridNum - numPerRow : activeIndex % numPerRow === numPerRow - 1 || activeIndex === gridNum - 1;

	switch (direction)
	{
		case "up":
			if (!isTopRow)
				return updateActiveItem(active, gridChildren[columns ? activeIndex - 1 : activeIndex - numPerRow]);
			break;
		case "down":
			if (!isBottomRow)
				return updateActiveItem(active, gridChildren[columns ? activeIndex + 1 : activeIndex + numPerRow]);
			break;
		case "left":
			if (!isLeftColumn)
				return updateActiveItem(active, gridChildren[columns ? activeIndex - numPerRow : activeIndex - 1]);
			break;
		case "right":
			if (!isRightColumn)
				return updateActiveItem(active, gridChildren[columns ? activeIndex + numPerRow : activeIndex + 1]);
			break;
	}
}
/**
 * Returns true if window.screen.availWidth does not currently throw an exception.
 */
export function WindowScreenIsAvailable()
{
	try
	{
		return !!window.screen.availWidth;
	}
	catch (ex)
	{
		// can cause "Unhandled Error (global handler): can't access dead object"
		console.error(ex);
	}
	return false;
}

export function SkipToMainContent()
{
	let targ = document.getElementById("docContent");
	if (!targ)
		targ = document.getElementById("main2");
	if (targ)
	{
		targ.focus();
		var ofsts = FindOffsets(targ);
		var top = ofsts.top - 200;
		if (top < 0)
			top = 0;
		window.scrollTo(0, top);
		//targ.scrollIntoView(); 
	}
	else
		ScreenReaderReadText("Main content not available yet. Please try again.");
}
Element.prototype.scrollPosition = function ()
{
	var val = this.getBoundingClientRect().top;
	if (typeof (window.scrollY) !== 'undefined')
		val += window.scrollY;
	else
		val += document.documentElement.scrollTop;
	return val;
};

export function FocusDefault()
{
	try
	{
		var defaultFocusTarget = document.querySelectorAll(".defaultFocusTarget");
		if (defaultFocusTarget && defaultFocusTarget.length > 0)
		{
			defaultFocusTarget[0].focus();
		}
	}
	catch (ex)
	{
		if (typeof appRoot === "object")
			ReportError(appRoot.$store.getters.urlRoot, "FocusDefault error: " + ex.message, undefined, ex, appRoot.$store.state.sid);
	}
}

// Recursive function that navigates the DOM to reversably disable all keyboard tab indexes in descendents of the given DOM object.
export function DisableAllTabStopsIn(targetObject)
{
	//if (targetObject.nodeName == "#text")
	//	console.log(targetObject.textContent);
	//else
	//	console.log(targetObject);
	if (typeof (targetObject.getAttribute) !== 'undefined' && (targetObject.getAttribute("tabindex") || targetObject.tagName.toLowerCase() == "a" || targetObject.tagName.toLowerCase() == "input"))
	{
		var currenttidx;
		if (targetObject.getAttribute("alwaysFocusable") != "true")
		{
			if (targetObject.getAttribute("tabindex"))
				currenttidx = targetObject.getAttribute("tabindex");
			else
				currenttidx = "NA"; // <a> or <input> tag.
			if (!targetObject.getAttribute("tabindexbackup"))
				targetObject.setAttribute("tabindexbackup", currenttidx);
			if (targetObject.tagName == "svg")
			{
				targetObject.setAttribute("focusable", "false");
			}
			targetObject.setAttribute("tabindex", "-1");
		}

	}
	for (var i = 0; i < targetObject.childNodes.length; i++)
	{
		var node = targetObject.childNodes[i];
		if (typeof (node) === 'undefined' || !node)
			console.log("undefined child node? ");
		DisableAllTabStopsIn(node);
	}
}
export function RestoreAllTabStopsIn(targetObject)
{
	if (typeof (targetObject.getAttribute) !== 'undefined' && targetObject.getAttribute("tabindexbackup"))
	{
		if (targetObject.getAttribute("tabindexbackup") === "NA")
			targetObject.removeAttribute("tabindex");
		else
		{
			if (targetObject.tagName == "svg")
				targetObject.setAttribute("focusable", "true");
			targetObject.setAttribute("tabindex", targetObject.getAttribute("tabindexbackup"));
		}
	}

	for (var i = 0; i < targetObject.childNodes.length; i++)
	{
		var node = targetObject.childNodes[i];
		if (typeof (node) === 'undefined' || !node)
			console.log("undefined child node? ");
		RestoreAllTabStopsIn(node);
	}
}

export function SkipLink(targetArray, failMsg)
{
	// A generic skip link handler.
	var targetId;
	var target;
	if (!targetArray || targetArray.length < 1)
		return;
	targetId = targetArray[0];
	target = document.getElementById(targetId);
	var i = 1;
	while (!target && targetArray.length > i)
	{
		targetId = targetArray[i++];
		target = document.getElementById(targetId);
	}
	if (target)
	{
		target.focus();
		var ofsts = FindOffsets(target);
		var top = ofsts.top - 200;
		if (top < 0)
			top = 0;
		window.scrollTo(0, top);
	}
	else if (failMsg)
		MakeToast({ message: failMsg, type: "info", timeout: 5000 });
}
/**
 * Returns true if the current page is in a secure context.
 * @returns {Boolean} true if the current page is in a secure context.
 * */
export function IsSecureContext()
{
	if (typeof window.isSecureContext !== "undefined")
		return window.isSecureContext;
	else
		return location.protocol === "https:";
}
/**
 * Decodes a string in Base64Url format, returning a copy of the original byte array.
 * This method can also handle standard Base64 input strings, however with reduced efficiency.
 * This method can handle input strings which have had their trailing padding characters removed.
 * This method can handle input strings with certain unwanted punctuation appended to the end.
 * @param {String} b64UrlEncoded A string in Base64Url format.
 * @returns {Uint8Array} Byte array.
 */
export function Base64UrlDecode(b64UrlEncoded)
{
	return base64url.parse(b64UrlEncoded, { loose: true });
}
/**
 * Copies a string to the clipboard.
 * @param {String} str String to copy to the clipboard.
 */
export function CopyToClipboard(str)
{
	try
	{
		navigator.clipboard.writeText(str);
		return;
	}
	catch (ex)
	{
		console.error("Unable to copy to clipboard using standard method.", ex);
	}
	const el = document.createElement('textarea');
	el.value = str;
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
}

/**
 * When match tagging gets split to fix nesting issues, visible gaps appear in the middle of the match due to the border CSS.
 * This function iterates all match objects and adjusts the CSS to remove those borders.
 */
export function RemoveInnerMatchBorders()
{
	let allMatchMarkup = document.querySelectorAll(".nuggetMatch");
	let lastMatchNum = "0";
	for (let i = 0; i < allMatchMarkup.length; i++)
	{
		let ele = allMatchMarkup[i];
		let matchNum = ele.id;
		if (matchNum == lastMatchNum)
		{
			// Previous element is same match.  Remove left border.
			ele.style.borderLeft = "0px";
		}
		if (i + 1 < allMatchMarkup.length && allMatchMarkup[i + 1].id == ele.id)
		{
			// Next element is same match.  Remove right border.
			ele.style.borderRight = "0px";
		}
		lastMatchNum = matchNum;
	}
}

export function RemoveEmptyMatchTags()
{
	let emptyInnerTags = document.querySelectorAll(".startmatch:empty");

	for (let i = 0; i < emptyInnerTags.length; i++)
	{
		let ele = emptyInnerTags[i];
		ele.parentElement.removeChild(ele);
	}
	let emptyOuterTags = document.querySelectorAll(".nuggetmatch:empty");
	for (let i = 0; i < emptyOuterTags.length; i++)
	{
		let ele = emptyOuterTags[i];
		ele.parentElement.removeChild(ele);
	}
}

/**
 * Absolutely positioned elements that are descendents of something with transform css applied get their offsets screwed up.
 * This function ajusts those offsets so that the element appears where it should.
 * It is currently used by Annotation and ToolsMenu.
 * @param {String} parent DOM element containing the element being positioned.  This is typically a paragraph element.
 * @param {Integer} left Left coordinate that would work if the ancestor wasn't rotated.
 * @param {Integer} top Top coordinate that would work if the ancestor wasn't rotated.
 * @returns {Object} Object containing fields "left" and "top" containing the input values adjusted such that they correct for the rotated ancestor's positioning effects.
 */
export function RotatedAncestorOffsetAdjustment(parent, left, top)
{
	let rotationRoot = null;
	let ccwResult = null;
	if (parent && window.getComputedStyle(parent).writingMode == 'vertical-rl')
	{
		// This is for annotations in counter clockwise rotated table cells.  The rotation causes the rotated object to
		// become the offset root and it thoroughly messes up coordinates, so this is a hack to fix them.

		// Unfortunately, clockwise rotated table cells trigger this if statement as well, but we can't apply these modifications there.
		// This is the only way I could find to detect this.

		ccwResult = IsCCWRotatedCell(parent);

		if (ccwResult.isCCW)
		{
			left = -left;
			top = -top;

			let rotationRootOffsets = FindOffsets(ccwResult.rotationRoot);
			left = -(rotationRootOffsets.width + left);
			top = -(rotationRootOffsets.height + top);
		}
	}
	return { left: left, top: top, rotationRoot: ccwResult ? ccwResult.rotationRoot : null }
}

/**
 * Detect if the given node is a descendant of a counter-clockwise rotated cell.  This significantly affects offset calculations.
 * @returns {Object} Object containing boolean value isCCW and DOM object rotationRoot, which is non-null only if isCCW is true, and contains the CCW rotated ancestor element.
 */
export function IsCCWRotatedCell(node)
{
	let n = node;
	let isCCW = false;
	let rotationRoot = null;
	while (n)
	{
		if (n.classList && n.classList.contains("cellRotateCW"))
		{
			isCCW = false;
			break;
		}
		else if (n.classList && n.classList.contains("cellRotateCCW"))
		{
			isCCW = true;
			rotationRoot = n;
			break;
		}
		n = n.parentElement;
	}
	return { isCCW: isCCW, rotationRoot: rotationRoot };
}