<template>
	<div :class="{ srCustomCheckboxLabel: true, cbContainer:true, srCustomCheckboxBlock: displayBlock }">
		<input ref="myInput" :aria-label="ariaLabel" :id="idprefix + index" :index="index" 
			   @focus="OnFocus()" :type="inputType" :role="roleStr" :name="radioName" class="srCustomCheckbox" 
			   @keydown.enter.stop.prevent="ToggleMe($event)" v-bind:checked="cbValue" 
			   @change="updateValue($event, $event.target.checked, $event.target.value)" 
			   :tabindex="srtabindex" :value="radioValue"
			   @keydown.up.prevent="KeyUp($event)" @keydown.down.prevent="KeyDown($event)"/>
		<div class="graphics nowrap">
			<span @mousedown="stopProp" @mouseup="stopProp" @click.stop.prevent="ToggleMe($event)" 
				  v-bind:class="{ srCustomCheckboxSpan: true, srCustomCheckboxSpanRound: !square, srCustomCheckboxSpanSquare: square, srCustomCheckboxSpanPrimaryColor: primaryColor, srCustomCheckboxSpanBlock: displayBlock, radio: (radio && !unselectable) }" :style="cbSpanStyle">
				<vsvg class="checkmark" :sprite="checkmarkSprite" title="" desc="" role="presentation" aria-hidden="true" />
			</span>
			<label v-if="useLabelTag && !ariaLabelOnly" class="srCustomCheckboxLabelContent" :for="idprefix + index" v-show="hasSlot" :style="labelStyle" @click.prevent="ToggleMe($event)"><slot></slot><span v-if="!hasSlot">{{ ariaLabel }}</span></label>
			<span v-else-if="!ariaLabelOnly" class="srCustomCheckboxLabelContent" :aria-label="ariaLabel" :for="idprefix + index" v-show="hasSlot" :style="labelStyle"><slot></slot><span v-if="!hasSlot">{{ ariaLabel }}</span></span>
		</div>
	</div>
</template>

<script>
	// A note about using SRCustomCheckbox: You should use v-model="someDataField" to get and set the 'checked' state.
	// You can also listen for the "input" event to be notified of checked-state changes.
	// Example: <SRCustomCheckbox v-model="item.selected">Label Goes Here</SRCustomCheckbox>

	import svg1 from "tdsAppRoot/images/sprite/checkmark.svg";
	import svg2 from "tdsAppRoot/images/sprite/radio_button.svg";
	import { MoveFocus } from "tdsAppRoot/library/TDSUtil.js";

	export default {
		components: {},
		props:
		{
			square: { // If false, round
				type: Boolean, // This does type validation.
				default: false
			},
			primaryColor: { // If true, uses an alternative style based on the theme's primary color
				type: Boolean,
				default: false
			},
			displayBlock: {
				type: Boolean,
				default: true
			},
			borderColor: {
				type: String,
				default: null
			},
			value: {
				default: false
			},
			srtabindex: {
				type: String,
				default: "0"
			},
			radioName: {
				type: String,
				default: ""
			},
			radioValue: {
				type: String,
				default: ""
			},
			radio: { // If true, this appears and behaves as a radio button.
				type: Boolean,
				default: false
			},
			unselectable: { // If radio and unselectable, then clicking the selected button will unselect it.
				type: Boolean,
				default: false
			},
			size: {
				type: Number,
				default: 0
			},
			fontSize: {
				type: String,
				default: "12pt"
			},
			useLabelTag: {
				type: Boolean,
				default: true
			},
			ariaLabelOnly: { 
				type: Boolean,
				default: false
			},
			ariaLabel: {
				type: String,
				default: null
			},
			index: {
				type: Number,
				default: 0
			},
			total: { // total number of checkboxes in the set, for arrow key navigation.
				type: Number,
				default: 1
			},
			idprefix: {
				type: String,
				default: ""
			},
			maxLabelWidth: {
				type: Number,
				default: -1
			}
		},
		mounted()
		{
			if (this.$refs.myInput)
				this.$refs.myInput.component = this;
		},
		methods:
		{
			OnFocus()
			{
				this.$emit("focus");
			},
			updateValue: function (event, checked, rbValue)
			{
				event.stopPropagation();
				if (this.radio && !this.unselectable)
					this.$emit("input", checked ? rbValue : "");
				else
					this.$emit("input", checked, rbValue);
			},
			ToggleMe(event)
			{
				if (!this.$refs.myInput)
					return;
				if (this.radio && !this.unselectable && this.$refs.myInput.checked) // Radio buttons are toggled off by toggling ON a different one in the group.
					return;
				this.updateValue(event, !this.$refs.myInput.checked, this.$refs.myInput.value);
				return false;
			},
			stopProp: function (event)
			{
				event.stopPropagation();
			},
			KeyUp(event)
			{
				if (this.total > 1)
					MoveFocus(event, -1, this.total, this.idprefix);
			},
			KeyDown(event)
			{
				if (this.total > 1)
					MoveFocus(event, 1, this.total, this.idprefix);
			}
		},
		computed:
		{
			roleStr()
			{
				if (this.radio)
					return "radio";
				else
					return null;
			},
			cbValue()
			{
				if (this.radio)
					return this.value === this.radioValue;
				else
					return this.value;
			},
			checkmarkSprite()
			{
				if (this.radio && !this.unselectable)
					return "#radio_button";
				else
					return "#checkmark";
			},
			inputType()
			{
				return this.radio && !this.unselectable ? "radio" : "checkbox";
			},
			cbSpanStyle()
			{
				let css = (this.borderColor ? "border-color:" + this.borderColor + "; fill: " + this.borderColor + ";" : "fill: #505050;");
				if (this.size > 0)
					return "width: " + this.size + "px; height: " + this.size + "px; " + css;
				else
					return css;
			},
			hasSlot()
			{
				return !!this.$slots.default;
			},
			labelStyle()
			{
				let css = "font-size: " + this.fontSize + ";";
				if (this.displayBlock)
					css += " display: inline-block;";

				if (this.maxLabelWidth >= 0)
					css += " max-width: " + this.maxLabelWidth + "px";

				return css;
			}
		}
	}
</script>

<style>
	.srCustomCheckboxLabel
	{
		cursor: pointer;
	}

	.srCustomCheckboxLabelContent
	{
		white-space: normal;
	}

	.cbContainer
	{
		display: inline-block;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		font-size: 0px; /* prevents unwanted whitespace between elements */
	}


	.nowrap
	{
		white-space: nowrap;
	}

	.srCustomCheckbox
	{
		opacity: 0;
		margin: 0px;
		border: none;
		padding: 0px;
		width: 0px;
		height: 0px;
		position: relative;
		left: 12px; /* why move an invisible element?  Because some screen readers (ChromeVox) highlight around this, not around our visible checkbox built from a span.*/
	}

	/*.srCustomCheckboxSpan:after is the "checked" mark inside the checkbox.
	These first two style sets control displaying it when the hidden internal checkbox is checked.
	The third one controls it's look.
	*/
	.srCustomCheckboxSpan .checkmark
	{
		display: none;
		width: 70%;
		height: 70%;
		margin: 15%;
	}

	.srCustomCheckboxSpan.radio .checkmark
	{
		margin: 2px;
		width: 10px;
		height: 10px;
	}

	.cbContainer input:focus-visible ~ .graphics > .srCustomCheckboxSpan
	/*,.cbContainer input:active ~ .srCustomCheckboxSpan*/ /* ":active" rule disabled because it introduces a visual bug in Chrome when rapidly tabbing and pressing space bar, multiple checkboxes remain "active" after losing focus */
	{
		border: 2px solid black;
		outline: 2px solid white;
		padding: 0px;
	}

	.cbContainer input.focus-visible ~ .graphics > .srCustomCheckboxSpan
	/*,.cbContainer input:active ~ .srCustomCheckboxSpan*/ /* ":active" rule disabled because it introduces a visual bug in Chrome when rapidly tabbing and pressing space bar, multiple checkboxes remain "active" after losing focus */
	{
		border: 2px solid black;
		outline: 2px solid white;
		padding: 0px;
	}

	.cbContainer input:checked ~ .graphics > .srCustomCheckboxSpan
	{
		background-color: #FFFFFF;
	}

		.cbContainer input:checked ~ .graphics > .srCustomCheckboxSpan .checkmark
		{
			display: block;
		}

	.cbContainer:hover input ~ .graphics > .srCustomCheckboxSpan
	{
		background-color: #dcdcdc;
	}

	.cbContainer:hover input:checked ~ .graphics > .srCustomCheckboxSpan
	{
		background-color: #e6e6e6;
	}

	.srCustomCheckboxLabelContent
	{
		display: inline;
		vertical-align: middle;
	}

	.srCustomCheckboxSpan
	{
		display: inline-block;
		vertical-align: middle;
		margin: 2px 7px 2px 4px;
		width: 14px;
		height: 14px;
	}

	/* This is the visible checkbox circle. */
	.srCustomCheckboxSpanRound
	{
		border: 1px solid #989671;
		padding: 1px;
		background-color: #FFFFFF;
		border-radius: 50%;
	}

	.srCustomCheckboxSpanSquare
	{
		border: 1px solid #989671;
		padding: 1px;
		background-color: #FFFFFF;
	}

	.srCustomCheckboxSpanPrimaryColor
	{
		border-radius: 3px;
		border-color: var(--cb-border-color);
		background-color: var(--cb-background-color);
		width: 20px;
		height: 20px;
	}

	.srCustomCheckboxBlock
	{
		display: block;
/*		flex-direction: row;
		align-items: center;*/
	}

		.srCustomCheckboxBlock *
		{
			display: inline-block;
		}
/*
	.srCustomCheckboxSpanBlock
	{
		display: block;
		flex-shrink: 0;
	}*/

</style>