import Vue from 'vue';
import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
import { GetNode, GetTreeWithExpansions } from "tdsAppRoot/API/TocAPI.js";
//import { ModalMessageDialog } from "tdsAppRoot/library/ModalDialog.js";

var NodeState = function (id)
{
	this.id = id;
	this.children = null;
};
Object.seal(NodeState);

let activeRequests = new Object(); // Keep track of which nodes have been requested, so we don't request them more than once.

function AddNodeInternal(state, expansionState, node)
{
	if (node)
	{
		state.$expansionStateMap[node.id] = expansionState;
		if (node.children && node.children.length > 0)
		{
			if (expansionState.children && expansionState.children.length > 0)
			{
				// If this node is already expanded in our expansion state, just traverse it and process its children.
				for (var i = 0; i < node.children.length; i++)
				{
					let childnode = node.children[i];
					let nstate = expansionState.children[i];
					AddNodeInternal(state, nstate, childnode);
				}
			}
			else
			{
				// Node is not expanded in our expansion state.  Expand it by adding the children from the server data.
				let expChildren = [];
				for (var i = 0; i < node.children.length; i++)
				{
					let childnode = node.children[i];
					let nstate = new NodeState(String(childnode.id));
					expChildren.push(nstate);
					AddNodeInternal(state, nstate, childnode);
				}
				expansionState.children = expChildren;
			}
			//commit("AddExpansionChildren", { nodeid: node.id, expChildren });
		}
		node.children = null; // Breaking this link for memory management reasons.  Use the expansionState tree to find children.

		//Vue.set(state.$tocNodeCache, String(node.id), node);
		state.$tocNodeCache[String(node.id)] = node;
		//commit("SetNodeCache", { nodeid: String(node.id), node: node });
	}
}
function ApplyTocResponse(data, state, commit, clearState = false)
{
	if (data.nodedata)
	{
		let node = data.nodedata;
		if (data.fullResetRequired)
		{
			if (node.level !== 0)
				throw Error("Unable to reset TOC as the root TOC node was not provided by the server.");
			console.log("TOC sync lost due to title update.  Performing full TOC cache reset.");
			//ModalMessageDialog("This title has been updated on the server.  The Table of Contents can no longer automatically synchronize unless you load another document.", "Notice", { showButtons: false });
			commit("InvalidateDocCacheForTitle", {fxid: node.fxid, clear: false });
			commit("FullReset");
		}
		let newExpState;
		if (state.$expansionStateMap[String(node.id)])
			newExpState = state.$expansionStateMap[String(node.id)];
		else
			newExpState = new NodeState(String(node.id));
		if (node.level === 0 || node.level === -1)
		{
			if (!node.parentId || !state.$tocNodeCache[node.parentId])
			{
				if (clearState)
					commit("ClearExpansionState", data.nodedata.fxid);

				commit("SetFxTocMapItem", { fxid: node.fxid, nodeid: node.id });
			}
			node.hasTitle = data.hasTitle;
		}
		commit("AddNode", { expansionState: newExpState, node: node });
	}
}
function CollapseNode(expansionStateMap, $tocNodeCache, nodeid)
{

	let expStateNode = expansionStateMap[nodeid];
	if (!expStateNode)
		return;

	if (expStateNode.children)
	{
		for (let i = 0; i < expStateNode.children.length; i++)
		{
			let childNodeId = expStateNode.children[i].id;
			CollapseNode(expansionStateMap, $tocNodeCache, childNodeId);
			if ($tocNodeCache[childNodeId])
				delete $tocNodeCache[childNodeId];
			if (expansionStateMap[childNodeId])
			{
				delete expansionStateMap[childNodeId];
			}
		}
		expStateNode.children = null;
	}
}
function CollectExpandedNodeIdsFromExpansionState(state, nodeId, outArray, autoExpandRootNode = true)
{
	if (!state.$expansionStateMap || !state.$expansionStateMap[nodeId])
		return;
	if ((outArray.length === 0 && autoExpandRootNode) || state.$expansionStateMap[nodeId].children)
		outArray[outArray.length] = nodeId; // Automatically expand first node.

	let rootNode = state.$expansionStateMap[nodeId];
	if (rootNode && rootNode.children)
	{
		for (let i = 0; i < rootNode.children.length; i++)
		{
			//result.push(rootNode.children[i].id);
			CollectExpandedNodeIdsFromExpansionState(state, rootNode.children[i].id, outArray, autoExpandRootNode);

		}
	}
}

// This state isn't persisted, unlike most state.  This exception is coded in the setState function in store.js.
const tocModule = {
	state()
	{
		return {
			$tocNodeCache: new Object(), // Simple map of nodeid on to node data.  NOT SAVED in session storage.
			tocExpansionChangeWatcher: 0, // Changed to manually trigger reactivity of expansionStateMap, which updates far too often in a loop to allow reactivity for every change.
			forceSyncWatcher: 0,	// Changed to manually trigger a reactive toc sync.
			$expansionStateMap: new Object(), // Map of nodeid on to a NodeState object in an expansion state tree.  Saved in session storage.
			fxTocMap: new Object(), // Map of fxid to toc root node nodeid.  Saved in session storage.
			selectedNodeId: null // ID of the currently "selected" toc node, for TOC sync.  The node's text gets colored red.
		};
	},
	mutations: {
		ReactToc: (state) =>
		{
			state.tocExpansionChangeWatcher++;
		},
		SetNodeCache: (state, { nodeid, node }) =>
		{
			Vue.set(state.$tocNodeCache, nodeid, node);
		},
		ForceSync: (state) =>
		{
			state.forceSyncWatcher++;
		},
		SetSelectedNodeId: (state, payload) =>
		{
			state.selectedNodeId = payload;
		},
		FullReset: (state, payload) =>
		{
			state.$expansionStateMap = new Object();
			state.$tocNodeCache = new Object();
			state.fxTocMap = new Object();
			state.selectedNodeId = null;
		},
		InitExpansionState: (state, payload) =>
		{
			state.$expansionStateMap = new Object();
		},
		ClearExpansionState: (state, fxid) =>
		{
			let rootNodeId = state.fxTocMap[fxid];
			CollapseNode(state.$expansionStateMap, state.$tocNodeCache, rootNodeId);

		},
		SetFxTocMapItem: (state, { fxid, nodeid }) =>
		{
			Vue.set(state.fxTocMap, fxid, nodeid);
		},
		AddNode: (state, { expansionState, node }) =>
		{
			AddNodeInternal(state, expansionState, node);
		},
		RemoveNode: (state, { nodeid }) =>
		{
			Vue.set(state.$tocNodeCache, nodeid, null);
		},
		AddExpansionChildren: (state, { nodeid, expChildren }) =>
		{
			// Adds children to an existing NodeState object.  The object must first be mapped by commiting a MapExpansionState mutation.
			let expStateNode = state.$expansionStateMap[nodeid];
			expStateNode.children = expChildren;
		},
		CollapseNode: (state, nodeid) =>
		{
			CollapseNode(state.$expansionStateMap, state.$tocNodeCache, nodeid);
			state.tocExpansionChangeWatcher++;
		}
	},
	actions: {
		// nodeid is optional.  If left out, the toc from the root level, expanded down to the document in docAddress, is returned.
		// docAddress is NOT optional.
		// fullTree means the server should return the entire toc tree from the root to the target node, in order to fill in gaps in 
		// local TOC cache.
		GetTocNode({ commit, dispatch, state, rootState, rootGetters }, { docAddress, fxid_override, nodeid, fullTree, directAction })
		{
			if (!nodeid)
				nodeid = null;
			return GetNode(docAddress, nodeid, fullTree, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, fxid_override, directAction).then(data =>
			{
				console.log("GetTocNode got response.");
				if (data.nodedata)
				{
					if (nodeid <= 0 && data.nodedata.level !== 0)
						ReportError(rootGetters.urlRoot, "Toc error.  Expected a toc tree starting with a level 0 node, since no node id was passed to server, but the root node returned is level " + node.level + ".", undefined, undefined, rootState.sid);
					if (typeof (state.$expansionStateMap) === 'undefined')
						commit("InitExpansionState");
					ApplyTocResponse(data, state, commit, nodeid == null);
					commit("ReactToc");
					if (data.fullResetRequired)
						return true;
				}
				return false;
			}).catch(err =>
			{
				console.error(err);
				if (err.name === "ApiError")
					ShowErrorWindow(err.message);
				else
					ReportError(rootGetters.urlRoot, "Toc error: " + err.message, null, err, rootState.sid);
			});
		},
		EnsureSelectedTocNodeLoaded({ commit, dispatch, state, rootState, rootGetters }, { docAddress, prevTocId, forceFullTree })
		{ // This is used by toc / doc scroll position sync and is logged as such.


			if (state.$tocNodeCache[state.selectedNodeId])
			{
				// Verify that all ancestors are loaded.  If they aren't, remove this node from the cache, then proceed to reload it below.
				let curNodeId = state.selectedNodeId;
				let targetNode = state.$tocNodeCache[curNodeId];

				let rootNode = state.fxTocMap[targetNode.fxid];

				let i = 0;
				while (targetNode.id !== rootNode)
				{
					if (targetNode.parentId && state.$tocNodeCache[targetNode.parentId])
					{
						targetNode = state.$tocNodeCache[targetNode.parentId];
					}
					else
					{
						// Node's parent is missing.  Remove the node.
						commit("RemoveNode", { nodeid: state.selectedNodeId });
					}
					i++;
					if (i > 10)
						break;
				}
				if (i > 10)
					console.log("ERROR: infinite loop.");
			}

			if (!state.$tocNodeCache[state.selectedNodeId] || !state.$expansionStateMap[state.selectedNodeId])
			{
				if (activeRequests[state.selectedNodeId])
					return Promise.resolve();
				activeRequests[state.selectedNodeId] = true;
				// First, find out if the previous toc node in the paragraph index is in our cache.  
				// If not, we have to request the entire toc tree down to our target node.
				let needFullTree = false;
				if (forceFullTree || !prevTocId || (prevTocId && (!state.$tocNodeCache[prevTocId] || !state.$expansionStateMap[state.prevTocId])))
					needFullTree = true;

				//console.log("Calling GetTocNode");
				return dispatch("GetTocNode", { docAddress, nodeid: state.selectedNodeId, fullTree: needFullTree, directAction: false }).then(tocSyncDisable =>
				{
					//console.log("GetTocNode expected to be complete.");
					delete activeRequests[state.selectedNodeId];
					commit("ReactToc");
					return Promise.resolve(tocSyncDisable);
				}).catch(err =>
				{
					delete activeRequests[state.selectedNodeId];
					commit("ReactToc");
					return Promise.reject("Unable to get TOC node " + state.selectedNodeId);
				});
			}
			return Promise.resolve();
		},
		RestoreTocCache({ commit, dispatch, state, rootState, rootGetters }, { fxid, autoExpandRootNode })
		{
			if (!fxid)
				return Promise.resolve();

			if (!state.$tocNodeCache[state.fxTocMap[fxid]])
			{
				let nodeids = [];
				CollectExpandedNodeIdsFromExpansionState(state, state.fxTocMap[fxid], nodeids, autoExpandRootNode);
				return GetTreeWithExpansions(nodeids, fxid, autoExpandRootNode, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }).then(data =>
				{
					ApplyTocResponse(data, state, commit, true);
					commit("ReactToc");
				}).catch(err =>
				{
					if (err.data && err.data.serviceReady)
					{
						console.error(err);
						if (!err.data.squelchErrorWindow)
						{
							if (err.name === "ApiError")
								ShowErrorWindow(err.message);
							else
								ReportError(rootGetters.urlRoot, "Toc restore error: " + err.message + "\nfxid: " + fxid, null, err, rootState.sid); // apibase already reports non-ApiError errors.  This report is redundant.  And is missing the session ID.
						}
					}
					else
					{

					}
				});
			}
		}
	}
};

export default tocModule;