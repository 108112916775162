import { CallServer } from "tdsAppRoot/API/apibase.js";

// Mirrors DocRequest on the server
var DocParams = function ()
{
	var docAddress = "";
	var searchid = null;
	var searchArgs = null; // SearchArgs object
	var categoryType = "";
};
Object.seal(DocParams);

var DocAddressParams = function ()
{
	var docAddress = "";
};
Object.seal(DocAddressParams);

var LogFilterParams = function ()
{
	var filterType = "";
};
Object.seal(LogFilterParams);

var RecaptchaVerifyParams = function ()
{
	var recaptchaToken = null;
};
Object.seal(RecaptchaVerifyParams);

export function GetDoc(docAddress, searchid, searchArgs, categoryType, store, useAlternateBuilder)
{
	let myargs = new DocParams();
	myargs.docAddress = docAddress;
	myargs.searchid = searchid;
	myargs.searchArgs = searchArgs;
	myargs.categoryType = categoryType;
	myargs.altDocBuilder = useAlternateBuilder;

	// Include docAddress in query string for logging purposes.  Otherwise logs that do not include the request body do not indicate which document was being requested.
	return CallServer("Doc", "", myargs, store, "da=" + encodeURIComponent(docAddress));
}

export function DocAddressToFxId(docAddress, store)
{
	let myargs = new DocAddressParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "DocAddressToFxid", myargs, store);
}

export function GetDocKeywords(docAddress, store)
{
	let myargs = new DocParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "Keywords", myargs, store);
}

export function RecaptchaVerify(store, recaptchaToken)
{
	let myargs = new DocParams();
	myargs.recaptchaToken = recaptchaToken;

	return CallServer("Doc", "RecaptchaVerify", myargs, store);
}


// This method is for concurrency verification.
export function ConfirmCache(docAddress, store)
{
	let myargs = new DocAddressParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "ConfirmCache", myargs, store);
}

// For logging document retrievals that hit the cache instead of the server.
export function LogDoc(docAddress, store)
{
	let myargs = new DocAddressParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "Loaded", myargs, store);
}

export function LogFilterTab(filterType, store)
{
	let myargs = new LogFilterParams();
	myargs.filterType = filterType;

	return CallServer("Doc", "FilterTab", myargs, store);
}

// Previous docAddress.  Internal use only.
export function Pdid(docAddress, store)
{
	let myargs = new DocAddressParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "Pdid", myargs, store);
}

// Next docAddress.  Internal use only.
export function Ndid(docAddress, store)
{
	let myargs = new DocAddressParams();
	myargs.docAddress = docAddress;

	return CallServer("Doc", "Ndid", myargs, store);
}
