<template>
	<div class="forgotPwBlock">
		<div class="desc">Please enter your {{userNameType}}, and we will email you a link to reset your password.</div>
		<label for="forgotPwUserNameInput">{{ userNamePlaceholder }}</label>
		<div class="commonLoginInputs">
			<div title="User name" class="loginInputDiv">
				<vsvg class="inputIcon" sprite="#person_fill" aria-hidden="true" title="" desc="" />
				<input id="forgotPwUserNameInput" type="text" ref="userNameInput" autocorrect="off" class="userNameInput"
					   autocapitalize="none" v-model="userName" autofocus autocomplete="username"/>
			</div>
		</div>
		<invisible-recaptcha sitekey="6LfrjUsUAAAAAIhDbhKQAtBYk4uJ2o8bkpiYIWu3" :validate="OnValidate" :callback="SendLinkClick"
							 class="commonLoginButton HideRecaptchaBadge" type="submit" :disabled="false" ref="captchaComponent" aria-label="Send Reset Link">
			Send Reset Link
		</invisible-recaptcha>
		<RecaptchaBadgeSubstitute />
		<FullScreenLoadingMsg :show="loading" msg="Processing…" />
	</div>
</template>

<script>
	import { InitiatePasswordResetRequest } from 'tdsAppRoot/API/ForgotPasswordAPI.js';
	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import { ModalMessageDialog } from "tdsAppRoot/library/ModalDialog.js";
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import InvisibleRecaptcha from 'tdsAppRoot/library/3rdParty/InvisibleRecaptcha.vue';
	import RecaptchaBadgeSubstitute from 'tdsAppRoot/components/Controls/RecaptchaBadgeSubstitute.vue';
	import svg1 from "tdsAppRoot/images/sprite/person_fill.svg";

	export default {
		components: { FullScreenLoadingMsg, InvisibleRecaptcha, RecaptchaBadgeSubstitute },
		props:
		{
			accountType: {
				type: Number,
				required: true
			},
			initialUserName: {
				type: String,
				default: ""
			}
		},
		data: function ()
		{
			return {
				userName: "",
				loading: false
			};
		},
		created()
		{
			this.userName = this.initialUserName;
		},
		mounted()
		{
			//this.SetFocus();
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.userNameInput.focus();
			},
			OnValidate()
			{
				return new Promise((resolve, reject) =>
				{
					if (this.userName)
						resolve(true);
					else
						reject(false);
				});
			},
			SendLinkClick(recaptchaToken)
			{
				if (!this.userName)
					ShowErrorWindow("Please enter a user name");
				else
				{
					this.loading = true;
					InitiatePasswordResetRequest(this.$store, this.accountType, this.userName, recaptchaToken).then(data =>
					{
						this.loading = false;
						ModalMessageDialog(data.message);
						this.$emit("requestSent");
					}
					).catch(err =>
					{
						this.loading = false;
						if (err.name === "ApiError")
						{
							if (err.data.reCAPTCHAFailure)
							{
								this.$refs.captchaComponent.ResetRecaptcha.apply(this.$refs.captchaComponent);
							}
							ShowErrorWindow(err.message);
						}
						else
						{
							ShowErrorWindow();
							ReportError(this.$store.getters.urlRoot, "Forgot Password error", null, err);
						}
					});
				}
			}
		},
		computed:
		{
			userNameType()
			{
				return this.accountType === 1 ? "profile's email address" : "user name";
			},
			userNamePlaceholder()
			{
				return this.accountType === 1 ? "Email address" : "User name";
			}
		}
	}
</script>

<style scoped>
	.desc
	{
		display: block;
		margin-bottom: 15px;
	}

	.commonLoginInputs
	{
		margin-bottom: 20px;
	}

	.forgotPwBlock, .userNameInput
	{
		color: black;
	}



	.commonLoginButton
	{
		box-sizing: border-box;
		text-align: center;
		font-family: Arial, Sans-serif;
		font-size: 15pt;
	}
</style>