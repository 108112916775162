var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "codeEntry" }, [
    _c("label", [
      _vm._v(
        "\n\t\tEnter the " +
          _vm._s(_vm.codeLabelString) +
          _vm._s(_vm.codeFromString) +
          ":\n\t\t"
      ),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.otpInput,
              expression: "otpInput"
            }
          ],
          ref: "otpInputEle",
          staticClass: "otpInput",
          attrs: {
            type: "text",
            pattern: "[0-9]*",
            inputmode: "numeric",
            placeholder: _vm.codeLabelString,
            autocomplete: "one-time-code"
          },
          domProps: { value: _vm.otpInput },
          on: {
            keypress: _vm.KeyPress,
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.otpInput = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _vm.canRememberDevice && _vm.method.Configured
      ? _c(
          "div",
          {
            staticClass: "rightAlignContent",
            staticStyle: { margin: "1em 0px 1em 0px" }
          },
          [
            _c(
              "SRCustomCheckbox",
              {
                attrs: { square: true, size: 16 },
                model: {
                  value: _vm.rememberDevice,
                  callback: function($$v) {
                    _vm.rememberDevice = $$v
                  },
                  expression: "rememberDevice"
                }
              },
              [_vm._v("\n\t\t\tRemember this device\n\t\t")]
            )
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.method.ID === 2
      ? _c("div", { staticStyle: { margin: "1em 0px" } }, [
          _vm._v("\n\t\tCan't find it? "),
          _c(
            "a",
            {
              staticClass: "alwaysUnvisited",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: function($event) {
                  return _vm.ResendEmailCode()
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.ResendEmailCode()
                }
              }
            },
            [_vm._v("Click here to resend the email.")]
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "rightAlignContent",
        staticStyle: { "margin-top": "1.25em" }
      },
      [
        _c(
          "button",
          {
            staticClass: "medButton greenHighButton submitButton",
            attrs: { tabindex: "0", disabled: !_vm.submitEnabled },
            on: {
              click: function($event) {
                return _vm.SubmitOTPCode()
              }
            }
          },
          [
            _vm._v(
              _vm._s(_vm.method.Configured ? "Submit" : "Enable MFA Method")
            )
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.inputTooLong
      ? _c(
          "div",
          {
            staticClass: "centerContent codeLengthMessage",
            staticStyle: { "margin-top": "1.25em" }
          },
          [_vm._v("\n\t\t(Code is too long)\n\t")]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }