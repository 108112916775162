var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "authenticatorExport" },
    [
      _c("div", { staticClass: "steplist", attrs: { role: "list" } }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "step", attrs: { role: "listitem" } }, [
          _c("b", [_vm._v("Step 2:")]),
          _vm._v(
            " " +
              _vm._s(
                _vm.showQR
                  ? "Scan the QR code below"
                  : "Configure the app with the values below"
              )
          )
        ]),
        _vm._v(" "),
        _vm._m(1)
      ]),
      _vm._v(" "),
      _vm.showQR
        ? [
            _c("div", { staticClass: "centerContent" }, [
              _c("img", {
                staticClass: "qrCode",
                attrs: {
                  src: _vm.data.AuthenticatorQrCodeDataUri,
                  alt: "QR code for Authenticator app setup"
                }
              })
            ])
          ]
        : [
            _c("div", { staticStyle: { "margin-bottom": "1em" } }, [
              _c(
                "div",
                { staticClass: "manualInstructionRow manualInstructionTitle" },
                [_vm._v("Details for manual setup:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "manualInstructionRow" }, [
                _vm._v("Account name: "),
                _c("div", { staticClass: "code" }, [
                  _vm._v(_vm._s(_vm.appName)),
                  _vm.myUsername
                    ? _c("span", [_vm._v(" (" + _vm._s(_vm.myUsername) + ")")])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "manualInstructionRow" }, [
                _vm._v("Secret key: "),
                _c("div", {
                  staticClass: "code",
                  domProps: { innerHTML: _vm._s(_vm.secretKeyHtml) }
                })
              ]),
              _vm._v(" "),
              _vm._m(2)
            ])
          ],
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "centerContent",
          staticStyle: { "margin-bottom": "1em" }
        },
        [
          _c(
            "a",
            {
              staticClass: "alwaysUnvisited",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: function($event) {
                  _vm.showQR = !_vm.showQR
                },
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  _vm.showQR = !_vm.showQR
                }
              }
            },
            [_vm._v(_vm._s(_vm.showQR ? "Can't scan?" : "Show QR code"))]
          )
        ]
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step", attrs: { role: "listitem" } }, [
      _c("b", [_vm._v("Step 1:")]),
      _vm._v(" Install and open an Authenticator app on your mobile device")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "step", attrs: { role: "listitem" } }, [
      _c("b", [_vm._v("Step 3:")]),
      _vm._v(
        ' Enter a 6-digit code from the app into the box below and click "Enable MFA Method"'
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "manualInstructionRow" }, [
      _vm._v("Type of key: "),
      _c("div", { staticClass: "code" }, [_vm._v("Time based")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }