import { CallServer } from "tdsAppRoot/API/apibase.js";

// Mirrors ForgotPasswordRequest on server
var ForgotPasswordRequest = function ()
{
	var accountType;
	var accountIdentifier;
	var token;
};
Object.seal(ForgotPasswordRequest);

export function InitiatePasswordResetRequest(store, accountType, accountIdentifier, recaptchaToken)
{
	try
	{
		let myargs = new ForgotPasswordRequest();
		myargs.accountType = FixMangledArrayArgument(accountType);
		myargs.accountIdentifier = FixMangledArrayArgument(accountIdentifier);
		myargs.token = FixMangledArrayArgument(recaptchaToken);
		return CallServer("ForgotPasswordData", "InitiateRequest", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SendPasswordResetCommand(store, accountType, accountIdentifier, token)
{
	try
	{
		let myargs = new ForgotPasswordRequest();
		myargs.accountType = FixMangledArrayArgument(accountType);
		myargs.accountIdentifier = FixMangledArrayArgument(accountIdentifier);
		myargs.token = FixMangledArrayArgument(token);
		return CallServer("ForgotPasswordData", "Reset", myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
function FixMangledArrayArgument(a)
{
	if (Array.isArray(a) && a.length > 0)
		return a[a.length - 1];
	else
		return a;
}