var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "forgotProfilePwRoot",
      attrs: {
        id: "forgotProfilePwRoot",
        role: "dialog",
        "aria-label": "Forgot Profile Password.  Press escape to close."
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "dialogBody" },
        [
          _c("ForgotPasswordBlock", {
            ref: "forgotPwBlock",
            attrs: { accountType: 1, initialUserName: _vm.initialUserName },
            on: { requestSent: _vm.closeMe }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "closeBtn" }, [
            _c(
              "a",
              {
                staticClass: "alwaysUnvisited",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.closeMe,
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.closeMe.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        { staticClass: "title", attrs: { id: "fppMsgTitle", role: "alert" } },
        [_vm._v("Forgot Profile Password")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }