var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      class: _vm.target.key,
      attrs: { target: "_blank", href: _vm.target.link }
    },
    [
      _c("vsvg", {
        attrs: {
          sprite: "#" + _vm.target.key,
          title: _vm.target.title,
          desc: _vm.target.desc,
          isPresentation: false
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }