import Vue from 'vue';
import axios from 'axios';
import { MakeToast } from 'tdsAppRoot/library/Toast.js';
import ErrorWindow from 'tdsAppRoot/components/Errors/ErrorWindow.vue';
import { ModalDialog, CountOpenDialogs } from 'tdsAppRoot/library/ModalDialog.js';
import { GetLastPromiseStackTrace } from "tdsAppRoot/library/PromiseTrace.js";
import { getStackTrace } from "tdsAppRoot/library/TDSUtil.js";
import StackTrace from "stacktrace-js";


export function ReportError(urlRoot, message, optionalRequestURL, optionalError, optionalSessionId)
{
	// Message should be a simple string describing what or where the problem was.
	// optionalError should be an Error object (e.g. Exception caught by "catch", with "stack" property)
	// optionalRequestURL is the user's requested URL.  window.location.href will be used if null or empty.

	let err = optionalError;

	if (process.BROWSER)
	{
		let fakeError = { stack: "FakeErrorToGetStack\n" + getStackTrace(), message: "", name: "FakeErrorToGetStack" };
		StackTrace.fromError(fakeError)
			.then(reportCallstackMapped =>
			{
				if (err && err.stack)
				{
					StackTrace.fromError(err)
						.then(errStackMapped =>
						{
							ReportError2(urlRoot, message, optionalRequestURL, errStackMapped, err, optionalSessionId, reportCallstackMapped, fakeError.stack);
						})
						.catch(ex =>
						{
							ReportError2(urlRoot, message, optionalRequestURL, null, err, optionalSessionId, reportCallstackMapped, fakeError.stack);
						});
				}
				else
					ReportError2(urlRoot, message, optionalRequestURL, null, err, optionalSessionId, reportCallstackMapped, fakeError.stack);
			})
			.catch(ex =>
			{
				if (err && err.stack)
				{
					StackTrace.fromError(err)
						.then(errStackMapped =>
						{
							ReportError2(urlRoot, message, optionalRequestURL, errStackMapped, err, optionalSessionId, null, fakeError.stack);
						})
						.catch(ex =>
						{
							ReportError2(urlRoot, message, optionalRequestURL, null, err, optionalSessionId, null, fakeError.stack);
						});
				}
				else
					ReportError2(urlRoot, message, optionalRequestURL, null, err, optionalSessionId, null, fakeError.stack);
			});
	}
	else
		ReportError2(urlRoot, message, optionalRequestURL, null, err, optionalSessionId);
}

let lastReportedError = null;
let count_IncludedApiRecents = 0; // Prevents excessive upload usage in the event of a long chain of errors.
function ReportError2(urlRoot, message, optionalRequestURL, mappedStack, err, sid, reportCallstackMapped, reportCallstack)
{
	if (err && err === lastReportedError)
	{
		console.error("A duplicate error report was prevented.", arguments);
		return;
	}
	lastReportedError = err;

	if (!sid && typeof appRoot === "object" && appRoot.$store.state.sid)
		sid = appRoot.$store.state.sid;

	let args = new ReportErrorRequest();
	args.SessionID = sid;
	args.message = message;
	args.callstack = mappedStack ? mappedStack.join("\r\n") : "";
	if (err)
	{
		args.filename = err.filename;
		if (err.stack)
			args.originalCallstack = err.stack;
		else
			args.originalCallstack = "Not available. err object had no stack: " + JSON.stringify(err, null, 2);
	}
	else
		args.originalCallstack = "Not available";

	args.reportCallstack = reportCallstack;
	args.reportCallstackMapped = reportCallstackMapped ? reportCallstackMapped.join("\r\n") : "";
	if (typeof navigator !== 'undefined')
		args.userAgent = navigator.userAgent;


	args.url = optionalRequestURL;
	if (typeof args.url === "undefined" || args.url === "undefined")
		args.url = null;
	if (!args.url && typeof window !== 'undefined' && typeof window.router !== 'undefined')
		args.route = window.router.currentRoute.fullPath;
	if (!args.url && typeof window !== 'undefined')
		args.url = window.location.href;

	if (typeof appRoot === "object" && count_IncludedApiRecents++ < 3)
		args.apiRecentCalls = JSON.parse(JSON.stringify(appRoot.$store.state.apiRecents));

	args.date = Date.now();

	axios.post(urlRoot + 'ReportError', args)
		.then(response =>
		{
			if (err)
				console.error(err);
			else
				console.error("Error: " + message, args.callstack ? args.callstack : args.originalCallstack);
		}).catch(ex =>
		{
			console.error("Error reporting error \"" + message + "\" Error Reporting URL: \"" + urlRoot + 'ReportError' + "\": ", ex, args.callstack ? args.callstack : args.originalCallstack);
		});
}

/**
 * Shows an error message in a toast.
 * @param {any} message A String, ApiError, or null.  If it is an ApiError instance, the message will be pulled from that.  If it is null or not a String, then a generic error message is shown.  As such, it is safe to pass in any kind of object without worry that an inappropriate error message will be shown.
 */
export function ShowErrorWindow(message = null)
{
	ShowCustomErrorWindow("TOAST", message);
}
/**
 * Shows an error message in a modal dialog.
 * @param {any} message A String, ApiError, or null.  If it is an ApiError instance, the message will be pulled from that.  If it is null or not a String, then a generic error message is shown.  As such, it is safe to pass in any kind of object without worry that an inappropriate error message will be shown.
 */
export function ShowModalErrorWindow(message = null)
{
	ShowCustomErrorWindow("MODAL", message);
}
/**
 * Shows an error message in a modal dialog.
 * @param {any} message A String, ApiError, or null.  If it is an ApiError instance, the message will be pulled from that.  If it is null or not a String, then a generic error message is shown.  As such, it is safe to pass in any kind of object without worry that an inappropriate error message will be shown.
 */
export function ShowMinorError(message = null)
{
	ShowCustomErrorWindow("MINOR", message);
}
/**
 * Shows an error message in a toast or modal dialog window.
 * @param {String} windowType "TOAST" or "TURNAWAY" or "MODAL" or "MINOR"
 * @param {any} message A String, ApiError, or null.  If it is an ApiError instance, the message will be pulled from that.  If it is null or not a String, then a generic error message is shown.  As such, it is safe to pass in any kind of object without worry that an inappropriate error message will be shown.
 */
export function ShowCustomErrorWindow(windowType = "TOAST", message = null)
{
	if (message && message.name === "ApiError")
	{
		if (message.data.squelchErrorWindow)
			return;
		message = message.message;
	}
	if (!message || typeof message !== "string")
		message = "An error has occurred.  Technical support has been notified.  We are sorry for the inconvenience.";
	if (windowType)
		windowType = windowType.toUpperCase();
	if (windowType === "TURNAWAY" || windowType === "MODAL")
	{
		if (CountOpenDialogs(c => c.props && c.props.contentComponent === ErrorWindow) <= 4)
			ModalDialog(ErrorWindow, { message, windowType });
		else
			console.error("Error Window Overflow", message);
	}
	else if (windowType === "MINOR" && typeof appRoot === "object")
		appRoot.$store.commit("EnqueueMinorError", message);
	else
		MakeToast({ message, type: "error", timeout: 60000 });
}

/**
 * A convenience function for showing error dialogs and reporting errors. This can be used as the catch handler for a promise.
 * If necessary, callers should check for and react to specific error conditions before deciding to call this.
 * When passing in a string, it is reported and shown in an error window.
 * When passing in an ApiError instance, it is not reported, but the message is shown in an error window. (ApiError comes from the server; it should have already sent emails if necessary)
 * When passing in a non-ApiError Error instance, it is reported and a generic message is shown in an error window. (non-ApiError error messages typically don't come from us)
 * @param {any} err Error object or string.
 */
export function DefaultErrorHandler(err)
{
	if (typeof appRoot === "object")
	{
		if (err && err.name)
		{
			if (err.name !== "ApiError")
				ReportError(appRoot.$store.getters.urlRoot, err.message, null, err, getSID());
		}
		else if (err)
			ReportError(appRoot.$store.getters.urlRoot, err, null, null, getSID());
	}
	ShowErrorWindow(err);
}

//Vue.config.warnHandler = function (msg, vm, trace) {
//	console.error("Warning: " + msg + trace);
//	if (appContext && appContext.urlRoot)
//		ReportError(appContext.urlRoot, msg + " - trace: " + trace, null, null);
//}

Vue.config.errorHandler = function (err, vm, info)
{
	// `info` is a Vue-specific error info, e.g. which lifecycle hook
	// the error was found in. 
	console.error(err, info, vm);
	if (typeof err.tdsErrorHandled === "undefined" || !err.tdsErrorHandled)
	{
		err.tdsErrorHandled = true;
		if (appContext && appContext.urlRoot)
		{
			if (err.message)
			{
				if (err.message.indexOf('The play() request was interrupted by a call to pause().') === 0)
					return;
				if (err.message.indexOf('The play() request was interrupted because the media was removed from the document.') === 0)
					return;
				//let componentHtml = null;
				//try
				//{
				//	componentHtml = vm.$el.innerHTML;
				//}
				//catch (ex2) { }
				//if (componentHtml && componentHtml.indexOf('xlink:href="#play"') > -1)
				//{
				//	// Video Player random error
				//	if (err.message === 'The operation is not supported.')
				//		return;
				//	if (err.message === 'The element has no supported sources.')
				//		return;
				//}
				if (err.message.indexOf("Failed to execute 'querySelector' on 'Document':") === 0)
					return;
			}
			var errMsg = 'Unhandled Error (Vue): ' + err.message;
			try
			{
				errMsg += '\r\n\r\nVue-specific error info: "' + info + '"';
			}
			catch (ex2)
			{
				errMsg += '\r\n\r\nVue-specific error info: [unable to read info object]';
			}
			try
			{
				errMsg += '\r\n\r\nComponent HTML: ' + vm.$el.innerHTML;
			}
			catch (ex2)
			{
				errMsg += '\r\n\r\nComponent HTML: [unable to read component html]';
			}
			ReportError(appContext.urlRoot, errMsg, null, err, getSID());
		}

		ShowErrorWindow();
	}
};

function ErrorHandler(e)
{
	if (e === null)
	{
		return;
	}
	let err;
	if (typeof e.error === "undefined" || e.error === null)
	{
		err = e;
	}
	else
		err = e.error;

	if (err.message === "ResizeObserver loop limit exceeded")
		return; // swallow this error, it is harmless.
	else if (err.message === "TypeError: fullscreen error" || err.message === "TypeError: Fullscreen request denied")
	{
		// Known error spam from clappr despite successful fullscreen toggle. Caused by double-clicking the video player to enter fullscreen on some devices, sometimes.
		return;
	}
	else if (err.message === "Cannot set property timeStamp of #<Event> which has only a getter")
	{
		return; // Known error spam from clappr. Not reproducible.
	}
	else if (err.message === "Can't find variable: setIOSParameters"
		|| err.message === "TypeError: undefined is not an object (evaluating 'p.manager')"
		|| err.message === "TypeError: undefined is not an object (evaluating 'p.manager')"
		|| err.message.indexOf('hide-icon hidden mce-visual-caret-hidden') > -1
		|| err.message === "Identifier 'originalPrompt' has already been declared"
		|| err.message === "Cannot redefine property: BetterJsPop")
	{
		return; // Known browser extension spam
	}
	else if (err.stack)
	{
		let stackStr = err.stack.toString();
		if (stackStr.indexOf('Object.fdWebExtension.fdObserveMutations') > -1)
		{
			return; // Known browser extension spam
		}
	}

	// When an error occurs in a script loaded from another domain that didn't provide an acceptable "Access-Control-Allow-Origin" header, the browser generates an error with a generic message and no call stack.
	let isGenericScriptError = (err.message === "Script error." || err.message === "Script error") && !err.stack;
	let reportIt = !isGenericScriptError;

	if (typeof err.tdsErrorHandled === "undefined" || !err.tdsErrorHandled)
	{
		err.tdsErrorHandled = true;
		if (reportIt)
			ReportError(appContext.urlRoot, "Unhandled Error (global handler): " + err.message, null, err, getSID());
		if (!isGenericScriptError)
			ShowMinorError(err.message || "Unhandled error. May have been caused by a browser extension.");
	}
}

function getSID()
{
	return typeof appRoot === "object" && appRoot.$store && appRoot.$store.state.sid ? appRoot.$store.state.sid : undefined;
}

// Sometimes the vue error handler is not called because the error didn't happen in a vue callstack.
if (typeof window !== "undefined" && typeof appContext !== "undefined")
{
	window.addEventListener('error', function (e)
	{
		ErrorHandler(e);
	});
	let numGlobalPromiseRejectionEmails = 0;
	window.addEventListener('unhandledrejection', function (e)
	{
		let stackTrace = GetLastPromiseStackTrace();
		let msg = e.reason ? e.reason.toString() : "";

		let err = undefined;
		if (e.reason && e.reason.stack)
			err = e.reason;
		else if (stackTrace) // If you are investigating an error email with the following message in it, note that the stack trace you received may refer to a different promise than the one which caused the unhandled rejection.  Especially if the browser was IE.
			err = { stack: "FakeErrorToGetStack: NOTE: This stack trace is from the last recorded Promise.then call, and may not match the promise that had an unhandled rejection\n" + stackTrace, message: "NOTE: This stack trace is from the last recorded Promise.then call, and may not match the promise that had an unhandled rejection", name: "FakeErrorToGetStack" };

		let report = true;
		if (msg === "Error: Network Error")
		{
			// ApiBase should have already shown a toast, typically.
		}
		else if (msg === "Timeout" || msg === "Timeout (u)")
			ShowErrorWindow("A network error has prevented communication with our servers.  Please try again later.");
		else if (msg === "TypeError: fullscreen error" || msg === "TypeError: Fullscreen request denied")
		{
			// Known error spam from clappr despite successful fullscreen toggle. Caused by double-clicking the video player to enter fullscreen.
			report = false;
		}
		else
		{
			if (msg.indexOf('Object Not Found Matching Id:') === 0
				//|| msg.indexOf('The play() request was interrupted by a call to pause().') === 0 // I'd like to know when these are unhandled, so I tried to handle it at the most likely source.
				|| ++numGlobalPromiseRejectionEmails > 5)
			{
				report = false;
			}
			ShowMinorError(msg || "Unhandled Promise rejection. May have been caused by a browser extension.");
		}

		if (report)
			ReportError(appContext.urlRoot, "Unhandled Promise Rejection (global handler): " + msg, undefined, err, getSID());
	});
}

// Mirrors ReportErrorRequest on server
var ReportErrorRequest = function ()
{
	var SessionID;
	var message;
	var callstack;
	var originalCallstack;
	var reportCallstack;
	var userAgent;
	var route;
	var url;
	var filename;
};
Object.seal(ReportErrorRequest);
