<template>
	<div id="forgotProfilePwRoot" class="forgotProfilePwRoot" role="dialog" aria-label="Forgot Profile Password.  Press escape to close.">
		<div class="titleBar">
			<div class="title" id="fppMsgTitle" role="alert">Forgot Profile Password</div>
		</div>
		<div class="dialogBody">
			<ForgotPasswordBlock ref="forgotPwBlock" :accountType="1" :initialUserName="initialUserName" @requestSent="closeMe" />
			<div class="closeBtn">
				<a role="button" tabindex="0" class="alwaysUnvisited" @click="closeMe" @keypress.enter.space.prevent="closeMe">Cancel</a>
			</div>
		</div>
	</div>
</template>

<script>
	import ForgotPasswordBlock from "tdsAppRoot/components/Controls/ForgotPasswordBlock.vue";

	export default {
		components: { ForgotPasswordBlock },
		methods:
		{
			SetFocus()
			{
				if (this.$refs.forgotPwBlock)
					this.$refs.forgotPwBlock.SetFocus();
			},
			closeMe()
			{
				this.$emit('close', false);
			}
		},
		computed:
		{
			initialUserName()
			{
				return this.$store.getters.GetProfileRegistrationField("email");
			}
		}
	}
</script>

<style scoped>
	.forgotProfilePwRoot
	{
		max-width: 320px;
		min-width: 200px;
		background-color: #FFFFFF;
	}

	.titleBar
	{
		padding: 8px 14px;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.dialogBody
	{
		max-width: 320px;
		padding: 20px;
		box-sizing: border-box;
	}

	.closeBtn
	{
		margin-top: 20px;
		text-align: center;
	}
</style>