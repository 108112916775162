<template>
	<div class="errorPage" role="dialog" aria-modal="true" aria-labelledby="errMsgLbl" aria-describedby="errMsgLbl">
		<div class="errorPageTitle">
			{{ pageTitle }}
		</div>
		<div class="errorPagePanel">
			<div class="errorMessage" id="errMsgLbl">{{ errMsg }}</div>
			<div v-if="link" class="link"><a :href="link">{{ linkTxt }}</a></div>
			<!--<div>Support Email: {{ supportEmail }}</div>
			<div>Support Phone: {{ supportEmail }}</div>-->
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			errMsg: {
				type: String,
				default: "An error has occurred.  Technical support has been notified."
			},
			supportEmail: {
				type: String,
				default: null
			},
			supportPhone: {
				type: String,
				default: null
			},
			link: {
				type: String,
				default: null
			},
			linkTxt: {
				type: String,
				default: null
			},
			pageTitle: {
				type: String,
				default: "Error"
			}
		}
	}
</script>

<style scoped>
	.errorPage
	{
		width: 100%;
		padding-top: 10px;
	}

	.errorPageTitle {
		text-align: center;
		width: 100%;
		font-size: 30pt;
		color: #6699cc;
	}

	.errorMessage 
	{
		text-align: center;
	}
	
	.link 
	{
		margin-top: 20px;
	}

	.errorPagePanel
	{
		margin: auto;
		width: 600px;
		padding: 4px 5px 4px 5px;
		margin-top: 20px;
	}

</style>