import axios from 'axios';
import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
import { isLocalStorageEnabled, IsSessionStorageEnabled } from "tdsAppRoot/library/TDSUtil.js";
import { ShowCornerLoader, HideCornerLoader } from 'tdsAppRoot/library/ModalDialog.js';

//export var SessionMixin = {
//	SessionID: ""
//};


/**
 * Calls a controller action and returns a promise.
 * 
 * The promise will resolve only upon a graceful response with status "OK".
 * 
 * If the server responds gracefully with status "Error", the promise is rejected with an ApiError instance as the reason. The ApiError's message will be a copy of the data.errMsg field.  The response data is also provided with the ApiError so that other data fields may be inspected.
 * 
 * If the server responds gracefully with status "NeedsSession", this method will redirect the browser to the login page.
 * 
 * Any other error, such as a network failure or unexpected response, will result in the promise being rejected.
 * 
 * @param {String} controller Name of the ASP.NET controller to call.
 * @param {String} action Name of the controller action to invoke.  Optional (invokes "Index" action if omitted).
 * @param {Object} argumentObject Object containing all necessary arguments for the action.  Sent to the server in its entirety as JSON.  Should correspond as exactly as possible with one of the classes in Core/KentAPI/RequestsFromClient.
 * @param {any} store The vuex store singleton.
 * @param {String} query Query string.  It is strongly encouraged to use the argumentObject instead of this where possible.
 * @param {Number} retryCount (Internal Use) for counting retries
 * @returns {Promise} A promise that resolves if the API response has status "OK", rejects otherwise.
 */
export function CallServer(controller, action, argumentObject, store, query, retryCount = 0)
{
	if (query && query[0] === '?')
		query = query.substr(1, query.length - 1);
	if (retryCount === 0 && typeof argumentObject !== 'undefined' && argumentObject && typeof argumentObject.docAddress !== 'undefined' && argumentObject.docAddress)
	{
		var a = ""; for (var b = 0; b < argumentObject.docAddress.length; b++) { var c = argumentObject.docAddress.charAt(b), d = c.charCodeAt(0), e = !isNaN(parseInt(c, 10)), f = !1; c.match(/[a-zA-Z]/i) ? (90 >= d && (f = !0), d += 13, f && 90 < d ? d = 64 + (d - 90) : 122 < d && (d = 96 + (d - 122))) : e && (d += 5, 57 < d && (d = 47 + (d - 57))); a += String.fromCharCode(d) } argumentObject.docAddress = a;
	}
	if (!store)
		console.error("An API call was made without passing in a valid store reference.");
	let fullUrl = store.getters.urlRoot + controller + (action ? "/" + action : "") + (store.state.sid ? "?SessionID=" + store.state.sid + (query ? "&" + query : "") : (query ? "?" + query : ""));
	let headers = {
		'X-Requested-With': 'XMLHttpRequest',
		'Accept': 'application/json'
	};
	if (document.documentElement && document.documentElement.className && document.documentElement.className.indexOf("translated-ltr") !== -1)
	{
		headers = {
			'X-Requested-With': 'XMLHttpRequest',
			'Accept': 'application/json',
			'MachineTranslated': 'true'
		};
	}
	return axios.post(fullUrl,
		argumentObject,
		{
			headers: headers
		}
	).then(response =>
	{
		TrackAPICall(store, fullUrl, argumentObject, response, false);
		// Basic response validation
		HideCornerLoader();
		if (!response)
			return Promise.reject(new Error("API response for URL " + fullUrl + " was " + typeof response === "undefined" ? "undefined" : "null"));
		let data = response.data;
		if (!data)
			return Promise.reject(new Error("API response for URL " + fullUrl + " was missing the data field: " + JSON.stringify(response, null, 2)));
		if (typeof data === "string")
		{
			try
			{
				data = JSON.parse(data);
			}
			catch (ex)
			{
				console.error(ex);
			}
		}
		if (typeof data.status === 'undefined')
		{
			// A common error we see is where a McAfee Web Gateway intercepts the API call and breaks it.
			if (response.headers && response.headers["via"] && response.headers["via"].indexOf("McAfee Web Gateway") !== -1)
			{
				let newErr = new Error("McAfee Web Gateway intercepted the response.");
				newErr.mcafee = true;
				throw newErr;
			}

			return Promise.reject(new Error("API response data for URL " + fullUrl + " was missing the status field: " + JSON.stringify(response, null, 2)));
		}
		if (data.serviceReady !== 'undefined')
			store.commit("SetServerState", data.serviceReady);

		// Handle app updates
		if (typeof appContext !== "undefined" && data.build && data.build !== appContext.build)
		{
			let actionToTake;
			if (data.build < appContext.build) // Downgrading; use action specified in appContext.
				actionToTake = appContext.onDowngrade;
			else // Upgrading; use action specified in data.
				actionToTake = data.onUpdate;

			if (actionToTake && actionToTake !== "None")
			{
				console.log("Migrating from build " + appContext.build + " to " + data.build + " with action \"" + actionToTake + "\"");
				if (actionToTake === "Reload")
				{
					location.reload(true);
					return new Promise((resolve, reject) => { });
				}
				else if (actionToTake === "ClearState")
				{
					if (IsSessionStorageEnabled())
						sessionStorage.clear();
					if (isLocalStorageEnabled())
						localStorage.clear();
					// Keep cookies (including session)
					location.reload(true);
					return new Promise((resolve, reject) => { });
				}
			}
		}

		// Handle missing/expired session
		if (data.status === "NeedsSession")
		{
			store.commit("SetSessionID", null);
			if (typeof window !== 'undefined') 
			{
				console.log("Request failed due to lack of an active session.");

				let targetUrl;
				if (data.isPendingLogin)
					targetUrl = store.getters.urlRoot + "login/PendingLogin?groupId=" + data.groupId + "&";
				else
					targetUrl = store.getters.urlRoot + "login?";
				targetUrl += "path=" + encodeURIComponent(window.location.pathname + "?" + window.location.search) + (data.errMsg ? "&errMsg=" + data.errMsg : "");
				window.location.href = targetUrl;
				// We've triggered navigation, but script execution continues.
				// Rather than make all API calls handle NeedsSession responses, we'll just make this promise never resolve.
				return new Promise((resolve, reject) => { });
			}
			else
				return Promise.reject(new NeedsSessionError(data.status, data)); // This should only happen when doing serverside rendering, and is probably not the way we want to handle the situation.
		}

		// Detect automatic session recovery ("autologon")
		if (data.newSid)
		{
			if (typeof window !== "undefined" && window.appRoot && data.isAutoLogon)
				window.appRoot.ShowAutoLogonMsg();
			store.commit("SetSessionID", data.newSid);
		}

		// Detect profile login/logout
		if (!(controller.toLowerCase() === 'mystatref' && action.toLowerCase() === 'getcurrent'))
		{
			if ((typeof data.isActive === 'undefined' || data.isActive) && data.currentProfile && !data.currentProfile.hasProfile && store.state.profile !== null)
				store.dispatch("ProfileLoggedOut");
			else if (data.currentProfile && data.currentProfile.hasProfile && (store.state.profile === null || data.currentProfile.profileEmail !== store.state.profile.ProfileEmail))
				store.dispatch("RefreshProfile");
		}

		// Branch based on status value.
		if (data.status === "Error")
		{
			// These error messages are typically intended to be shown to the user.
			// The calling code can check if the exception name is "ApiError".
			return Promise.reject(new ApiError(data.errMsg, data));
		}
		else if (data.status === "OK")
		{
			// Guarantee: The promise is only resolved if data.status is "OK"
			return Promise.resolve(data);
		}
		return Promise.reject(new Error('Unhandled response data.status "' + data.status + '"'));
	}
	).catch(ex =>
	{
		TrackAPICall(store, fullUrl, argumentObject, ex, true);
		if (ex.data && typeof ex.data.serviceReady !== 'undefined')
			store.commit("SetServerState", ex.data.serviceReady);
		// No need to report "ApiError" since these messages come gracefully from the server.
		if (ex.name !== "ApiError" && ex.name !== "NeedsSessionError")
		{
			let isNetworkError = ex.message === "Network Error" || ex.message === "timeout of 0ms exceeded" || ex.mcafee || ex.message === "Request aborted";  // "timeout of 0ms exceeded" tends to come from Apple products
			if (retryCount < 5
				&& (isNetworkError
					|| (ex.response && ex.response.status === 503) // 503 Service Unavailable was seen often around Kent launch, suspected of being generated by exprozy servers.
					|| (ex.response && ex.response.status === 504) // 504 Gateway Timeout was seen rarely around Kent launch, suspected of being generated by exprozy servers.
					|| ex.message === "Request failed with status code 503"
					|| ex.message === "Request failed with status code 504"
				))
			{
				// Retry a couple times
				ShowCornerLoader();
				return new Promise((resolve, reject) =>
				{
					setTimeout(() =>
					{
						CallServer(controller, action, argumentObject, store, query, retryCount + 1).then(resolve).catch(reject);
					}, 500 * (retryCount + 1));
				});
			}
			else
			{
				// Check for header "Tdsresponse: 1"
				let tdsResponse = ex.response && ex.response.headers && ex.response.headers.tdsresponse === "1" ? "" : "\n[We were unable to verify that this response came from TDS]";
				console.error("API call error for " + controller + "/" + action + ": " + ex.message + tdsResponse);
				if (isNetworkError)
				{
					if (ex.mcafee)
					{
						ShowErrorWindow("It appears that a McAfee Web Gateway is intercepting attempts to communicate with the " + appContext.appName + " servers.  This typically happens when McAfee Web Gateway tries to send a progress page instead of the actual server response.");
					}
					else if (IsSessionStorageEnabled())
					{
						let lastReload = window.sessionStorage.getItem("networkErrorReload");
						if (lastReload)
						{
							lastReload = new Date(lastReload);
							if ((new Date()) - lastReload > 600000) // 10 minutes
							{
								// It's been long enough, try the reload again.
								lastReload = null;
							}
						}
						window.sessionStorage.setItem("networkErrorReload", (new Date()).toString());
						if (!lastReload)
						{
							window.location.reload(); // This has the potential to cause an infinite reload loop.
							return new Promise((resolve, reject) => { });
						}
					}
					let ssMessage = IsSessionStorageEnabled() ? "" : "\n[Session Storage is not available on this client]";
					ReportError(store.getters.urlRoot, "Repeated network errors for " + controller + "/" + action + ": " + ex.message + ssMessage + tdsResponse, null, ex, store.state.sid);
					if (!ex.mcafee && (!ex.data || !ex.data.squelchErrorWindow))
						ShowErrorWindow("A network error has prevented communication with our servers.  Please try again later.");
				}
				else 
				{
					ReportError(store.getters.urlRoot, "API call error for " + controller + "/" + action + ": " + ex.message + tdsResponse, null, ex, store.state.sid);
					if (!ex.data || !ex.data.squelchErrorWindow)
						ShowErrorWindow("An error has occurred.  Technical support has been notified.  We are sorry for the inconvenience.");
				}
			}
		}
		HideCornerLoader();
		return Promise.reject(ex);
	});
}
/**
 * 
 * @param {any} store Vuex store
 * @param {String} fullUrl Full URL of the API call
 * @param {Object} args API arguments
 * @param {Object} result Result of the API call
 * @param {Boolean} failed True if the API call returned error status or if the request otherwise failed.
 */
function TrackAPICall(store, fullUrl, args, result, failed)
{
	let meta = { fullUrl: TruncateAndTag(fullUrl, 4000), date: Date.now(), failed: !!failed };
	if (failed)
		meta.error = GetErrorFields(result);
	try
	{
		meta.args = TruncateAndTag(JSON.stringify(args, null, 2), 8000);
	}
	catch (ex)
	{
		meta.args = "Failed to serialize args:" + JSON.stringify(ex, null, 2);
	}
	try
	{
		meta.result = TruncateAndTag(JSON.stringify(result, null, 2), 15000);
	}
	catch (ex)
	{
		meta.result = "Failed to serialize result:" + JSON.stringify(ex, null, 2);
	}
	store.commit("TrackAPICall", meta);
}
function TruncateAndTag(str, maxLength = 15000)
{
	return str.length > maxLength ? str.substr(0, maxLength) + "[…truncated]" : str;
}
function GetErrorFields(err)
{
	try
	{
		if (err)
			return { name: err.name, message: err.message, stack: err.stack };
		else
			return { name: "GetErrorFields Error", message: "Argument was null.", stack: "" };
	}
	catch (ex)
	{
		return { name: "GetErrorFields Error", message: "Unabled to get error fields from argument. " + err ? err.message : "", stack: "" };
	}
}
/**
 * An instance of ApiError is provided as the CallServer promise reject reason when the response from the server had status "Error" (typically with an error message string).  The message will be the errMsg provided by the server.  The full response data is also made available through this object.
 */
export class ApiError extends Error
{
	constructor(message, data)
	{
		super(message);
		this.name = "ApiError";
		this.data = data;
	}
}
/**
 * An instance of NeedsSessionError is provided as the CallServer promise reject reason when the response from the server had status "NeedsSession".
 */
export class NeedsSessionError extends Error
{
	constructor(message, data)
	{
		super(message);
		this.name = "NeedsSessionError";
		this.data = data;
	}
}