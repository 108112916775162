<template>
	<div :class="{ modalDialogRoot: true, positionAbsolute: usePositionAbsolute }"
		 :aria-hidden="modalDialogIndex < (modalDialogCount - 1)"
		 :style="rootStyle">
		<div class="modalDialogOverlay" @mousedown="overlayMouseDown" @mouseup="overlayClick"></div>
		<div class="modalDialogPositioner" :style="dynPosStyle" @mousedown="overlayMouseDown" @mouseup="overlayClick">
			<!-- Needed beause iOS treats position: fixed as position: absolute for these dialogs -->
			<div v-if="useFocusCatcher" class="FocusCatcher" tabindex="0" @focus="FocusCatcher(false)"></div>
			<div class="modalDialogContent"
				 :style="dynContentStyle"
				 @mousedown.stop="contentMouseDown"
				 @mouseup.stop.prevent="noop"
				 @click="contentClick">
				<component v-bind:is="contentComponent"
						   v-bind="contentProps"
						   ref="contentComponent"
						   @close="closeRequested"
						   aria-modal="true" />
			</div>
			<div v-if="useFocusCatcher" class="FocusCatcher" tabindex="0" @focus="FocusCatcher(true)"></div>
		</div>
	</div>
</template>

<script>
	// All modal dialogs in the app should use this component as a base, to provide easier maintenance and consistent behavior.

	import ModalDialogAccessibilityMixin from 'tdsAppRoot/library/ModalDialogAccessibilityMixin.js';
	import { BrowserIsIOS, FocusDescendant } from 'tdsAppRoot/library/TDSUtil.js';

	export default {
		mixins: [ModalDialogAccessibilityMixin],
		props:
		{
			contentComponent: {
				type: Object,
				required: true
			},
			contentProps: null,
			zIndex: {
				type: Number,
				default: 0
			},
			mediumWidth: { // Makes the dialog expand to fill a reasonable amount of a wide window, but no more.  Currently 777px.
				type: Boolean,
				default: false
			},
			positionAbsolute: {
				type: Boolean,
				default: false
			},
			overflowHidden: {
				type: Boolean,
				default: false
			},
			halfHeight: {
				type: Boolean, // If true, the dialog will be centered in the top half of the window instead of the full window.
				default: false
			},
			returnFocus: {
				type: HTMLElement,
				default: null
			},
			modalDialogIndex: {
				type: Number,
				required: true
			},
			modalDialogCount: {
				type: Number,
				required: true
			},
			valign: {
				type: String,
				default: "center"
			},
			offsetTop: {
				type: String,
				default: ""
			},
			defaultCloseOnOverlayClick: {
				type: Boolean, // If true, clicking the overlay calls DefaultClose, just like pressing Escape does.
				default: true
			}
		},
		data()
		{
			return {
				lastMouseDownWasOnOverlay: false,
				absPosTop: 0,
				useFocusCatcher: true,
				enableClose: false  // Prevents automatic immediate close on load which can otherwise happen in some situations.
			};
		},
		methods:
		{
			noop()
			{
				// no op
			},
			contentMouseDown(e)
			{
				e.stopPropagation();
				this.lastMouseDownWasOnOverlay = false;
			},
			contentClick(e)
			{
				e.stopPropagation();
			},
			overlayMouseDown(e)
			{
				this.lastMouseDownWasOnOverlay = true;
			},
			overlayClick(e)
			{
				if (this.defaultCloseOnOverlayClick && this.lastMouseDownWasOnOverlay)
					this.defaultClose();
			},
			defaultClose()
			{
				if (this.enableClose)
				{
					if (this.$refs.contentComponent && typeof this.$refs.contentComponent.DefaultClose === "function")
						this.$refs.contentComponent.DefaultClose();
					else
						this.$emit("close", false);
				}
			},
			closeRequested(args)
			{
				if (typeof args === "undefined")
					this.$emit("close", false);
				else
					this.$emit("close", args);
			},
			SetFocus()
			{
				if (!this.$refs.contentComponent)
					return;
				if (typeof this.$refs.contentComponent.SetFocus === "function")
				{
					this.$nextTick(() =>
					{
						if (this.$refs.contentComponent)
							this.$refs.contentComponent.SetFocus();
					});
				}
				else
				{
					//console.error("ModalDialog contentComponent does not have SetFocus function");
					this.FocusCatcher(true);
				}
			},
			FocusCatcher(focusFirstItem)
			{
				if (this.$refs.contentComponent)
					FocusDescendant(this.$refs.contentComponent.$el, focusFirstItem);
			}
		},
		computed:
		{
			myRole()
			{
				if (this.type === "alertdialog")
					return "alertdialog";
				return "dialog";
			},
			rootStyle()
			{
				let style = {};
				if (this.zIndex)
					style.zIndex = this.zIndex;
				if (this.usePositionAbsolute && this.absPosTop)
					style.top = this.absPosTop;
				return style;
			},
			isIOS()
			{
				return BrowserIsIOS();
			},
			usePositionAbsolute()
			{
				return this.positionAbsolute || this.isIOS;
			},
			dynContentStyle()
			{
				var styleObj = {};
				if (!this.usePositionAbsolute)
				{
					styleObj.maxHeight = "100vh";
					styleObj.maxWidth = "100vw";
				}
				if (this.mediumWidth)
				{
					styleObj.maxWidth = "777px";
					styleObj.width = "100%";
				}
				if (this.overflowHidden)
					styleObj.overflow = "hidden";

				return styleObj;
			},
			dynPosStyle()
			{
				let styleObj = {};
				if (this.halfHeight)
				{
					styleObj.height= "50%";
					styleObj.minHeight= "50%";
				}
				else if (this.usePositionAbsolute)
					styleObj.height = "auto";
				if (this.offsetTop)
					styleObj.top = this.offsetTop;
				if (this.valign === "top")
					styleObj.justifyContent = "flex-start";
				return styleObj;
			}
		},
		created()
		{
			this.absPosTop = window.pageYOffset;
			this.oldFocus = this.returnFocus;
		},
		mounted()
		{
			//if (!this.$refs.contentComponent)
			//	this.useFocusCatcher = true;
			//if (typeof this.$refs.contentComponent.SetFocus === "function")
			//	this.useFocusCatcher = false;
			//else
			this.useFocusCatcher = true;
			setTimeout(() =>
			{
				this.enableClose = true;
			}, 500);
		}
	}
</script>

<style scoped>
	.modalDialogRoot
	{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		z-index: 4010;
		font-size: 0px;
	}

	.modalDialogPositioner
	{
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		min-height: 100%; /* 100vh here causes a broken layout on iOS for the EULA. */
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		overflow: visible;
	}

	.modalDialogOverlay
	{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.15);
	}

	.modalDialogContent
	{
		position: relative;
		background-color: var(--offwhite-background-color);
		border-radius: 5px;
		-webkit-box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 0px 10px 6px rgba(0, 0, 0, 0.25);
		overflow-y: auto;
	}

		.modalDialogContent > *
		{
			font-size: 12pt;
		}

	.FocusCatcher
	{
		width: 0px;
		height: 0px;
	}

	.positionAbsolute
	{
		position: absolute;
		background-color: transparent;
	}

		.positionAbsolute .modalDialogContent
		{
			margin: 40px 5px;
			max-height: none;
		}

	@media (min-width: 400px)
	{
		.modalDialogContent
		{
			border-radius: 10px;
		}
	}
</style>