import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
import { IsSessionActive_server, CreateSession, AttemptAutoLogon_server } from "tdsAppRoot/API/SessionAPI.js";
import { ModalMessageDialog } from "tdsAppRoot/library/ModalDialog.js";
import { isLocalStorageEnabled, IsSessionStorageEnabled, getStackTrace } from "tdsAppRoot/library/TDSUtil.js";

/**
 * Attempts to log in using the specified user name and password.
 * @param {any} store vuex store
 * @param {String} userName user name
 * @param {String} passWord password
 * @param {String} relativePath path portion of the current URL (starting with appPath)
 * @param {Object} mfaArgs MFA arguments from the MFAInterface component.
 */
export function StartSession(store, userName, passWord, relativePath, mfaArgs)
{
	return new Promise(function (resolve, reject)
	{
		let defaultReject = { sid: "", errMsg: "An error occurred processing your login request.  Technical support has been notified.  We are sorry for the inconvenience.", errorType: "error" };
		CreateSession(userName, passWord, relativePath, mfaArgs, store)
			.then(data =>
			{
				console.log("Login Result: " + JSON.stringify(data));
				if ((data.sid && data.sid.length > 0) || (data.statusMessage && data.statusMessage.length > 0))
				{
					if (data.sid && data.sid.length > 0)
						store.commit("SetSessionID", data.sid);
					return resolve(data);
				}
				return reject(defaultReject);
			})
			.catch(err =>
			{
				if (err.name === "ApiError")
				{
					console.log("Login Error Result: " + JSON.stringify(err.data));
					ShowErrorWindow(err.message);
					return reject(err.data);
				}
				else
				{
					console.error(err);
					ShowErrorWindow("There was an error logging you in.  Technical support has been notified.  We are sorry for the inconvenience.");
					ReportError(store.getters.urlRoot, "", null, err, store.state.sid);
					return reject(defaultReject);
				}
			});
	});
}

function AttemptAutoLogon(store)
{
	return AttemptAutoLogon_server(store)
		.then(data =>
		{
			return Promise.resolve(data);
		})
		.catch(err =>
		{
			console.error("Autologon error: " + err);
			ShowErrorWindow();
			return Promise.reject(err);
		});
}

/// If true, cache is ignored and the server is always queried.
/// Note that this client side session check is not a security feature, but rather is designed to enable a predictable session experience.
export var IsSessionActive = function (store)
{

	if (typeof appContext === 'undefined' || appContext === null || appContext.urlRoot === null || !appContext.isClient)
		return Promise.resolve(true);

	return IsSessionActive_server(store)
		.then(data =>
		{
			if (typeof data === "undefined")
				ReportError(store.getters.urlRoot, "IsSessionActive: Data is undefined! " + getStackTrace(), null, null, store.state.sid);
			if (data.isActive)
			{
				if (data.lastQuery != null)
					store.commit("UpdateTDSAPIQuery", data.lastQuery);
				return Promise.resolve(true);
			}
			return AttemptAutoLogon(store);
		})
		.catch(err =>
		{
			console.error("Server session check error: " + err);
			ShowErrorWindow();
			return Promise.reject(err);
		});

};

export var IsRestrictedSession = function ()
{
	if (typeof appContext !== 'undefined' && appContext && appContext.restrictedSessionProductType)
		return true;
	return false;
};

/**
 * Returns an object that can be assigned as the "to" prop of a router-link component for the purposes of making a link to the homepage.
 * @returns An object that can be assigned as the "to" prop of a router-link component.
 */
export function GetHomeLink()
{
	if (IsRestrictedSession())
		return { name: 'productgateway', params: { pageToken: appContext.restrictedSessionProductId } };
	else
		return { name: 'root' };
}

export var RestrictedSessionCheck = function ()
{

	if (typeof appContext !== 'undefined' && appContext && appContext.restrictedSessionProductType)
	{
		ModalMessageDialog("Because your subscription is expired, this feature is not currently available.");
		return true;
	}
	return false;
};

export var Logout = function (store, cookieController)
{
	// Clear the cache and forward the user to the server side logout page.
	if (!store || !store.state || !store.state.sid)
		return;
	var urlRoot = store.getters.urlRoot;
	var sid = store.state.sid;
	if (IsSessionStorageEnabled())
		sessionStorage.clear();
	if (isLocalStorageEnabled())
	{
		for (let i = 0; i < localStorage.length; i++)
		{
			if (localStorage.key(i) && localStorage.key(i).startsWith("vuex-"))
				localStorage.removeItem(localStorage.key(i));
		}
	}
	cookieController.delete("STATRefSID");
	location.href = urlRoot + "Session/logout?SessionID=" + sid;
};

let activity;
let activeSession;

function OnMouseMove()
{
	activity = true;
}
document.addEventListener('mousemove', OnMouseMove);

function OnKeyPress()
{
	activity = true;
}
document.addEventListener('keypress', OnKeyPress);

export var StartKeepalive = function (store)
{
	activity = false; // We don't want the first call to waste time with an ajax call to the server.
	activeSession = true;
	SessionKeepalive(store);
};
// Runs forever, making periodic XHR requests to the server to keep the user's session alive if and only
// if they are still actively using the app (moving the mouse, scrolling, etc.) and still have
// an active session.
var SessionKeepalive = function (store)
{
	if (activity && activeSession)
	{
		console.log("Session keepalive server connection.");
		IsSessionActive_server(store, true)
			.then(data =>
			{
				if (typeof data === "undefined")
					ReportError(store.getters.urlRoot, "SessionKeepalive: Data is undefined! " + getStackTrace(), null, null, store.state.sid);
				if (data.isActive)
				{
					console.log("Session is still active.");
					activeSession = true;
				}
				else
				{
					console.log("Session no longer active.  Aborting keepalive.");
					activeSession = false;
					if (document && typeof document.removeEventListener === "function")
					{
						document.removeEventListener('onmousemove', OnMouseMove);
						document.removeEventListener('onkeypress', OnKeyPress);
					}
				}
			})
			.catch(err =>
			{
				if (err.message !== "Network Error")
					ReportError(store.getters.urlRoot, "Keepalive thread failed to touch session: " + err.message, null, err, store.state.sid);
			});
	}
	activity = false;

	if (activeSession)
		setTimeout(function ()
		{
			SessionKeepalive(store)
		}, 30000);
};