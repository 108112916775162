import ModalDialogComponent from 'tdsAppRoot/components/Controls/ModalDialog.vue';
const makeModalDialog = create({ component: ModalDialogComponent, wrapper: 'dialogFade' });

import CornerLoader from 'tdsAppRoot/components/Controls/CornerLoader.vue';
const makeCornerLoader = create({ component: CornerLoader, wrapper: 'dialogFade' });

import EventBus from 'tdsAppRoot/library/EventBus.js';

import ConfirmPopup from 'tdsAppRoot/components/Controls/ConfirmPopup.vue';
import TextInputPopup from 'tdsAppRoot/components/Controls/TextInputPopup.vue';
import SearchSetEdit from 'tdsAppRoot/components/Controls/SearchSetEdit.vue';
import ContactUs from 'tdsAppRoot/components/Controls/ContactUs.vue';
import SearchResultMetadataPopup from 'tdsAppRoot/components/Search/Metadata/MetadataPanel.vue';
import MultipleTargetLinkPopup from 'tdsAppRoot/components/Controls/MultipleTargetLinkPopup.vue';
import ForgotProfilePasswordPopup from 'tdsAppRoot/components/Controls/ForgotProfilePasswordPopup.vue';
import EULA from 'tdsAppRoot/components/Auth/EULA.vue';
import LinkToPage from 'tdsAppRoot/components/Controls/LinkToPage.vue';
import EditGroupDefaultPanelsPopup from 'tdsAppRoot/components/Controls/EditGroupDefaultPanelsPopup.vue';
import SupportInfoPopup from 'tdsAppRoot/components/Controls/SupportInfoPopup.vue';
import MinorErrorPopup from 'tdsAppRoot/components/Controls/MinorErrorPopup.vue';
import MFAInterfacePopup from 'tdsAppRoot/components/Auth/MFAInterfacePopup.vue';
import TempLoginExpiredPopup from 'tdsAppRoot/components/Auth/TempLoginExpiredPopup.vue';


//////////////////////////////////////////////////
// Container Registration / Dialog Registration //
//////////////////////////////////////////////////
let allContainers = {};
let containerCount = 0;
export function RegisterModalDialogContainer(containerComponent)
{
	if (allContainers[containerComponent.name] !== containerComponent)
	{
		allContainers[containerComponent.name] = containerComponent;
		containerCount++;
		if (containerCount === 1)
			document.body.addEventListener("keydown", GlobalKeydownHandler);
	}
}
export function UnregisterModalDialogContainer(containerComponent)
{
	if (allContainers[containerComponent.name] === containerComponent)
	{
		delete allContainers[containerComponent.name];
		containerCount--;
		if (containerCount === 0)
			document.body.removeEventListener("keydown", GlobalKeydownHandler);
	}
}
function GlobalKeydownHandler(ev)
{
	let code = ev.which || ev.keyCode;
	if (ev.key === "Escape" || code === 27)
	{
		if (!CloseTopModalDialog())
			CloseAllPopups();
	}
}
/**
 * Returns a function to create a dialog from the specified component, in the specified container.
 * @param {Object} param0 An object containing two properties. [component] should be a reference to a component (suggestion: load via "import"). [wrapper] should be a string name of a ModalDialogContainer element that has been added to the root vue component.
 * @returns {Function} Returns a function.  The function accepts as an argument an object defining the props to be passed to the created component.  The function returns a promise which resolves when the dialog is closed.
 */
function create({ component, wrapper })
{
	return props =>
	{
		//console.log("Creating TDS Dialog");
		return new Promise((resolve /* this never rejects */) =>
		{
			let container = allContainers[wrapper];
			if (!container)
			{
				console.error('Dialog container "' + wrapper + '" does not exist. Make sure you have added <ModalDialogContainer name="' + wrapper + '" /> somewhere in the project.');
				resolve(false);
				return;
			}

			container.CreateDialog(component, props, dialogResult =>
			{
				// Called upon dialog close
				EventBus.OpenDialogCount = CountOpenDialogs();
				//console.log("Open Dialogs", EventBus.OpenDialogCount);
				resolve(dialogResult);
			});
			EventBus.OpenDialogCount = CountOpenDialogs();
			//console.log("Open Dialogs", EventBus.OpenDialogCount);
		});
	};
}
export function CountOpenDialogs(condition)
{
	let total = 0;
	for (let key in allContainers)
		if (allContainers.hasOwnProperty(key))
		{
			let c = allContainers[key];
			if (c && c.components)
			{
				if (typeof condition === "function")
				{
					for (let i = 0; i < c.components.length; i++)
						if (condition(c.components[i]))
							total++;
				}
				else if (c.components.length)
					total += c.components.length;
			}
		}
	return total;
}
///////////////////////////////
// Dialog-Creation Functions //
///////////////////////////////
/**
 * Creates a modal dialog containing the specified component, passing along the specified props.
 * Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {any} contentComponent A vue component to serve as the content component for the dialog (suggestion: get this via an import statement).
 * @param {Object} contentProps Props to be passed to the content component.
 * @param {Object} options Options for the dialog.
 * @param {HTMLElement} returnFocus Optional HTML element to focus after the dialog closes.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalDialog(contentComponent, contentProps, options, returnFocus)
{
	options = Object.assign({
		zIndex: null, // (default is defined in ModalDialog.vue's style block). Beware that setting this will break normal dialog ordering where the last dialog opened is the top dialog.
		positionAbsolute: false,
		mediumWidth: false,
		halfHeight: false,
		valign: "center",
		offsetTop: "",
		defaultCloseOnOverlayClick: true
	}, options);

	let args = {
		contentComponent,
		contentProps,
		zIndex: options.zIndex,
		positionAbsolute: options.positionAbsolute,
		overflowHidden: options.overflowHidden,
		halfHeight: options.halfHeight,
		mediumWidth: options.mediumWidth,
		returnFocus: returnFocus,
		valign: options.valign,
		offsetTop: options.offsetTop,
		defaultCloseOnOverlayClick: options.defaultCloseOnOverlayClick
	};
	if (!args.contentComponent)
		console.error("No valid contentComponent was provided to ModalDialog function");
	return makeModalDialog(args);
}
/**
 * Creates a modal message dialog, returning a promise which resolves when the dialog closes. Does not reject.
 * @param {String} message A message for the dialog.
 * @param {String} title Optional title for the dialog.
 * @param {Object} props Optional object containing additional properties for the dialog.
 * @param {Object} options Options for the dialog.
 * @param {HTMLElement} returnFocus Optional HTML element to focus after the dialog closes.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalMessageDialog(message, title, props = null, options = null, returnFocus = null)
{
	let args = { message: message };
	if (props)
		Object.assign(args, props);
	if (typeof title !== "undefined")
		args.title = title;

	return ModalDialog(ConfirmPopup, args, options, returnFocus);
}
/**
 * Creates a modal confirm dialog, returning a promise which resolves when the dialog closes. Does not reject.
 * The resolve value is true if the user clicked the accept button.
 * @param {any} message A string message for the dialog. Or, optionally, an args object (for advanced use).
 * @param {String} title Optional title for the dialog.
 * @param {String} yesText Text to show in the accept button.
 * @param {String} noText Text to show in the decline button.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalConfirmDialog(message, title, yesText, noText)
{
	let args;
	if (typeof message === "object")
	{
		args = message;
		args.confirm = true;
	}
	else
	{
		args = { message: message, confirm: true };
		if (typeof title !== "undefined")
			args.title = title;
		if (typeof yesText !== "undefined")
			args.yesText = yesText;
		if (typeof noText !== "undefined")
			args.noText = noText;
	}
	return ModalDialog(ConfirmPopup, args);
}
/**
 * Opens a dialog box with a text input field inside, and returns a promise that resolves with an object { value: "input text" }, or false if the dialog was canceled.
 * Returns a promise which resolves when the dialog closes. Does not reject.
 * @param {any} title Optional title for the dialog box. Appears specially styled. (omitted if null or empty). May optionally be an object containing all the arguments for the text input dialog.
 * @param {String} message Optional message for the dialog box. (omitted if null or empty)
 * @param {String} placeholder Optional placeholder text for the text input. (omitted if null or empty)
 * @param {String} initialText Optional text that should be in the text input when it first appears.
 * @param {String} checkboxText If provided, a checkbox will be inserted into the dialog box with this text, and the resolve value will also have a "checked" field.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function TextInputDialog(title, message, placeholder, initialText, checkboxText)
{
	let args;
	if (typeof title === "object")
		args = title;
	else
	{
		args = { title: title };
		if (typeof message !== "undefined") args.message = message;
		if (typeof placeholder !== "undefined") args.placeholder = placeholder;
		if (typeof initialText !== "undefined") args.initialText = initialText;
		if (typeof checkboxText !== "undefined") args.checkboxText = checkboxText;
	}
	return ModalDialog(TextInputPopup, args);
}
export function ModalSearchSetEditorDialog(searchSetName, localTitleSet)
{
	let args = { searchSetName: searchSetName, localTitleSet: localTitleSet };
	return ModalDialog(SearchSetEdit, args);
}
export function ModalContactUsDialog(htmlMessage)
{
	let args = { htmlMessage: htmlMessage };
	return ModalDialog(ContactUs, args);
}
export function ModalSearchResultMetadataDialog(searchid)
{
	let args = { searchid: searchid };
	return ModalDialog(SearchResultMetadataPopup, args);
}
export function ModalMultipleTargetLinkDialog(links)
{
	let args = { links };
	return ModalDialog(MultipleTargetLinkPopup, args);
}
export function ModalForgotProfilePasswordDialog()
{
	return ModalDialog(ForgotProfilePasswordPopup, null);
}
export function ModalEULADialog(showAcceptDeclineButtons, returnUrl)
{
	let args = { showButtons: showAcceptDeclineButtons, returnUrl };
	return ModalDialog(EULA, args);
}
export function ModalLinkToPageDialog(args, message)
{
	if (args)
		return ModalDialog(LinkToPage, args);
	else if (typeof message === "string")
		return ModalMessageDialog(message);
	else
		return ModalMessageDialog("Linking is not currently available for this location.");
}
export function ModalEditGroupDefaultPanelsDialog()
{
	return ModalDialog(EditGroupDefaultPanelsPopup, null);
}
export function ModalSupportInfoDialog()
{
	return ModalDialog(SupportInfoPopup, null);
}
/**
 * Shows a dialog with the title "Errors" that simply shows a list of errors that have occurred recently.
 * @param {Array} errors Array of error message strings.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalMinorErrorDialog(errors)
{
	let args = { errors };
	return ModalDialog(MinorErrorPopup, args);
}
/**
 * Creates a modal dialog containing the Multi-Factor Authentication interface.
 * @param {String} mode "initial_setup" or "challenge" or "setup"
 * @param {String} username User name, iff mode is initial_setup.
 * @param {String} password Password, iff mode is initial_setup.
 * @param {Array} mfaUserData MFAUserData object from the server which tells us which MFA methods are available/configured/pending.
 * @param {Function} mfaSubmitCallback Callback function to call when the user submits an MFA response.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject. If MFA verification is successful, the browser may be navigated before this promise resolves.
 */
export function ModalMFADialog(mode, username, password, mfaUserData, mfaSubmitCallback)
{
	let args;
	if (typeof mode === "object")
		args = mode;
	else
		args = { mode, username, password, mfaUserData, mfaSubmitCallback };
	return ModalDialog(MFAInterfacePopup, args, { valign: "top", offsetTop: "4vh", defaultCloseOnOverlayClick: false });
}
/**
 * Creates a modal dialog that is shown to a user when their profile is not usable as a temporary login account.
 * @param {Object} args Arguments including { user, pass, isExpired, groupName, expDate }.  `user` and `pass` are required. `isExpired` should be true if the account is expired, false if it was never activated as a temporary login account.
 * @returns {Promise} Returns a promise which resolves when the dialog closes. Does not reject.
 */
export function ModalTempLoginExpiredDialog(args)
{
	return ModalDialog(TempLoginExpiredPopup, args, { valign: "top", offsetTop: "4vh" });
}
let activeCornerLoaderArgs = null;
export function ShowCornerLoader()
{
	if (activeCornerLoaderArgs && !activeCornerLoaderArgs.dismiss)
		return;
	activeCornerLoaderArgs = { dismiss: false };
	makeCornerLoader(activeCornerLoaderArgs);
}
export function HideCornerLoader()
{
	if (activeCornerLoaderArgs)
	{
		activeCornerLoaderArgs.dismiss = true;
		activeCornerLoaderArgs = null;
	}
}

export function CloseTopModalDialog()
{
	// Gets the topmost modal dialog still being displayed, if any.
	// IMPORTANT: If we ever create multiple Modal Dialog Containers, this method will need to be updated to handle it.
	if (allContainers && Object.keys(allContainers).length > 0)
	{
		var dialogContainer = allContainers[Object.keys(allContainers)[0]];
		return dialogContainer.CloseTopDialog();
	}
	else
		return false;
}

function CloseAllPopups()
{
	var menus = document.querySelectorAll(".toolMenuRoot");
	if (menus)
	{
		for (var i = 0; i < menus.length; i++)
		{
			var menu = menus[i].component;
			if (menu)
				menu.OnCloseRequested();
		}
	}
	var hlps = document.querySelectorAll(".hlpContainer");
	if (hlps && hlps.length > 0)
		hlps[0].component.CloseHlps();

	var tooltips = document.querySelectorAll(".tooltipRoot");
	if (tooltips && tooltips.length > 0)
	{
		for (var i = 0; i < tooltips.length; i++)
		{
			var tooltip = tooltips[i].component;
			if (tooltip)
				tooltip.Close();
		}
	}

	var hoverThumb = document.querySelectorAll(".hoverThumb");
	if (hoverThumb && hoverThumb.length > 0)
		hoverThumb[0].component.HoverThumbClose();


	var toasts = document.querySelectorAll(".toast");
	if (toasts && toasts.length > 0)
		toasts[toasts.length - 1].component.onCloseRequested();

	var docRoot = document.getElementById("docRootDomNode");
	if (docRoot && docRoot.component)
		docRoot.component.OnCloseFullscreenImage();
}