import { CallServer } from "tdsAppRoot/API/apibase.js";

var MFAUserBeginSetupParams = function ()
{
	var username;
	var password;
	var mfaMethodType;
};
Object.seal(MFAUserBeginSetupParams);

var MFAUserEndSetupParams = function ()
{
	var username;
	var password;
	var mfaMethodType;
	var mfaValue;
	var secretKey;
};
Object.seal(MFAUserEndSetupParams);

var MFAGetUserDataParams = function ()
{
	var username;
	var password;
	var deviceGuid;
};
Object.seal(MFAGetUserDataParams);

var MFARemoveRememberedDeviceParams = function ()
{
	var username;
	var password;
	var deviceId;
};
Object.seal(MFARemoveRememberedDeviceParams);

/**
 * Step 1/2 of configuring an MFA method for a user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @returns {Promise} API Response promise
 */
export function MFAUserBeginSetup(store, user, pass, mfaMethodId)
{
	try
	{
		let args = new MFAUserBeginSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		return CallServer("MFAUser", "BeginSetup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Step 2/2 of configuring an MFA method for a user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @param {String} mfaValue One-time password entered by user.
 * @param {String} secretKey SecretKey string given in response from [MFAUserBeginSetup].  This is compared with what the server knows so that if it doesn't match, we can provide a more accurate error message instead of just having MFA validation fail.
 * @returns {Promise} API Response promise
 */
export function MFAUserEndSetup(store, user, pass, mfaMethodId, mfaValue, secretKey)
{
	try
	{
		let args = new MFAUserEndSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		args.mfaValue = mfaValue;
		args.secretKey = secretKey;
		return CallServer("MFAUser", "EndSetup", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Gets an MFAUserData instance for the current session.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} deviceGuid GUID of this device, if one is stored
 * @returns {Promise} API Response promise
 */
export function MFAGetUserData(store, user, pass, deviceGuid)
{
	try
	{
		let args = new MFAGetUserDataParams();
		args.username = user;
		args.password = pass;
		args.deviceGuid = deviceGuid;
		return CallServer("MFAUser", "GetUserData", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes an MFA method from this user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {Number} mfaMethodId MFA Method Type
 * @returns {Promise} API Response promise
 */
export function MFARemoveConfiguration(store, user, pass, mfaMethodId)
{
	try
	{
		let args = new MFAUserBeginSetupParams();
		args.username = user;
		args.password = pass;
		args.mfaMethodType = mfaMethodId;
		return CallServer("MFAUser", "RemoveConfiguration", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Sends an email containing an MFA verification code for this user account.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @returns {Promise} API Response promise
 */
export function MFAUserEmailSend(store, user, pass)
{
	try
	{
		let args = new MFAGetUserDataParams();
		args.username = user;
		args.password = pass;
		return CallServer("MFAUser", "EmailSend", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
/**
 * Deletes a remembered device.
 * @param {Object} store Vuex store
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} deviceId Device ID (hashed GUID)
 * @returns {Promise} API Response promise
 */
export function MFARemoveRememberedDevice(store, user, pass, deviceId)
{
	try
	{
		let args = new MFARemoveRememberedDeviceParams();
		args.username = user;
		args.password = pass;
		args.deviceId = deviceId;
		return CallServer("MFAUser", "RemoveRememberedDevice", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}