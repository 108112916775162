var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "rootRef",
      class: _vm.toastClasses,
      style: _vm.toastStyle,
      on: {
        mouseenter: _vm.onMouseEnter,
        mousedown: _vm.StopProp,
        click: _vm.StopProp
      }
    },
    [
      _c("vsvg", {
        staticClass: "closeBtn",
        attrs: {
          role: "button",
          isPresentation: false,
          "aria-label": "Close message",
          sprite: "#x",
          title: "Close Message",
          desc: "",
          tabindex: "0"
        },
        on: {
          click: function($event) {
            return _vm.$emit("close")
          },
          keypress: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.$emit("close")
          }
        }
      }),
      _vm._v(" "),
      _vm.args.contentComponent
        ? _c(
            _vm.args.contentComponent,
            _vm._b(
              {
                tag: "component",
                staticClass: "contentComponent",
                on: { close: _vm.onCloseRequested }
              },
              "component",
              _vm.args.contentProps,
              false
            )
          )
        : _c("div", { staticClass: "message", attrs: { role: "alert" } }, [
            _vm._v(_vm._s(_vm.args.message))
          ]),
      _vm._v(" "),
      _vm.args.type === "error"
        ? _c("vsvg", {
            staticClass: "errorIcon",
            attrs: {
              role: "presentation",
              "aria-hidden": "true",
              sprite: "#error_outline",
              title: "",
              desc: ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }