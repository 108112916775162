import Vue from 'vue';
import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
import { Search, ResultPage, RelatedConcepts } from "tdsAppRoot/API/SearchAPI.js";
import { CategoryIsNoAsync, CategoryUsesDocAddressMap } from 'tdsAppRoot/library/Search.js';
import { escapeRegExp } from 'tdsAppRoot/library/TDSUtil.js';

const searchModule = {
	state()
	{
		return {
			searchRequestHistory: new Object(), // Saves search definitions / arguments keyed on searchId.
			searchResultHistory: new Object(), // Saves search results keyed on searchId. See server-side SearchResponse class in Core.
			docAddressMap: new Object(), // Saves search result indexes keyed on searchid, then categoryType, then docAddress. Populated when each result is opened.
			relatedConcepts: new Object(), // Saves related concepts results.  Map of searchId to an array of terms.
			relatedConceptsFromBestResult: new Object(), // Map of searchId to an array of terms where the terms are the top concepts in the best result document.
			relatedConceptsFromQuery: new Object(), // Map of searchId to an array of terms where the terms are the top concepts in the search query.
			//lastSearchDef: null, // Search definition (possibly with results) for the last performed search. Designed to be used only to detect duplicate search attempts.
			queryPersisted: "", // Remembers search box input between page loads.  Also used by the Search current title feature.
			tdsAPIQuery: null, // If the current search query changes externally due to TDS API context sharing, it is updated here and the change is picked up by SearchBar.vue.
			setSearchQuery: "", // Used to update the search query text in response to clicking a spell check suggestion, or for any other programmatic reason.
			showCoverPics: true,
			filterBy: "Category",    // Current filter by setting.
			searchDisciplineId: null // Current search discipline.  Intended to make search discipline sticky.
			
		};
	},
	mutations:
	{
		SetSearchQuery: (state, payload) =>
		{
			state.setSearchQuery = payload;
		},
		SetShowCoverPics: (state, payload) =>
		{
			state.showCoverPics = payload;
		},
		DefineSearchRequest(state, req)
		{
			Vue.set(state.searchRequestHistory, req.searchid, req);
			//state.lastSearchDef = req;
		},
		PreloadSearchResult(state, searchid)
		{
			let result = {};
			result.canceled = false;
			result.categories = [];
			result.dymAlternatives = null;
			result.finished = false;
			result.searchid = searchid;
			Vue.set(state.searchResultHistory, searchid, result);
		},
		SetSearchResult(state, result)
		{
			if (result.categories)
			{
				for (let i = 0; i < result.categories.length; i++)
					CategoryPageInit(result.categories[i]);
			}
			result.finished = false; // Define the "finished" field before the result object becomes reactive.
			Vue.set(state.searchResultHistory, result.searchid, result);
			if (preloadDocAddressMap)
				UpdateDocAddressMap(state);
		},
		ContinuedSearchResult: (state, result) =>
		{
			if (!state.searchResultHistory[result.searchid])
				return;
			if (result.categories)
			{
				for (let i = 0; i < result.categories.length; i++)
				{
					CategoryPageInit(result.categories[i]);
					state.searchResultHistory[result.searchid].categories.push(result.categories[i]);
				}
			}
			if (result.canceled)
				state.searchResultHistory[result.searchid].canceled = true;
			if (preloadDocAddressMap)
				UpdateDocAddressMap(state);
		},
		FinalizeAsyncSearch: (state, searchid) =>
		{
			if (!state.searchResultHistory[searchid])
			{
				console.log("🔍 [" + searchid + "] Search ID was not found in searchResultHistory.");
				return;
			}
			state.searchResultHistory[searchid].finished = true;
			console.log("🔍 [" + searchid + "] Finished retrieving search results.");
		},
		UnCancelSearch(state, searchid)
		{
			if (state.searchResultHistory[searchid])
			{
				state.searchResultHistory[searchid].canceled = false;
				state.searchResultHistory[searchid].finished = false;
			}
		},
		SetResultPage: (state, { categoryResults, pageNum, data, firstResult, lastResult }) =>
		{
			if (data)
			{
				// Add this page's results to the category's results array
				for (let i = firstResult, j = 0; i <= lastResult; i++ , j++)
				{
					if (data.results.length > j)
					{
						//categoryResults.results[i] = data.results[j]; // Non-reactive is substantially faster
						Vue.set(categoryResults.results, i, data.results[j]);
					}
				}
			}

			if (pageNum <= categoryResults.numPages && pageNum >= 1)
				categoryResults.pageNum = pageNum;
			if (preloadDocAddressMap)
				UpdateDocAddressMap(state);
		},
		SetRelatedConcepts: (state, { searchid, concepts }) =>
		{
			Vue.set(state.relatedConcepts, searchid, concepts);
		},
		SetRelatedConceptsFromBestResult: (state, { searchid, concepts }) =>
		{
			Vue.set(state.relatedConceptsFromBestResult, searchid, concepts);
		},
		SetRelatedConceptsFromQuery: (state, { searchid, concepts }) =>
		{
			Vue.set(state.relatedConceptsFromQuery, searchid, concepts);
		},
		//SetQuery: (state, { searchid, newQuery }) => {
		//	state.searchResultHistory[searchid].query = newQuery;
		//},
		SetChoiceMisspellingItems: (state, { searchid, word, replacement }) =>
		{
			// Set the replacement value for the misspelled word, as many times as that misspelled word might appear in dymAlternatives.
			for (let i = 0; i < state.searchResultHistory[searchid].dymAlternatives.length; i++)
			{
				let alt = state.searchResultHistory[searchid].dymAlternatives[i];
				if (alt.misspelledWord.toUpperCase() === word.toUpperCase())
				{
					Vue.set(alt, "replacement", replacement);
					Vue.set(alt, "chosen", true);
				}
			}
		},
		RestoreMisspellingItems: (state, searchid) =>
		{
			// Restore all replacement string to null in the dymAlternatives field for the results for this searchid.
			for (let i = 0; i < state.searchResultHistory[searchid].dymAlternatives.length; i++)
			{
				let alt = state.searchResultHistory[searchid].dymAlternatives[i];
				Vue.set(alt, "replacement", null);
				Vue.set(alt, "chosen", false);
			}
		},
		UpdatePersistedQuery: (state, query) =>
		{
			state.queryPersisted = query;
		},
		UpdateTDSAPIQuery: (state, query) =>
		{
			state.tdsAPIQuery = query;
		},
		SearchResultOpening(state, { searchid, categoryType, docAddress, resultIndex })
		{
			if (docAddress && CategoryUsesDocAddressMap(categoryType))
				SetDocAddressMapping(state, searchid, categoryType, docAddress, resultIndex);
		},
		/**
		 * Any cached search result sets containing results from the specified categoryType will have that category's results removed, and the result set will be marked as canceled so that the next time that search is repeated, the result category will be reloaded fresh from the server. (although the server may have a cached copy; if using this method it is appropriate to also call the ClearSearchCategory method server-side)
		 * @param {any} state store state
		 * @param {any} categoryType categoryType to clear from result sets
		 */
		ClearSearchCategory(state, categoryType)
		{
			for (let searchid in state.searchResultHistory)
			{
				let res = state.searchResultHistory[searchid];
				if (res)
				{
					for (let i = 0; i < res.categories.length; i++)
					{
						if (res.categories[i].type === categoryType)
						{
							//console.log("Clearing Partial Search State: " + categoryType + "/" + searchid);
							res.categories.splice(i, 1);
							res.canceled = true;
							break;
						}
					}
				}
			}
		},
		ResetSearchResults(state)
		{
			state.searchResultHistory = new Object();
		},
		SetSearchDisciplineId(state, discId)
		{
			state.searchDisciplineId = discId;
		},
		SetFilterBy(state, filter)
		{
			state.filterBy = filter;
		}
	},
	getters:
	{
		OneOrMoreResultsExistForSearchId: function (state, getters)
		{
			return function (searchid)
			{
				let res = getters.GetSearchResult(searchid);
				if (res && res.categories)
					return res.categories.some(cat => cat && cat.results && cat.results.length > 0);
				return false;
			};
		},
		AnyResultCategoryLoaded: function (state, getters)
		{
			return function (searchid)
			{
				let res = getters.GetSearchResult(searchid);
				if (res && res.categories)
					return res.categories.some(cat => cat && cat.results);
				return false;
			};
		},
		/**
		 * Returns an array of categories that have 1 or more search results loaded.
		 * @param {Object} state store state
		 * @param {Object} getters store getters
		 * @returns {function} The actual function.
		 */
		GetCategoriesWithSearchResults: function (state, getters)
		{
			return function (searchid)
			{
				let res = getters.GetSearchResult(searchid);
				if (res && res.categories)
				{
					let cats = res.categories.filter(cat => cat && cat.results && cat.results.length > 0);
					// Sort the categories to ensure stable ordering as more result categories obtain results.
					cats.sort((a, b) =>
					{
						let diff = a.loadedTime - b.loadedTime;
						if (diff === 0)
							diff = a.type.localeCompare(b.type);
						return diff;
					});
					return cats;
				}
				return [];
			};
		},
		/**
		 * Returns the search result object for the specified searchid. Populated once ExecuteSearch is called, but results may not have arrived yet.
		 * @param {Object} state store state
		 * @returns {function} The actual function.
		 */
		GetSearchResult: function (state)
		{
			return function (searchid)
			{
				if (state.searchResultHistory !== null && state.searchResultHistory[searchid])
					return state.searchResultHistory[searchid];
				return null;
			};
		},
		/**
		 * Returns the search request object with the specified searchid. Null if no search request has been defined with this id.
		 * @param {Object} state store state
		 * @returns {function} The actual function.
		 */
		GetSearchRequest: function (state)
		{
			return function (searchid)
			{
				if (state.searchRequestHistory !== null && state.searchRequestHistory[searchid])
					return state.searchRequestHistory[searchid];
				return null;
			};
		},
		/**
		 * Returns true if the search with this searchid includes a request for the specified categoryType.
		 * @param {Object} state store state
		 * @param {Object} getters store getters
		 * @returns {function} The actual function.
		 */
		IsCategoryRequested: function (state, getters)
		{
			return function (searchid, categoryType)
			{
				let req = getters.GetSearchRequest(searchid);
				if (req)
					return req.categoryRequests.some(cat => cat.CategoryType === categoryType);
				return false;
			};
		},
		GetCategorySearchResults: function (state, getters)
		{
			return function (searchid, categoryType)
			{
				let resultData = getters.GetSearchResult(searchid);
				if (resultData)
				{
					for (let i = 0; i < resultData.categories.length; i++)
					{
						if (resultData.categories[i].type === categoryType)
							return resultData.categories[i];
					}
				}
				return null;
			};
		},
		ResultsPageLoaded: function (state, getters)
		{
			return function (searchid, categoryType)
			{
				let categoryResults = getters.GetCategorySearchResults(searchid, categoryType);
				if (categoryResults
					&& categoryResults.results.length > getters.GetLastResultIdx(searchid, categoryType))
				{
					return true;
				}
				return false;
			};
		},
		GetFirstResultIdx: function (state, getters)
		{ // Gets the first result index for the current result page.
			return function (searchid, categoryType, pageNum = -1)
			{
				let categoryResults = getters.GetCategorySearchResults(searchid, categoryType);
				if (categoryResults)
				{
					let nPageNum;
					if (pageNum >= 1)
						nPageNum = pageNum;
					else
						nPageNum = categoryResults.pageNum;
					let startIdx = (nPageNum - 1) * categoryResults.resultsPerPage;
					//if (startIdx === 0)
					//	startIdx = 1; // Skip the top result, which is shown in the Top Results box.  We'll only show resultsPerPage-1 (e.g., 9) results on the current page.
					return startIdx;
				}
				return 0;
			};
		},
		GetLastResultIdx: function (state, getters)
		{ // Gets the last result index for the current result page.
			return function (searchid, categoryType, pageNum)
			{
				let categoryResults = getters.GetCategorySearchResults(searchid, categoryType);
				if (categoryResults)
				{
					let nPageNum;
					if (pageNum >= 1)
						nPageNum = pageNum;
					else
						nPageNum = categoryResults.pageNum;
					let lastResultIdx = (nPageNum * categoryResults.resultsPerPage) - 1;
					if (lastResultIdx >= categoryResults.totalResults)
						lastResultIdx = categoryResults.totalResults - 1;
					return lastResultIdx;
				}
				return 0;
			};
		},
		HasSpellingAlternatives: function (state, getters)
		{
			return function (searchid)
			{
				let resultData = getters.GetSearchResult(searchid);
				if (resultData && resultData.dymAlternatives && resultData.dymAlternatives.length > 0)
					return true;
				return false;
			};
		},
		GetResultIndex(state, getters)
		{
			return function (searchid, categoryType, docAddress)
			{
				let catMap = state.docAddressMap[searchid];
				if (catMap)
				{
					let docMap = catMap[categoryType];
					if (docMap)
					{
						let index = docMap[docAddress];
						if (typeof index !== "undefined")
							return index;
					}
				}
				let cat = getters.GetCategorySearchResults(searchid, categoryType);
				if (cat && cat.results)
				{
					for (let i = 0; i < cat.results.length; i++)
					{
						let r = cat.results[i];
						if (r && r.docAddress === docAddress)
							return i;
					}
				}
				return null;
			};
		},
		GetCategoryForSearchId(state, getters)
		{
			return function (searchid)
			{
				let request = getters.GetSearchRequest(searchid);
				if (request && request.args)
					return request.args.titleCategoryId;
				return null;
			}
		},
		/**
		 * Builds the suggested search query based on the original query modified by spelling suggestions.
		 * @param {any} state state object
		 * @param {any} getters getters object
		 * @returns {Function} Returns a getter function accepting a searchid as an argument.
		 */
		GetDymSug(state, getters)
		{
			return function (searchid)
			{
				let request = getters.GetSearchRequest(searchid);
				if (request)
				{
					let q = request.args.query.toUpperCase();
					for (let i = 0; i < state.searchResultHistory[searchid].dymAlternatives.length; i++)
					{
						let alt = state.searchResultHistory[searchid].dymAlternatives[i];
						if (alt.chosen)
						{
							if (alt.replacement === null)
								q = q.replace(new RegExp(escapeRegExp(alt.misspelledWord), 'gi'), "");
							else
								q = q.replace(new RegExp(escapeRegExp(alt.misspelledWord), 'gi'), alt.replacement);
						}
						else if (alt.suggestions.length > 0)
							q = q.replace(new RegExp(escapeRegExp(alt.misspelledWord), 'gi'), alt.suggestions[0]);
					}
					q = q.replace(new RegExp("  +", 'g'), " ").trim();
					if (q === "")
						return request.args.query.toUpperCase();
					return q;
				}
				return "";
			};
		}
	},
	actions:
	{
		/// Get related concepts for the current search results.  Perform this after a search.
		// The {} argument here is using destructuring, an ES6 feature that we're getting through babel.
		// The actual argument name is context, which is an argument containg commit, state, and getters.
		GetRelatedConcepts({ commit, state, rootState, rootGetters, dispatch }, searchid)
		{
			let req = this.getters.GetSearchRequest(searchid);
			if (!req)
			{
				let errMsg = 'GetRelatedConcepts could not complete because history for searchid "' + searchid + '" was not found.';
				console.log(errMsg);
				ReportError(this.getters.urlRoot, errMsg, null, null, this.state.sid);
				return;
			}
			RelatedConcepts({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, req.args.query)
				.then(data =>
				{
					if (data.concepts)
						commit("SetRelatedConcepts", { searchid: searchid, concepts: data.concepts });
					if (data.bestResultConcepts)
						commit("SetRelatedConceptsFromBestResult", { searchid: searchid, concepts: data.bestResultConcepts });
					if (data.queryConcepts)
						commit("SetRelatedConceptsFromQuery", { searchid: searchid, concepts: data.queryConcepts });
				})
				.catch(err =>
				{
					console.log("GetRelatedConcepts error: " + err.message);
					if (err.name !== "ApiError")
						ReportError(this.getters.urlRoot, "GetRelatedConcepts error: " + err.message, null, err, this.state.sid);
					ShowErrorWindow(err);
				});
		},
		SwitchToResultPage({ commit, state, rootState, rootGetters, dispatch }, { searchid, categoryType, pageNum })
		{
			// Do we have results loaded for the now-current results page?
			let req = this.getters.GetSearchRequest(searchid);
			let cat = this.getters.GetCategorySearchResults(searchid, categoryType);
			if (!req || !cat)
				return Promise.reject(new Error("Unknown search id/category: " + searchid + "/" + categoryType));
			if (pageNum > cat.numPages || pageNum <= 0)
				return Promise.resolve(true);
			let firstResult = (pageNum - 1) * cat.resultsPerPage;
			let lastResult = firstResult + cat.resultsPerPage - 1;
			// NOTE: When asked to load the last page (e.g. page 3 of 3), we might have it cached already, but we aren't 100% sure because we do not know how many results are supposed to be in the last page.  So in most cases, the last page will not load from cache.
			if (cat.results.length > lastResult && cat.results[firstResult] && cat.results[lastResult])
			{
				// Yes, we do. We don't need to load anything.
				commit("SetResultPage", { categoryResults: cat, pageNum: pageNum });
				return Promise.resolve(true);
			}

			// No, we don't have results for this page.  Let's load some.
			return ResultPage(searchid, req.args, firstResult, lastResult, cat.type, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
				.then(data =>
				{
					if (data.categories.length === 1)
						data = data.categories[0];
					if (data.results && data.results.length >= 1)
					{
						commit("SetResultPage", { categoryResults: cat, pageNum, data, firstResult, lastResult });
					}
					else
					{
						ReportError(rootGetters.urlRoot, "No results returned by GetResultSubset: " + JSON.stringify(data), null);
						return Promise.reject(new Error("Unable to retrieve results.  Please try your search again.  We are sorry for the inconvenience."));
					}
					return Promise.resolve(true);
				})
				.catch(err =>
				{
					console.error("Search result page change error: " + err.message);
					if (err.name !== "ApiError")
						ReportError(rootGetters.urlRoot, "Search result page change error: " + err.message, null, err);
					ShowErrorWindow("There was an error loading search results.  Technical support has been notified.  We are sorry for the inconvenience.");
				});
		},
		EnsureResultLoaded({ commit, dispatch, state, rootState, rootGetters }, { searchid, categoryType, resultidx })
		{
			try
			{
				let req = this.getters.GetSearchRequest(searchid);
				if (!req)
				{
					let err = new Error("EnsureResultLoaded error: Search request not found: " + JSON.stringify({ searchid, categoryType, resultidx }));
					err.invalidSearchId = true;
					return Promise.reject(err);
				}
				if (!categoryType)
					return Promise.reject(new Error("EnsureResultLoaded error: categoryType argument was invalid: " + JSON.stringify({ searchid, categoryType, resultidx })));

				if (!this.getters.IsCategoryRequested(searchid, categoryType))
					return Promise.reject(new Error("EnsureResultLoaded error: Search request found, but it did not include this category: " + JSON.stringify({ searchid, categoryType, resultidx })));

				let switchResultPageFn = (resultCat) =>
				{
					return new Promise((resolve, reject) =>
					{
						let cat = this.getters.GetCategorySearchResults(searchid, categoryType);
						if (!cat)
						{
							reject(new Error('EnsureResultLoaded.switchResultPageFn could not find the search result category "' + categoryType + '" for searchid "' + searchid + '"'));
							return;
						}
						// Calculate which page the desired result is on, and load it.
						let idx = resultidx;
						if (typeof resultidx === "function")
							idx = resultidx();
						if (!idx)
							idx = 0;

						if (cat.results.length > idx && cat.results[idx])
						{
							// The desired result has been loaded.
							return resolve(true);
						}

						let targetPage = Math.floor(idx / resultCat.resultsPerPage) + 1;
						dispatch("SwitchToResultPage", { searchid: searchid, categoryType: categoryType, pageNum: targetPage })
							.then(result =>
							{
								resolve(true);
							})
							.catch(reject);
					});
				};

				let res = this.getters.GetSearchResult(searchid);
				if (!res)
				{
					// The search has not yet begun.
					dispatch("ExecuteSearch", req);
				}

				// Our desired category has not loaded yet.
				return dispatch("ResolveWhenCategoryLoaded", { searchid, categoryType })
					.then(resultCat =>
					{
						return switchResultPageFn(resultCat);
					})
					.catch(err =>
					{
						if (err.name !== "ApiError")
							ReportError(rootGetters.urlRoot, "EnsureResultLoaded error: Search results could not be loaded for this category: " + JSON.stringify({ searchid, categoryType, resultidx }) + ": " + err.message, null, err);
						ShowErrorWindow();
						return Promise.reject(err);
					});
			}
			catch (err)
			{
				ReportError(rootGetters.urlRoot, "EnsureResultLoaded error: " + err.message, null, err);
				ShowErrorWindow();
			}
			return Promise.reject(null);
		},
		ResolveWhenCategoryLoaded({ commit, dispatch, state, rootState, rootGetters }, { searchid, categoryType })
		{
			try
			{
				return new Promise((resolve, reject) =>
				{
					let unwatch = this.watch(
						(state, getters) => getters.GetSearchResult(searchid), // watch the category search results
						(oldValue, newValue) =>
						{
							let cat = this.getters.GetCategorySearchResults(searchid, categoryType);
							if (cat)
							{
								if (unwatch)
									unwatch();
								resolve(cat);
								return;
							}
							let res = this.getters.GetSearchResult(searchid);
							if (res && res.finished)
							{
								if (unwatch)
									unwatch();
								reject('Search result was finalized before category "' + categoryType + '" was loaded.');
								return;
							}
						},
						{
							deep: true, // Make this a "deep" watcher so it sees changes to the search result object.
							immediate: true
						}
					);
				});
			}
			catch (err)
			{
				ReportError(rootGetters.urlRoot, "ResolveWhenCategoryLoaded error: " + err.message, null, err);
				ShowErrorWindow();
			}
		},
		ExecuteSearch({ commit, dispatch, state, rootState, rootGetters }, request)
		{
			// This only does a search and commits changes to the vuex store.  It does not do navigation.
			// request is a SearchRequest object (SearchAPI.js) containing a "searchid", "args", and categoryRequests.
			try
			{
				if (!request || !request.args || !request.args.query || !request.categoryRequests)
				{
					console.error("🔍 [" + request.searchid + "] Search error: Bad search request.", JSON.parse(JSON.stringify(request)));
					ReportError(rootGetters.urlRoot, "Bad search request: " + JSON.stringify(request));
					ShowErrorWindow("An error has occurred.  Technical support has been notified.  We are sorry for the inconvenience.");
					return;
				}
				commit("PreloadSearchResult", request.searchid);
				request = JSON.parse(JSON.stringify(request)); // Make non-reactive copy
				return Search(request, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
					.then(result =>
					{
						try
						{
							console.log("🔍 [" + request.searchid + "] Search response received from server.");
							result.searchid = request.searchid;
							commit("SetSearchResult", result);
						}
						catch (err)
						{
							commit("FinalizeAsyncSearch", request.searchid);
							ReportError(rootGetters.urlRoot, "Error processing search results: " + err.message, undefined, err, rootState.sid);
							ShowErrorWindow();
							return;
						}
						dispatch("GetRelatedConcepts", request.searchid);
						dispatch("ContinueSearch", request);
					})
					.catch(err =>
					{
						commit("FinalizeAsyncSearch", request.searchid);
						if (err.data && err.data.serviceReady)
						{
							console.error("🔍 [" + request.searchid + "] Search error: " + err.message);
							if (err.name !== "ApiError")
								ReportError(rootGetters.urlRoot, "Search error: " + err.message, null, err);
							ShowErrorWindow(err);
						}
					});

			}
			catch (err)
			{
				commit("FinalizeAsyncSearch", request.searchid);
				console.error("🔍 [" + searchid + "] " + err);
				ReportError(rootGetters.urlRoot, "Search error: " + err.message, null, err);
				ShowErrorWindow();
			}
		},
		ContinueSearch({ commit, dispatch, state, rootState, rootGetters }, request)
		{
			// This continues retrieving results from a previously started search and commits changes to the vuex store.
			// If all requested results are retrieved, or if the search has been canceled, this action will self-abort.
			let searchid = request ? request.searchid : "none";
			try
			{
				if (!request || !request.args || !request.args.query || !request.categoryRequests)
				{
					console.error("🔍 [" + searchid + "] (Continued) Search error: Bad search request.", JSON.parse(JSON.stringify(request)));
					ReportError(rootGetters.urlRoot, "(Continued) Search error: Bad search request: " + JSON.stringify(request));
					ShowErrorWindow("An error has occurred.  Technical support has been notified.  We are sorry for the inconvenience.");
					commit("FinalizeAsyncSearch", searchid);
					return;
				}
				let res = rootGetters.GetSearchResult(searchid);
				if (res && (res.canceled || res.finished))
				{
					commit("FinalizeAsyncSearch", searchid);
					return;
				}
				// Remove completed categories.
				request = JSON.parse(JSON.stringify(request)); // Make non-reactive copy
				request.categoryRequests = request.categoryRequests.filter(ele =>
				{
					return !rootGetters.GetCategorySearchResults(searchid, ele.CategoryType)
						&& !CategoryIsNoAsync(ele.CategoryType);
				});
				if (request.categoryRequests.length === 0)
				{
					commit("FinalizeAsyncSearch", searchid);
					return;
				}
				console.log("🔍 [" + searchid + "] Continuing to retrieve search results.");
				let minimumEmptyResponseTime = 15000;
				let startTime = performance.now();
				Search(request, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
					.then(result =>
					{
						let responseTime = performance.now() - startTime;
						try
						{
							console.log("🔍 [" + searchid + "] (Continued) Search response received from server." + (result.canceled ? " Search was canceled." : ""));
							result.searchid = searchid;
							commit("ContinuedSearchResult", result);
							if (!result.canceled)
							{
								if (!result.categories || !result.categories.length)
								{
									if (responseTime < minimumEmptyResponseTime)
									{
										// An "empty" response came back too soon, so we assume that something is broken and cease polling for results.
										let errMsg = "🔍 [" + searchid + "] Aborting async search result retrieval because an empty response was received sooner than expected.";
										console.error(errMsg);
										ReportError(rootGetters.urlRoot, errMsg);
										commit("FinalizeAsyncSearch", searchid);
										return;
									}
								}
							}
							dispatch("ContinueSearch", request);
						}
						catch (err)
						{
							ReportError(rootGetters.urlRoot, "Error processing (Continued) search results: " + err.stack);
							ShowErrorWindow();
							commit("FinalizeAsyncSearch", searchid);
							return;
						}
					})
					.catch(err =>
					{
						console.error("🔍 [" + searchid + "] (Continued) Search error: " + err.message);
						if (err.name !== "ApiError")
							ReportError(rootGetters.urlRoot, "(Continued) Search error: " + err.message, null, err);
						ShowErrorWindow(err);
						commit("FinalizeAsyncSearch", searchid);
					});

			}
			catch (err)
			{
				if (err.name === "ApiError")
				{
					if (err.data.serviceReady)
					{
						ShowErrorWindow(err.message);
					}
				}
				else
				{
					ReportError(rootGetters.urlRoot, "(Continued) Search error: " + err.message, null, err);
					ShowErrorWindow();
				}
				commit("FinalizeAsyncSearch", searchid);
			}
		},
		PrevResultPage({ commit, dispatch, state, rootState }, { searchid, categoryType })
		{
			return dispatch("SwitchToResultPage", { searchid: searchid, categoryType: categoryType, pageNum: this.getters.GetCategorySearchResults(searchid, categoryType).pageNum - 1 });
		},
		NextResultPage({ commit, dispatch, state }, { searchid, categoryType })
		{
			return dispatch("SwitchToResultPage", { searchid: searchid, categoryType: categoryType, pageNum: this.getters.GetCategorySearchResults(searchid, categoryType).pageNum + 1 });
		},
		SpellingUseSuggestion({ commit, dispatch, state }, { searchid, sug, forWord })
		{
			commit("SetChoiceMisspellingItems", { searchid: searchid, word: forWord, replacement: sug });
		},
		SpellingKeepWord({ commit, dispatch, state }, { searchid, word })
		{
			commit("SetChoiceMisspellingItems", { searchid: searchid, word: word, replacement: word });
		},
		SpellingRemoveWord({ commit, dispatch, state }, { searchid, word })
		{
			commit("SetChoiceMisspellingItems", { searchid: searchid, word: word, replacement: null });
		}
	},
	watchers:
		[
			[state => state.sid, (newValue, oldValue, store) =>
			{
				store.commit("ResetSearchResults");
			}]
		]
};
export default searchModule;

function CategoryPageInit(cat)
{
	cat.pageNum = 1;
	cat.numPages = ~~(cat.totalResults / cat.resultsPerPage); // ~~(N) drops decimal places like (int)N in C#
	if (cat.totalResults % cat.resultsPerPage > 0)
		cat.numPages++;
	cat.loadedTime = Date.now();
}

/**
 * docAddressMap exists so that Documents can know which search result they represent.  In a perfect world we could get this information directly from the search results, but the full results require too much space to persist in localStorage/sessionStorage, so they are lost upon opening a new tab or reloading the page.
 * 
 * As an optimization, we load only the needed values into docAddressMap just before loading search result documents, such that we don't waste time setting values for documents we probably will never open. If needed in the future, preloading of all docAddressMap items may be enabled here.
 */
let preloadDocAddressMap = false;
/**
 * Adds all cached search results to docAddressMap using only one reactive set operation.
 * This was found to be much more efficient than adding items to a set that was already reactive.
 * @param {any} state store state
 */
function UpdateDocAddressMap(state)
{
	let dam = JSON.parse(JSON.stringify(state.docAddressMap));
	let docMap = null;
	for (let searchid in state.searchResultHistory)
	{
		if (state.searchResultHistory.hasOwnProperty(searchid))
		{
			let res = state.searchResultHistory[searchid];
			if (res && res.categories)
			{
				for (let i = 0; i < res.categories.length; i++)
				{
					let cat = res.categories[i];
					if (cat && cat.results && cat.results.length > 0 && CategoryUsesDocAddressMap(cat.type))
					{
						docMap = GetDocAddressMappingsForCat(dam, res.searchid, cat.type);
						for (let r = 0; r < cat.results.length; r++)
							docMap[cat.results[r].docAddress] = r;
					}
				}
			}
		}
	}
	state.docAddressMap = dam;
}
function GetDocAddressMappingsForCat(docAddressMap, searchid, categoryType)
{
	if (!docAddressMap)
		docAddressMap = new Object();
	let catMap = docAddressMap[searchid];
	if (!catMap)
	{
		catMap = new Object();
		Vue.set(docAddressMap, searchid, catMap);
	}
	let docMap = catMap[categoryType];
	if (!docMap)
	{
		docMap = new Object();
		Vue.set(catMap, categoryType, docMap);
	}
	return docMap;
}
function SetDocAddressMapping(state, searchid, categoryType, docAddress, resultIndex)
{
	let docMap = GetDocAddressMappingsForCat(state.docAddressMap, searchid, categoryType);
	Vue.set(docMap, docAddress, resultIndex);
}