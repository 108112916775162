<template>
	<div id="contactUsFormRoot" role="dialog" aria-label="Contact Us.  Press escape to close." aria-describedby="contactUsFormDescription">
		<div id="contactUsFormTitleBar" class="titleBar" role="heading" aria-level="1">Contact Us</div>
		<div id="contactUsFormDescription" class="contactUsMsg" v-html="htmlMessage" />
		<div class="contactUsInputs" role="form" ref="validateForm">
			<div class="emailBox">
				<div class="labelAndValidator">
					<label for="contactUsEmail" class="label">Your Email</label>
					<span id="contactUsYourEmailValid" class="validationWarning" 
						  v-show="validator.has('email')">{{ validator.first('email').msg }}</span>
				</div>
				<input id="contactUsEmail" aria-required="true" role="textbox" aria-label="Your email" ref="emailBox" name="email"
					   class="box" data-vv-as="Email" type="text" v-model="email" tabindex="0"
					   validatespec="required|email" maxlength="250" autocomplete="email"
					   :aria-describedby="validator.has('email') ? 'contactUsYourEmailValid' : null"
					   :aria-invalid="validator.has('email') ? 'true' : null"/>
			</div>
			<div class="sideBySideInputs">
				<div class="leftInputField nameBox">
					<label for="contactUsName" class="label">Name</label>
					<span id="contactUsNameValid" class="validationWarning" v-show="validator.has('name')">{{ validator.first('name').msg }}</span>
					<input id="contactUsName" aria-required="true" role="textbox" aria-label="Name" 
						   ref="nameBox" name="name"
						   class="box" data-vv-as="Name" type="text" v-model="name" tabindex="0"
						   validatespec="required" maxlength="100" autocomplete="name"
						   :aria-describedby="validator.has('name') ? 'contactUsNameValid' : null"
						   :aria-invalid="validator.has('name') ? 'true' : null"/>
				</div>
				<div class="rightInputField phoneBox">
					<label for="contactUsPhone" class="label">Phone</label>
					<span id="contactUsPhoneValid" class="validationWarning" v-show="validator.has('phone')">{{ validator.first('phone').msg }}</span>
					<input id="contactUsPhone" aria-required="true" role="textbox" aria-label="phone number" ref="phoneBox"
						   name="phone" class="box" data-vv-as="Phone" type="text" v-model="phone"
						   tabindex="0" validatespec="required" maxlength="100" autocomplete="tel"
						   :aria-describedby="validator.has('phone') ? 'contactUsPhoneValid' : null"
						   :aria-invalid="validator.has('phone') ? 'true' : null"/>
				</div>
			</div>
			<div class="sideBySideInputs">
				<div class="leftInputField subjectBox">
					<label for="contactUsSubject" class="label">Subject</label>
					<span id="contactUsSubjectValid" class="validationWarning" v-show="validator.has('subject')">{{ validator.first('subject').msg }}</span>
					<input id="contactUsSubject" aria-required="true" role="textbox" aria-label="Subject of message" ref="subjectBox"
						   name="subject" class="box" data-vv-as="Subject" type="text" v-model="subject"
						   tabindex="0" validatespec="required" maxlength="120"
						   :aria-describedby="validator.has('subject') ? 'contactUsSubjectValid' : null"
						   :aria-invalid="validator.has('subject') ? 'true' : null"/>
				</div>
				<div class="rightInputField groupInstBox">
					<label for="contactUsGroup" class="label">Group/Institution (optional)</label>
					<input id="contactUsGroup" name="groupInst" role="textbox"
						   aria-label="Group or institution name" class="box"
						   data-vv-as="Your group/institution" type="text" v-model="groupInst"
						   tabindex="0" maxlength="120" />
				</div>
			</div>
			<label for="comments" class="commentsLabel label">Comments</label>
			<span id="contactUsCommentsValid" class="commentsValidation validationWarning" v-show="validator.has('comments')">{{ validator.first('comments').msg }}</span>
			<textarea role="textbox" maxlength="50000" aria-label="Comments" class="commentBox" id="comments" 
					  name="comments" v-model="comments" data-vv-as="Comments" validatespec="required" 
					  tabindex="0" :aria-describedby="validator.has('comments') ? 'contactUsCommentsValid' : null" 
					  :aria-invalid="validator.has('comments') ? 'true' : null"/>
			<div class="buttons">
				<div role="button" class="ctrlButton" v-on:click="submitClick" tabindex="0" @keydown.space.enter.stop.prevent="submitClick">Submit</div>
				<div role="button" class="ctrlButton" v-on:click="cancelClick" tabindex="0" @keydown.space.enter.stop.prevent="cancelClick">Cancel</div>
			</div>
		</div>
		<FullScreenLoadingMsg :show="sendingEmail" msg="Sending…" />
	</div>
</template>

<script>
	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import { ContactUs } from 'tdsAppRoot/API/ContactUsAPI.js';
	import { ModalMessageDialog, ModalConfirmDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { Validation } from 'tdsAppRoot/library/ValidationHelper.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";

	export default {
		components: { FullScreenLoadingMsg },
		props:
		{
			htmlMessage: {
				type: String,
				default: "Please let us know what we can do to help you. You may also contact Customer Support at (800) 901-5494."
			}
		},
		data()
		{
			return {
				name: "", email: "", groupInst: "", phone: "", subject: "", comments: "",
				sendingEmail: false,
				validator: new Validation()
			}
		},
		created()
		{
			if (this.$store.state.profile && this.$store.state.profile.ProfileEmail)
				this.email = this.$store.state.profile.ProfileEmail;
		},
		methods:
		{
			DefaultClose()
			{
				this.cancelClick();
			},
			SetFocus()
			{
				this.$refs.emailBox.focus();
			},
			PreventDefault(event)
			{
				event.preventDefault();
				return false;
			},
			submitClick(sender)
			{
				event.preventDefault();
				console.log("SubmitClick");
				var storeSave = this.$store;
				var vald = this.validator;
				this.validator.ValidateAll(this.$data).then(result =>
				{
					this.$data.sendingEmail = true;
					if (result)
					{
						ContactUs(this.$store, this.$data.name, this.$data.email, this.$data.groupInst, this.$data.phone, this.$data.subject, this.$data.comments).then(data =>
						{
							ModalMessageDialog("Your message has been sent", "Confirmation");
							this.$data.sendingEmail = false;

							this.closePanel();
						}
						).catch(err =>
						{
							event.stopPropagation();
							console.error("Error sending e-mail", err);
							this.$data.sendingEmail = false;
							if (err.name === "ApiError")
							{
								ShowErrorWindow(err.message);
							}
							else
							{
								console.log("Contact Us Error: " + err.message);
								ReportError(storeSave.getters.urlRoot, "Contact Us Error: " + err.message, null, err, storeSave.state.sid);
								ShowErrorWindow("There was an error sending your e-mail.  Technical support has been notified.  We are sorry for the inconvenience, please try again later.");
							}
						});
					}
				}
				).catch(err =>
				{
					if (vald.HasAnyErrors())
					{
						var targetInput = document.getElementById(vald.first().domId);
						targetInput.focus();
					}
					this.$data.sendingEmail = false;
				});
			},
			ConfirmClose()
			{
				return ModalConfirmDialog({
					message: "Your message has not been sent yet.",
					title: "Message Not Sent",
					yesText: "Stay Open",
					noText: "Discard Form",
					cancelMeansYes: true
				});
			},
			cancelClick()
			{
				if (this.name || this.email || this.groupInst || this.phone || this.subject || this.comments || this.sendingEmail)
				{
					this.ConfirmClose().then(stayOpen =>
					{
						if (!stayOpen)
							this.closePanel();
					});
				}
				else
				{
					this.closePanel();
				}
			},
			closePanel()
			{
				this.$emit("close");
			}
		},
		mounted()
		{
			this.validator.setFormRoot(this.$refs.validateForm);
		}
	}
</script>


<style scoped>

	input[type='text']
	{
		color: black;
	}

	.contactUsInputs
	{
		box-sizing: border-box;
		width: 100%;
		margin: 0px;
		border: none;
		padding: 0px;
		position: relative;
	}

	.label
	{
		font-size: 14px;
	}


	.emailBox, .commentBox, .sideBySideInputs
	{
		width: 96%;
		margin: 0px 2% 0px 2%;
		padding: 0px 0px 10px 0px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
	}

	.commentsLabel
	{
		width: 96%;
		margin: 0px 0px 0px 2%;
	}

	.emailBox
	{
		display: block;
	}


	.commentsValidation
	{
		margin-left: 2%;
	}


	.nameBox, .phoneBox, .subjectBox, .groupInstBox
	{
		margin: 0px;
		padding: 0px;
		width: 100%;
	}

	.phoneBox, .groupInstBox
	{
		margin-left: 10px;
	}

	@media (max-width: 430px)
	{
		.emailBox, .commentBox, .sideBySideInputs
		{
			display: block;
		}

		.phoneBox, .groupInstBox
		{
			margin-top: 10px;
			margin-left: 0px;
		}
	}

	.commentBox
	{
		height: 120px;
		padding: 5px;
	}

	.emailBox input.box
	{
		width: 100%;
	}

	.box
	{
		border-radius: 5px;
		height: 30px;
		font-size: 16px;
		padding-left: 5px;
		padding-right: 5px;
	}

	.validationWarning
	{
		font-size: 12px;
		color: red;
		margin-left: 20px;
	}

	.rightInputField
	{
		/*float: right;*/
	}

	.rightInputField, .leftInputField
	{
		/*display: inline-block;*/
	}

		.rightInputField input.box, .leftInputField input.box
		{
			width: 100%;
		}

	.buttons
	{
		display: flex;
		flex-direction: row;
	}

	.ctrlButton
	{
		clear: both;
		width: 20%;
		margin: 15px auto 15px auto;
		border-radius: 5px;
		cursor: pointer;
		color: white;
		font-weight: bold;
		font-size: 18px;
		border: 2px solid transparent;
		box-sizing: border-box;
		text-align: center;
		padding: 3px 0px 3px 0px;
		background-color: #3D8538;
	}

		.ctrlButton:focus-visible
		{
			border: 2px solid black;
			outline: none;
		}

		.ctrlButton.focus-visible
		{
			border: 2px solid black;
			outline: none;
		}

	.titleBar
	{
		background-color: var(--primary-color);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-sizing: border-box;
		padding: 21px 42px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		color: var(--text-color-on-primary-color);
		font-weight: bold;
		font-size: 18pt;
	}

	.contactUsMsg
	{
		font-family: Arial;
		font-size: 14px;
		color: #6C6A6C;
		width: 96%;
		margin: 0px 2% 0px 2%;
		padding: 10px 0px 15px 0px;
	}
</style>