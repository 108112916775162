<template>
	<span v-if="logo">
		<!-- Wrapper span is necessary in IE11 for the logo to be centered on the login page. May have other layout consequences too. -->
		<vsvg v-if="img && img.svgSprite" :class="dynSvgClass" :style="dynImgStyle" :sprite="img.svgSprite" :title="logo.title" :desc="desc" :isPresentation="rolePresentation ? true : false" />
		<img v-else class="logoImg" :style="dynImgStyle" :src="logoImgSrc" :title="logo.title" />
	</span>
</template>

<script>
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import { Clamp } from "tdsAppRoot/library/TDSUtil.js";

	export default {
		mixins: [ThemeMixin],
		props:
		{
			logoType: {
				type: String,
				required: true
			},
			includeDesc: {
				type: Boolean,
				default: true
			},
			width: { /* Must provide either width or height. If both are provided, the image will be fitted inside the defined square, preserving the image's aspect ratio. */
				type: Number,
				default: 0
			},
			height: { /* See above ^ _ ^ */
				type: Number,
				default: 0
			},
			rolePresentation: {
				type: Boolean,
				default: false
			},
			logoKey: {
				type: String,
				default: "platformLogo"
			}
		},
		computed:
		{
			logo()
			{
				return this.themeMetadata[this.logoKey];
			},
			img()
			{
				if (!this.logo)
				{
					console.error('Logo [' + this.logoKey + '] was not found in the current theme "' + this.themeMetadata.key + '".');
					return null;
				}
				if (!this.logo.images[this.logoType])
					console.error('Logo [' + this.logoKey + '["' + this.logoType + '"]] was not found in the current theme "' + this.themeMetadata.key + '".');
				return this.logo.images[this.logoType];
			},
			logoImgSrc()
			{
				if (this.img)
					return this.$store.getters.urlRoot + this.img.file;
				return null;
			},
			desc()
			{
				if (this.includeDesc && typeof this.logo.desc !== 'undefined')
					return this.logo.desc;
				else
					return "";
			},
			dynImgStyle()
			{
				if (!this.img)
					return {};

				let maxW = Clamp(this.width, 0, 65535);
				let maxH = Clamp(this.height, 0, 65535);
				if (maxW && maxH)
				{
					if (maxW / maxH > this.img.aspectRatio)
						maxW = 0; // Limiting box is wider than the image will be. Clear the max width.
					else
						maxH = 0; // Limiting box is taller than the image will be. Clear the max height.
				}

				let calcWidth = maxW;
				let calcHeight = maxH;
				if (calcWidth)
					calcHeight = calcWidth / this.img.aspectRatio;
				else if (calcHeight)
					calcWidth = calcHeight * this.img.aspectRatio;
				else
					console.error("LogoImage did not receive a valid width or height", this.width, this.height);

				return {
					width: (Math.round(calcWidth * 100) / 100) + "px",
					height: (Math.round(calcHeight * 100) / 100) + "px"
				};
			},
			dynSvgClass()
			{
				return this.logoKey + "-" + this.logoType;
			}
		}
	}
</script>

<style scoped>
	svg.platformLogo-standalone
	{
		fill: var(--primary-color);
	}

	svg.platformLogo-onPrimaryColor
	{
		fill: var(--text-color-on-primary-color);
	}

	svg.subproductLogo-defaultLogo
	{
		fill: var(--primary-color-text);
	}
</style>