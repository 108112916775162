<template>
	<div ref="panelRoot" class="panelRoot" role="alertdialog" tabindex="0" aria-labelledby="editGroupDefaultPanelsMsgTitle" aria-describedby="editGroupDefaultPanelsMsgLabel">
		<div class="titleBar">
			<div class="title" id="editGroupDefaultPanelsMsgTitle" role="alert">Edit Default Panels for <span v-html="groupTitle"></span> <span class="visuallyhidden">Press escape to cancel.</span></div>
		</div>
		<div class="dialogBody">
			<div ref="msgLabel" id="editGroupDefaultPanelsMsgLabel" class="messageText" role="document">
				<p>The buttons below allow you to change the default home page panel order for all users of your subscription.</p>
				<p v-if="customTitleToolCount === 0"><b>Note:</b> You can add title panels to your home page, and these will be included when you save your current panel order as default for your subscription.</p>
				<p v-if="customTitleToolCount > 0"><b>Note:</b> You have added <b>{{customTitleToolCount}} title <span v-if="customTitleToolCount === 1">panel</span><span v-else>panels</span></b> to your home page. <span v-if="customTitleToolCount === 1">This panel</span><span v-else>These panels</span> will be included when you save your current panel order as default for your subscription.</p>
				<p v-if="hiddenToolCount > 0"><b>Note:</b> You have <b>{{hiddenToolCount}} hidden <span v-if="hiddenToolCount === 1">panel</span><span v-else>panels</span></b> configured in your Profile.  Hidden panels are specific to your Profile and will not be hidden in your subscription's defaults.</p>
			</div>
			<div class="controls">
				<div role="button" class="medButton primaryColorButton" tabindex="0" @click="SetGroupDefaultToolSettings" @keypress.enter.space.prevent.stop="SetGroupDefaultToolSettings">
					Save my current panel order as default for my subscription
				</div>
				<div role="button" class="medButton primaryColorButton" tabindex="0" @click="DeleteGroupDefaultToolSettings" @keypress.enter.space.prevent.stop="DeleteGroupDefaultToolSettings">
					Reset my subscription's default panel order
				</div>
				<div ref="btnClose" role="button" class="medButton primaryColorButton" tabindex="0" @click="DefaultClose" @keypress.enter.space.prevent.stop="DefaultClose">
					cancel
				</div>
			</div>
		</div>
		<FullScreenLoadingMsg :show="busy" msg="Processing…" />
	</div>
</template>

<script>
	import { SetGroupDefaultToolSettings, DeleteGroupDefaultToolSettings } from 'tdsAppRoot/API/MyStatrefAPI.js';
	import { DefaultErrorHandler } from 'tdsAppRoot/library/ErrorReporter.js';
	import { ModalMessageDialog } from 'tdsAppRoot/library/ModalDialog.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";

	export default {
		components: { FullScreenLoadingMsg },
		data()
		{
			return {
				busy: false
			};
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.panelRoot)
					this.$refs.panelRoot.focus();
			},
			DefaultClose()
			{
				this.$emit('close');
			},
			SetGroupDefaultToolSettings()
			{
				if (this.busy)
					return;
				this.busy = true;
				SetGroupDefaultToolSettings(this.$store, this.$store.getters.SplashToolOrderHiddenAtEnd, this.customTitleToolIds)
					.then(data =>
					{
						this.busy = true;
						ModalMessageDialog("Your subscription's default panel order has been set. Active users must log out and back in to see the changes.", "Success");
						this.DefaultClose();
					})
					.catch(err =>
					{
						DefaultErrorHandler(err);
					})
					.finally(() =>
					{
						this.busy = false;
					});
			},
			DeleteGroupDefaultToolSettings()
			{
				if (this.busy)
					return;
				this.busy = true;
				DeleteGroupDefaultToolSettings(this.$store)
					.then(data =>
					{
						ModalMessageDialog("Your subscription's default panel order has been reset. Active users must log out and back in to see the changes.", "Success");
						this.DefaultClose();
					})
					.catch(err =>
					{
						DefaultErrorHandler(err);
					})
					.finally(() =>
					{
						this.busy = false;
					});
			}
		},
		computed:
		{
			groupTitle()
			{
				return appContext.groupTitle;
			},
			hiddenToolCount()
			{
				return this.$store.getters.SplashTools.filter(tool => tool.inactive).length;
			},
			customTitleToolIds()
			{
				return this.$store.getters.SplashTools.filter(tool => tool.isCustomTitleTool).map(tool => tool.args.fxid);
			},
			customTitleToolCount()
			{
				return this.$store.getters.SplashTools.filter(tool => tool.isCustomTitleTool && !tool.isAppSettingsTool).length;
			}
		},
		mounted()
		{
		}
	}
</script>

<style scoped>
	.panelRoot
	{
		max-width: 90vw;
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}

	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}

	.messageText
	{
		padding: 8px 14px 20px 14px;
	}

	.controls
	{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.controls > div
	{
		margin: 10px;
	}
</style>