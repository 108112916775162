<template>
	<transition name="fsFade">
		<div v-if="show" ref="mainDiv" class="modalRootLight" role="alert" aria-labelledby="loadingMsgContent"
			 :style="rootStyle" @click.stop.prevent="OnClick" aria-modal="true">
			<div class="content" :style="{ backgroundColor: backgroundColor }" v-bind:class="{ shadow: shadow }">
				<ScaleLoader class="animation" /><span id="loadingMsgContent">{{ msg }}</span>
			</div>
			<div ref="focusCatcherA" class="FocusCatcher" tabindex="0" @focus="FocusCatcher(false)"></div>
			<div ref="focusEle" tabindex="0">
			</div>
			<div ref="focusCatcherB" class="FocusCatcher" tabindex="0" @focus="FocusCatcher(true)"></div>
		</div>
	</transition>
</template>

<script>
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	//import ModalDialogAccessibilityMixin from 'tdsAppRoot/library/ModalDialogAccessibilityMixin.js';
	import { FocusDescendant } from 'tdsAppRoot/library/TDSUtil.js';
	import addRemovedHook from "vue-removed-hook-mixin";

	export default {
		components: { ScaleLoader },
		mixins: [addRemovedHook],
		props:
		{
			msg: {
				type: String,
				default: "Loading"
			},
			backgroundColor: {
				type: String,
				default: "white"
			},
			shadow: {
				type: Boolean,
				default: false
			},
			belowDialogs: {
				type: Boolean,
				default: false
			},
			show: {
				type: Boolean,
				default: true
			}
		},
		data()
		{
			return {
				oldFocus: null
			};
		},
		mounted()
		{
			this.$store.commit("SetFullScreenLoadingMsgVisible", true);
			this.oldFocus = document.activeElement;
			if (this.show)
			{
				var root = document.getElementById("appContentRoot");
				if (root)
					root.setAttribute("aria-hidden", "true");
				this.SetFocus();
			}
		},
		beforeDestroy()
		{
			var root = document.getElementById("appContentRoot");
			if (root)
				root.removeAttribute("aria-hidden");
			this.RestoreFocus();
		},
		removed()
		{
			this.$store.commit("SetFullScreenLoadingMsgVisible", false);
		},
		methods:
		{
			SetFocus()
			{
				if (this.show)
				{
					if (this.$refs.focusEle)
						this.$refs.focusEle.focus();
				}
			},
			RestoreFocus()
			{
				if (document.activeElement == this.$refs.focusEle
					|| document.activeElement == this.$refs.focusCatcherA
					|| document.activeElement == this.$refs.focusCatcherB)
				{
					if (this.oldFocus)
						this.oldFocus.focus({
							preventScroll: true
						});
				}
			},
			FocusCatcher(focusFirstItem)
			{
				if (this.show)
				{
					if (this.$refs.mainDiv)
						FocusDescendant(this.$refs.mainDiv, focusFirstItem);
				}
			},
			OnClick()
			{
				this.SetFocus();
			}
		},
		watch:
		{
			show(newVal)
			{
				var root = document.getElementById("appContentRoot");
				if (newVal)
				{
					this.oldFocus = document.activeElement;
					root.setAttribute("aria-hidden", "true");
					setTimeout(() =>
					{
						this.SetFocus();
					}, 0);
				}
				else
				{
					this.RestoreFocus();
					root.removeAttribute("aria-hidden");
				}
			}
		},
		computed:
		{
			rootStyle()
			{
				let style = {};
				if (this.belowDialogs)
					style.zIndex = 4000; // Dialogs are at 4010
				return style;
			}
		}
	}
</script>

<style scoped>
	.fsFade-enter-active
	{
		transition: opacity 0.6s; /* Fairly slow transition so that very short-duration loading messages may intentionally go unnoticed. */
	}

	.fsFade-leave-active
	{
		transition: opacity 0.2s; /* Quick transition so the message disappears quickly. */
	}

	.fsFade-enter, .fsFade-leave-to
	{
		opacity: 0;
	}

	.modalRootLight
	{
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.content
	{
		padding: 0px 15px 5px 15px;
		border-radius: 10px;
		color: dimgray;
		font-size: 30pt;
		border: none;
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.shadow
	{
		-webkit-box-shadow: 0px 0px 10px 6px rgba(68,68,68,1);
		-moz-box-shadow: 0px 0px 10px 6px rgba(68,68,68,1);
		box-shadow: 0px 0px 10px 6px rgba(68,68,68,1);
	}

	.animation
	{
		margin-right: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	@media (min-width: 600px)
	{
		.content
		{
			font-size: 50pt;
		}
	}
</style>