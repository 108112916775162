var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "dialogRoot",
      class: { inputDialogRoot: true, shake: _vm.shake },
      attrs: {
        role: "dialog",
        "aria-labelledby": "textInputMsgTitle",
        "aria-describedby": _vm.message ? "textInputMsg" : "textInputMsgTitle"
      }
    },
    [
      _vm.title
        ? _c("div", { staticClass: "titleBar" }, [
            _c(
              "div",
              {
                staticClass: "title",
                attrs: { id: "textInputMsgTitle", role: "alert" }
              },
              [
                _vm._v(_vm._s(_vm.title) + " "),
                _c("span", { staticClass: "visuallyhidden" }, [
                  _vm._v("Press escape to cancel.")
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _vm.message
          ? _c(
              "div",
              { staticClass: "messageText", attrs: { id: "textInputMsg" } },
              [
                _vm._v(_vm._s(_vm.message) + " "),
                _c("span", { staticClass: "visuallyhidden" }, [
                  _vm._v("Press escape to cancel.")
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "inputWrapper" }, [
          _c("label", { staticClass: "labelWrapper" }, [
            _c("div", { staticClass: "label" }, [
              _vm._v(_vm._s(_vm.inputLabel))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model:value",
                  value: _vm.value,
                  expression: "value",
                  arg: "value"
                }
              ],
              ref: "inputText",
              staticClass: "textInput",
              attrs: { type: "text", maxlength: _vm.maxTextLength },
              domProps: { value: _vm.value },
              on: {
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.okClicked.apply(null, arguments)
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value = $event.target.value
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _vm.checkboxText
          ? _c(
              "div",
              { staticClass: "inputWrapper checkbox" },
              [
                _c(
                  "SRCustomCheckbox",
                  {
                    attrs: { square: true, size: 15, fontSize: "11pt" },
                    model: {
                      value: _vm.checked,
                      callback: function($$v) {
                        _vm.checked = $$v
                      },
                      expression: "checked"
                    }
                  },
                  [_vm._v("\n\t\t\t\t" + _vm._s(_vm.checkboxText) + "\n\t\t\t")]
                )
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "buttons" }, [
          _c(
            "div",
            {
              ref: "okBtn",
              staticClass: "dialogButton okButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.okClicked,
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.okClicked.apply(null, arguments)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "right", 39, $event.key, [
                        "Right",
                        "ArrowRight"
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 2) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.FocusCancel.apply(null, arguments)
                  }
                ]
              }
            },
            [_vm._v("\n\t\t\t\tOK\n\t\t\t")]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "cancelBtn",
              staticClass: "dialogButton cancelButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.cancelClicked,
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.cancelClicked.apply(null, arguments)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "left", 37, $event.key, [
                        "Left",
                        "ArrowLeft"
                      ])
                    ) {
                      return null
                    }
                    if ("button" in $event && $event.button !== 0) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.FocusOK.apply(null, arguments)
                  }
                ]
              }
            },
            [_vm._v("\n\t\t\t\tCancel\n\t\t\t")]
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }