<template>
	<div class="mfaRememberedDevices">
		<div class="titleBar">Remembered Devices</div>
		<div class="body">
			<div class="rememberedDescription">Remembered devices have already been authenticated with MFA and are not required to complete additional tasks when logging in.</div>
			<div v-if="rememberedDevices.length === 0">
				Your user account does not have any remembered devices.
			</div>
			<div v-else :class="{ rememberedDeviceList: true, isDeleting: isDeleting }">
				<div class="rememberedDeviceListHeader">
					{{rememberedDevices.length}} remembered device{{rememberedDevices.length === 1 ? '' : 's'}}
				</div>
				<div class="rememberedDevice" v-for="device in rememberedDevices">
					<div class="rememberedDeviceDelete" tabindex="0" @click="$emit('delete', device.ID)" @keypress.enter.space.stop.prevent="$emit('delete', device.ID)" role="button" aria-label="Delete device"><vsvg sprite="#delete_forever" title="Delete device" desc="" aria-hidden="true" /></div>
					<div class="rememberedDeviceDescription">{{device.Description}}</div>
					<div class="rememberedDeviceIsThisDevice" v-if="device.IsCurrentDevice">You are using this device</div>
					<div class="rememberedDeviceTime"><b>First Login:</b> {{DateStr(device.SetupTimeUTC)}}</div>
					<div class="rememberedDeviceLocation" title="Location at first login">{{device.Location}}</div>
					<div class="rememberedDeviceIP" title="IP Address at first login">{{device.IPAddress}}</div>
					<div class="rememberedDeviceTime"><b>Last Login:</b> {{DateStr(device.LastUsedTimeUTC)}}</div>
				</div>
			</div>
			<template v-if="deviceIsRemembered">
				<!--This device is remembered by
				<span v-html="appNameHtml"></span>.-->
			</template>
			<div class="rememberNoteNegative" v-else-if="!allowedToRemember">This user is not allowed to add new remembered devices.</div>
			<div class="rememberNoteNegative" v-else-if="!ableToRemember">This app/device is not able to be remembered.</div>
			<div class="rememberNoteNeutral" v-else-if="rememberedDevices.length > 0">This device is not remembered.</div>
		</div>
	</div>
</template>
<script>
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";
	import svg1 from "tdsAppRoot/images/sprite/delete_forever.svg";

	export default {
		components: { SRCustomCheckbox },
		props:
		{
			rememberedDevices: {
				type: Array,
				required: true
			},
			allowedToRemember: {
				type: Boolean,
				required: true
			},
			ableToRemember: {
				type: Boolean,
				required: true
			},
			isDeleting: {
				type: Boolean,
				default: false
			},
		},
		data()
		{
			return {
				appNameHtml: appContext.appNameHtml
			};
		},
		created()
		{
		},
		mounted()
		{
		},
		computed:
		{
			deviceIsRemembered()
			{
				for (let i = 0; i < this.rememberedDevices.length; i++)
					if (this.rememberedDevices[i].IsCurrentDevice)
						return true;
				return false;
			}
		},
		methods:
		{
			DateStr(ts)
			{
				return new Date(ts).toLocaleString();
			}
		},
		watch:
		{
		}
	}
</script>
<style scoped>
	.isDeleting
	{
		filter: blur(2px);
	}

	.body
	{
		margin: 2px;
	}

	.titleBar
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5em;
		font-weight: bold;
		color: var(--primary-color-text);
		margin-bottom: 1em;
	}

	.rememberedDeviceDelete
	{
		float: right;
	}

		.rememberedDeviceDelete svg
		{
			width: 40px;
			height: 40px;
			fill: var(--primary-color-text);
			cursor: pointer;
		}

			.rememberedDeviceDelete svg:hover
			{
				fill: var(--primary-color-text-light1);
				filter: drop-shadow( 0px 0px 2px rgba(0,0,0,0.4) );
			}

	.rememberedDescription
	{
		margin: 1em 0px;
	}

	.rememberedDeviceList
	{
		border: 1px solid #AAAAAA;
		border-radius: 10px;
		padding: 1em;
	}

	.rememberedDeviceListHeader
	{
		font-weight: bold;
		font-size: 1.2em;
		margin-bottom: 1.5em;
		color: #333333;
	}

	.rememberedDevice
	{
		margin: 1em 0px;
		border-bottom: 1px solid #AAAAAA;
	}

		.rememberedDevice:last-child
		{
			border-bottom: none;
		}

		.rememberedDevice *:last-child
		{
			margin-bottom: 1em;
		}

	.rememberedDeviceDescription
	{
		font-weight: bold;
		margin-bottom: 0.33em;
	}

	.rememberedDeviceTime
	{
		margin-top: 0.33em;
		margin-left: 15px;
	}

	.rememberedDeviceLocation,
	.rememberedDeviceIP
	{
		margin-left: 30px;
	}

	.rememberedDeviceIsThisDevice
	{
		display: inline-block;
		font-weight: bold;
		background-color: #008000;
		color: #FFFFFF;
		border-radius: 1em;
		margin: 0.33em 0.5em;
		padding: 0.2em 0.7em;
		font-size: 1.2em;
	}

	.rememberNotePositive,
	.rememberNoteNegative,
	.rememberNoteNeutral
	{
		margin: 1em 0px;
		padding: 10px;
		border: 3px dotted black;
		background-color: var(--offwhite-panel-color);
		font-size: 1.1em;
	}

	.rememberNotePositive
	{
		border-color: #2e932e;
		background-color: #FFFFFF;
	}

	.rememberNoteNegative
	{
		border-color: #990a0a;
	}
</style>
