<template>
	<button tabindex="0" :class="computedClass" :id="id || _uid" :ref="reference" @click="click" @keydown.space.enter.prevent="click">
		<div :id="captchaDivId" @focusin="Focused" @focusout="Unfocused" style="height: 0px; width: 0px; border: none;"></div>
		<div>
			<slot></slot>
		</div>
	</button>
</template>

<script type="text/javascript">
	import { ModalMessageDialog } from 'tdsAppRoot/library/ModalDialog.js';
	import { ReportRecaptchaLoadFailure } from 'tdsAppRoot/API/MyStatrefAPI.js';
	import { render } from 'nprogress';
	// This control has been significantly modified by TDS since it was forked from a 3rd-party source.
	export default {
		props: {
			sitekey: {
				type: String,
				required: true
			},

			badge: {
				type: String,
				required: false
			},

			theme: {
				type: String,
				required: false
			},

			validate: {
				type: Function,
				required: false
			},

			callback: {
				type: Function,
				required: true
			},

			disabled: {
				type: Boolean,
				required: false
			},

			id: {
				type: String,
				required: false
			},

			reference: {
				type: String,
				required: false
			}
		},

		data: function ()
		{
			return {
				widgetId: false,
				loaded: false,
				timesWaitedForRenderFunc: 0,
				recaptchaLoadFailed: false,
				renderTimeout: null
			};
		},

		methods: {
			render: function ()
			{
				if (this.loaded)
					return;
				var cb = this.callback;
				var saveThis = this;
				this.widgetId = grecaptcha.render(this.captchaDivId, {
					sitekey: this.sitekey,
					size: "invisible",
					badge: this.badge || "bottomright",
					theme: this.theme || "dark",
					callback: function (token)
					{
						cb(token);
						grecaptcha.reset(saveThis.widgetId);
					}
				});
				this.loaded = true;
			},

			renderWait: function ()
			{
				const self = this;
				this.renderTimeout = setTimeout(function ()
				{
					if (typeof grecaptcha !== "undefined")
					{
						// The render function loads asynchronously!
						if (typeof grecaptcha.render === "function")
							self.render();
						else
						{
							if (++this.timesWaitedForRenderFunc > 50)
							{
								// Failed to render Recaptcha.
								this.recaptchaLoadFailed = true;
							}
							else
								self.renderWait();
						}
					}
					else
						self.renderWait();
				}, 200);
			},

			click: function (event)
			{
				event.preventDefault();
				if (this.recaptchaLoadFailed)
				{
					ReportRecaptchaLoadFailure(this.$store);
					ModalMessageDialog("A necessary component failed to load.  Please reload the page and try again.  Please contact technical support using the Contact link at the bottom of the page if the problem persists.  Sorry for the inconvenience.");
					return;
				}
				if (!this.loaded || this.disabled)
					return;
				if (this.validate)
				{
					this.validate().then(result =>
					{
						if (result)
							grecaptcha.execute(this.widgetId);
					}).catch(err =>
					{
						if (err)
						{
							console.log("Error executing recaptcha: " + err.message);
							throw err;
						}
					});
				}
			},

			ResetRecaptcha: function ()
			{
				grecaptcha.reset(this.widgetId);
			},
			Focused: function ()
			{
				// At the moment, these don't actually seem to get called in Chrome anyway, but just in case that changes, I'm leaving this check in.
				if (window.document.documentMode) // IE?
				{
					var target = document.querySelectorAll("#" + this.captchaDivId + " .grecaptcha-badge");
					target[0].style.transitionProperty = null;
					target[0].style.right = "0px";
				}

			},
			Unfocused: function ()
			{
				if (window.document.documentMode) // IE?
				{
					var target = document.querySelectorAll("#" + this.captchaDivId + " .grecaptcha-badge");
					target[0].style.right = "-186px";
					target[0].style.transitionProperty = "right";
				}
			}

		},

		computed: {
			computedClass: function ()
			{
				var classArray = ["invisible-recaptcha"];
				if (!this.loaded || this.disabled)
					classArray.push("disabled");
				return classArray;
			},
			captchaDivId: function ()
			{
				return this.id + "_cptDiv";
			}
		},

		mounted: function ()
		{
			this.recaptchaLoadFailed = false;
			if (typeof grecaptcha === "undefined")
			{
				this.timesWaitedForRenderFunc = 0;
				var script = document.createElement("script");
				script.id = "recaptchaScript";
				script.src = "https://www.google.com/recaptcha/api.js?render=explicit";
				script.onload = this.renderWait;

				document.head.appendChild(script);
			}
			else
				this.render();
		},
		destroyed()
		{
			var script = document.getElementById("recaptchaScript");
			if (script)
			{
				script.onload = null;
				script.parentElement.removeChild(script);
			}
			if (this.renderTimeout != null)
				clearTimeout(this.renderTimeout);
		}
	};
</script>
<style scoped>
	.invisible-recaptcha
	{
		display: block;
	}

		.invisible-recaptcha.disabled
		{
			opacity: 0.67;
		}

		.invisible-recaptcha:focus
		{
			outline-style: solid;
			outline-width: 4px;
		}
</style>