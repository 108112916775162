var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "supportInfoRoot",
      attrs: {
        id: "supportInfoDialog",
        role: "dialog",
        "aria-labelledby": "supportInfoTitle",
        "aria-describedby": "supportInfoLabel"
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialogBody",
          attrs: { id: "supportInfoLabel", role: "document" }
        },
        [
          _c("table", [
            _c(
              "tbody",
              _vm._l(_vm.info, function(item) {
                return _c("tr", { key: item.key }, [
                  _c("td", [_vm._v(_vm._s(item.key))]),
                  _c("td", [_vm._v(_vm._s(item.value))])
                ])
              }),
              0
            )
          ]),
          _vm._v(" "),
          _vm.allowCauseError
            ? _c(
                "div",
                {
                  staticClass: "debuggingToolsButton",
                  attrs: { role: "button", title: "toggle debugging tools" },
                  on: {
                    click: _vm.ToggleDebuggingTools,
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar"
                        ]) &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.ToggleDebuggingTools.apply(null, arguments)
                    }
                  }
                },
                [
                  _c("vsvg", {
                    attrs: {
                      sprite: "#ChevronsMatch",
                      title: "Debugging Tools",
                      desc: "",
                      isPresentation: false
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "closeBtnWrapper" }, [
            _c(
              "div",
              {
                ref: "closeBtn",
                staticClass: "smButton primaryColorButton",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.DefaultClose,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.DefaultClose.apply(null, arguments)
                  }
                }
              },
              [_vm._v("\n\t\t\t\tclose\n\t\t\t")]
            )
          ]),
          _vm._v(" "),
          _vm.showDebuggingTools
            ? _c("div", { staticClass: "debuggingTools" }, [
                _c(
                  "div",
                  {
                    staticClass: "smButton primaryColorButton",
                    attrs: { role: "button", tabindex: "0" },
                    on: {
                      click: _vm.ViewStorageInfo,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar"
                          ]) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.ViewStorageInfo.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\tStorage Info\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "smButton primaryColorButton",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      title: "Causes an error (to test error reporting)"
                    },
                    on: {
                      click: _vm.CauseError,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar"
                          ]) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.CauseError.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\tcause error\n\t\t\t")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "smButton primaryColorButton",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      title: "Causes an error (to test error reporting)"
                    },
                    on: {
                      click: _vm.CauseError2,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar"
                          ]) &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                        return _vm.CauseError2.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\tcause error 2\n\t\t\t")]
                )
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "titleBar" }, [
      _c(
        "div",
        {
          staticClass: "title",
          attrs: { id: "supportInfoTitle", role: "heading" }
        },
        [
          _vm._v("Support Info "),
          _c("span", { staticClass: "visuallyhidden" }, [
            _vm._v("Press escape to close.")
          ])
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }