<template>
	<div :class="titleInfoRootClasses">
		<template v-if="success">
			<DynamicLink :to="linkTo" :href="linkHref" :div="coverPicIsDiv" :insetOutline="false" class="titleLink">
				<img class="titleCoverImage" alt="" role="presentation" :src="titleImgSrc" />
				<span class="titleName" v-html="name"></span>
			</DynamicLink>
			<p></p>
			<div v-if="favorites" class="favoritesContainer">
				<div class="favoritesHeading">Favorites</div>
				<MiniFavoritesList :favorites="favorites" class="favoritesList" />
			</div>
			<span v-else class="titleInfoTooltipDescription" v-html="description"></span>
		</template>
		<div v-else-if="error">{{error}}</div>
		<div v-else class="loading">
			<ScaleLoader class="animation" />
			<div class="loadingtxt">Loading description…</div>
		</div>
	</div>
</template>

<script>
	import { GetTitleInfo } from 'tdsAppRoot/API/TitleInfoAPI.js';
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import DynamicLink from "tdsAppRoot/components/Controls/DynamicLink.vue";
	import MiniFavoritesList from "tdsAppRoot/components/Favorites/MiniFavoritesList.vue";
	import { CreateDocLinkRoute } from "tdsAppRoot/library/TDSUtil.js";

	export default {
		components: { ScaleLoader, DynamicLink, MiniFavoritesList },
		props:
		{
			fxid: {
				type: Number,
				required: true
			},
			isResourceWidget: {
				type: Boolean,
				default: false
			},
			categoryType: { // If provided, links to this title's first document should include this as the categoryType query argument.
				type: String,
				default: ""
			},
			enableLink: {
				type: Boolean,
				default: true
			}
		},
		data()
		{
			return {
				success: false,
				error: null,
				loadedFxid: 0,
				docAddress: null,
				name: "",
				description: ""
			};
		},
		created()
		{
			this.loadTooltip();
		},
		watch:
		{
			fxid()
			{
				this.loadTooltip();
			}
		},
		methods:
		{
			loadTooltip()
			{
				this.success = false;
				this.error = null;
				this.loadedFxid = 0;
				this.docAddress = null;
				this.name = "";
				this.description = "";
				GetTitleInfo(this.$store, this.fxid).then(data =>
				{
					this.loadedFxid = data.fxid;
					this.docAddress = data.docAddress;
					if (data.name && data.name.length > 6 && data.name.substr(0, 6) == "_help_")
						data.name = data.name.substr(6, data.name.length - 6);
					this.name = data.name;
					this.description = data.description;
					if (!this.description || this.description.length == 0)
					{
						this.description = "Description not available.";
					}

					this.success = true;
					this.$emit("load", data);
				}
				).catch(err =>
				{
					this.error = err.message;
				});
			}
		},
		computed:
		{
			favorites()
			{
				if (!this.isResourceWidget || !this.$store.state.profile)
					return null;
				let f = this.$store.getters.getTitleFavorites(this.fxid);
				if (f && f.length)
					return f;
				else
					return null;
			},
			titleImgSrc()
			{
				if (this.success && window)
					return window.appContext.appPath + "TitleImages/" + this.loadedFxid + ".jpg";
				else
					return "";
			},
			titleInfoRootClasses()
			{
				let classes = ["titleInfoRoot"];
				if (this.isResourceWidget)
					classes.push("isResourceWidget");
				if (this.favorites)
					classes.push("hasFavorites");
				return classes;
			},
			linkTo()
			{
				if (this.enableLink && this.isResourceWidget && this.docAddress)
					return CreateDocLinkRoute(this.docAddress, undefined, this.categoryType);
				return null;
			},
			linkHref()
			{
				if (this.enableLink && this.isResourceWidget && !this.docAddress)
					return appContext.urlRoot + "p/" + this.fxid;
				return null;
			},
			coverPicIsDiv()
			{
				return !this.linkTo;
			}
		}
	}
</script>

<!-- Unscoped CSS Block -->
<style>
	.titleInfoTooltipDescription ul
	{
		list-style-type: none;
		text-indent: -12px;
	}
</style>

<style scoped>
	.simulateAnchor
	{
		color: indigo;
		text-decoration: underline;
		cursor: pointer;
	}

		.simulateAnchor:hover
		{
			text-shadow: 0 0 0px #333, 0 0 0px #333;
		}

	.favoritesContainer
	{
		clear: both;
		padding-top: 5px;
	}

	.titleInfoRoot
	{
		font-size: unset;
		line-height: normal;
		color: black;
		white-space: normal;
		word-wrap: normal;
		font-weight: normal;
		cursor: initial;
	}

	.favoritesHeading
	{
		background: #dcdcdc;
		text-align: center;
	}

	.favoritesList
	{
		font-size: 14px;
	}

	img.titleCoverImage
	{
		max-width: 38px;
		max-height: 52px;
		float: left;
		margin-right: 10px;
	}

	.isResourceWidget img.titleCoverImage
	{
		max-width: 76px;
		max-height: 104px;
	}

	.hasFavorites img.titleCoverImage
	{
		max-width: 80px;
		max-height: 80px;
	}

	.titleName
	{
		font-weight: bold;
	}

	a.titleLink
	{
		text-decoration: underline;
		display: flex;
		flex-direction: row;
	}

	.loading
	{
		padding: 10px;
		text-align: center;
	}
</style>