import Vue from 'vue';
import { SetInactiveTool, EditTitleTool } from "tdsAppRoot/API/HomepageToolsAPI.js";
import { ShowErrorWindow, DefaultErrorHandler } from 'tdsAppRoot/library/ErrorReporter.js';
import ShowMySRLoginWindow from "tdsAppRoot/library/MySRLogin.js";
import { SetToolOrder } from 'tdsAppRoot/API/MyStatrefAPI.js';
import EventBus from 'tdsAppRoot/library/EventBus.js';
import { ProductName as drugInteractionsProductName } from 'tdsAppRoot/library/DrugInteractions.js';
import svg1 from "tdsAppRoot/images/sprite/ChevronsMatch.svg";

/**
 * Add a tool to the tools array, if the current platform is in the allowedPlatforms array, or the array is null.
 * @param {Array} tools
 * @param {Object} tool
 * @param {Array} allowedPlatforms 
 */
function AddTool(tools, tool, allowedPlatforms)
{
	let add = false;
	if (!allowedPlatforms)
		add = true;
	else
	{
		for (let i = 0; i < allowedPlatforms.length; i++)
		{
			if (allowedPlatforms[i] === themeMetadata.key)
			{
				add = true;
				break;
			}
		}
	}
	if (add)
		tools.push(tool);
}

const toolModule = {
	state()
	{
		return {
			customToolOrder: [], // Array of componentKey strings specifying the custom tool order. This order is applied after the default order specified in appContext.
			currentHeldTool: null // For keyboard control of moving tools around in edit mode, this is the currently held tool.
		};
	},
	mutations:
	{
		SetLocalToolOrder(state, order)
		{
			state.customToolOrder = order;
		},
		PickUpTool(state, tool)
		{
			state.currentHeldTool = tool;
		},
		ReleaseTool(state)
		{
			state.currentHeldTool = null;
		}
	},
	getters:
	{
		/**
		 * Returns the master list of tools used by the splash/home page and the Resources menu.
		 * 
		 * Tool fields:
		 * 
		 * componentKey   - [Required] Used for unique identification of the tool and persisting tool order. These keys should never change after being assigned.
		 * componentType  - [Required] The Vue component name for the splash panel.
		 * args           - Optional argument object to pass to the vue component's props.
		 * text           - [Optional. If omitted, this tool will not appear in the Resources menu] A short HTML string which can represent the tool in the Resources menu
		 * icon           - An icon for the Resources menu
		 * vueRouterRoute - A complete route object resolvable in our vue router. Clicking the tool in the Resources menu navigates to here.
		 * 
		 * Other fields accepted by the ToolsMenuItem component or by the ResourceWidget component are also acceptable here ("link", "onclick", etc).
		 * 
		 * @param {any} state this module's state
		 * @param {any} getters this module's getters
		 * @param {any} rootState the store root's state
		 * @param {any} rootGetters the store root's getters
		 * @returns {Array} Array of tools.
		 */
		SplashTools(state, getters, rootState, rootGetters)
		{
			let tools = [];

			// Load custom title tools list from appContext and profile.
			let customTitleToolUniqueness = {};
			let customTitleToolDefs = [];

			if (appContext.globalCustomTitleTools) // appSettings-defined tools to add (kentAdditionalTitleSplashPanels)
				appContext.globalCustomTitleTools.forEach(item =>
				{
					if (!customTitleToolUniqueness[item.fxid])
					{
						item.isAppSettingsTool = true;
						customTitleToolDefs.push(item);
						customTitleToolUniqueness[item.fxid] = true;
					}
				});
			if (appContext.addedCustomTitleTools) // appContext-defined tools to add
				appContext.addedCustomTitleTools.forEach(item =>
				{
					if (!customTitleToolUniqueness[item.fxid])
					{
						customTitleToolDefs.push(item);
						customTitleToolUniqueness[item.fxid] = true;
					}
				});

			if (rootState.profile)
			{
				if (rootState.profile.addedCustomTitleTools) // profile-defined tools to add
					rootState.profile.addedCustomTitleTools.forEach(item =>
					{
						if (!customTitleToolUniqueness[item.fxid])
						{
							customTitleToolDefs.push(item);
							customTitleToolUniqueness[item.fxid] = true;
						}
					});
				if (rootState.profile.removedCustomTitleTools) // profile-defined tools to remove
					rootState.profile.removedCustomTitleTools.forEach(fxid =>
					{
						if (customTitleToolUniqueness[fxid])
						{
							let idx = customTitleToolDefs.findIndex(item => item.fxid === fxid);
							customTitleToolDefs.splice(idx, 1);
							customTitleToolUniqueness[fxid] = false;
						}
					});
			}

			// Add custom title tools
			customTitleToolDefs.forEach(item =>
			{
				if (item && item.fxid)
				{
					AddTool(tools, {
						componentKey: "singleTitle_" + item.fxid
						, componentType: 'SingleTitleWidget'
						, args: { fxid: item.fxid }
						, text: item.nameHtml // For Resources menu
						, icon: require("tdsAppRoot/images/toolsMenu/book_blue32.png") // For Resources menu
						, vueRouterRoute: { path: appContext.appPath + (item.docAddress ? ("document/" + item.docAddress) : ("entrytitle/" + item.fxid)) } // For Resources menu
						, isCustomTitleTool: true
						, isAppSettingsTool: typeof (item.isAppSettingsTool) !== 'undefined' && item.isAppSettingsTool
					});
				}
			});

			if (appContext.GroupSplashPanelHtml)
			{
				AddTool(tools, {
					componentKey: "GroupCustomWidget"
					, componentType: 'GroupCustomWidget'
				});
			}

			if ((!window || !window.themeMetadata)
				|| (window && window.themeMetadata && window.themeMetadata.key == "tds_health")
				|| (window && window.themeMetadata && window.themeMetadata.welcomePanel))
			{
				AddTool(tools, {
					componentKey: "WelcomeWidget"
					, componentType: 'WelcomeWidget'
				});
			}

			AddTool(tools, {
				componentKey: "StatRefWidget"
				, componentType: 'StatRefWidget'
			}, ['tds_health']);

			AddTool(tools, {
				componentKey: "InnovationsWidget"
				, componentType: 'InnovationsWidget'
			}, ['tds_health']);

			AddTool(tools, {
				componentKey: "MobileAppsWidget"
				, componentType: 'MobileAppsWidget'
			}, ['tds_health']);

			AddTool(tools, {
				componentKey: "ProfilePromoWidget",
				componentType: "ProfilePromoWidget"
			});


			if (rootState.dictionaryDetails && appContext.dictionary)
			{
				AddTool(tools, {
					componentKey: "Dictionary"
					, componentType: 'SingleTitleWidget'
					, args: { fxid: rootState.dictionaryDetails.id, categoryType: "Dictionary" }
					, text: rootState.dictionaryDetails.name // For Resources menu
					, icon: require("tdsAppRoot/images/dictionary.png") // For Resources menu
					, vueRouterRoute: { name: "dictionary" } // For Resources menu
				});
			}

			// Load hidden key list from profile.
			let hiddenKeys = {};
			if (appContext.hiddenTools)
				appContext.hiddenTools.forEach(key =>
				{
					hiddenKeys[key] = true;
				});
			if (rootState.profile)
			{
				rootState.profile.hiddenTools.forEach(key =>
				{
					hiddenKeys[key] = true;
				});
			}


			// Add showcase tools
			appContext.showcases.forEach(showcase =>
			{
				let tool = {
					componentKey: showcase.Type.toString()
					, componentType: showcase.ShowcaseSpecialization
					, args: { showcase }
					, text: showcase.Name
					, icon: showcase.MenuIcon ? require("tdsAppRoot/images/toolsMenu/" + showcase.MenuIcon) : require("tdsAppRoot/images/toolsMenu/book_blue32.png")
					, vueRouterRoute: { name: "showcase", params: { pageToken: showcase.Type } }
				};
				AddTool(tools, tool);
			});

			// Add third party product tools
			appContext.thirdPartyProducts.forEach(product =>
			{
				let tool = {
					componentKey: product.Id.toString()
					, componentType: (product.SplashSpecialization ? product.SplashSpecialization : 'ThirdPartyProductWidget')
					, args: { product }
					, text: product.PanelName
					, icon: product.MenuIcon ? require("tdsAppRoot/images/toolsMenu/" + product.MenuIcon) : require("tdsAppRoot/images/toolsMenu/book_blue32.png")
				};
				if (product.SplashContentLink && product.SplashContentLink !== "[product]")
				{
					tool.link = product.SplashContentLink;
					tool.linkNewWindow = true;
				}
				else
				{
					if (product.Type == "TDSAPI")
						tool.vueRouterRoute = { path: appContext.appPath + "productgateway/" + product.Id };
					else if (product.Type == "LTITool")
						tool.vueRouterRoute = { path: appContext.appPath + "productgateway/" + product.Id };
					else
						tool.vueRouterRoute = { path: appContext.appPath + "productgateway/" + product.Type };
				}
				AddTool(tools, tool);
			});

			// Add remaining hard-coded tools
			if (appContext.EBMcalc)
				AddTool(tools, {
					componentKey: "EBMcalcWidget"
					, componentType: "EBMcalcWidget"
					, text: "EBMcalc"
					, icon: require("tdsAppRoot/images/toolsMenu/mc3k32.gif")
					, vueRouterRoute: { name: "EBMcalc" }
				});
			if (appContext.alertProducts.EvidenceAlerts)
				AddTool(tools, {
					componentKey: "EvidenceAlerts"
					, componentType: "AlertsWidget"
					, args: { alertSystem: 'EvidenceAlerts' }
					, text: "Evidence Alerts"
					, icon: require("tdsAppRoot/images/toolsMenu/evidalert.png")
					, vueRouterRoute: { path: appContext.appPath + "Alerts/EvidenceAlerts" }
				});
			if (appContext.alertProducts.DrugShortages)
				AddTool(tools, {
					componentKey: "DrugShortages"
					, componentType: "AlertsWidget"
					, args: { alertSystem: 'DrugShortages' }
					, text: "Drug Shortages"
					, icon: require("tdsAppRoot/images/toolsMenu/drugshortages.png")
					, vueRouterRoute: { path: appContext.appPath + "Alerts/DrugShortages" }
				});
			if (appContext.alertProducts.MedicalNewsFeed)
				AddTool(tools, {
					componentKey: "MedicalNewsFeed"
					, componentType: "AlertsWidget"
					, args: { alertSystem: 'MedicalNewsFeed' }
					, text: "Thomson Reuters® Medical News"
					, icon: require("tdsAppRoot/images/toolsMenu/Reuters_Circle32.png")
					, vueRouterRoute: { path: appContext.appPath + "Alerts/MedicalNewsFeed" }
				});
			if (EventBus.drugInteractionsAvailable)
				AddTool(tools, {
					componentKey: "DrugInteractions"
					, componentType: "TMLDrugInteractionsWidget"
					, text: drugInteractionsProductName
					, icon: require("tdsAppRoot/images/toolsMenu/TML76.png")
					, vueRouterRoute: { name: "druginteractions" }
				});
			if (EventBus.wileyContentAvailable)
				AddTool(tools, {
					componentKey: "WileyContent"
					, componentType: "WileyContentWidget"
					, text: "Wiley Visual Library"
					, icon: require("tdsAppRoot/images/WileyContent/WileyIcon.svg")
					, vueRouterRoute: { name: "wileycontent" }
				});
			AddTool(tools, {
				componentKey: "RecentDocuments"
				, componentType: "RecentHistoryWidget"
				, args: { historyType: 'Document' }
				, text: "Recent Documents"
				, icon: require("tdsAppRoot/images/toolsMenu/document32.gif")
				, vueRouterRoute: { name: "recentdocuments" }
			});
			AddTool(tools, {
				componentKey: "RecentSearches"
				, componentType: "RecentHistoryWidget"
				, args: { historyType: 'Search' }
				, text: "Recent Searches"
				, icon: require("tdsAppRoot/images/toolsMenu/document_view32.gif")
				, vueRouterRoute: { name: "recentsearches" }
			});
			AddTool(tools, {
				componentKey: "Favorites"
				, componentType: "FavoritesWidget"
				, text: "Favorites"
				, icon: require("tdsAppRoot/images/toolsMenu/star_yellow32.gif")
				, vueRouterRoute: { name: "profilefavorites" }
			});
			AddTool(tools, {
				componentKey: "LinkWizardWidget"
				, componentType: "LinkWizardWidget"
				, icon: require("tdsAppRoot/images/link_new.png")
				, vueRouterRoute: { name: "linkwizard" }
				, text: "Link Wizard"
			});
			AddTool(tools, {
				componentKey: "ICD10Widget"
				, componentType: "ICD10Widget"
				, text: "ICD Conversion Tool"
				, icon: require("tdsAppRoot/images/toolsMenu/text_binary32.png")
				, vueRouterRoute: { name: "icdconvert" }
			});
			//AddTool(tools, {
			//	componentKey: "AphaDrugInfoLine"
			//	, componentType: "AphaDrugInfoLine"
			//	// This item specifically has no text field, so it will not appear in the resources menu.
			//}, ['tds_health']);

			// All tools have been added.
			// Mark inactive tools as inactive
			tools.forEach(tool =>
			{
				tool.inactive = !!hiddenKeys[tool.componentKey];
			});

			// Sort the tools in the desired order
			tools = ReorderTools(tools, appContext.customToolOrder); // Default order for group
			tools = ReorderTools(tools, state.customToolOrder); // Order for profile/session

			// Remove tools that are blacklisted in AppSettings
			RemoveBlacklistedTools(tools);

			return tools;
		},
		GetToolIndex(state, getters, rootState, rootGetters)
		{
			return key =>
			{
				return getters.SplashTools.findIndex(tool => tool.componentKey === key);
			};
		},
		SplashToolOrder(state, getters, rootState, rootGetters)
		{
			return getters.SplashTools.map(tool => tool.componentKey);
		},
		SplashToolsHiddenAtEnd(state, getters, rootState, rootGetters)
		{
			let tools = getters.SplashTools.filter(tool => !tool.inactive);
			getters.SplashTools
				.filter(tool => tool.inactive)
				.forEach(tool => tools.push(tool));
			return tools;
		},
		SplashToolOrderHiddenAtEnd(state, getters, rootState, rootGetters)
		{
			return getters.SplashToolsHiddenAtEnd.map(tool => tool.componentKey);
		},
		TitleToolExists(state, getters, rootState, rootGetters)
		{
			return fxid =>
			{
				if (rootState.profile)
				{
					let wasAdded = rootState.profile.addedCustomTitleTools.some(item => item.fxid === fxid);
					let wasRemoved = rootState.profile.removedCustomTitleTools.some(v => v === fxid);
					let isAppsettingsPanel = appContext.globalCustomTitleTools ? appContext.globalCustomTitleTools.some(item => item.fxid === fxid) : false;
					return (wasAdded && !wasRemoved) || isAppsettingsPanel;
				}
				return false;
			};
		},
		ResourcesMenuData(state, getters, rootState, rootGetters)
		{
			let tools = getters.SplashTools.filter(t => t.text); // This needs to make a copy

			// Separate active and inactive tools
			let inactiveTools = tools.filter(t => t.inactive);
			tools = tools.filter(t => !t.inactive);

			// Create edit button
			tools.push({
				componentKey: "EditTools"
				, text: "Manage Home Page Panels"
				, icon: require("tdsAppRoot/images/edit.png")
				, vueRouterRoute: { name: 'root', query: { mode: 'edit' } }
			});

			// Create container for inactive tools
			if (inactiveTools.length > 0)
			{
				tools.push({
					componentKey: "HiddenItems"
					, text: "Hidden Panels"
					, icon: "#ChevronsMatch"
					, svgFill: "PrimaryText"
					, items: inactiveTools
					/*, customRole: "button"*/
				});
			}

			// Remove tools that are blacklisted in AppSettings
			RemoveBlacklistedTools(tools);

			return { items: tools };
		}
	},
	actions:
	{
		MoveSplashTool({ commit, dispatch, state, rootState, rootGetters, getters }, { key, newIndex })
		{
			let srcIndex = getters.SplashToolOrder.findIndex(t => t === key);
			if (srcIndex === newIndex)
				return;
			let order = getters.SplashToolOrder.slice(); // Make a copy of the order array
			if (srcIndex >= 0)
				order.splice(srcIndex, 1);
			order.splice(newIndex, 0, key);
			return dispatch("SetToolOrder", order);
		},
		SetToolOrder({ commit, dispatch, state, rootState, rootGetters, getters }, order)
		{
			commit("SetLocalToolOrder", order);
			if (rootState.profile)
			{
				return SetToolOrder({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, order)
					.then(data =>
					{
						return data;
					})
					.catch(err =>
					{
						DefaultErrorHandler(err);
					});
			}
		},
		SetToolActiveState({ commit, dispatch, state, rootState, rootGetters, getters }, { componentKey, inactive })
		{
			if (rootState.profile)
			{
				commit("SetToolActiveState", { componentKey, inactive });
				return SetInactiveTool({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, componentKey, inactive)
					.then(data =>
					{
						return data;
					})
					.catch(err =>
					{
						ShowErrorWindow("We were unable to save a change to your tool layout. Please reload the page and try again.");
					});
			}
		},
		MakeTitleToolAction({ commit, dispatch, state, rootState, rootGetters, getters }, { fxid, addTitle, nameHtml })
		{
			return EditTitleTool({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, fxid, addTitle)
				.then(result =>
				{
					commit('EditLocalTitleTool', { fxid, addTitle, nameHtml });
					if (addTitle)
					{
						let newKey = "singleTitle_" + fxid;
						// Move this tool to the top of the sort order
						let order = state.customToolOrder.filter(key => key !== newKey); // Make a copy of the order without this new key in it
						order.splice(0, 0, newKey); // Add the new key at the beginning
						return dispatch('SetToolOrder', order)
							.then(orderResult =>
							{
								let tool = getters.SplashTools.find(tool => tool.componentKey === newKey);
								if (tool && tool.inactive)
									return dispatch('SetToolActiveState', { componentKey: newKey, inactive: false });
								else
									return Promise.resolve(result);
							})
							.catch(err =>
							{
								DefaultErrorHandler(err);
							});
					}
					else
						return Promise.resolve(result);
				})
				.catch(err =>
				{
					if (err.name === "ApiError" && (!err.data.currentProfile || !err.data.currentProfile.hasProfile))
					{
						return ShowMySRLoginWindow().then(success =>
						{
							if (success)
								return dispatch("MakeTitleToolAction", { fxid, addTitle, nameHtml });
							return false;
						});
					}
					else
						DefaultErrorHandler(err);
				});
		}
	}
};
/**
 * Reorders the tools and returns a new tools array.  Tools not specified in the order array are appended at the end in their original order.
 * @param {Array} tools Array of tools.
 * @param {Array} order Array of componentKey strings specifying the desired order.
 */
function ReorderTools(tools, order)
{
	if (tools && tools.length && order && order.length)
	{
		let toolMap = {}, toolKeys = [];
		tools.forEach((tool, i) =>
		{
			toolKeys.push(tool.componentKey);
			toolMap[tool.componentKey] = tool;
		});

		// Push tools in the order we have recorded.
		let orderedTools = [];
		order.forEach(key =>
		{
			let tool = toolMap[key];
			if (tool)
			{
				orderedTools.push(tool);
				let i = toolKeys.indexOf(key);
				if (i >= 0)
					toolKeys.splice(i, 1);
			}
		});

		// Insert any remaining tools in their original order at the front of the array.
		toolKeys.reverse().forEach(key => orderedTools.splice(0, 0, toolMap[key]));
		return orderedTools;
	}
	else
		return tools;
}
function RemoveBlacklistedTools(tools)
{
	let blacklist = {};
	if (appContext.SplashPanelsBlacklist && appContext.SplashPanelsBlacklist.length)
	{
		appContext.SplashPanelsBlacklist.split(',').forEach(key => blacklist[key.toLowerCase()] = true);
		for (let i = 0; i < tools.length; i++)
		{
			if (blacklist[tools[i].componentKey.toLowerCase()])
			{
				tools.splice(i, 1);
				i--;
			}
		}
	}
}
export default toolModule;