<template>
	<a target="_blank" :href="target.link" :class="target.key"><vsvg :sprite="'#' + target.key" :title="target.title" :desc="target.desc" :isPresentation="false" /></a>
</template>

<script>
	//Import all social media icons here!
	import svg1 from "tdsAppRoot/images/sprite/facebook.svg";
	//import svg2 from "tdsAppRoot/images/sprite/twitter.svg";
	import svg3 from "tdsAppRoot/images/sprite/linkedin.svg";
	import svg2 from "tdsAppRoot/images/sprite/instagram.svg";

	export default {
		props: {
			target: {
				type: Object,
				required: true
			}
		}
	}
</script>


<style scoped>
	.facebook
	{
		fill: #3B579D;
		background-color: #FFFFFF;
		border-radius: 10%;
	}

	svg
	{
		width: 100%;
		height: 100%;
	}

	.twitter
	{
		fill: #FFFFFF;
		background-color: #1DA1F2;
		border-radius: 10%;
	}

		.twitter svg
		{
			width: 65%;
			height: 65%;
			padding: 17.5%;
		}

	.linkedin
	{
		fill: #0077B5;
		background-color: #FFFFFF;
		border-radius: 10%;
	}
</style>