<template>
	<div class="minorErrorRoot" role="dialog" aria-labelledby="minorErrorTitle" aria-describedby="minorErrorLabel">
		<div class="titleBar">
			<div ref="closeBtn" tabindex="0" role="button" aria-label="close" class="closeButton" @click="DefaultClose" @keypress.enter.space.stop.prevent="DefaultClose" title="close"><vsvg sprite="#x" title="" desc="" role="presentation" aria-hidden="true" /></div>
			<div class="title" id="minorErrorTitle" role="heading">Recent Errors <span class="visuallyhidden">Press escape to close.</span></div>
		</div>
		<div id="minorErrorLabel" class="dialogBody" role="document">
			<ul>
				<li v-for="error in errors">{{error}}</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/ChevronsMatch.svg";
	import svg2 from "tdsAppRoot/images/sprite/x.svg";

	export default {
		data()
		{
			return {
				timeOpened: performance.now()
			};
		},
		props:
		{
			errors: {
				type: Array,
				required: true
			}
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.closeBtn.focus();
			},
			DefaultClose()
			{
				if (performance.now() - this.timeOpened > 1000)
					this.$emit('close');
			}
		}
	};
</script>

<style scoped>
	.minorErrorRoot
	{
		display: flex;
		flex-direction: column;
		max-height: 90vh;
		width: 640px;
		max-width: 90vw;
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 8px 8px 14px;
		box-sizing: border-box;
		position: sticky;
		top: 0px;
		background-color: #EDEFF2;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: 1px solid #CDCDCD;
	}

	.title
	{
		text-align: center;
		color: black;
		font-size: 20pt;
		margin-top: 5px;
	}

	.closeButton
	{
		float: right;
		cursor: pointer;
		padding: 3px;
		border: 1px solid #BBBBBB;
		background-color: rgba(255,255,255,0.5);
		box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
		fill: var(--primary-color);
	}

		.closeButton:focus
		{
			outline: none;
		}

		.closeButton:focus-visible,
		.closeButton.focus-visible
		{
			border: 2px solid black;
			padding: 2px;
		}

		.closeButton:hover
		{
			background-color: rgba(255,255,255,1);
		}

		.closeButton svg
		{
			width: 32px;
			height: 32px;
		}

	.dialogBody
	{
		padding: 8px 14px;
		box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
		flex: 0 1 auto;
		max-height: 100%;
		overflow-y: auto;
	}
</style>