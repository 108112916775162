<template>
	<div class="authenticatorExport">
		<div class="steplist" role="list">
			<div class="step" role="listitem"><b>Step 1:</b> Install and open an Authenticator app on your mobile device</div>
			<div class="step" role="listitem"><b>Step 2:</b> {{ showQR ? "Scan the QR code below" : "Configure the app with the values below" }}</div>
			<div class="step" role="listitem"><b>Step 3:</b> Enter a 6-digit code from the app into the box below and click "Enable MFA Method"</div>
		</div>
		<template v-if="showQR">
			<div class="centerContent">
				<img class="qrCode" :src="data.AuthenticatorQrCodeDataUri" alt="QR code for Authenticator app setup" />
			</div>
		</template>
		<template v-else>
			<div style="margin-bottom: 1em;">
				<div class="manualInstructionRow manualInstructionTitle">Details for manual setup:</div>
				<div class="manualInstructionRow">Account name: <div class="code">{{appName}}<span v-if="myUsername"> ({{myUsername}})</span></div></div>
				<div class="manualInstructionRow">Secret key: <div class="code" v-html="secretKeyHtml"></div></div>
				<div class="manualInstructionRow">Type of key: <div class="code">Time based</div></div>
			</div>
		</template>
		<div class="centerContent" style="margin-bottom: 1em;">
			<a role="button" class="alwaysUnvisited" @click="showQR = !showQR" @keydown.enter.space.stop.prevent="showQR = !showQR" tabindex="0">{{ showQR ? "Can't scan?" : "Show QR code" }}</a>
		</div>
	</div>
</template>

<script>
	import { EscapeHTML } from 'tdsAppRoot/library/TDSUtil.js';

	export default {
		components: {},
		props:
		{
			data: {
				type: Object,
				required: true
			},
			username: null
		},
		data()
		{
			return {
				showQR: true, // If true, the preferred method of Authenticator setup will be QR code.  If false, information for Manual entry will be shown.
				appName: appContext.appName,
				myUsername: appContext.userName
			};
		},
		created()
		{
			if (!this.myUsername)
				this.myUsername = this.username;
		},
		mounted()
		{
		},
		computed:
		{
			secretKeyHtml()
			{
				let arr = [];
				for (let i = 0; i < this.data.SecretKey.length; i++)
				{
					let c = this.data.SecretKey.charAt(i);
					if (c >= '0' && c <= '9')
						arr.push('<span class="number">' + c + '</span>');
					else
						arr.push(EscapeHTML(c));
				}
				return arr.join("");
			}
		},
		methods:
		{
		},
		watch:
		{
		}
	}
</script>

<style scoped>
	.steplist
	{
		padding-bottom: 1em;
	}

		.steplist .step
		{
			padding-left: 50px;
			text-indent: -50px;
		}

	.centerContent
	{
		display: flex;
		justify-content: center;
	}

	.qrCode
	{
		width: 240px;
		max-width: 100%;
	}

	.manualInstructionRow
	{
		margin-bottom: 0.5em;
	}

		.manualInstructionRow .code
		{
			margin-top: 1px;
		}

	.manualInstructionTitle
	{
		font-size: 1.2em;
		font-weight: bold;
	}

	.code
	{
		user-select: all;
		font-family: Consolas, monospace;
		padding: 4px 10px;
		border: 1px solid #CCCCCC;
		background-color: #F6F6F6;
		color: #000000;
		display: block;
		overflow-wrap: break-word;
	}

	.icode
	{
		user-select: all;
		font-family: Consolas, monospace;
		padding: 4px 10px;
		border: 1px solid #CCCCCC;
		background-color: #F6F6F6;
		display: inline-block;
	}

	.code::v-deep .number
	{
		color: #025492;
		font-weight: bold;
	}
</style>