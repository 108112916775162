import { getStackTrace } from "tdsAppRoot/library/TDSUtil.js";

let didSetupAlready = false;
let lastPromiseStackTrace = null;

export function InitializePromiseTracking()
{
	if (didSetupAlready)
		return;
	didSetupAlready = true;
	try
	{
		var tds_original_promise_then = Promise.prototype.then;
		Promise.prototype.then = function ()
		{
			lastPromiseStackTrace = getStackTrace(1);
			return tds_original_promise_then.apply(this, arguments);
		};

		console.log("Initialized promise tracking");
	}
	catch (ex)
	{
		console.error(ex);
	}
}
/**
 * Gets the stack trace of the last "Promise.then()" call recorded by this utility.
 * @returns {String} The stack trace of the last "Promise.then()" call recorded by this utility.
 */
export function GetLastPromiseStackTrace()
{
	return lastPromiseStackTrace;
}