<template>
	<div class="mfaPopup" role="dialog" aria-describedby="mfaInterfacePopup" aria-label="Multi-Factor Authentication.  Press escape to cancel.">
		<div id="mfaInterfacePopup" style="display: none">
			Multi-Factor Authentication.  Press escape to cancel.
		</div>
		<MFAInterface ref="mfaInterface" @close="DefaultClose" @submit="Submitted" v-bind="$attrs" />
	</div>
</template>

<script>
	import MFAInterface from "tdsAppRoot/components/MFA/MFAInterface.vue";

	export default {
		components: { MFAInterface },
		props:
		{
			mfaSubmitCallback: null
		},
		methods:
		{
			DefaultClose()
			{
				this.$emit("close", false);
			},
			SetFocus()
			{
				this.$refs.mfaInterface.SetFocus();
			},
			Submitted(args)
			{
				this.mfaSubmitCallback(args);
			}
		}
	}
</script>
<style scoped>
	.mfaPopup
	{
		background-color: #FFFFFF;
		padding: 18px;
		max-width: 425px;
		max-height: 92vh;
		overflow-y: auto;
		box-sizing: border-box;
	}
</style>