var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass: "appFooter",
      style: _vm.footerStyle,
      attrs: { role: "contentinfo" }
    },
    [
      _vm.systemNotice
        ? _c("div", {
            staticClass: "systemNotice",
            domProps: { innerHTML: _vm._s(_vm.systemNotice) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footerLinks", style: _vm.footerLinksStyle }, [
        _c(
          "div",
          { staticClass: "footerInternalLinks" },
          [
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  role: "button",
                  "aria-haspopup": "dialog",
                  tabindex: "0"
                },
                on: {
                  click: _vm.PublicComputer,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.PublicComputer.apply(null, arguments)
                  }
                }
              },
              [_vm._v("PUBLIC COMPUTER?")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  role: "button",
                  "aria-haspopup": "dialog",
                  tabindex: "0"
                },
                on: {
                  click: _vm.OnClearPastSessions,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.OnClearPastSessions.apply(null, arguments)
                  }
                }
              },
              [_vm._v("CLEAR PAST SESSIONS")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  role: "button",
                  "aria-haspopup": "dialog",
                  tabindex: "0",
                  "aria-label": "Contact Us - Click here to send us an e-mail"
                },
                on: {
                  click: _vm.displayContactUs,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.displayContactUs.apply(null, arguments)
                  }
                }
              },
              [_vm._v("CONTACT")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  href: "https://www.tetondata.com/help.cshtml",
                  target: "_blank"
                }
              },
              [_vm._v("SUPPORT")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  href: "https://www.tetondata.com/training.cshtml",
                  target: "_blank"
                }
              },
              [_vm._v("TRAINING")]
            ),
            _vm._v(" "),
            !_vm.isRestrictedSession
              ? _c(
                  "router-link",
                  {
                    staticClass: "footerLink coloredLink",
                    attrs: {
                      to: {
                        name: "htmlfilepagedisplay",
                        query: { filetoken: "titleUpdates" }
                      }
                    }
                  },
                  [_vm._v("TITLE UPDATES")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "footerLink coloredLink",
                attrs: {
                  role: "button",
                  "aria-haspopup": "dialog",
                  tabindex: "0"
                },
                on: {
                  click: _vm.OnEula,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.OnEula.apply(null, arguments)
                  }
                }
              },
              [_vm._v("EULA")]
            ),
            _vm._v(" "),
            _vm.pwaInstallAvailable
              ? _c(
                  "a",
                  {
                    staticClass: "footerLink coloredLink",
                    attrs: {
                      role: "button",
                      tabindex: "0",
                      title: _vm.installTooltip
                    },
                    on: {
                      click: _vm.OnInstall,
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          ) &&
                          _vm._k($event.keyCode, "space", 32, $event.key, [
                            " ",
                            "Spacebar"
                          ])
                        ) {
                          return null
                        }
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.OnInstall.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("INSTALL")]
                )
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "footerSocialMediaLinks" },
          _vm._l(_vm.socialMediaLinks, function(link) {
            return _c("SocialMediaIcon", {
              key: link.key,
              attrs: { target: link }
            })
          }),
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "footerBottom" },
        [
          _c("LogoImage", {
            staticClass: "logoPrintOnly",
            staticStyle: { "padding-left": "8px" },
            attrs: {
              width: 70,
              logoType: "standalone",
              includeDesc: false,
              rolePresentation: true
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "supportInfoBtn",
              attrs: {
                role: "button",
                tabindex: "0",
                "aria-label": "Support Info",
                "aria-haspopup": "dialog"
              },
              on: {
                click: _vm.OpenSupportInfo,
                keydown: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.OpenSupportInfo.apply(null, arguments)
                }
              }
            },
            [
              _c("vsvg", {
                attrs: {
                  sprite: "#bug",
                  title: "Support Info",
                  desc: "",
                  isPresentation: false
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            [
              _vm.themeMetadata.key === "tds_health"
                ? _c(
                    "router-link",
                    {
                      staticClass: "footerLink alone",
                      attrs: {
                        title: "Product Release Notes",
                        "aria-label":
                          "Copyright © Teton Data Systems " +
                          _vm.footerYear +
                          " - Product Release Notes",
                        to: {
                          name: "htmlfilepagedisplay",
                          query: { filetoken: "releaseNotes" }
                        },
                        type: "button"
                      }
                    },
                    [
                      _vm._v(
                        "Copyright © Teton Data Systems " +
                          _vm._s(_vm.footerYear)
                      )
                    ]
                  )
                : _c("span", [
                    _vm._v(
                      "Copyright © Teton Data Systems " + _vm._s(_vm.footerYear)
                    )
                  ]),
              _vm._v(" "),
              _c("br", { staticClass: "onlyOnMobile" }),
              _vm._v(" "),
              _vm.groupTitle
                ? _c("span", {
                    staticClass: "footerGroupTitle",
                    domProps: { innerHTML: _vm._s(_vm.groupTitle) }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }