export const hasNativeCssVarsSupport = ((window || {}).CSS || {}).supports && window.CSS.supports('(--a: 0)');
/**
 * Returns the value of the specified css variable. Note that in IE 11, returned values will not be correct if they are loaded before css-vars-ponyfill has completed its work.
 * @param {String} varName Name of the css variable. e.g. "--primary-color"
 * @param {String} fallbackValue Value to return if the css variable does not exist.
 * @returns {String} The value of the css variable, or the fallback value.
 */
export function ReadCssVar(varName, fallbackValue = "")
{
	if (hasNativeCssVarsSupport)
	{
		let v = getComputedStyle(document.documentElement).getPropertyValue(varName);
		if (v)
			return v;
	}
	else
	{
		let v = storedCssVarValues[varName];
		if (v)
			return v;
	}
	return fallbackValue;
}
let storedCssVarValues = {};
/**
 * Remembers the value assigned to a specific css variable.  This function has no effect if used in a browser that natively supports css variables.
 * @param {String} varName Name of the css variable. e.g. "--primary-color"
 * @param {String} value Value to remember for this variable.
 */
export function RememberCssVar(varName, value)
{
	if (!hasNativeCssVarsSupport)
		storedCssVarValues[varName] = value;
}