import Vue from 'vue';
import { GetBookshelfTitles, GetBookshelfCategories } from 'tdsAppRoot/API/BookshelfAPI.js';

let currentBookshelfLoadPromise = null;

const bookshelfModule = {
	state()
	{
		return {
			bookshelfTitles: null, // The bookshelf title list cache.
			bookshelfCategories: null // The bookshelf category list cache.  If null, it is not currently loaded.  If empty array, there was an error loading the list.
		};
	},
	mutations:
	{
		SetCachedBookshelfTitles: (state, titles) => // This can be passed a null titles argument in order to force the next load to be from the server.
		{
			state.bookshelfTitles = titles;
		},
		SetCachedBookshelfCategories_Internal: (state, categories) => // Should only be called by actions in this store.
		{
			state.bookshelfCategories = categories;
		},
		ResetBookshelfModule: (state) =>
		{
			state.bookshelfTitles = null;
			state.bookshelfCategories = null;
		}
	},
	getters:
	{
		GetBookshelfCategoryById: function (state, getters)
		{
			return function (id)
			{
				if (getters.IsBookshelfLoaded)
				{
					id = new Number(id).valueOf();
					for (let i = 0; i < state.bookshelfCategories.length; i++)
					{
						let cat = state.bookshelfCategories[i];
						if (cat.Id === id)
							return cat;
					}
				}
				return null;
			};
		},
		IsBookshelfLoaded: state => !!(state.bookshelfCategories && state.bookshelfCategories.length > 0),
		BookshelfCategories: state => state.bookshelfCategories
	},
	actions:
	{
		GetBookshelfTitles({ commit, dispatch, state, rootState, rootGetters })
		{
			if (state.bookshelfTitles)
				return Promise.resolve(state.bookshelfTitles.slice());
			if (currentBookshelfLoadPromise)
				return currentBookshelfLoadPromise;
			let myPromise = currentBookshelfLoadPromise = GetBookshelfTitles({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
				.then(data =>
				{
					commit("SetCachedBookshelfTitles", data.titles.slice());
					return Promise.resolve(data.titles);
				})
				.finally(() =>
				{
					currentBookshelfLoadPromise = null;
				});
			return myPromise;
		},
		/**
		 * Sets state.bookshelfCategories = null, then triggers reloading of the categories from the server.
		 * @param {any} param0 Store reference
		 */
		ResetBookshelfCategories({ commit, dispatch, state, rootState, rootGetters })
		{
			let refreshData = state.bookshelfCategories !== null;
			commit("SetCachedBookshelfCategories_Internal", null);
			if (refreshData)
				dispatch("LoadBookshelfCategories");
		},
		/**
		 * Loads bookshelf categories if they aren't already loaded. Components which need this should dispatch the action and watch the BookshelfCategories getter to use the loaded categories.
		 * @param {any} param0 Store reference
		 */
		LoadBookshelfCategories({ commit, dispatch, state, rootState, rootGetters })
		{
			if (state.bookshelfCategories)
				return Promise.resolve();
			return GetBookshelfCategories({ state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch })
				.then(data =>
				{
					data.categories.sort(categoryNameLocaleCompare);
					commit("SetCachedBookshelfCategories_Internal", data.categories);
				}
				).catch(err =>
				{
					commit("SetCachedBookshelfCategories_Internal", []);
				});
		}
	},
	watchers:
		[
			[state => state.sid, (newValue, oldValue, store) =>
			{
				store.commit("ResetBookshelfModule");
			}]
		]
};
function categoryNameLocaleCompare(a, b)
{
	return a.Name.localeCompare(b.Name);
}

export default bookshelfModule;