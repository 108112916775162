var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "center" }, [
    _c("div", { staticClass: "errorBody" }, [
      _c(
        "div",
        { staticClass: "logo" },
        [
          _c(
            "router-link",
            { attrs: { to: _vm.rootLink } },
            [
              _c("LogoImage", {
                staticClass: "logoImg",
                attrs: {
                  width: 192,
                  logoType: "standalone",
                  includeDesc: false
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "textArea" }, [
        _c("div", { staticClass: "statusCode" }, [_vm._v("404")]),
        _vm._v(" "),
        _c("div", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "message" },
          [
            _c(
              "router-link",
              { staticClass: "reEnterButton", attrs: { to: _vm.rootLink } },
              [
                _vm._v(
                  "\n\t\t\t\t\tRe-enter " +
                    _vm._s(_vm.appContext.appName) +
                    "\n\t\t\t\t"
                )
              ]
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }