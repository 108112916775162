import Vue from 'vue';
/////////////////////////////////////////
// READ THIS BEFORE IMPORTING ANYTHING //
/////////////////////////////////////////
// EventBus is a vue component that is created when this file is imported.
//
// Circular dependencies can create a complex situation where a dependency imported by EventBus.js is not loaded before the EventBus component is created.
//
// For example, at an earlier point in time, the desktopLayoutPx variable was exported from TDSUtil and imported here.  But TDSUtil imported other things which eventually imported EventBus, creating a circular dependency.  By luck, EventBus ended up being imported first, and this worked.  Then something changed and TDSUtil was imported first.  Suddenly, the desktopLayoutPx variable was being imported with a value of undefined.
//
// So, if you need to import something here, make sure that EventBus.js is imported very early in the application initialization.  A good place would be client.js, just after importing babel-polyfill.  In fact, I'm going to add that import right now so it will probably already be done for you.  Anyway, be careful with imports in EventBus.js.
/////////////////////////////////////////

var desktopLayoutPx = 700;

/**
 * This object can be used to provide "global" event handling.  Based on https://alligator.io/vuejs/global-event-bus/
 * Import the EventBus and use EventBus.$on('eventName', …), EventBus.$off('eventName', …), and EventBus.$emit('EventName', …)
 * Or depending on your need, simply create reactive data using the EventBus data property.
 * The EventBus is good for things that need to be shared between components with fast reactivity and should not be persisted between page reloads.
 * EventBus is orders of magnitude faster than the store.
 */
const EventBus = new Vue({
	data:
	{
		desktopLayoutPx: desktopLayoutPx,
		CurrentSlidebar: null,
		PendingLoginArgs: null, // Used to pass complex arguments to the DirectPendingLogin component when changing the route.
		OpenDialogCount: 0,
		windowWidth: -1,
		windowHeight: -1,
		pageXOffset: 0,
		pageYOffset: 0,
		recentHistoryCounts: {}, // For reactively setting recent history left nav counters.
		fullDesktopLayoutMediaQuery: null,
		mobileLayoutMediaQuery: null,
		wide800MediaQuery: null,
		wide820MediaQuery: null,
		shortViewportMediaQuery: null,
		printMediaQuery: null,
		fullDesktopLayout: false, // If true, the site is in full desktop layout (>= 680px).
		fullMobileLayout: false, // If true, the site is in full mobile layout (< 600px)
		// If fullDesktopLayout and fullMobileLayout are both false, the viewport width is in the range [600px, 680px).
		wide800: false, // If true, the viewport width is >= 800px
		wide820: false, // If true, the viewport width is >= 820px
		shortViewport: false, // "HIDE TOOLBAR" is never to be shown when this is false
		drugInteractionsAvailable: false,
		drugInteractionsTurnaway: false,
		wileyContentAvailable: false,
		wileyContentTurnaway: false,
		searchCategoryOverrides: null, // An object which the Search Bar and Search Category Select components look at to determine what to do.
		topBarFull: false, // True if the top bar is using its full-size desktop layout. False if it is using the narrow mobile layout.
		topBarStatic: false, // True if the top bar is positioned statically. False if it is floating (fixed-position).
		showTopBarHideButton: true, // False if the top bar should suppress its toggle button when it is in the "hide" state (such as if the document nav control is active)
		printDialogIsOpen: false, // True if the "print" media query is currently matched
		pwaInstallAvailable: false, // True if the progressive web application can be installed during this session.
		pwaInstallEvent: null, // If not null, this is the beforeinstallprompt event object.  Call "prompt()" on it to show the install prompt.
		pwaInstalled: false, // True if the progressive web application has been installed during this session.
		disableFocusOutlineOnDocuments: false, // True to disable the focus outline on documents (when you press shift).
		autoGenId: 0 // Used for automatically generated element IDs.  Currently used to put IDs on teleport targets which lack them in Document.vue.
	},
	created()
	{
		window.addEventListener('resize', this.OnResize);
		window.addEventListener('scroll', this.OnScroll);
		this.windowWidth = window.innerWidth;
		this.windowHeight = window.innerHeight;
		if (window.matchMedia)
		{
			this.fullDesktopLayoutMediaQuery = window.matchMedia("(min-width: " + desktopLayoutPx + "px)");
			this.fullDesktopLayoutMediaQuery.addListener(this.onDesktopLayoutChanged);
			this.onDesktopLayoutChanged(this.fullDesktopLayoutMediaQuery);

			this.mobileLayoutMediaQuery = window.matchMedia("(min-width: 600px)");
			this.mobileLayoutMediaQuery.addListener(this.onMobileLayoutChanged);
			this.onMobileLayoutChanged(this.mobileLayoutMediaQuery);

			this.wide800MediaQuery = window.matchMedia("(min-width: 800px)");
			this.wide800MediaQuery.addListener(this.onWide800Changed);
			this.onWide800Changed(this.wide800MediaQuery);

			this.wide820MediaQuery = window.matchMedia("(min-width: 820px)");
			this.wide820MediaQuery.addListener(this.onWide820Changed);
			this.onWide820Changed(this.wide820MediaQuery);

			this.shortViewportMediaQuery = window.matchMedia("(min-height: 450px)");
			this.shortViewportMediaQuery.addListener(this.onShortViewportChanged);
			this.onShortViewportChanged(this.shortViewportMediaQuery);

			this.printMediaQuery = window.matchMedia("print");
			this.printMediaQuery.addListener(this.onPrintChanged);
			this.onPrintChanged(this.printMediaQuery);
		}
		if (typeof appContext !== "undefined")
		{
			this.drugInteractionsAvailable = !!appContext.DrugInteractions;
			this.wileyContentAvailable = !!appContext.WileyContent;
			this.disableFocusOutlineOnDocuments = !!appContext.disableFocusOutlineOnDocuments;
		}
	},
	beforeDestroy()
	{
		window.removeEventListener('resize', this.OnResize);
		window.removeEventListener('scroll', this.OnScroll);
		if (this.fullDesktopLayoutMediaQuery)
		{
			this.fullDesktopLayoutMediaQuery.removeListener(this.onDesktopLayoutChanged);
			this.mobileLayoutMediaQuery.removeListener(this.onMobileLayoutChanged);
			this.wide800MediaQuery.removeListener(this.onWide800Changed);
			this.wide820MediaQuery.removeListener(this.onWide820Changed);
			this.shortViewportMediaQuery.removeListener(this.onShortViewportChanged);
			this.printMediaQuery.removeListener(this.onPrintChanged);
		}
	},
	methods:
	{
		OnResize(event)
		{
			//console.log("EventBus window resize event");
			this.windowWidth = window.innerWidth;
			this.windowHeight = window.innerHeight;
		},
		OnScroll(event)
		{
			this.pageXOffset = window.pageXOffset;
			this.pageYOffset = window.pageYOffset;
		},
		onDesktopLayoutChanged(mediaQuery)
		{
			this.fullDesktopLayout = mediaQuery.matches;
		},
		onMobileLayoutChanged(mediaQuery)
		{
			this.fullMobileLayout = !mediaQuery.matches;
		},
		onWide800Changed(mediaQuery)
		{
			this.wide800 = mediaQuery.matches;
		},
		onWide820Changed(mediaQuery)
		{
			this.wide820 = mediaQuery.matches;
		},
		onShortViewportChanged(mediaQuery)
		{
			this.shortViewport = !mediaQuery.matches;
		},
		onPrintChanged(mediaQuery)
		{
			this.printDialogIsOpen = mediaQuery.matches;
		}
	},
	computed:
	{
	}
});
window.tdsEventBus = EventBus; // Handle for debugging
export default EventBus;