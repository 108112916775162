
import { CallServer } from "tdsAppRoot/API/apibase.js";

var IsSessionActiveParams = function ()
{
	var isKeepalive;
}
Object.seal(IsSessionActiveParams);

export function IsSessionActive_server(store, isKeepalive)
{
	try
	{
		var args = new IsSessionActiveParams();
		args.isKeepalive = isKeepalive;
		return CallServer("Session", "IsSessionActive", args, store, isKeepalive ? "cust=false" : "");
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function AttemptAutoLogon_server(store)
{
	try
	{
		return CallServer("Session", "AttemptAutoLogon", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}


var LoginParams = function ()
{
	var un;
	var pw;
	var relativePath;
	var mfaMethodType;
	var mfaPayload;
	var mfaDeviceGuid;
};
Object.seal(LoginParams);

/**
 * Attempts to log in with a user name and password.
 * @param {String} user User name
 * @param {String} pass Password
 * @param {String} relativePath Current URL in the browser.
 * @param {Object} mfaArgs MFA arguments from the MFAInterface component.
 * @param {any} store vuex store
 * @returns {Promise} a promise that resolves or rejects when the API call completes.
 */
export function CreateSession(user, pass, relativePath, mfaArgs, store)
{
	try
	{
		var args = new LoginParams();
		args.un = user;
		args.pw = pass;
		args.relativePath = relativePath;
		if (mfaArgs)
		{
			if (mfaArgs.mfaMethodType)
				args.mfaMethodType = mfaArgs.mfaMethodType;
			if (mfaArgs.mfaPayload)
				args.mfaPayload = mfaArgs.mfaPayload;
			if (mfaArgs.mfaDeviceGuid)
				args.mfaDeviceGuid = mfaArgs.mfaDeviceGuid;
		}
		store.commit("SetSessionID", null);
		return CallServer("Session", "CreateSession", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function ConfirmEULA(store)
{
	try
	{
		return CallServer("Session", "ConfirmEULA", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function CheckTDSAPIContext(store)
{
	try
	{
		return CallServer("Session", "CheckContext", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
