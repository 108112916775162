<template>
	<div class="mfaInterface">
		<div class="titleBar" v-if="mode === 'initial_setup' || mode === 'setup'">Multi-Factor Authentication Setup <LogoImage class="logoImg" :includeDesc="true" :width="60" :height="29" logoType="standalone" :rolePresentation="true" /></div>
		<div class="titleBar" v-else-if="mode === 'challenge'">Authentication challenge</div>
		<div class="body">
			<div v-if="error" class="errorMessage">
				<p>{{error}}</p>
				<p v-if="httpsLink"><a :href="httpsLink">{{httpsLink}}</a></p>
			</div>
			<template v-else>
				<template v-if="!password && passwordEntryRequired">
					<label>
						To continue, please confirm the password for user "{{UN}}":
						<div class="centerContent" style="margin-top: 1em;">
							<input class="tb" type="text" autocomplete="username" style="display: none;" /> <!-- Fake username field to prevent autofill putting the user name into the search box.-->
							<input class="tb" type="password" v-model="PW" autocomplete="current-password" />
						</div>
					</label>
					<div style="margin-top: 1em;">
						<button class="medButton primaryColorButton fullWidthButton" @click="ConfirmPassword()" tabindex="0">Confirm Password</button>
					</div>
				</template>
				<template v-else>
					<TransitionTwoPanel :isShowingLeftPanel="isShowingLeftPanel" @start="TransitionStart" @end="TransitionEnd">
						<div key="methodList" v-if="!selectedMfaMethod" class="methodList">
							<!-- No MFA method is selected. Show a list of available MFA methods. -->
							<div v-if="mode === 'initial_setup'" class="descriptionBlock">The user "{{UN}}" is required to configure an MFA method to proceed with login.</div>
							<div v-if="mode === 'challenge'" class="descriptionBlock">Select MFA method:</div>
							<div v-if="mode === 'setup'" class="descriptionBlock">Configure MFA methods:</div>
							<div v-if="!mfaMethodsAvailable.length" class="descriptionBlock">
								No MFA methods are available to your user account.
								<span v-if="mode === 'initial_setup'">Please <a role="button" aria-haspopup="dialog" class="alwaysUnvisited" @click="displayContactUs" tabindex="0" @keydown.enter.space.stop.prevent="displayContactUs">contact support</a> for help.</span>
							</div>
							<div v-for="method in mfaMethodsAvailable" :key="method.ID" class="mfaMethodListItem" role="button" tabindex="0" @click="SelectMfaMethod(method)" @keydown.space.enter.prevent="SelectMfaMethod(method)" :data-methodid="method.ID">
								<vsvg class="methodIcon greenCheckmark" sprite="#checkmark" title="" desc="" aria-hidden="true" v-if="method.Configured && mode === 'setup'" />
								<vsvg class="methodIcon" :sprite="'#' + GetMethodSpriteId(method)" title="" desc="" aria-hidden="true" />
								<span class="methodName">{{method.Name}}</span>
								<vsvg class="rightArrow" sprite="#arrow_down_thin2" title="" desc="" aria-hidden="true" />
							</div>
						</div>
						<div key="selectedMethod" v-else class="selectedMethod">
							<a v-if="!noMfaMethodList"
							   class="backToMfaList" role="button" tabindex="0"
							   @click="SelectMfaMethod(null)" @keydown.space.enter.prevent="SelectMfaMethod(null)">
								<vsvg class="leftArrow" sprite="#arrow_down_thin2" title="" desc="" aria-hidden="true" /> Back to method list
							</a>
							<div class="descriptionBlock mfaNameHeading"><b>{{selectedMfaMethod.Name}}</b></div>
							<template v-if="selectedMfaMethod.Configured || pendingMethodData">
								<!-- Current MFA method is fully configured or is pending configuration. -->
								<template v-if="selectedMfaMethod.Configured && mode !== 'challenge'">
									<button class="medButton redHighButton fullWidthButton" @click="RemoveConfiguration()" tabindex="0">Remove this configuration</button>
								</template>
								<template v-else-if="selectedMfaMethod.ID === 1">
									<!-- Authenticator App -->
									<MFAAuthenticatorExport v-if="pendingMethodData" :data="pendingMethodData" :username="UN" />
									<MFACodeEntry ref="codeEntry" :method="selectedMfaMethod" :canRememberDevice="canRememberDevice" @submit="SubmitOTPCode" />
								</template>
								<template v-else-if="selectedMfaMethod.ID === 2">
									<!-- Email -->
									<MFACodeEntry ref="codeEntry" :method="selectedMfaMethod" :canRememberDevice="canRememberDevice" @submit="SubmitOTPCode" @resendemail="SendEmailCode(true)" />
								</template>
								<template v-else>
									<!-- Unknown -->
									An error has occurred. The selected Multi-Factor Authentication method is not recognized.
								</template>
							</template>
							<template v-else>
								<div>
									<button class="medButton primaryColorButton fullWidthButton" @click="BeginSetup()" tabindex="0">Begin Setup</button>
								</div>
							</template>
						</div>
					</TransitionTwoPanel>
					<div style="margin-top: 4em;" v-if="mode === 'setup'">
						<MFARememberedDevices :rememberedDevices="rememberedDevices"
											  :allowedToRemember="allowedToRemember"
											  :ableToRemember="ableToRemember"
											  :isDeleting="deletingRememberedDevice"
											  @delete="RemoveRememberedDevice" />
					</div>
				</template>
				<div class="rightAlignContent" style="margin-top: 2em;" v-if="!noCancelButton">
					<button class="medButton redHighButton" @click="Cancel()" tabindex="0">Cancel</button>
				</div>
			</template>
		</div>
		<FullScreenLoadingMsg :show="showLoadingOverlay" msg="Loading" />
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/arrow_down_thin2.svg";
	import svg2 from "tdsAppRoot/images/sprite/phone_android.svg";
	import svg3 from "tdsAppRoot/images/sprite/email.svg";
	import svg4 from "tdsAppRoot/images/sprite/unlock.svg";
	import svg5 from "tdsAppRoot/images/sprite/checkmark.svg";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import { ReportError, ShowModalErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import { MFAUserBeginSetup, MFAUserEndSetup, MFAGetUserData, MFARemoveConfiguration, MFAUserEmailSend, MFARemoveRememberedDevice } from "tdsAppRoot/API/MFAAPI.js";
	import TransitionTwoPanel from 'tdsAppRoot/components/Controls/TransitionTwoPanel.vue';
	import MFAAuthenticatorExport from "tdsAppRoot/components/MFA/MFAAuthenticatorExport.vue";
	import MFACodeEntry from "tdsAppRoot/components/MFA/MFACodeEntry.vue";
	import MFARememberedDevices from "tdsAppRoot/components/MFA/MFARememberedDevices.vue";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";
	import { ModalMessageDialog, ModalContactUsDialog, ModalConfirmDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { LoadDeviceGuidForMFA, SaveDeviceGuidForMFA, isLocalStorageEnabled, IsSecureContext } from 'tdsAppRoot/library/TDSUtil.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";

	export default {
		components: { ScaleLoader, TransitionTwoPanel, LogoImage, MFAAuthenticatorExport, MFACodeEntry, MFARememberedDevices, FullScreenLoadingMsg },
		props:
		{
			mode: { // "initial_setup" or "challenge" or "setup"
				type: String,
				required: true
			},
			username: { // MFA configuration always requires UN/PW, even if there is an active session.
				type: String,
				required: true
			},
			password: { // MFA configuration always requires UN/PW, even if there is an active session.
				type: String,
				default: ""
			},
			externalGuid: { // External apps can provide their GUID string here for the "Remember this device" function to work. Ignored if [isExternalApp] is false.
				type: String,
				default: ""
			},
			isExternalApp: { // If true, we won't try to read the Device GUID from any source except from the [externalGuid] prop, and we won't save it in localStorage.  If false, this component will load/save the Device GUID in localStorage and will get a new GUID if necessary from the server.
				type: Boolean,
				default: false
			},
			mfaUserData: null, // If mode is "initial_setup" or "challenge", this is the list of available MFA methods.
			noCancelButton: {
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				error: null,
				httpsLink: null,
				allowedToRemember: false, // False if the server says this user account is not allowed to remember devices.
				mfaMethodsAvailable: [],
				rememberedDevices: [],
				deviceGuid: null, // The device GUID that identifies this device. Before using this GUID, check [canRememberDevice].
				UN: "",
				PW: "",
				passwordEntryRequired: false,
				selectedMfaMethod: null,
				previouslySelectedId: null,
				isTransitioning: false,
				displayingContactUs: false,
				pendingMethodData: null, // This data is requested from the server when the user begins the setup procedure for an MFA method.
				sendingEmailCode: false,
				changingMfaConfiguration: false,
				loadingUserData: false,
				deletingRememberedDevice: false,
				lastAutoSentEmailCodeTime: performance.now() - 60000

			};
		},
		created()
		{
			if (!IsSecureContext())
			{
				if (location.href.toUpperCase().startsWith("HTTP:")
					&& (!location.port || location.port === "80"))
				{
					this.error = 'Multi-Factor Authentication requires the "https" protocol.';
					this.httpsLink = "https" + location.href.substr(4);
				}
				else
					this.error = 'Multi-Factor Authentication requires the "https" protocol, which was not used to load this page.';
			}
			this.UN = this.username;
			if (this.password)
				this.PW = this.password;
			if (this.isExternalApp)
				this.deviceGuid = this.externalGuid;
			else
				this.deviceGuid = LoadDeviceGuidForMFA(); // Not external app. We're responsible for storing the Device GUID.
			if (this.mode === "initial_setup" || this.mode === "challenge")
			{
				if (!this.mfaUserData)
				{
					this.internalError("MFAInterface was created in " + this.mode + " mode but did not include the mfaUserData parameter.");
					return;
				}
				this.IngestUserData(this.mfaUserData);
			}
			else if (this.mode === "setup")
			{
				this.passwordEntryRequired = true;
			}
			else
			{
				this.internalError("MFAInterface was created with unsupported mode " + this.mode + ".");
			}
		},
		mounted()
		{
		},
		computed:
		{
			isShowingLeftPanel()
			{
				return !this.selectedMfaMethod;
			},
			canRememberDevice()
			{
				return this.allowedToRemember // This user account is allowed to remember devices
					&& this.ableToRemember;
			},
			ableToRemember()
			{
				return this.deviceGuid // We have a GUID string
					&& (this.isExternalApp || isLocalStorageEnabled()); // and this GUID string is expected to be persisted
			},
			noMfaMethodList()
			{
				return this.mfaMethodsAvailable.length === 1 && this.mode !== "setup";
			},
			showLoadingOverlay()
			{
				return this.sendingEmailCode || this.changingMfaConfiguration || this.loadingUserData || this.deletingRememberedDevice;
			}
		},
		methods:
		{
			IngestUserData(userData)
			{
				if (userData)
				{
					this.allowedToRemember = userData.AllowedToRemember;
					for (let i = 0; i < userData.Methods.length; i++)
					{
						let m = userData.Methods[i];
						if (this.mode === "challenge" && !m.Configured)
							continue; // In challenge mode, suppress methods that are not fully configured.
						this.mfaMethodsAvailable.push(m);
					}
					if (this.noMfaMethodList)
					{
						this.selectedMfaMethod = this.mfaMethodsAvailable[0];

						if (this.selectedMfaMethod.ID === 2 && this.mode === "challenge")
							this.SendEmailCode(false);
					}
					for (let i = 0; i < userData.RememberedDevices.length; i++)
					{
						this.rememberedDevices.push(userData.RememberedDevices[i]);
					}
					if (!this.isExternalApp && !this.deviceGuid)
						this.deviceGuid = userData.RandomGuid; // JavaScript can't natively create GUID strings, so the server does it for us.
				}
			},
			internalError(privateMessage)
			{
				ReportError(this.$store.getters.urlRoot, privateMessage, null, null, this.$store.getters.sid);
				ShowModalErrorWindow("An internal error occurred. Technical support has been notified.");
				this.error = "An internal error occurred. Technical support has been notified.";
			},
			GetMethodSpriteId(method)
			{
				if (method.ID === 1)
					return "phone_android";
				else if (method.ID === 2)
					return "email";
				else
					return "unlock";
			},
			SelectMfaMethod(method)
			{
				if (!this.isTransitioning)
				{
					this.previouslySelectedId = this.selectedMfaMethod ? this.selectedMfaMethod.ID : null;
					this.selectedMfaMethod = method;
					this.pendingMethodData = null;
				}
			},
			SetFocus()
			{
				this.$nextTick(() =>
				{
					let ele = this.$refs.codeEntry;
					if (!ele)
						ele = document.querySelector('.mfaMethodListItem');
					if (!ele)
						ele = document.querySelector(".backToMfaList");
					if (ele)
						ele.focus();
				});
			},
			Cancel()
			{
				if (this.noCancelButton)
					this.GetMFAUserDataFromServer();
				else
					this.$emit('close');
			},
			SendEmailCode(overrideThrottleTimer)
			{
				if (this.sendingEmailCode)
					return;
				let timeDiff = performance.now() - this.lastAutoSentEmailCodeTime;
				if (timeDiff < 15000 && !overrideThrottleTimer)
					return;
				this.lastAutoSentEmailCodeTime = performance.now();
				this.sendingEmailCode = true;
				MFAUserEmailSend(this.$store, this.UN, this.PW)
					.then(result =>
					{
						ModalMessageDialog("An email containing a verification code has been sent to " + result.email);
					})
					.catch(err =>
					{
						ShowModalErrorWindow(err);
					})
					.finally(() =>
					{
						this.sendingEmailCode = false;
					});
			},
			SubmitOTPCode({ code, rememberDevice })
			{
				if (this.changingMfaConfiguration)
					return;
				if (!this.selectedMfaMethod)
				{
					ReportError(this.$store.getters.urlRoot, "SubmitOTPCode() failed because no MFA method was selected.", null, null, this.$store.getters.sid);
					return;
				}
				if (this.pendingMethodData)
				{
					// Finalize Configuration of MFA Method.
					this.changingMfaConfiguration = true;
					MFAUserEndSetup(this.$store, this.UN, this.PW, this.selectedMfaMethod.ID, code, this.pendingMethodData.SecretKey)
						.then(result =>
						{
							ModalMessageDialog("Multi-Factor Authentication Setup Complete").then(result =>
							{
								this.Cancel();
							});
						})
						.catch(err =>
						{
							ShowModalErrorWindow(err);
							if (err && err.data && err.data.ErrorCode !== "otp_invalid")
								this.Cancel();
						})
						.finally(() =>
						{
							this.changingMfaConfiguration = false;
						});
				}
				else
				{
					// Standard challenge submit
					let guid = this.canRememberDevice && rememberDevice ? this.deviceGuid : null;
					if (!this.isExternalApp)
					{
						// Not external app. We're responsible for storing the Device GUID.
						SaveDeviceGuidForMFA(guid);
					}

					this.$emit("submit", { // MFA Arguments that will ultimately end up in CreateSession from SessionAPI.js.
						mfaMethodType: this.selectedMfaMethod.ID,
						mfaPayload: code,
						mfaDeviceGuid: guid
					});
				}
			},
			TransitionStart()
			{
				this.isTransitioning = true;
			},
			TransitionEnd()
			{
				this.isTransitioning = false;
				let ele = null;
				if (this.previouslySelectedId)
					ele = document.querySelector('.mfaMethodListItem[data-methodid="' + this.previouslySelectedId + '"]');
				else
				{
					ele = this.$refs.codeEntry;
					if (!ele)
						ele = document.querySelector(".backToMfaList");
				}
				if (ele)
					ele.focus();
			},
			displayContactUs()
			{
				if (this.displayingContactUs)
				{
					console.log("Ignoring extra Contact Us click.");
					return;
				}

				this.displayingContactUs = true;
				ModalContactUsDialog().then(
					result =>
					{
						this.displayingContactUs = false;
					});
			},
			BeginSetup()
			{
				if (this.changingMfaConfiguration)
					return;
				this.changingMfaConfiguration = true;
				MFAUserBeginSetup(this.$store, this.UN, this.PW, this.selectedMfaMethod.ID)
					.then(result =>
					{
						this.pendingMethodData = { SecretKey: result.SecretKey, AuthenticatorQrCodeDataUri: result.AuthenticatorQrCodeDataUri };
						this.$nextTick(() =>
						{
							if (this.$refs.codeEntry)
								this.$refs.codeEntry.focus();
							if (this.selectedMfaMethod.ID === 2)
								this.SendEmailCode(true);
						});
					})
					.catch(err =>
					{
						ShowModalErrorWindow(err);
					})
					.finally(() =>
					{
						this.changingMfaConfiguration = false;
					});
			},
			ConfirmPassword()
			{
				if (!this.PW)
				{
					ShowModalErrorWindow("Please enter your password.");
					return;
				}
				this.GetMFAUserDataFromServer();
			},
			GetMFAUserDataFromServer()
			{
				if (this.loadingUserData)
					return Promise.resolve();
				this.loadingUserData = true;
				this.allowedToRemember = false;
				this.mfaMethodsAvailable = [];
				this.rememberedDevices = [];
				this.SelectMfaMethod(null);
				this.error = null;


				return MFAGetUserData(this.$store, this.UN, this.PW, this.deviceGuid)
					.then(result =>
					{
						this.IngestUserData(result.userData);
						this.passwordEntryRequired = false;
					})
					.catch(err =>
					{
						ShowModalErrorWindow(err);
						this.passwordEntryRequired = true;
					})
					.finally(() =>
					{
						this.loadingUserData = false;
						this.deletingRememberedDevice = false;
					});
			},
			RemoveConfiguration()
			{
				if (this.changingMfaConfiguration)
					return;
				ModalConfirmDialog("Are you sure you want to delete the configuration \"" + this.selectedMfaMethod.Name + "\"?", "Confirm", "Delete", "Cancel")
					.then(result =>
					{
						if (!result)
							return;
						this.changingMfaConfiguration = true;
						MFARemoveConfiguration(this.$store, this.UN, this.PW, this.selectedMfaMethod.ID)
							.then(result =>
							{
								this.changingMfaConfiguration = false;
								this.GetMFAUserDataFromServer();
							})
							.catch(err =>
							{
								this.changingMfaConfiguration = false;
								ShowModalErrorWindow(err);
							});
					});
			},
			RemoveRememberedDevice(id)
			{
				if (this.deletingRememberedDevice)
					return;
				this.deletingRememberedDevice = true;
				MFARemoveRememberedDevice(this.$store, this.UN, this.PW, id)
					.then(result =>
					{
						this.GetMFAUserDataFromServer()
							.finally(() =>
							{
								this.deletingRememberedDevice = false;
							});
					})
					.catch(err =>
					{
						this.deletingRememberedDevice = false;
						ShowModalErrorWindow(err);
					});
			}
		},
		watch:
		{
			selectedMfaMethod()
			{
				if (this.selectedMfaMethod)
				{
					if (this.mode === "challenge" && this.selectedMfaMethod.ID === 2)
						this.SendEmailCode(false);
				}
			}
		}
	}
</script>

<style scoped>
	.body
	{
		margin: 2px;
	}

	.titleBar
	{
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 1.5em;
		font-weight: bold;
		color: var(--primary-color-text);
		margin-bottom: 1em;
	}

	.errorMessage
	{
		word-break: break-word;
	}

	.logoImg
	{
		margin-left: 10px;
	}

	.loading
	{
		padding: 20px;
		text-align: center;
		width: 100%;
		box-sizing: border-box;
	}

	.mfaInterface
	{
		overflow-x: hidden;
	}

	.descriptionBlock
	{
		margin-top: 1em;
		margin-bottom: 1em;
	}

	.mfaNameHeading
	{
		font-size: 1.2em;
	}

	.rightArrow
	{
		transform: rotate(-90deg);
		width: 16px;
		height: 16px;
		fill: #666666;
	}

	.leftArrow
	{
		transform: rotate(90deg);
		width: 16px;
		height: 16px;
		fill: #666666;
	}

	.methodIconWrapper
	{
	}

	.methodIcon
	{
		width: 32px;
		height: 32px;
	}

	.mfaMethodListItem
	{
		margin: 0px 3px;
		padding: 10px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #AAAAAA;
		cursor: pointer;
	}

		.mfaMethodListItem:last-child
		{
			border-bottom: none;
		}

		.mfaMethodListItem .methodIcon
		{
			margin-right: 10px;
		}

		.mfaMethodListItem .methodName
		{
			flex: 1 1 auto;
		}

		.mfaMethodListItem .rightArrow
		{
			margin-left: 10px;
		}

		.mfaMethodListItem:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.greenCheckmark
	{
		fill: #00B100;
	}

	.backToMfaList
	{
		margin: 0px 3px 1em 3px;
		padding: 10px;
		display: flex;
		align-items: center;
		cursor: pointer;
		text-decoration: none;
		color: var(--default-text-color);
	}

		.backToMfaList .leftArrow
		{
			margin-right: 10px;
		}

		.backToMfaList:hover
		{
			background-color: rgba(0,0,0,0.05);
		}

	.centerContent
	{
		display: flex;
		justify-content: center;
	}

	.rightAlignContent
	{
		display: flex;
		justify-content: flex-end;
	}

	.resendCodeButton
	{
		font-size: 15pt;
	}

	.fullWidthButton
	{
		width: 100%;
	}

	.tb
	{
		border-radius: 5px;
		line-height: 18px;
		font-size: 16px;
		color: black;
		padding: 13px 20px 13px 20px;
		border: 1px solid #8E958E;
		width: 100%;
	}

		.tb:focus
		{
			font-size: 16px; /* Has to be at least this size or iOS will zoom in when focused. */
		}

		.tb:focus-visible
		{
			border: 2px solid black;
			padding: 12px 19px 12px 19px;
		}

		.tb.focus-visible
		{
			border: 2px solid black;
			padding: 12px 19px 12px 19px;
		}
</style>