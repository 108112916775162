import { ShowErrorWindow, ReportError, DefaultErrorHandler } from 'tdsAppRoot/library/ErrorReporter.js';
import { SaveFavorite, GetFavorites, ResolveFavId } from "tdsAppRoot/API/FavoritesAPI.js";
import ShowMySRLoginWindow from "tdsAppRoot/library/MySRLogin.js";
import { ModalMessageDialog, ModalDialog } from "tdsAppRoot/library/ModalDialog.js";
import EmailLink from "tdsAppRoot/components/Favorites/EmailLink.vue";
import { CreateDocLinkRoute, TruncateString } from "tdsAppRoot/library/TDSUtil.js";
import { InitSearch } from 'tdsAppRoot/library/Search.js';

/**
 * Saves the specified favorite, returning a promise that resolves with the API call result if successful, or false if unsuccessful.
 * This function handles error reporting and will not reject the promise.
 * @param {any} store vuex store
 * @param {String} type note type
 * @param {String} docAddress docAddress
 * @param {String} text note text
 * @param {String} context context string for use in later note resolving
 * @param {any} docDate date modified of document
 * @param {any} fxHash …
 * @param {any} paraId paragraph id where the document note is attached
 * @param {any} favid …
 * @param {any} path …
 * @param {Boolean} disableDateCheck …
 * @param {any} searchArgs …
 * @returns {Promise} A promise which resolves upon success, or rejects in case of error (the error will already have been handled).
 */
export function SaveFav(store, type, docAddress, text, context, docDate, fxHash, paraId, favid, path, highlightStartOffset, highlightLength, highlightText, disableDateCheck = false, searchArgs = null, color = null)
{
	return SaveFavorite(store, type, docAddress, text, context, docDate, fxHash, paraId, favid, path, highlightStartOffset, highlightLength, highlightText, disableDateCheck, searchArgs, color)
		.then(result =>
		{
			if (result.statusMsg)
				ModalMessageDialog(result.statusMsg);
			else if (result.fav)
			{
				let existingFav = store.state.profile && store.state.profile.favmap && result.fav.id ? store.state.profile.favmap[result.fav.id] : null;
				if (existingFav && typeof (existingFav.hidden) !== 'undefined')
				{
					result.fav.hidden = existingFav.hidden; // The server's state for this field won't be valid.
					store.commit("AddOrUpdateFavorite", { fav: existingFav, favData: result.fav });
				}
				else
				{
					store.commit("AddOrUpdateFavorite", { fav: null, favData: result.fav });
				}
			}
			return result;
		})
		.catch(err =>
		{
			if (err.name === "ApiError"
				&& (!err.data.currentProfile || !err.data.currentProfile.hasProfile)
				&& err.data.state == "noprofile")
			{
				return ShowMySRLoginWindow(true) // Causes a document reload from cache, breaking any saved range objects.  This breaks new highlight creation.
					.then(success =>
					{

						if (success)
							return SaveFav.apply(this, arguments).then(result =>
							{
								if (result.fav)
								{
									// Reload document.
									let doc = document.getElementById("docRootDomNode");
									if (doc)
										doc.component.LoadDoc();
								}
							});
						else
							return false;
					});
			}
			DefaultErrorHandler(err);
			return false;
		});
}

/**
 * Get the paragraph ID where this favorite is associated.  Can change based on if the favorite is in the current document being displayed, or a subdoc.
 * @param {String} prefix String that goes at the beginning of the ID.  Usually "p" but it can unfortunately also be "cell"
 * @param {Object} fav The favorite or highlight data object.
 * @param {int} currentDocAddress The current document address.  Not necessary the fav's document address.  If this doesn't match the fav's document address, a subdoc version paragraph ID is 
 * returned.
 */
export function GetParagraphIdForFav(prefix, fav, currentDocAddress)
{
	if (!currentDocAddress)
	{
		let docComp = document.getElementById("docRootDomNode");
		if (docComp)
			docComp = docComp.component;
		if (docComp)
			currentDocAddress = docComp.docAddress;
	}
	if (fav.docAddress == currentDocAddress || isNaN(fav.offset))
		return prefix + fav.offset; // fav.offset is expected to have the "SubDoc" stuff in it when necessary.
	else
	{
		return prefix + "SubDoc" + fav.docid + "_" + fav.offset;
	}
}

var favoritesLastSet = null;

// Returns a paragraph dom object that matches the fav's location.  A visible one if one exists.
export function GetPgForFav(fav, docAddress)
{
	let pg = null;
	let targets = document.querySelectorAll("#" + GetParagraphIdForFav("p", fav, docAddress));
	if (!targets || targets.length == 0)
		targets = document.querySelectorAll("#" + GetParagraphIdForFav("cell", fav, docAddress));
	for (let j = 0; j < targets.length; j++)
	{
		let tar = targets[j];
		if (tar.checkVisibility && tar.checkVisibility())
		{
			pg = tar;
			break;
		}
		else if (!pg)
			pg = tar;
	}
	return pg;
}

/**
 * Loads the profile's favorites, returning a promise that resolves with the API call result if successful, or false if unsuccessful.
 * This function handles error reporting and will not reject the promise.
 * @param {any} store vuex store
 * @param {bool} preserveHideState If true, and if favorites are being REloaded, preserve the hide state of annotations.  If you leave this false, all annotations reload as hidden.
 * @returns {Promise} Returns a promise which resolves when favorites are loaded.
 */
export function EnsureFavoritesLoaded(store, preserveHideState = false)
{
	var hasLoadedFavs = false;
	if (store && store.state && store.state.profile && store.state.profile.favorites && store.state.profile.favmap)
		hasLoadedFavs = true;
	if (!hasLoadedFavs || !favoritesLastSet || (Date.now() - favoritesLastSet > 30000)) // Since clicking on a highlight calls this now, I've increased the timer to 30 seconds instead of 3 seconds.
	{
		return GetFavorites(store)
			.then(result =>
			{
				var favmap = new Object();
				//var bydoc = new Object();
				// Search favorites don't have the productName field filled out. This field is important for FavoritesList.vue rendering and filtering.
				for (let i = 0; i < result.favorites.length; i++)
				{
					if (!result.favorites[i].productName)
						result.favorites[i].productName = GetProductText(result.favorites[i]);
					favmap[result.favorites[i].id] = result.favorites[i];
					if (preserveHideState)
					{
						if (store.state.profile && store.state.profile.favmap && store.state.profile.favmap[result.favorites[i].id])
							favmap[result.favorites[i].id].hidden = store.state.profile.favmap[result.favorites[i].id].hidden;
					}
					//if (result.favorites[i].docAddress)
					//{
					//	//if (!bydoc[result.favorites[i].docAddress])
					//	//	bydoc[result.favorites[i].docAddress] = new Object();
					//	//if (!bydoc[result.favorites[i].docAddress][result.favorites[i].offset])
					//	//	bydoc[result.favorites[i].docAddress][result.favorites[i].offset] = [];
					//	//bydoc[result.favorites[i].docAddress][result.favorites[i].offset].push(result.favorites[i]);
					//}
				}
				favoritesLastSet = Date.now();
				store.commit("SetFavorites", { favorites: result.favorites, favmap: favmap/*, bydoc: bydoc*/ });
				let docEle = document.getElementById("docRootDomNode");
				if (docEle && docEle.component)
					docEle.component.LoadAnnotationsWhenReady(0, false); // Documents create a filtered favorites list and store their own references to fav objects.  They don't automatically detect that the fav objects in the store have been replaced.  This tells the document to get its fav objects from the store again.
				return result;
			})
			.catch(err =>
			{
				if (err.name === "ApiError"
					&& (!err.data.currentProfile || !err.data.currentProfile.hasProfile)
					&& err.message == "You must be logged in to a profile in order to use this feature.")
				{
					return ShowMySRLoginWindow()
						.then(success =>
						{
							if (success)
								return EnsureFavoritesLoaded.apply(this, arguments);
							else
								return false;
						});
				}
				DefaultErrorHandler(err);
				return false;
			});
	}
	else
		return Promise.resolve(store.state.profile.favorites);
}


export function ShowEmailLinkWindow(fav)
{
	ModalDialog(EmailLink, { fav });
}

/// Translates the legacy note type coding to something simpler.
/// objectType:  The desired objectType.  'normal', 'hl', or 'anno'.  Returns true if [fav] qualifies as that objectType.  'normal' objectType includes favorites, annotations, and saved searches.  'fav' works the same as 'normal'.  We currently don't have a way to just list favorites that have no annotations or to exclude searches from a favorites list.
export function FavTypeCheck(fav, objectType)
{
	let isAnnotation = fav.type.indexOf("anon") === -1 && fav.type.indexOf("fav") === -1 && fav.type.indexOf("srch") === -1;
	let isSearch = fav.type.indexOf("srch") !== -1;
	let isHl = !isSearch && fav.highlightLength > 0;
	let isFav = !isHl && (fav.type.indexOf("fav") !== -1 || isSearch);

	if (objectType == 'hl' && isHl)
		return true;
	if ((objectType == 'fav' || objectType == 'normal' || objectType == '') && (isFav || isAnnotation || isSearch)) // Added isSearch here because otherwise DeleteAllFavorites leaves search favs behind.
		return true;
	if (objectType == 'anno' && isAnnotation)
		return true;

	return false;
}
/**
 * Returns the productName field from the specified favorite, or in the case of search favorites, generates a suitable name from the search query.
 * @param {Object} fav The favorite to get the productName field for.
 * @returns {String} The productName field or an appropriate substitute.
 */
function GetProductText(fav)
{
	if (fav.type.indexOf("srch") !== -1)
	{
		let searchArgs = JSON.parse(fav.path);
		let advanced = false;
		if (searchArgs.headingsOnly || searchArgs.intendToUse !== "" || searchArgs.relatedTerms || searchArgs.titleCategoryId !== 0 || searchArgs.yearLimit !== 0)
			advanced = true;
		let truncatedQuery = TruncateString(searchArgs.query, 155);

		return (advanced ? "Advanced " : "") + "Search: " + truncatedQuery;
	}
	else
	{
		return fav.productName;
	}
}

/**
 * Tries to resolve a note and navigate to it.
 * @param {Object} fav The favorite (note) to navigate to.
 */
export function GoToFavorite(fav)			// call using "call":			GoToFavorite.call(this, fav);
{
	ResolveFavId(this.$store, fav.id)
		.then(result =>
		{
			this.$store.commit("SetFavResolveState", { fav: fav, resolveState: result.resolved });
			if (result.resolved)
			{
				if (fav.type.indexOf("doc") !== -1)
					this.$router.push(CreateDocLinkRoute(result.docAddress, null, null, null, null, GetParagraphIdForFav("p", fav, result.docAddress), fav.type == "doc" ? fav : null));
				else if (fav.type.indexOf("srch") !== -1)
				{
					let args = JSON.parse(fav.path);
					InitSearch(args.query, this.$store, this.$router, args);
				}
				else
				{
					throw Error("Unhandled fav type clicked in Favorites list: " + fav.type);
				}
				this.$emit("close");
			}
			else if (result.resolveStatus === 3)
				ModalMessageDialog("We're sorry, but the document that this link referred to was in a title that has been either significantly updated or discontinued, and we can no longer locate the original position of this link.");
			else if (result.resolutionFailureMsg && result.resolutionFailureMsg.length > 0)
				ModalMessageDialog(result.resolutionFailureMsg);
			else if (result.errMsg && result.errMsg.length > 0)
				ModalMessageDialog(result.errMsg);
			else if (result)
			{
				throw new Error("Unusable ResolveFavId response: " + util.inspect(result, { showHidden: false, depth: null }));
			}
		})
		.catch(err =>
		{
			if (err.name === "ApiError")
				ModalMessageDialog(err.message);
			else
			{
				ShowErrorWindow();
				ReportError(this.$store.getters.urlRoot, err.message, undefined, err, this.$store.state.sid);
			}
		});
}

