<template>
	<div id="eulaRoot" role="dialog" :aria-label="ariaLabel">
		<div class="eulaContentOuter">
			<div class="eulaContent" v-if="eulaContent" v-html="eulaContent">
			</div>
			<div class="loadingMsg" v-if="!eulaContent">
				<ScaleLoader class="animation" />Loading
			</div>
		</div>
		<div class="buttons" v-if="showButtons">
			<button ref="acceptBtn" :class="{ greenHighButton:true, inputButton: true, disabled: !eulaLoaded }" @click="Accept" tabindex="0" v-on:keypress.enter="Accept" aria-label="I Accept">
				I Accept
			</button>
			<button class="redHighButton inputButton" @click="Decline" tabindex="0" v-on:keypress.enter="Decline" aria-label="I do not accept">
				I do NOT Accept
			</button>
		</div>
		<div v-else class="buttons">
			<button class="primaryColorButton inputButton" ref="closeBtn" @click="DefaultClose" tabindex="0" v-on:keypress.enter="DefaultClose" aria-label="Close">
				Close
			</button>
		</div>
	</div>
</template>

<script>
	import { ConfirmEULA } from 'tdsAppRoot/API/SessionAPI.js';
	import { ReportError } from 'tdsAppRoot/library/ErrorReporter.js';
	import { Logout } from "tdsAppRoot/library/Session.js";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import { EULALoader } from 'tdsAppRoot/library/RobustImport.js';
	import URLParse from 'url-parse';

	export default {
		components: { ScaleLoader },
		props:
		{
			returnUrl: { // Mapped to the "path" query string parameter by the router, but mapped to EulaHost.vue and not directly to this component.
				type: String,
				default: null
			},
			grpAlias: {
				type: String,
				default: null
			},
			showButtons: {
				type: Boolean,
				default: true
			}
		},
		data()
		{
			return {
				eulaContent: null,
				eulaLoaded: false
			}
		},
		computed:
		{
			ariaLabel()
			{
				if (themeMetadata.key == "tds_health")
					return "STAT!Ref® and TDS Health User Responsibilities";
				else
					return appContext.appName + " User Responsibilities";
			}
		},
		mounted()
		{
			EULALoader()
				.then(response =>
				{
					this.$data.eulaContent = response.default;
					this.eulaLoaded = true;
				})
				.catch(err =>
				{
					this.$data.eulaContent = "We couldn't load this document due to a network error. Please try again later.";
					ReportError(this.$store.getters.urlRoot, err.message, null, err);
				});
			if (typeof appContext !== 'undefined' && appContext.passthroughSid && appContext.passthroughSid.length > 0)
				this.$store.commit("SetSessionID", appContext.passthroughSid);
			if (this.$props.grpAlias)
				this.$store.commit("SetGrpAlias", this.$props.grpAlias);
			else if (typeof appContext !== 'undefined' && appContext.grpAlias && appContext.grpAlias.length > 0)
				this.$store.commit("SetGrpAlias", appContext.grpAlias);
			//this.SetFocus();
		},
		beforeDestroy()
		{
		},
		methods:
		{
			Close()
			{
				this.$emit("close", false);
			},
			SetFocus()
			{
				if (this.showButtons)
					this.$refs.acceptBtn.focus();
				else
					this.$refs.closeBtn.focus();
			},
			DefaultClose()
			{
				if (!this.showButtons)
					this.Close();
			},
			Accept()
			{
				if (!this.eulaLoaded)
					return;
				if (this.$store.state.grpalias)
				{
					this.$cookie.set("eula_" + this.$store.state.grpalias, "", 365);
				}
				let saveThis = this;
				let suppressBrowserWarning = false;
				var uAddr = URLParse(window.location.href, true);
				if (uAddr.query.suppressBrowserWarning == "true")
					suppressBrowserWarning = true;
				ConfirmEULA(this.$store).then(response =>
				{
					if (saveThis.$props.returnUrl)
					{
						let retUrl = saveThis.$props.returnUrl;
						if (retUrl[0] == '/')
							retUrl = retUrl.substr(1, retUrl.length - 1);
						if (retUrl.indexOf("?") != -1)
							window.location.href = saveThis.$store.getters.urlRoot + retUrl + "&SessionID=" + this.$store.state.sid + (suppressBrowserWarning ? "&suppressBrowserWarning=true" : "");
						else
							window.location.href = saveThis.$store.getters.urlRoot + retUrl + "?SessionID=" + this.$store.state.sid + (suppressBrowserWarning ? "&suppressBrowserWarning=true" : "");
					}
					else
						window.location.href = saveThis.$store.getters.urlRoot + "?SessionID=" + this.$store.state.sid + (suppressBrowserWarning ? "&suppressBrowserWarning=true" : "");
				}).catch(err =>
				{
					ReportError(this.$store.getters.urlRoot, "Failed to confirm EULA with server: " + err.message, null, err, appContext.passthroughSid);
				});
			},
			Decline()
			{
				Logout(this.$store, this.$cookie);
			}
		}
	}
</script>

<style>
	.eulaContent a
	{
		color: #4242FF;
	}
</style>

<style scoped>
	.buttons
	{
		text-align: center;
		margin: 10px;
	}

	.eulaContentOuter
	{
		width: 100%;
		background-color: white;
		color: black;
		max-width: 650px;
		height: 500px;
		max-height: 70vh;
		box-sizing: border-box;
		overflow: auto;
	}

	.eulaContent
	{
		padding: 10px;
	}

	.loadingMsg
	{
		width: 100%;
		height: 100%;
		max-width: 650px;
		min-width: 300px;
		font-size: 40pt;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.animation
	{
		margin-right: 10px;
		position: relative;
		top: 2px;
	}
</style>