<template>
	<div class="mtlpRoot" role="alertdialog" aria-labelledby="mtlpMsgTitle">
		<div id="mtlpMsgTitle" class="heading" role="alert">Follow link to</div>
		<a v-for="(link, index) in links" :key="index" :href="link.href" v-html="link.html" @click="$emit('close', true)" target="_blank" ref="links" tabindex="0"></a>
		<span class="visuallyhidden">Press escape to cancel.</span>
	</div>
</template>

<script>
	export default {
		props:
		{
			links: {
				type: Array,
				required: true
			}
		},
		methods:
		{
			SetFocus()
			{
				if (this.$refs.links && this.$refs.links.length > 0)
					this.$refs.links[0].focus(); // <a> elements require tabindex="0" or else this focus call won't make the focus outline appear on the link!
			}
		}
	}
</script>

<style scoped>
	.mtlpRoot
	{
		max-width: 90vw;
		background-color: #FFFFFF;
		font-size: 0px;
		text-align: center;
	}

	.heading
	{
		font-size: 18pt;
		color: #5B9CAE;
		border-bottom: 1px solid #5B9CAE;
		padding: 7px;
	}

	.mtlpRoot a
	{
		display: inline-block;
		vertical-align: middle;
		padding: 2px;
		margin: 10px;
		font-size: 12pt;
		text-decoration: underline;
	}
</style>