var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fsFade" } }, [
    _vm.show
      ? _c(
          "div",
          {
            ref: "mainDiv",
            staticClass: "modalRootLight",
            style: _vm.rootStyle,
            attrs: {
              role: "alert",
              "aria-labelledby": "loadingMsgContent",
              "aria-modal": "true"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.OnClick.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "div",
              {
                staticClass: "content",
                class: { shadow: _vm.shadow },
                style: { backgroundColor: _vm.backgroundColor }
              },
              [
                _c("ScaleLoader", { staticClass: "animation" }),
                _c("span", { attrs: { id: "loadingMsgContent" } }, [
                  _vm._v(_vm._s(_vm.msg))
                ])
              ],
              1
            ),
            _vm._v(" "),
            _c("div", {
              ref: "focusCatcherA",
              staticClass: "FocusCatcher",
              attrs: { tabindex: "0" },
              on: {
                focus: function($event) {
                  return _vm.FocusCatcher(false)
                }
              }
            }),
            _vm._v(" "),
            _c("div", { ref: "focusEle", attrs: { tabindex: "0" } }),
            _vm._v(" "),
            _c("div", {
              ref: "focusCatcherB",
              staticClass: "FocusCatcher",
              attrs: { tabindex: "0" },
              on: {
                focus: function($event) {
                  return _vm.FocusCatcher(true)
                }
              }
            })
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }