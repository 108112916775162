<template>
	<div id="mySRLoginDialog" role="dialog" aria-describedby="proflogindesc" aria-label="Personalized Profile Account login.  Press escape to close.">
		<div id="proflogindesc" style="display: none">
			Personalized Profile Account login.  Press escape to close.
		</div>
		<MySRLogin ref="loginCtrl" @success="onSuccess" :isInPopup="true" :disableAutomaticDocReload="disableAutomaticDocReload"></MySRLogin>
	</div>
</template>

<script>
	import MySRLogin from "tdsAppRoot/components/Controls/MySRLogin.vue";

	export default {
		components: { MySRLogin },
		props:
		{
			// Logging in to a profile will normally reload the current document.  Set this to disable that behavior.  Currently
			// used when attempting to save Favorites, as the document must be reloaded after the save, which itself can't happen
			// until after the profile login, and we don't want to reload twice.
			disableAutomaticDocReload: {
				type: Boolean,
				default: false
			}
		},
		methods:
		{
			onSuccess()
			{
				this.$emit("close", true);
			},
			DefaultClose()
			{
				this.$emit("close", false);
			}
			//},
			//SetFocus()
			//{
			//	this.$refs.loginCtrl.SetFocus();
			//}
		}
	}
</script>