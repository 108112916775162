<template>
	<div class="category">
		<div class="heading">{{categoryType}}</div>
		<div class="body">
			<table v-if="category">
				<tbody>
					<tr class="item" v-for="(item, index) in lineItems" :key="index">
						<td class="itemKey">{{item.key}}:</td>
						<td class="itemValue" v-html="item.value"></td>
					</tr>
				</tbody>
			</table>
			<div v-else-if="loading" class="loading">
				<ScaleLoader class="animation" />
				<div class="loadingtxt">Loading</div>
			</div>
			<div v-else-if="canceled" class="canceled">
				Canceled
			</div>
			<div v-else class="failed">
				Result set did not load
			</div>
		</div>
	</div>
</template>
<script>
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import { msToTime } from 'tdsAppRoot/library/TDSUtil.js';
	export default {
		components: { ScaleLoader },
		props:
		{
			searchid: {
				type: String,
				required: true
			},
			categoryType: {
				type: String,
				required: true
			}
		},
		data: function ()
		{
			return {
			};
		},
		methods:
		{
		},
		computed:
		{
			category()
			{
				return this.$store.getters.GetCategorySearchResults(this.searchid, this.categoryType);
			},
			lineItems()
			{
				let items = [];
				let meta = this.category.metadata;
				if (meta)
				{
					items.push({ key: "Duration", value: (meta.searchDuration / 1000) + " seconds" });
					items.push({ key: "Cached", value: this.cachedAge });
					items.push({ key: "Result Count", value: this.category.totalResults });
					if (meta.hitCount && meta.hitCount !== this.category.totalResults)
						items.push({ key: "Hit Count", value: meta.hitCount });
				}
				return items;
			},
			cachedAge()
			{
				if (!this.category.metadata)
					return "";
				if (this.category.metadata.cacheAge <= 0)
					return '<span style="color: #009900">Not Cached</span>';
				else
					return msToTime(this.category.metadata.cacheAge) + " old";
			},
			resultContainer()
			{
				return this.$store.getters.GetSearchResult(this.searchid);
			},
			resultsReceived()
			{
				return !!this.category;
			},
			loading()
			{
				return this.$store.getters.IsCategoryRequested(this.searchid, this.categoryType)
					&& !this.canceled
					&& this.resultContainer
					&& !this.resultContainer.finished
					&& !this.resultsReceived;
			},
			canceled()
			{
				return this.resultContainer && !!this.resultContainer.canceled && !this.resultsReceived;
			}
		}
	};
</script>
<style scoped>
	.category
	{
	}

	.heading
	{
		font-size: 16pt;
		font-weight: bold;
		border-bottom: 1px solid #AAAAAA;
		margin-bottom: 7px;
	}

	.itemKey
	{
		padding-right: 7px;
	}

	.itemValue
	{
	}

	.loading,
	.canceled,
	.failed
	{
		padding: 3px;
		text-align: center;
	}

	.canceled
	{
		font-weight: bold;
		color: #FF0000;
	}
</style>
