<template>
	<div class="cornerLoader">
	</div>
</template>

<script>
	export default {
		props:
		{
			dismiss: {
				type: Boolean,
				default: false
			}
		},
		watch:
		{
			dismiss()
			{
				if (this.dismiss)
					this.$emit("close", false);
			}
		}
	}
</script>

<style scoped>
	.cornerLoader
	{
		z-index: 10000;
		pointer-events: none;
		position: fixed;
		top: 3px;
		right: 3px;
		box-sizing: border-box;
		width: 16px;
		height: 16px;
		border-width: 2px;
		border-style: solid;
		border-color: #00adff #00adff transparent;
		border-radius: 100%;
		webkit-animation: spinAnim 750ms linear 0s infinite;
		animation: spinAnim 750ms linear 0s infinite;
	}

	.cornerLoaderInner
	{
		z-index: 10000;
		pointer-events: none;
		position: absolute;
		top: 0px;
		left: 0px;
		box-sizing: border-box;
		width: 32px;
		height: 32px;
	}

	@keyframes spinAnim
	{
		0%
		{
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}

		100%
		{
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
</style>