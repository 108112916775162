<template>
	<footer role="contentinfo" class="appFooter" :style="footerStyle">
		<div class="systemNotice" v-if="systemNotice" v-html="systemNotice">
		</div>
		<div class="footerLinks" :style="footerLinksStyle">
			<div class="footerInternalLinks">
				<a role="button" aria-haspopup="dialog" class="footerLink coloredLink" tabindex="0" @click="PublicComputer" @keydown.enter.space.stop.prevent="PublicComputer">PUBLIC COMPUTER?</a>
				<a role="button" aria-haspopup="dialog" class="footerLink coloredLink" tabindex="0" @click="OnClearPastSessions" @keydown.enter.space.stop.prevent="OnClearPastSessions">CLEAR PAST SESSIONS</a>
				<a role="button" aria-haspopup="dialog" class="footerLink coloredLink" @click="displayContactUs" tabindex="0" aria-label="Contact Us - Click here to send us an e-mail" @keydown.enter.space.stop.prevent="displayContactUs">CONTACT</a>
				<a class="footerLink coloredLink" href="https://www.tetondata.com/help.cshtml" target="_blank">SUPPORT</a>
				<a class="footerLink coloredLink" href="https://www.tetondata.com/training.cshtml" target="_blank">TRAINING</a>
				<router-link :to="{ name: 'htmlfilepagedisplay', query: { filetoken: 'titleUpdates' } }" v-if="!isRestrictedSession" class="footerLink coloredLink">TITLE UPDATES</router-link>
				<a role="button" aria-haspopup="dialog" class="footerLink coloredLink" tabindex="0" @click="OnEula" @keydown.enter.space.stop.prevent="OnEula">EULA</a>
				<a role="button" v-if="pwaInstallAvailable" class="footerLink coloredLink" tabindex="0" @click="OnInstall" @keydown.enter.space.stop.prevent="OnInstall" :title="installTooltip">INSTALL</a>
			</div>
			<div class="footerSocialMediaLinks">
				<SocialMediaIcon v-for="link in socialMediaLinks" :target="link" :key="link.key" />
			</div>
		</div>
		<div class="footerBottom">
			<LogoImage :width="70" class="logoPrintOnly" logoType="standalone" style="padding-left: 8px" :includeDesc="false" :rolePresentation="true" />
			<div class="supportInfoBtn" role="button" tabindex="0" @click="OpenSupportInfo" 
				 @keydown.enter.space.stop.prevent="OpenSupportInfo" aria-label="Support Info" 
				 aria-haspopup="dialog">
				<vsvg sprite="#bug" title="Support Info" desc="" :isPresentation="false" />
			</div>
			<span>
				<router-link v-if="themeMetadata.key === 'tds_health'" title="Product Release Notes" :aria-label="'Copyright © Teton Data Systems ' + footerYear + ' - Product Release Notes'" :to="{ name: 'htmlfilepagedisplay', query: { filetoken: 'releaseNotes' } }" class="footerLink alone" type="button">Copyright © Teton Data Systems {{footerYear}}</router-link>
				<span v-else>Copyright © Teton Data Systems {{footerYear}}</span>
				<br class="onlyOnMobile" />
				<span v-if="groupTitle" class="footerGroupTitle" v-html="groupTitle"></span>
			</span>
		</div>
	</footer>
</template>

<script>
	import svg4 from "tdsAppRoot/images/sprite/bug.svg";
	import { ModalContactUsDialog, ModalConfirmDialog, ModalMessageDialog, ModalEULADialog, ModalSupportInfoDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { ReportError } from "tdsAppRoot/library/ErrorReporter.js";
	import { isLocalStorageEnabled, IsSessionStorageEnabled } from "tdsAppRoot/library/TDSUtil.js";
	import { Logout } from "tdsAppRoot/library/Session.js";
	import Vue from 'vue';
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";
	import SocialMediaIcon from "tdsAppRoot/components/Controls/SocialMediaIcon.vue";
	import EventBus from "tdsAppRoot/library/EventBus";


	export default {
		mixins: [ThemeMixin],
		components: { LogoImage, SocialMediaIcon },
		props:
		{
			setWidth: {
				type: Number,
				default: 0
			}
		},
		data()
		{
			return {
				displayingContactUs: false,
				footerLinksStyle: {},
				systemNotice: appContext.systemNotice,
				installTooltip: "Install " + appContext.appName + " as an app on your device."
			}
		},
		methods:
		{
			displayContactUs: function (e)
			{
				if (this.$data.displayingContactUs)
				{
					console.log("Ignoring extra Contact Us click.");
					return;
				}

				this.$data.displayingContactUs = true;
				ModalContactUsDialog().then(
					result =>
					{
						this.$data.displayingContactUs = false;
					});
			},
			PreventDefault: function (event)
			{
				event.preventDefault();
				return false;
			},
			ClearSessions: function ()
			{
				if (isLocalStorageEnabled())
					localStorage.clear();
				var cookies = document.cookie.split(";");
				for (var i = 0; i < cookies.length; i++)
				{
					let domain = location.hostname;
					Vue.cookie.delete(cookies[i].split("=")[0]);
					//document.cookie = cookies[i].split("=")[0] + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
					//document.cookie = cookies[i].split("=")[0] + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;domain=' + domain;
					//domain = location.hostname.replace(/^demo\./i, "").replace(/^onlinebeta\./i, "").replace(/^online\./i, "").replace(/^www\./i, "");
					//document.cookie = cookies[i].split("=")[0] + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;domain=' + domain;
				}
			},
			OnClearPastSessions: function (e)
			{
				if (this.$store.state.app === "login")
				{
					this.ClearSessions();
					if (IsSessionStorageEnabled())
						sessionStorage.clear();
					ModalMessageDialog("Data has been cleared.");
				}
				else
				{
					ModalConfirmDialog("This will clear all saved " + appContext.appName + " session information and cookies from this computer, and will log you out.  Proceed?", null, "Yes (Log Out)", "Cancel").then(result =>
					{
						if (result)
						{
							this.ClearSessions();
							Logout(this.$store, this.$cookie);
						}
					});
				}
			},
			PublicComputer: function ()
			{
				ModalMessageDialog("Most information stored by " + appContext.appName + " is cleared when you log out or close the browser tab.  The important exception to this rule would be choosing the \"Remember Me\" option when logging in to a Personalized Profile Account.  If you choose this option on a public computer, others may be able to access your personal information.");
			},
			OnEula: function ()
			{
				ModalEULADialog(false);
			},
			//OnTrainingCenter: function () // Intentionally redundant with OnSupport
			//{

			//},
			recalcFooterLinksStyle()
			{
				let proposedWidth = window.innerWidth - window.scrollbarWidth;
				let clientWidth = document.documentElement.scrollWidth - 1; // -1 because in some browsers (IE) proposedWidth is often just slightly less than scrollWidth
				if (this.setWidth && this.setWidth > 0 && proposedWidth < clientWidth)
					this.footerLinksStyle = { maxWidth: proposedWidth + "px" };
				else
					this.footerLinksStyle = {};
			},
			OpenSupportInfo()
			{
				ModalSupportInfoDialog();
			},
			OnInstall()
			{
				EventBus.pwaInstallEvent.prompt();
			}
		},
		computed:
		{
			footerStyle()
			{
				if (this.setWidth && this.setWidth > 0)
					return { width: this.setWidth + "px" };
				return {};
			},
			groupTitle()
			{
				return appContext.groupTitle ? "Provided by: " + appContext.groupTitle : "";
			},
			isRestrictedSession()
			{
				return typeof appContext !== 'undefined' && appContext.restrictedSessionProductType !== null;
			},
			footerYear()
			{
				return appContext.year;
			},
			pwaInstallAvailable()
			{
				return false && EventBus.pwaInstallAvailable && !EventBus.pwaInstalled;
			}
		},
		watch:
		{
			setWidth()
			{
				this.recalcFooterLinksStyle();
			}
		},
		created()
		{
			if (typeof window !== "undefined")
			{
				window.addEventListener("resize", this.recalcFooterLinksStyle);
				this.recalcFooterLinksStyle();
			}
		},
		beforeDestroy()
		{
			if (typeof window !== "undefined")
				window.removeEventListener("resize", this.recalcFooterLinksStyle);
		}
	}
</script>

<style>
</style>

<style scoped>
	.appFooter a
	{
		color: var(--footer-text-color);
	}

		.appFooter a.coloredLink
		{
			color: var(--footer-link-color);
		}

	.appFooter
	{
		background-color: var(--footer-background-color);
		width: 100%;
		color: var(--footer-text-color);
		clear: both;
		position: static; /*was relative*/
		z-index: 0; /* This used to be set to 1000, but I couldn't think of a reason for it to be on top of anything else, and it was covering up the document menu on short documents in mobile layout. */
		margin-top: auto;
	}

	.systemNotice
	{
		margin: 10px;
		padding: 10px;
		text-align: center;
		border: 2px dashed #ff6a00;
	}

	.footerLinks
	{
		width: 100%;
		max-width: 1165px;
		display: flex;
		justify-content: space-between;
		font-family: 'Open Sans', sans serif;
		font-size: 15px;
	}

	.footerInternalLinks, .footerSocialMediaLinks
	{
		padding: 12px 0px;
		display: flex;
		flex-direction: column;
	}

	.footerLink
	{
		padding: 9px 10px;
		border-bottom: 1px solid var(--footer-linksep-color);
		cursor: pointer;
	}

	.footerSocialMediaLinks
	{
		align-items: flex-start;
		flex: 0 0 auto;
		margin: 0px 10px;
	}


		.footerSocialMediaLinks a
		{
			width: 40px;
			height: 40px;
			margin-bottom: 20px;
			display: block;
		}

			.footerSocialMediaLinks a:last-child
			{
				margin-bottom: 0px;
			}

	.footerLink:last-child,
	.footerLink.alone
	{
		border-bottom: none;
	}

	.footerLink.alone
	{
		padding: 0px;
	}



	.footerBottom
	{
		padding: 15px 30px;
		background-color: var(--footer-bottom-background-color);
	}

	.supportInfoBtn
	{
		position: absolute;
		left: 0px;
		display: inline-block;
		padding: 3px 4px;
		vertical-align: bottom;
		cursor: pointer;
	}

		.supportInfoBtn svg
		{
			fill: currentColor;
			visibility: hidden;
		}

		.supportInfoBtn,
		.supportInfoBtn svg
		{
			width: 19px;
			height: 19px;
		}

			.supportInfoBtn:hover,
			.supportInfoBtn:focus
			{
				background-color: rgba(255,255,255,0.05);
			}

				.supportInfoBtn:hover svg,
				.supportInfoBtn:focus svg
				{
					visibility: visible;
				}

	.copyrightNotice
	{
		text-align: center;
	}

	.logoPrintOnly
	{
		display: none;
	}

	@media (min-width: 600px)
	{
		.onlyOnMobile
		{
			display: none;
		}

		.footerLink.alone
		{
			display: inline;
			/*padding: 9px 10px;*/ /* This padding somehow caused an unnecessary vertical scroll bar in Chrome on pages that would otherwise have no scroll bar. */
		}

		.footerGroupTitle
		{
			padding-left: 20px;
		}

		.footerLinks
		{
			align-items: center;
		}

		.footerInternalLinks, .footerSocialMediaLinks
		{
			padding: 7px 0px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		.footerLink
		{
			margin: 5px 0px;
			padding: 0px 20px;
			border-bottom: none;
			border-right: 1px solid var(--footer-linksep-color);
		}

			.footerLink:last-child,
			.footerLink.alone
			{
				border-right: none;
			}

		.footerSocialMediaLinks
		{
			margin: 0px 30px;
		}

			.footerSocialMediaLinks a
			{
				margin-bottom: 0px;
				margin-right: 36px;
			}

				.footerSocialMediaLinks a:last-child
				{
					margin-right: 0px;
				}

		.footerBottom
		{
			padding: 5px 30px 8px;
		}

		.copyrightNotice
		{
			text-align: left;
		}
	}

	@media print
	{
		.appFooter,
		.footerBottom
		{
			background-color: #FFFFFF;
			color: #000000;
		}

			.appFooter a
			{
				color: #000000;
			}

		.twitter
		{
			fill: #1DA1F2;
			background-color: #FFFFFF;
		}

		.footerLinks
		{
			display: none;
		}

		.footerBottom
		{
			border: 1px solid black;
			display: flex;
			align-items: center;
			padding: 15px;
		}

		.onlyOnMobile
		{
			display: unset;
		}

		.footerGroupTitle
		{
			padding: 0px;
		}

		.supportInfoBtn
		{
			display: none;
		}

		.logoPrintOnly
		{
			display: inline-block;
			max-width: 70px;
			max-height: 40px;
			fill: var(--primary-color);
			margin-right: 20px;
		}
	}
</style>