var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.everShowed && (_vm.persistContent || _vm.show)
    ? _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.show,
              expression: "show"
            }
          ],
          ref: "tooltipRoot",
          staticClass: "tooltipRoot"
        },
        [
          _c(
            "div",
            {
              staticClass: "popupRoot",
              class: _vm.arrowClass,
              style: _vm.dynStyle,
              on: { click: _vm.StopProp }
            },
            [
              _c(
                "div",
                {
                  staticClass: "popupInner",
                  style: _vm.dynStyleInner,
                  on: { mouseup: _vm.StopProp }
                },
                [
                  _vm.htmlContent !== null
                    ? _c("div", {
                        staticClass: "content",
                        style: _vm.dynStyleContent,
                        domProps: { innerHTML: _vm._s(_vm.htmlContent) }
                      })
                    : _vm.contentComponent
                    ? _c(
                        _vm.contentComponent,
                        _vm._b(
                          { tag: "component", staticClass: "content" },
                          "component",
                          _vm.contentProps,
                          false
                        )
                      )
                    : _c(
                        "div",
                        { staticClass: "content" },
                        [_vm._t("default")],
                        2
                      )
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("VStyle", [
            _vm._v(
              "\n\n\t\t.popupRoot\n\t\t{\n\t\twidth: " +
                _vm._s(_vm.tooltipWidth) +
                ";\n\t\theight: " +
                _vm._s(_vm.tooltipHeight) +
                ";\n\t\t}\n\t"
            )
          ])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }