import EventBus from "tdsAppRoot/library/EventBus.js";
import
{
	Acquire as API_Acquire,
	Completion as API_Completion,
	DrugLookup as API_DrugLookup,
	Interactions as API_Interactions,
	MonographLookup as API_MonographLookup,
	GetResource as API_GetResource
}
	from "tdsAppRoot/API/DrugInteractionsAPI.js";

export var ProductName = "Drug Interactions from The Medical Letter®";

let drugCache = {
	acquire: new PromiseCache(API_Acquire),
	completions: new PromiseCache(API_Completion),
	drugLookups: new PromiseCache(API_DrugLookup),
	interactions: new PromiseCache((store, key) =>
	{
		let args = JSON.parse(key);
		return API_Interactions(store, args.names, args.sortby, args.includeMonographs);
	}),
	monographLookups: new PromiseCache(API_MonographLookup)
};

export function Acquire(store)
{
	return drugCache.acquire.Get(store);
}
export function Completion(store, query)
{
	return drugCache.completions.Get(store, query);
}
export function DrugLookup(store, name)
{
	return drugCache.drugLookups.Get(store, name);
}
export function Interactions(store, names, sortby, includeMonographs)
{
	let key = JSON.stringify({ names, sortby, includeMonographs });
	return drugCache.interactions.Get(store, key);
}
export function MonographLookup(store, id)
{
	return drugCache.monographLookups.Get(store, id);
}
export function GetResource(store, name, id)
{
	API_GetResource(store, name, id).then(() => { }).catch(() => { });
}
/**
 * Provides promise-sharing and caching of results to minimize API requests made.
 * @param {Function} GetNew A function which accepts the key string and returns a promise that resolves with the desired item.
 */
function PromiseCache(GetNew)
{
	let promises = {};
	let cache = {};
	this.Get = function (store, key)
	{
		let item = cache[key];
		if (item)
		{
			//console.log("PromiseCache", "cached", key);
			return new Promise((resolve, reject) => { resolve(item); });
		}
		else
		{
			let promise = promises[key];
			if (promise)
			{
				//console.log("PromiseCache", "existing promise", key);
				return promise;
			}
			else
			{
				//console.log("PromiseCache", "new API request", key);
				promises[key] = promise = GetNew(store, key)
					.then(result =>
					{
						EventBus.drugInteractionsTurnaway = false;
						cache[key] = result;
						return result;
					})
					.catch(err =>
					{
						if (err.name === "ApiError")
						{
							if (err.data.noaccess)
								EventBus.drugInteractionsAvailable = false;
							if (err.data.turnaway)
								EventBus.drugInteractionsTurnaway = true;
							if (!EventBus.drugInteractionsAvailable || EventBus.drugInteractionsTurnaway)
								cache = {};
						}
						return Promise.reject(err);
					})
					.finally(() =>
					{
						delete promises[key];
					});
				return promise;
			}
		}
	};
}

export var printImg = require("tdsAppRoot/images/adi/print48.png");
export var iconMinus = require("tdsAppRoot/images/adi/icon_minus.png");
export var iconPlus = require("tdsAppRoot/images/adi/icon_plus.png");
export var prtyDrug = require("tdsAppRoot/images/adi/prty_drug.png");
export var linkDrugs = require("tdsAppRoot/images/adi/link_drugs.png");
export var squareLogo = require("tdsAppRoot/images/adi/TMLADI_Logo76.png");

export function GetDrugSubHeading(drug)
{
	if (drug.ingredients && drug.ingredients.length > 0)
		return drug.ingredients.join(", ");
	else if (drug.type && drug.type !== "generic")
		return drug.type;
	else if (drug.class)
		return drug.class;
	else
		return null;
}
export function GetAllRatingDetails()
{
	return [
		GetRatingDetails("0"),
		GetRatingDetails("1"),
		GetRatingDetails("2"),
		GetRatingDetails("3"),
		GetRatingDetails("5"),
		GetRatingDetails("4")
	];
}
export function GetRatingDetails(rating)
{
	rating = rating.toString();
	if (rating === "0")
		return {
			rating: "0"
			, label: "Display interactions with significance: Avoid Concurrent Use"
			, alt: "X"
			, text: "X"
			, class: "adirating prty_0"
			, img: require("tdsAppRoot/images/adi/prty_0.png")
			, title: "Avoid Concurrent Use"
			, desc: "Absolute contraindications"
			, type: "interaction"
		};
	else if (rating === "1")
		return {
			rating: "1"
			, label: "Display interactions with significance 1: Avoid or Use Only with Close Monitoring"
			, alt: "1"
			, text: "1"
			, class: "adirating prty_1"
			, img: require("tdsAppRoot/images/adi/prty_1.png")
			, title: "Avoid or Use Only with Close Monitoring"
			, desc: "Relative contraindications"
			, type: "interaction"
		};
	else if (rating === "2")
		return {
			rating: "2"
			, label: "Display interactions with significance 2: Modification and or Monitoring Suggested"
			, alt: "2"
			, text: "2"
			, class: "adirating prty_2"
			, img: require("tdsAppRoot/images/adi/prty_2.png")
			, title: "Modification and/or Monitoring Suggested"
			, desc: "A non-interacting alternative to one of the drugs may be preferable. Measures should be taken to circumvent the interaction, and/or specific monitoring is suggested to minimize the risk."
			, type: "interaction"
		};
	else if (rating === "3")
		return {
			rating: "3"
			, label: "Display interactions with significance 3: No Special Precautions"
			, alt: "3"
			, text: "3"
			, class: "adirating prty_3"
			, img: require("tdsAppRoot/images/adi/prty_3.png")
			, title: "No Special Precautions"
			, desc: "No additional measures need to be taken, other than routine monitoring for efficacy and toxicity of the interacting drugs."
			, type: "interaction"
		};
	else if (rating === "4")
		return {
			rating: "4"
			, label: "Display interactions with significance 4: No Interactions Found"
			, alt: "No Interactions Found"
			, text: ""
			, class: "adirating prty_4"
			, img: require("tdsAppRoot/images/adi/prty_4.png")
			, title: "No Interactions Found"
			, desc: undefined
			, type: "nointeraction"
		};
	else if (rating === "5")
		return {
			rating: "5"
			, label: "Display interactions with significance 5: Therapeutic Duplication"
			, alt: "Therapeutic Duplication"
			, class: "adirating prty_5"
			, img: require("tdsAppRoot/images/adi/prty_5.png")
			, title: "Therapeutic Duplication"
			, desc: undefined
			, type: "duplication"
		};
	return null;
}

/**
 * Tries to replace the current route with one that does not contain the specified drug name. Returns true if the drug was found and removed from the route.
 * @param {any} component Vue component making this call.
 * @param {String} drugName Drug name to remove.
 * @returns {Boolean} True if the drug was found and removed from the route.
 */
export function RemoveDrugFromQuery(component, drugName)
{
	let me = drugName.toLowerCase();
	let query = Object.assign({}, component.$route.query);
	let oldDrugs = query.drugs ? query.drugs.split('|') : [];
	let newDrugs = oldDrugs.filter(n => n.toLowerCase() !== me);
	query.drugs = newDrugs.join('|');
	let didRemove = oldDrugs.length > newDrugs.length;
	if (query.d && query.d.toLowerCase() === me)
	{
		delete query.d;
		didRemove = true;
	}
	if (didRemove)
		component.$router.replace({ name: component.$route.name, query, update: true }).catch(routingError => { });
	return didRemove;
}