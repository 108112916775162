import { CallServer } from "tdsAppRoot/API/apibase.js";

import { getStackTrace } from "tdsAppRoot/library/TDSUtil.js";

var TocParams = function ()
{
	var nodeid = "";
	var docAddress = "";
	var fullTree = false;
	var fxid_override = -1;
	var directAction = false;
};
Object.seal(TocParams);

var NodeListParams = function ()
{
	var nodeidlist = [];
	var fxid = null;
	var autoExpandRootNode = true;
	var debug = null; // temporary debug string
};
Object.seal(NodeListParams);

var LogToggleRequest = function ()
{
	var docAddress = null;
	var fxid = null;
	var nodeid = null;
};

var FxToTitleRequest = function ()
{
	var fxid = null;
};
Object.seal(FxToTitleRequest);

// Logs direct TOC usage.
var LogToggle = function (docAddress, nodeid, store, fxid_override)
{
	var args = new LogToggleRequest();
	args.docAddress = docAddress;
	args.fxid = fxid_override;
	args.nodeid = nodeid;

	return CallServer("Toc", "TocToggle", args, store, null);
};

// Gets a TOC node and all of its children, if any, from the server.
var GetNode = function (docAddress, nodeid, fullTree, store, fxid_override, directAction)
{
	let myargs = new TocParams();
	myargs.nodeid = nodeid;
	myargs.docAddress = docAddress;
	myargs.fullTree = fullTree;
	myargs.fxid_override = fxid_override;
	myargs.directAction = directAction;

	return CallServer("Toc", "", myargs, store);
};

// Returns TOC data for a single title, where all nodes matching the provided node list are expanded.
var GetTreeWithExpansions = function (nodeIdArray, fxid, autoExpandRootNode, store)
{
	let myargs = new NodeListParams();

	myargs.nodeidlist = nodeIdArray;
	myargs.fxid = fxid;
	//myargs.debug = "fxid>" + fxid + "<fxid type>" + (typeof fxid) + "<type stack: " + getStackTrace();
	myargs.autoExpandRootNode = autoExpandRootNode;

	//console.log("Making TOC nodelist request:");
	//console.log(myargs);

	return CallServer("Toc", "NodeList", myargs, store);
};

var GetTitleFromFxId = function (fxid, store)
{
	let myargs = new FxToTitleRequest();
	myargs.fxid = fxid;

	return CallServer("Toc", "GetTitleFromFxId", myargs, store);
};

export { GetNode, GetTreeWithExpansions, LogToggle, GetTitleFromFxId };