<template>
	<Transition :name="isShowingLeftPanel ? 'twoPanelSlideRight' : 'twoPanelSlideLeft'" @before-enter="TransitionStart" @after-leave="TransitionEnd">
		<slot></slot>
	</Transition>
</template>

<script>
	// This reusable transition is intended to transition between two side-by-side panels of similar or identical height.
	export default {
		props:
		{
			isShowingLeftPanel: {
				type: Boolean,
				required: true
			}
		},
		data()
		{
			return {
			};
		},
		methods:
		{
			TransitionStart()
			{
				this.$emit('start');
			},
			TransitionEnd()
			{
				this.$emit('end');
			}
		}
	}

</script>

<style>
	.twoPanelSlideRight-enter-active,
	.twoPanelSlideRight-leave-active,
	.twoPanelSlideLeft-enter-active,
	.twoPanelSlideLeft-leave-active
	{
		transition: all .2s ease-in;
		/* These properties are here to get the browser to use hardware acceleration. */
		-webkit-transform: translateZ(0);
		-moz-transform: translateZ(0);
		-ms-transform: translateZ(0);
		-o-transform: translateZ(0);
		transform: translateZ(0);
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;
		animation-fill-mode: both;
	}

	.twoPanelSlideRight-leave-active,
	.twoPanelSlideLeft-leave-active
	{
		position: absolute;
	}

	.twoPanelSlideLeft-enter
	{
		transform: translate3d(100%, 0, 0);
	}

	.twoPanelSlideLeft-leave-to
	{
		transform: translate3d(-100%, 0, 0);
		opacity: 0;
	}

	.twoPanelSlideRight-enter
	{
		transform: translate3d(-100%, 0, 0);
	}

	.twoPanelSlideRight-leave-to
	{
		transform: translate3d(100%, 0, 0);
		opacity: 0;
	}
</style>