import { CallServer } from "tdsAppRoot/API/apibase.js";

var MyStatrefLoginParams = function ()
{
	var email;
	var pass;
};
Object.seal(MyStatrefLoginParams);

var MyStatrefRegisterParams = function ()
{
	var firstName;
	var lastName;
	var email;
	var pass;
	var recaptchaToken;
};
Object.seal(MyStatrefRegisterParams);

var MyStatrefDetailsSaveParams = function ()
{
	var firstName;
	var lastName;
	var email;
	var currentPass;
	var newPass;
	var repeatNewPass;
	var recaptchaToken;
};
Object.seal(MyStatrefDetailsSaveParams);

var MyStatrefTemporaryLoginActivateRequest = function ()
{
	var recaptchaToken;
};
Object.seal(MyStatrefTemporaryLoginActivateRequest);

var SearchSetBasicRequest = function ()
{
	var name;
};
Object.seal(SearchSetBasicRequest);

var SearchSetRenameRequest = function ()
{
	var oldName;
	var newName;
};
Object.seal(SearchSetRenameRequest);

var SearchSetUpdateRequest = function ()
{
	var name;
	var productIds;
};
Object.seal(SearchSetUpdateRequest);

var SetProfileSettingsRequest = function ()
{
	var settings; // MySRProfileSettings object (defined only on server, sent by server, modified by client, sent back to server)
};
Object.seal(SetProfileSettingsRequest);

var SetToolOrderRequest = function ()
{
	var toolOrder; // array of string (componentKeys)
};
Object.seal(SetToolOrderRequest);

var SetGroupDefaultToolSettingsRequest = function ()
{
	var toolOrder; // array of string (componentKeys)
	var customTitles; // array of int (fxids)
};
Object.seal(SetGroupDefaultToolSettingsRequest);

var DeleteProfileWithCredentialsRequest = function ()
{
	var user; // string
	var pass; // string
};
Object.seal(DeleteProfileWithCredentialsRequest);
export function MSRLogin(user, pass, store)
{
	try
	{
		var args = new MyStatrefLoginParams();
		args.email = user;
		args.pass = pass;
		return CallServer("MyStatref", "Login", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function MSRLogout(store)
{
	try
	{
		return CallServer("MyStatref", "Logout", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function MSRRegister(firstName, lastName, email, pass, recaptchaToken, store)
{
	try
	{
		var args = new MyStatrefRegisterParams();
		args.firstName = firstName;
		args.lastName = lastName;
		args.email = email;
		args.pass = pass;
		args.recaptchaToken = recaptchaToken;
		return CallServer("MyStatref", "Register", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

/**
 * Get the current profile data for the session from the server.
 * Useful when the profile has changed on the server from another browser tab.
 * @param {any} store
 */
export function GetCurrentMSRProfile(store)
{
	try
	{
		return CallServer("MyStatref", "GetCurrent", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function MSRDetailsSave(store, firstName, lastName, email, currentPass, newPass, repeatNewPass, recaptchaToken)
{
	try
	{
		var args = new MyStatrefDetailsSaveParams();
		args.firstName = firstName;
		args.lastName = lastName;
		args.email = email;
		args.currentPass = currentPass;
		args.newPass = newPass;
		args.repeatNewPass = repeatNewPass;
		args.recaptchaToken = recaptchaToken;
		return CallServer("MyStatref", "DetailsSave", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function MSRTemporaryLoginStatus(store)
{
	try
	{
		return CallServer("MyStatref", "TemporaryLoginStatus", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function MSRTemporaryLoginActivate(store, recaptchaToken)
{
	try
	{
		var args = new MyStatrefTemporaryLoginActivateRequest();
		args.recaptchaToken = recaptchaToken;
		return CallServer("MyStatref", "TemporaryLoginActivate", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function SearchSetGetAllNames(store)
{
	try
	{
		return CallServer("MyStatref", "SearchSetGetAllNames", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SearchSetAdd(store, name)
{
	try
	{
		var args = new SearchSetBasicRequest();
		args.name = name;
		return CallServer("MyStatref", "SearchSetAdd", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SearchSetDelete(store, name)
{
	try
	{
		var args = new SearchSetBasicRequest();
		args.name = name;
		return CallServer("MyStatref", "SearchSetDelete", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SearchSetRename(store, oldName, newName)
{
	try
	{
		var args = new SearchSetRenameRequest();
		args.oldName = oldName;
		args.newName = newName;
		return CallServer("MyStatref", "SearchSetRename", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SearchSetGet(store, name)
{
	try
	{
		var args = new SearchSetBasicRequest();
		args.name = name;
		return CallServer("MyStatref", "SearchSetGet", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function SearchSetUpdate(store, name, productIds)
{
	try
	{
		var args = new SearchSetUpdateRequest();
		args.name = name;
		args.productIds = productIds;
		return CallServer("MyStatref", "SearchSetUpdate", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function GetProfileSettings(store)
{
	try
	{
		return CallServer("MyStatref", "GetProfileSettings", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function SetProfileSettings(store, settings)
{
	try
	{
		var args = new SetProfileSettingsRequest();
		args.settings = settings;
		return CallServer("MyStatref", "SetProfileSettings", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function SetToolOrder(store, toolOrder)
{
	try
	{
		var args = new SetToolOrderRequest();
		args.toolOrder = toolOrder;
		return CallServer("MyStatref", "SetToolOrder", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function SetGroupDefaultToolSettings(store, toolOrder, customTitles)
{
	try
	{
		var args = new SetGroupDefaultToolSettingsRequest();
		args.toolOrder = toolOrder;
		args.customTitles = customTitles;
		return CallServer("MyStatref", "SetGroupDefaultToolSettings", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function DeleteGroupDefaultToolSettings(store)
{
	try
	{
		return CallServer("MyStatref", "DeleteGroupDefaultToolSettings", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function ReportRecaptchaLoadFailure(store)
{
	try
	{
		return CallServer("MyStatref", "ReportRecaptchaLoadFailure", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export function DeleteAccount(store)
{
	try
	{
		return CallServer("MyStatref", "DeleteAccount", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function DeleteProfileWithCredentials(store, user, pass)
{
	try
	{
		var args = new DeleteProfileWithCredentialsRequest();
		args.user = user;
		args.pass = pass;
		return CallServer("MyStatref", "DeleteProfileWithCredentials", args, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}