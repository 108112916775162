import VueRouter from 'vue-router';
import { DefaultErrorHandler } from 'tdsAppRoot/library/ErrorReporter.js';

/**
 Call this at the top of router index scripts, before Vue.use(VueRouter).  It will override VueRouter.prototype.push and make its promise not reject.
 */
export function OverrideRouterPush()
{
	const originalPush = VueRouter.prototype.push;
	VueRouter.prototype.push = function push(location)
	{
		return originalPush.call(this, location).catch((err) =>
		{
			if (VueRouter.isNavigationFailure(err))
				console.log("Router navigation silent failure: ", err); // We don't need to notify about these.
			else
			{
				let message = typeof err === "string" ? err : err.message;
				if (!message)
					message = "";
				if (message === "The operation is insecure.")
					DefaultErrorHandler("Navigation failed: " + message);
				else
					DefaultErrorHandler(err);
			}
		});
	};
}