var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mfaPopup",
      attrs: {
        role: "dialog",
        "aria-describedby": "mfaInterfacePopup",
        "aria-label": "Multi-Factor Authentication.  Press escape to cancel."
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: { display: "none" },
          attrs: { id: "mfaInterfacePopup" }
        },
        [
          _vm._v(
            "\n\t\tMulti-Factor Authentication.  Press escape to cancel.\n\t"
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "MFAInterface",
        _vm._b(
          {
            ref: "mfaInterface",
            on: { close: _vm.DefaultClose, submit: _vm.Submitted }
          },
          "MFAInterface",
          _vm.$attrs,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }