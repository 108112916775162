<template>
	<div ref="rootRef" :class="toastClasses" @mouseenter="onMouseEnter" v-bind:style="toastStyle" @mousedown="StopProp" @click="StopProp">
		<vsvg class="closeBtn" role="button" :isPresentation="false" aria-label="Close message" sprite="#x" title="Close Message" desc="" tabindex="0" 
			  @click="$emit('close')" @keypress.enter.space.prevent="$emit('close')" />
		<component v-if="args.contentComponent"
				   :is="args.contentComponent"
				   v-bind="args.contentProps"
				   @close="onCloseRequested"
				   class="contentComponent" />
		<div v-else class="message" role="alert">{{args.message}}</div>
		<vsvg v-if="args.type === 'error'" class="errorIcon" role="presentation" aria-hidden="true" sprite="#error_outline" title="" desc="" />
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/x.svg";
	import svg2 from 'tdsAppRoot/images/sprite/error_outline.svg';

	export default {
		props:
		{
			args: {
				required: true
			}
		},
		data()
		{
			return {
				selfDismissTimeout: null
			};
		},
		mounted()
		{
			if (this.$refs.rootRef)
				this.$refs.rootRef.component = this;
			if (this.args.boundLifecycleComponent)
			{
				this.args.boundLifecycleComponent.$on(["hook:beforeDestroy", "closeToasts"], () =>
				{
					this.$emit('close', false);
				});
			}
			if (typeof this.args.timeout === "number" && this.args.timeout > 0)
			{
				this.selfDismissTimeout = setTimeout(() =>
				{
					this.$emit('close', false);
				}, this.args.timeout);
			}
		},
		destroyed()
		{
			clearTimeout(this.selfDismissTimeout);
		},
		methods:
		{
			onMouseEnter()
			{
				clearTimeout(this.selfDismissTimeout);
			},
			onCloseRequested(arg)
			{
				this.$emit('close', arg);
			},
			StopProp: function (event)
			{
				event.stopPropagation();
			},
		},
		computed:
		{
			toastClasses()
			{
				let classes = ["toast", "customScroll1"];
				if (this.args.type === "error")
					classes.push("toast_error");
				if (this.args.type === "success")
					classes.push("toast_success");
				if (this.args.type === "info")
					classes.push("toast_info");
				return classes;
			},
			toastStyle()
			{
				let style = {};
				if (typeof this.args.opacity === "number")
					style.opacity = this.args.opacity;
				return style;
			}
		}
	}
</script>

<style scoped>
	.toast
	{
		position: relative;
		background-color: #FFFFFF;
		border: 1px solid #999999;
		border-radius: 5px;
		max-width: 400px;
		max-height: 200px;
		overflow: auto;
		box-shadow: 2px 2px 10px rgba(0,0,0,1);
		margin: 10px;
	}

		.toast:hover
		{
			transition: all 0ms;
			opacity: 1;
		}


	.toast_info
	{
		border-color: #757575;
		background-color: #474747;
		font-size: 12pt;
		color: #EEEEEE;
		fill: #EEEEEE;
		min-width: 192px;
		min-height: 96px;
	}

	.toast_error
	{
		border-color: #BD362F;
		background-color: #BD362F;
		font-size: 12pt;
		color: #EEEEEE;
		fill: #EEEEEE;
		min-width: 192px;
		min-height: 96px;
		opacity: 0.8;
	}

	.toast_success
	{
		border-color: #0EC40E;
		background-color: #0EC40E;
		font-size: 12pt;
		color: #000000;
		fill: #000000;
		min-width: 192px;
		min-height: 96px;
		opacity: 0.8;
	}

		.toast_error.customScroll1::-webkit-scrollbar-thumb,
		.toast_success.customScroll1::-webkit-scrollbar-thumb
		{
			background: rgba(255,255,255,0.25);
		}

	.errorIcon
	{
		width: 50px;
		height: 50px;
		position: absolute;
		right: 10px;
		bottom: 10px;
		opacity: .5;
	}

	.contentComponent
	{
		padding: 4px;
	}

	.message
	{
		padding: 4px 8px;
	}

	.closeBtn
	{
		float: right;
		width: 24px;
		height: 24px;
		padding: 2px;
		cursor: pointer;
		border: 2px solid transparent;
		outline: none;
	}

		.closeBtn:focus-visible
		{
			border: 2px solid black;
		}
		.closeBtn.focus-visible
		{
			border: 2px solid black;
		}

		.closeBtn:hover
		{
			background-color: rgba(255,255,255,0.1);
		}

		.closeBtn:active
		{
			background-color: rgba(255,255,255,0.2);
		}
</style>