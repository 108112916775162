var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "root",
      staticClass: "emailLinkRoot",
      attrs: {
        role: "dialog",
        "aria-label": "Email a Link.  Press escape to close."
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "modalDialogCloseButton",
          style: _vm.dynCloseBtnStyle,
          attrs: { tabindex: "0", role: "button", "aria-label": "Close" },
          on: {
            click: _vm.DefaultClose,
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "space", 32, $event.key, [
                  " ",
                  "Spacebar"
                ]) &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.DefaultClose.apply(null, arguments)
            }
          }
        },
        [
          _c("vsvg", {
            attrs: {
              sprite: "#x",
              title: "",
              desc: "",
              role: "presentation",
              "aria-hidden": "true"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "topBar" }, [
        _c("div", { staticClass: "title", attrs: { id: "emailLinkTitle" } }, [
          _vm._v("\n\t\t\tEmail a Link\n\t\t")
        ]),
        _vm._v(" "),
        _c("div", {
          staticClass: "path",
          domProps: { innerHTML: _vm._s(_vm.path) }
        })
      ]),
      _vm._v(" "),
      _c("div", { ref: "emailForm", staticClass: "emailForm" }, [
        _c("div", { staticClass: "formRow" }, [
          _c("span", { staticClass: "label" }, [_vm._v("Sent By:")]),
          _c("span", { staticClass: "sender formRight" }, [
            _vm._v(
              _vm._s(_vm.profileName) + " (" + _vm._s(_vm.profileEmail) + ")"
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "formRow" }, [
          _c("label", { staticClass: "label", attrs: { for: "emailLinkTo" } }, [
            _vm._v("Recipient's Address:")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.toEmail,
                expression: "toEmail"
              }
            ],
            ref: "to",
            staticClass: "formRight toInput",
            attrs: {
              type: "text",
              id: "emailLinkTo",
              "aria-describedby": "emailLinkToValid",
              name: "email_to",
              validateData: "toEmail",
              "data-vv-as": "Recipient's Address",
              validatespec: "required",
              maxlength: "250",
              "aria-invalid": _vm.validator.has("email_to") ? "true" : null
            },
            domProps: { value: _vm.toEmail },
            on: {
              keydown: [
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                },
                function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.OnSendClick.apply(null, arguments)
                }
              ],
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.toEmail = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "formRow" }, [
          _c(
            "label",
            { staticClass: "label", attrs: { for: "emailLinkMsg" } },
            [_vm._v("Message (optional):")]
          ),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            staticClass: "formRight messageInput",
            attrs: {
              rows: "5",
              type: "text",
              id: "emailLinkMsg",
              maxlength: "2000"
            },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { class: _vm.lastRow }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.validator.has("email_to"),
                  expression: "validator.has('email_to')"
                }
              ],
              staticClass: "validationWarning",
              attrs: { id: "emailLinkToValid" }
            },
            [
              _vm._v(
                "\n\t\t\t\t" +
                  _vm._s(_vm.validator.first("email_to").msg) +
                  "\n\t\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "formRight" }, [
            _c(
              "button",
              {
                staticClass: "sendBtn primaryColorButton inputButton",
                on: { click: _vm.OnSendClick }
              },
              [_vm._v("Send")]
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "warnings" }, [
        _c("div", { staticClass: "warningsHeading" }, [_vm._v("Warnings")]),
        _vm._v(" "),
        _c("div", [
          _vm._v("1. Content updates may cause link targets to be lost.")
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "2. Login authentication requires a valid IP address for this subscription. If used from an invalid IP address, a username and password will be requested."
          )
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v("3. Maximum of three recipients (separated by semicolons).")
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "4. If you want to create links that use a proxy server, simply create those links while connected to " +
              _vm._s(_vm.appContext.appName) +
              " through that proxy server."
          )
        ])
      ]),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.sending, msg: "Sending…" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }