<template>
	<div class="searchSetEditRoot" role="dialog" :aria-label="'Search set editor: ' + searchSetName + '. Press escape to close.'">
		<div class="normalBody">
			<div class="heading">
				<div ref="closeBtn" tabindex="0" role="button" aria-label="Save and Close" class="closeButton" @click="readyToClose" @keypress.enter.space.stop.prevent="readyToClose" title="Save and Close"><vsvg sprite="#save" title="" desc="" role="presentation" aria-hidden="true" /></div>
				<div class="title">{{searchSetName}}</div>
				<div class="headerButtonContainer">
					<span class="headerButton" tabindex="0" @click="selectAll" role="button" @keydown.enter.space.prevent="selectAll">Select {{selectAllMode ? "All" : "None"}} <vsvg class="downArrow" sprite="#arrow_down_thin" title="" desc="" role="presentation" aria-hidden="true" /></span> <span class="saveHelp">(Changes are saved when you close this panel)</span>
				</div>
			</div>
			<div class="loading" v-if="loading">
				<ScaleLoader class="animation" />
				<div class="loadingtxt">Loading</div>
			</div>
			<div class="error" v-else-if="error">{{error}}</div>
			<template v-else>
				<div class="allProducts" v-if="allProducts && allProducts.length > 0">
					<div class="product" v-for="(product, index) in allProducts" :key="product.id">
						<label><input type="checkbox" class="searchSetCb" :id="'searchSet' + searchSetName + '_' + index" :index="index" v-model="product.selected" @keydown.up.prevent="KeyUp($event)" @keydown.down.prevent="KeyDown($event)" @keydown.enter.prevent="product.selected = !product.selected"" /> {{product.name}}</label>
					</div>
				</div>
				<div class="allProducts" v-else>
					<div class="error">No titles are available to be added to this search set.</div>
				</div>
			</template>
		</div>
		<FullScreenLoadingMsg :show="committingChanges" msg="Saving Changes"></FullScreenLoadingMsg>
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/arrow_down_thin.svg";
	import svg2 from "tdsAppRoot/images/sprite/save.svg";

	import { SearchSetGet, SearchSetUpdate } from 'tdsAppRoot/API/MyStatrefAPI.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import { ModalConfirmDialog } from "tdsAppRoot/library/ModalDialog.js";
	import ScaleLoader from 'tdsAppRoot/library/3rdParty/ScaleLoader.vue';
	import { ReportError } from "tdsAppRoot/library/ErrorReporter.js";
	import { MakeToast } from 'tdsAppRoot/library/Toast.js';
	import { ScreenReaderReadText, MoveFocus } from 'tdsAppRoot/library/TDSUtil.js';

	export default {
		components: { ScaleLoader, FullScreenLoadingMsg },
		props:
		{
			searchSetName: {
				type: String,
				default: null
			},
			localTitleSet: null
		},
		data()
		{
			return {
				originalProducts: [],
				allProducts: [],
				mySearchsetName: "Loading",
				loading: false,
				error: null,
				committingChanges: false
			};
		},
		mounted()
		{
			this.loading = true;
			if (this.localTitleSet)
			{
				let serialized = JSON.stringify(this.localTitleSet); // Make copies of the input array
				this.originalProducts = JSON.parse(serialized);
				this.allProducts = JSON.parse(serialized);
				this.mySearchsetName = this.searchSetName;
				this.loading = false;
			}
			else
			{
				SearchSetGet(this.$store, this.searchSetName).then(data =>
				{
					// data.allProducts // [{ id: 1, name: "Name 1" }, { id: 2, name: "Name 2" }, …]
					// data.searchSet // {id: 2337, name: "Test Searchset 1", productIds: [1,4,7,3,6,5] }
					for (let i = 0; i < data.allProducts.length; i++)
					{
						let p = data.allProducts[i];
						p.selected = data.searchSet.productIds.indexOf(p.id) > -1;
					}
					this.originalProducts = JSON.parse(JSON.stringify(data.allProducts)); // Make a copy of the entire allProducts array so that we can compare with it later to detect if changes were made.
					this.allProducts = data.allProducts;
					this.mySearchsetName = data.searchSet.name;
				}
				).catch(err =>
				{
					if (err.name === "ApiError")
						this.error = err.message;
					else
					{
						ReportError(this.$store.getters.urlRoot, err.message, null, err, this.$store.getters.sid);
						this.error = "An unexpected error occurred. Technical support has been notified";
					}
				}
				).finally(() =>
				{
					this.loading = false;
				});
			}
		},
		methods:
		{
			selectAll()
			{
				if (!this.allProducts || this.allProducts.length === 0)
					return;
				let select = this.selectAllMode;
				for (let i = 0; i < this.allProducts.length; i++)
					this.allProducts[i].selected = select;
				if (select)
					ScreenReaderReadText("Selected all titles.  Press again to unselect all titles.");
				else
					ScreenReaderReadText("Unselected all titles.  Press again to select all titles.");
			},
			readyToClose()
			{
				// Were changes made?
				if (this.originalProducts.length === 0)
				{
					this.$emit("close", false);
					return;
				}
				let changesMade = false;
				let productIds = [];
				for (let i = 0; i < this.allProducts.length; i++)
				{
					if (this.originalProducts[i].selected !== this.allProducts[i].selected)
					{
						changesMade = true;
					}
					if (this.allProducts[i].selected)
						productIds.push(this.allProducts[i].id);
				}
				if (!changesMade)
				{
					this.$emit("close", false);
					return;
				}

				// Changes were made. Send the current selected product IDs as an argument to close only if the changes are saved.
				if (this.localTitleSet)
				{
					this.$emit("close", productIds);
				}
				else
				{
					// Try to save the changes.
					this.committingChanges = true;
					SearchSetUpdate(this.$store, this.searchSetName, productIds).then(data =>
					{
						this.$store.dispatch("ResetBookshelfCategories");
						MakeToast({ message: "Search Set Saved", type: "success", timeout: 4000 });
						this.$emit("close", productIds);
					}
					).catch(err =>
					{
						let errMsg = "Do you want to try again or discard your changes?";
						if (err.name === "ApiError")
							errMsg = 'Saving failed because "' + err.message + '". Do you want to try again or discard your changes?';
						let args = { message: errMsg, title: "Saving Failed", yesText: "Try Again", noText: "Discard", cancelMeansYes: true };
						ModalConfirmDialog(args).then(result =>
						{
							if (result)
								this.readyToClose();
							else
								this.$emit("close", false);
						});
					}
					).finally(() =>
					{
						this.committingChanges = false;
					});
				}
			},
			DefaultClose()
			{
				this.readyToClose();
			},
			SetFocus()
			{
				if (this.$refs.closeBtn && this.$refs.closeBtn.focus)
					this.$refs.closeBtn.focus();
			},
			KeyUp(event)
			{
				MoveFocus(event, -1, this.allProducts.length, 'searchSet' + this.searchSetName + '_');
			},
			KeyDown(event)
			{
				MoveFocus(event, 1, this.allProducts.length, 'searchSet' + this.searchSetName + '_')
			}
		},
		computed:
		{
			selectAllMode()
			{
				if (!this.allProducts || this.allProducts.length === 0)
					return true;
				for (let i = 0; i < this.allProducts.length; i++)
				{
					if (!this.allProducts[i].selected)
						return true;
				}
				return false;
			}
		}
	};
</script>

<style scoped>
	.searchSetEditRoot
	{
		max-width: 90vw;
		max-height: 90vh;
		box-sizing: border-box;
		background-color: #FFFFFF;
		overflow: hidden;
	}

	.loading
	{
		padding: 10px 20px;
		text-align: center;
	}

	.error
	{
		margin: 20px;
	}

	.normalBody
	{
		display: flex;
		flex-direction: column;
		max-height: 90vh;
		min-width: 200px;
	}



	@media all and (-ms-high-contrast: none)
	{
		/* IE hack */

		.normalBody
		{
			overflow-y: auto;
		}
	}

	.heading
	{
		background-color: #EDEFF2;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: 1px solid #CDCDCD;
	}

	.closeButton
	{
		float: right;
		cursor: pointer;
		padding: 3px;
		border: 1px solid #BBBBBB;
		margin: 6px;
		background-color: rgba(255,255,255,0.5);
		box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
		fill: var(--primary-color);
	}

		.closeButton:focus
		{
			outline: none;
		}

		.closeButton:focus-visible
		{
			border: 2px solid black;
			padding: 2px;
		}
		.closeButton.focus-visible
		{
			border: 2px solid black;
			padding: 2px;
		}

		.closeButton:hover
		{
			background-color: rgba(255,255,255,1);
		}

		.closeButton svg
		{
			width: 32px;
			height: 32px;
		}

	.title
	{
		font-size: 18pt;
		padding: 10px;
		word-break: break-word;
		/*font-weight: bold;*/
	}

	.headerButtonContainer
	{
		padding: 0px 10px 10px 10px;
	}

	.headerButton
	{
		color: var(--primary-color-text);
		cursor: pointer;
		margin-left: 8px;
		padding: 0px 5px;
		user-select: none;
		border-radius: 3px;
		border: 2px solid transparent;
	}

		.headerButton svg.downArrow
		{
			margin: 0px 0px 2px 5px;
			fill: currentColor;
			width: 12px;
			height: 7px;
		}

		.headerButton:focus
		{
			outline: none;
		}

		.headerButton:focus-visible
		{
			border: 2px solid black;
		}
		.headerButton.focus-visible
		{
			border: 2px solid black;
		}

	.saveHelp
	{
		padding-left: 10px;
		color: #666666;
	}

	.allProducts
	{
		padding: 0px 10px 10px 10px;
		box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
		flex: 0 1 auto;
		max-height: 100%;
		overflow-y: auto;
	}

	.product
	{
		margin: 10px 0px;
	}

		.product > *:hover
		{
			background-color: rgba(255,255,255,0.25);
		}

		.product input[type="checkbox"]
		{
			vertical-align: text-bottom;
		}

	.searchSetCb:focus
	{
		outline: none;
	}
	.searchSetCb:focus-visible
	{
		outline: 1px solid black;
	}
	.searchSetCb.focus-visible
	{
		outline: 1px solid black;
	}

	@media (min-width: 600px)
	{
		.searchSetEditRoot
		{
			max-width: 80vw;
		}
	}

	@media (min-width: 800px)
	{
		.searchSetEditRoot
		{
			max-width: 640px;
		}
	}
</style>