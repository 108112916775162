import Vue from 'vue';
import { DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter.js";
import { GetDoc, ConfirmCache, LogDoc } from "tdsAppRoot/API/DocumentAPI.js";

var documentLoading = new Object() // Keeps track of which documents are currently loading, so we can avoid doing it twice.

// This state isn't persisted, unlike most state.  This exception is coded in the setState function in store.js.
const documentModule = {
	state()
	{
		return {
			documents: new Object(), // The document cache.  A map of searchid_docaddress on to DocumentResponse objects.
			docTabBarWidth: 0,
			currentFontSize: 12,
			showBib: false,
			docTBFloating: false,
			docShouldReload: false, // Flag watched by documents.  They reload if it becomes true, then they set it back to false.
			lastDocKey: null // Tracks the last doc key the user loaded, so we can prevent accidental duplicate logging.  Expected to be cleared on page reload.
		};
	},
	mutations: {
		SetCachedDocument: (state, { key, content }) =>
		{
			if (content)
				Vue.set(state.documents, key, content);
		},
		DeleteCachedDocument: (state, key) =>
		{
			if (state.documents[key])
				delete state.documents[key];
		},
		ClearDocumentCache: (state, payload) =>
		{
			state.documents = new Object();
		},
		InvalidateDocumentCache: (state, payload) =>
		{
			// Marks all cached documents as stale, but leaves the objects in the cache.  Since the currently viewed document requires its cache to exist in order to
			// function properly, this mutation allows us to ensure that the next time the document is loaded it is refereshed from the server, but
			// the document currently being viewed does not get broken.

			for (let i = 0; i < Object.keys(state.documents).length; i++)
			{
				let key = Object.keys(state.documents)[i];
				Vue.set(state.documents[key], "stale", true);
			}

		},
		ClearDocumentCacheNotSid: (state, sid) =>
		{
			for (let i = 0; i < Object.keys(state.documents).length; i++)
			{
				let key = Object.keys(state.documents)[i];
				if (!state.documents[key] || state.documents[key].docInfo.sid != sid)
				{
					delete state.documents[key];

					console.log("Clearing cache for sid " + sid);
				}
			}
		},
		SetShowBib: (state, payload) =>
		{
			state.showBib = payload;
		},
		SetDocShouldReload: (state, value) =>
		{
			state.docShouldReload = value;
		},
		DisableTocSyncForDocument: (state, payload) =>
		{
			if (payload)
			{
				console.log("Marking document " + payload + " toc sync disabled.");
				if (state.documents[payload])
					Vue.set(state.documents[payload], "tocSyncDisabled", true);
			}
		},
		SetDocTabBarWidth: (state, payload) =>
		{
			state.docTabBarWidth = payload;
		},
		RemoveDocumentFromCache: (state, payload) =>
		{
			Vue.set(state.documents, payload, null);
		},
		InvalidateDocCacheForTitle: (state, { fxid, clear }) =>
		{
			console.log((clear ? "Clearing" : "Invalidating") + " doc cache for " + fxid);

			let i = 0;
			for (i = 0; i < Object.keys(state.documents).length; i++)
			{
				let key = Object.keys(state.documents)[i];
				if (state.documents[key].docInfo.fxId == fxid)
				{
					if (clear)
						delete state.documents[key];
					else
						Vue.set(state.documents[key], "stale", true);
					console.log((clear ? "Clearing" : "Invalidating") + " cache for title " + fxid + " key " + key);
				}
			}
		},
		DecreaseFontSize: (state) =>
		{
			if (state.currentFontSize > 8)
				state.currentFontSize -= 1;
		},
		IncreaseFontSize: (state) =>
		{
			if (state.currentFontSize < 20)
				state.currentFontSize += 1;
		},
		ResetCurrentFontSize: (state) =>
		{
			state.currentFontSize = 12;
		},
		SetDocTBFloating: (state, floating) =>
		{
			state.docTBFloating = floating;
		},
		SetLastDocKey: (state, lastDocKey) =>
		{
			state.lastDocKey = lastDocKey;
		}
	},
	actions: {
		GetDocument({ commit, dispatch, state, rootState, rootGetters }, { docAddress, searchid, categoryType })
		{
			let altDocBuilder = false;
			if ((window && window.router && window.router.currentRoute && window.router.currentRoute.query
				&& window.router.currentRoute.query.altDocBuilder)
				|| appContext.docBuilder2)
				altDocBuilder = appContext.docBuilder2 || (window.router.currentRoute.query.altDocBuilder == "true");
			let docKey = searchid + "_" + categoryType + "_" + docAddress;
			let doc = state.documents[docKey];
			if (doc && !doc.stale/* && (docWidth <= 0 || docWidth == doc.docWidth)*/)
			{
				if (state.lastDocKey != docKey)
				{
					commit("SetLastDocKey", docKey);
					LogDoc(docAddress, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }).catch(DefaultErrorHandler);
				}
				return Promise.resolve(doc);
			}
			//else if (doc)
			//	commit("DeleteCachedDocument", docKey);
			//console.log("Doc cache miss.  Requesting doc from server.");
			commit("SetLastDocKey", docKey);
			let req = rootGetters.GetSearchRequest(searchid);
			let searchArgs = req ? req.args : null;
			if (documentLoading[docKey])
			{
				console.log("Aborting document load because this document is already being loaded: " + docKey);
				return Promise.resolve(null);
			}
			documentLoading[docKey] = setTimeout(() =>
			{
				documentLoading[docKey] = null;
			}, 3000);
			return GetDoc(docAddress, searchid, searchArgs, categoryType/*, docWidth*/, { state: rootState, getters: rootGetters, commit: commit, dispatch: dispatch }, altDocBuilder)
				.then(data =>
				{
					//console.log("Retreived document from server.");
					commit("SetCachedDocument", { key: docKey, content: data });
					clearTimeout(documentLoading[docKey]);
					documentLoading[docKey] = null;
					return Promise.resolve(data);
				}).catch(err =>
				{
					clearTimeout(documentLoading[docKey]);
					documentLoading[docKey] = null;
					if (err.name === "ApiError")
					{
						err.data.html = err.message;
						err.data.isError = true;
						return Promise.resolve(err.data);
					}
					return Promise.reject(err);
				});
		}
	},
	watchers:
		[
			[state => state.sid, (newValue, oldValue, store) =>
			{
				store.commit("ClearDocumentCacheNotSid", newValue);
				store.commit("SetDocShouldReload", true);
			}]
		]
};

export default documentModule;