/*
 * This file is referenced from WebpackPlugins/ExportSearchCategoryJsonPlugin.js, so it should not import anything unnecessary.
 */

/**
 * Returns an array of all defined search categories, even those which the current session is not allowed to search.
@returns {Array} Array of all defined search categories.
*/
export function GetAllSearchCategories()
{
	// Priority 2: High
	// Priority 1: Medium
	// Priority 0: Low
	// Items with "search: true" should have type strings which match enum values serverside, as they map directly to search result categories.
	// Items with "primary: true" should appear in their declared order as tabs on the search results page.
	// Items with "primary: false" should appear under the "More" tab on the search results page.
	// Items with "hide: true" will not be shown in the ResultTabs UI.
	// Items with "noasync: true" will not be requested in async result retrieval. To guarantee that the results are obtained, these must be given priority 2.
	let cats = [
		{
			type: "All"
			, sprite: "#textbooks"
			, search: true
			, priority: 2
			, primary: true // Primary items appear as a tab on the results page
			, mapDocAddresses: true
			, showInDropdown: true
		},
		{
			type: "Images"
			, sprite: "#photo"
			, search: true
			, priority: 2
			, primary: true
			, mapDocAddresses: true
			, showInDropdown: true
		},
		{
			type: "Dictionary"
			, icon: require("../images/dictionary.png")
			, search: true
			, priority: 2
			, hide: false // Do include in the normal results UI
			, primary: false
			, mapDocAddresses: true
			, showInDropdown: true
		},
		{
			type: "Media"
			, sprite: "#videosIcon"
			, search: true
			, priority: 2 // Was 0; changed to 2 so that all STAT!Ref results would load in the initial response.
			, primary: true
			, mapDocAddresses: true
			, showInDropdown: true
		},
		{
			type: "Alerts"
			, name: "Alerts"
			, sprite: "#evidenceIcon"
			, search: true
			, priority: 2
			, primary: true
			, showInDropdown: true
		},
		//{
		//	type: "EvidenceAlerts"
		//	, name: "Alerts"
		//	, sprite: "#evidenceIcon"
		//	, search: true
		//	, priority: 1
		//	, primary: true
		//	, showInDropdown: true
		//},
		//{
		//	type: "News" // NOTE: The "News" categoryType was removed 2023-04-25.
		//	, sprite: "#journalsIcon"
		//	, search: true
		//	, priority: 1
		//	, primary: true
		//	, showInDropdown: true
		//},
		{
			type: "More"
			, sprite: "#more_h"
			, search: false // This is not a search category.
			, priority: 0
			, primary: true
			, providesOwnContentComponent: true // Do not use ResultPager for this.
		},
		{
			type: "Tables"
			, sprite: "#table"
			, search: true
			, priority: 2 // Was 0; changed to 2 so that all STAT!Ref results would load in the initial response.
			, primary: false // Non-primary items appear under the "More" tab.
			, mapDocAddresses: true
		},
		{
			type: "EBMcalc"
			, icon: require("../images/EBMcalc_calc_opt.png")
			, search: true
			, priority: 0
			, primary: false
			, showInDropdown: true
		},
		{
			type: "PubMed"
			, name: "PubMed®"
			, sprite: "#pubmed_opt"
			, search: true
			, priority: 0
			, primary: false
		},
		{
			type: "MedlinePlus"
			, name: "MedlinePlus®"
			, icon: require("../images/medlineplus_plus_opt.png")
			, search: true
			, external: true // Results are web links to external sites
			, priority: 0
			, primary: false
		},
		{
			type: "PLOS"
			, name: "PLOS"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "CDCPB"
			, name: "CDC Pink Book"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "CDCYB"
			, name: "CDC Yellow Book"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "TCG"
			, name: "The Community Guide"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "MMW"
			, name: "Morbidity Mortality Weekly"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "VADODCPG"
			, name: "VA/DoD Clinical Practice Guidelines"
			, sprite: "#library_books"
			, search: true
			, external: true
			, priority: 0
			, primary: false
		},
		{
			type: "AnatomyTV"
			, name: "Anatomy.tv"
			, icon: require("../images/AtvHead_opt.jpg")
			, search: true
			, priority: 0
			, primary: false
			, showInDropdown: true
		},
		//{
		//	type: "DrugShortages"
		//	, name: "Drug Shortages"
		//	, icon: require("../images/pill_green.png")
		//	, search: true
		//	, priority: 1
		//	, primary: false
		//	, showInDropdown: true
		//},
		{
			type: "DrugInteractions"
			, name: "Drug Interactions"
			, icon: require("../images/adi/TMLADI_Logo76.png")
			, search: true
			, priority: 0
			, primary: false
			, showInDropdown: true
		},
		{
			type: "WileyContent"
			, name: "Wiley Visual Library"
			, icon: require("../images/WileyContent/WileyIcon.svg")
			, search: true
			, priority: 0
			, primary: false
		},
		{
			type: "Help"
			, name: "Help"
			, icon: require("../images/help76.jpg")
			, search: true
			, priority: 2 // Was 0; changed to 2 so that all STAT!Ref results would load in the initial response.
			, primary: false
			, mapDocAddresses: true
			, showInDropdown: true
		}
	];

	if (typeof appContext !== 'undefined' && appContext.dynamicSearchCategories)
	{
		for (let i = 0; i < appContext.dynamicSearchCategories.length; i++)
		{
			if (appContext.dynamicSearchCategories[i].icon && appContext.dynamicSearchCategories[i].icon[0] == '#')
				cats.push({
					type: appContext.dynamicSearchCategories[i].type
					, name: appContext.dynamicSearchCategories[i].name
					, sprite: appContext.dynamicSearchCategories[i].icon
					, search: true
					, priority: 0
					, primary: false
					, showInDropdown: true
				});
			else
				cats.push({
					type: appContext.dynamicSearchCategories[i].type
					, name: appContext.dynamicSearchCategories[i].name
					, icon: appContext.dynamicSearchCategories[i].icon
					, search: true
					, priority: 0
					, primary: false
					, showInDropdown: true
				});
		}
	}

	return cats;
}