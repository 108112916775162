var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "RecaptchaBadgeSubstitute" }, [
      _vm._v("\n\tThis site is protected by reCAPTCHA and the Google\n\t"),
      _c(
        "a",
        {
          attrs: {
            href: "https://policies.google.com/privacy",
            target: "_blank"
          }
        },
        [_vm._v("Privacy Policy")]
      ),
      _vm._v(" and\n\t"),
      _c(
        "a",
        {
          attrs: { href: "https://policies.google.com/terms", target: "_blank" }
        },
        [_vm._v("Terms of Service")]
      ),
      _vm._v(" apply.\n")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }