<template>
	<div :class="{ minorErrorIcon: true, show: queueLength > 0 }" tabindex="0" @click="showMinorErrorDialog">
		<vsvg class="errorIcon" role="presentation" :aria-label="queueLength + ' Recent Errors'" sprite="#error_outline" title="" desc="" />
		<div class="errorCount" aria-hidden="true">{{queueLength}}</div>
	</div>
</template>

<script>
	// One instance of this component should exist near the end of the root vue component.
	import svg1 from 'tdsAppRoot/images/sprite/error_outline.svg';
	import { ModalMinorErrorDialog } from 'tdsAppRoot/library/ModalDialog';
	import { ScreenReaderReadText } from '../../library/TDSUtil';

	export default {
		methods:
		{
			showMinorErrorDialog()
			{
				ModalMinorErrorDialog(this.$store.state.minorErrorQueue);
				this.$store.commit("ClearMinorErrorQueue");
			}
		},
		computed:
		{
			queueLength()
			{
				return this.$store.state.minorErrorQueue.length;
			}
		},
		watch:
		{
			queueLength(val)
			{
				if (val > 0)
				{
					ScreenReaderReadText(val + " recent errors");
				}
			}
		}
	}
</script>

<style scoped>
	.minorErrorIcon
	{
		position: fixed;
		bottom: 10px;
		right: 10px;
		z-index: 5000;
		background-color: rgba(189,54,47,0.67);
		border-radius: 5px;
		box-shadow: 2px 2px 10px rgba(0,0,0,1);
		opacity: 0;
		display: none;
		cursor: pointer;
	}

		.minorErrorIcon.show
		{
			transition: opacity 400ms;
			opacity: 1;
			display: block;
		}

		.minorErrorIcon:hover
		{
			background-color: rgba(189,54,47,0.8);
		}

		.minorErrorIcon:active
		{
			background-color: rgba(189,54,47,1);
		}

		.minorErrorIcon:focus-visible,
		.minorErrorIcon.focus-visible
		{
			outline: 2px solid black;
		}


	.errorIcon
	{
		width: 50px;
		height: 50px;
		fill: #CCCCCC;
	}

	.errorCount
	{
		position: absolute;
		top: 2px;
		right: 2px;
		padding: 0px 3px;
		font-size: 14px;
		font-weight: bold;
		background-color: #BD362F;
		color: #FFFFFF;
	}
</style>