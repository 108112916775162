<template>
	<div ref="tooltipRoot" class="tooltipRoot" v-if="everShowed && (persistContent || show)" v-show="show">
		<div class="popupRoot" v-bind:class="arrowClass" v-on:click="StopProp" v-bind:style="dynStyle">
			<div class="popupInner" v-on:mouseup="StopProp" :style="dynStyleInner">
				<div v-if="htmlContent !== null" class="content" :style="dynStyleContent" v-html="htmlContent">
				</div>
				<component v-else-if="contentComponent" :is="contentComponent" v-bind="contentProps" class="content" />
				<div v-else class="content">
					<slot></slot>
				</div>
			</div>
		</div>
		<VStyle>

			.popupRoot
			{
			width: {{tooltipWidth}};
			height: {{tooltipHeight}};
			}
		</VStyle>
	</div>
</template>

<script>
	import { FindOffsets, CalcTooltipPosition } from 'tdsAppRoot/library/TDSUtil.js';
	import VStyle from 'tdsAppRoot/components/Controls/VStyle.vue';

	export default {
		components: { VStyle },
		props:
		{
			htmlContent: {
				type: String,
				default: null
			},
			persistContent: { // If true, the tooltip content will remain in the DOM, hidden, when the tooltip is hidden.
				type: Boolean,
				default: true
			},
			parentLevel: { // If > 0, the tooltip will attach to the ancestor this many levels up.  Affects event listeners and offset calculation only. If <= 0, the tooltip will attach to the parent component's root element.
				type: Number,
				default: 0
			},
			disableAutomaticOpen: {
				type: Boolean,
				default: false
			},
			tiny: { // Sets some CSS to accommodate small tooltips.
				type: Boolean,
				default: false
			},
			locationLeft: {		// If this and locationTop are both >= 0, the top left of the tooltip will be placed at these coordinates instead of attaching to a dom object.
				type: Number,
				default: -1
			},
			locationTop: {
				type: Number,
				default: -1
			},
			contentComponent: null,
			contentProps: null
		},
		data()
		{
			return {
				everShowed: false,
				show: false,
				top: 0,
				left: 0,
				arrowClass: "arrowBottom",
				myTimeout: null,
				showDelay: 750,
				hideDelay: 750,
				offsetRootNoe: null,
				tooltipWidth: "300px",
				tooltipHeight: "250px"
			};
		},
		methods:
		{
			StopProp(event)
			{
				event.stopPropagation();
			},
			RecalcPosition()
			{
				if (this.locationLeft > 0 || this.locationTop > 0)
				{
					this.top = this.locationTop;
					this.left = this.locationLeft;
				}
				else
				{
					if (!this.offsetRootNode || !this.offsetRootNode.offsetParent)
						return;
					let pos = CalcTooltipPosition(this.offsetRootNode, this.offsetRootNode.offsetParent, null);

					this.top = pos.top;
					this.left = pos.left;
					this.arrowClass = pos.arrowClass;
				}
				
			},
			mouseEnter(e)
			{
				clearTimeout(this.myTimeout);
				if (this.offsetRootNode && this.offsetRootNode.offsetParent)
				{

					if (!this.show)
					{
						this.myTimeout = setTimeout(() =>
						{
							this.Show();
							setTimeout(() =>
							{
								if (this.$refs.tooltipRoot)
									this.$refs.tooltipRoot.component = this;
							}, 0);
						}, this.showDelay);
					}
				}
			},
			Show()
			{
				// This is normally called automatically when the user holds the mouse over the node at [parentLevel].
				// See the mounted function for how that works.
				// Calling this directly instantly creates the tooltip from script.
				// When called directly, the tooltip must also be closed directly or it will not go away!

				this.show = true;
				this.everShowed = true;
				this.$emit('show', this);
				this.myTimeout = null;
				this.RecalcPosition();



			},
			Close()
			{
				this.show = false;
				this.$emit('hide', this);
				this.myTimeout = null;
			},
			mouseLeave(e)
			{
				clearTimeout(this.myTimeout);
				if (this.show)
				{
					this.myTimeout = setTimeout(() =>
					{
						this.Close();
					}, this.hideDelay);
				}
			},
			click(e)
			{
				clearTimeout(this.myTimeout);
				this.show = false;
			},
			scroll(e)
			{
				if (!this.show)
					clearTimeout(this.myTimeout);
			}
		},
		mounted()
		{
			if (this.tiny)
			{
				this.tooltipWidth = "auto";
				this.tooltipHeight = "auto";
			}
			let p = null;
			if (this.parentLevel > 0)
			{
				p = this.$el;
				for (let i = 0; i < this.parentLevel; i++)
					p = p.parentNode;
				this.offsetRootNode = p;

			}
			else
			{
				p = this.$parent;
				while (p && p.isTooltipProxy)
					p = p.$parent;
				this.offsetRootNode = p.$el;

			}

			if (!this.disableAutomaticOpen && this.offsetRootNode)
			{
				this.offsetRootNode.addEventListener('mouseenter', this.mouseEnter);
				this.offsetRootNode.addEventListener('mouseleave', this.mouseLeave);
				this.offsetRootNode.addEventListener('click', this.click);
			}
			if (window)
				window.addEventListener('scroll', this.scroll);

		},
		beforeDestroy()
		{
			try
			{
				if (this.offsetRootNode)
				{
					this.offsetRootNode.removeEventListener('mouseenter', this.mouseEnter);
					this.offsetRootNode.removeEventListener('mouseleave', this.mouseLeave);
					this.offsetRootNode.removeEventListener('click', this.click);
				}
				if (window)
					window.removeEventListener('scroll', this.scroll);
			}
			catch (ex) { }
		},
		computed:
		{
			dynStyle()
			{
				return {
					top: this.top,
					left: this.left
				};
			},
			dynStyleInner()
			{
				if (this.tiny)
					return {
						overflow: "hidden"
					};
				else
					return null;
			},
			dynStyleContent()
			{
				if (this.tiny)
					return null;

				return {
					paddingRight: "5px"
				}
			}
		}
	}
</script>

<style scoped>
	.popupRoot
	{
		position: absolute;
		border: 1px solid #808080;
		border-radius: 10px;
		background-color: white;
		z-index: 2010;
		padding: 10px;
		box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
	}

	.popupInner
	{
		width: 100%;
		height: 100%;
		overflow: auto;
	}

	.content
	{
		font-size: 12pt;
	}

	/* Arrow CSS */
	.arrowTopRight::before, .arrowTopLeft::before, .arrowBottomRight::before, .arrowBottomLeft::before
	{
		border: 28px solid transparent;
		border-right-color: white;
		margin-left: -28px;
		z-index: 2012;
	}

	.arrowTopRight::after, .arrowTopLeft::after, .arrowBottomRight::after, .arrowBottomLeft::after
	{
		border: 29px solid transparent;
		margin-left: -29px;
		z-index: 2011;
	}

	.arrowTopLeft::before, .arrowBottomLeft::before
	{
		left: 1px;
		border-left: 0;
		border-right-color: white;
	}

	.arrowTopRight::before, .arrowBottomRight::before
	{
		right: -27px;
		border-right: 0;
		border-left-color: white;
	}

	.arrowTopLeft::after, .arrowBottomLeft::after
	{
		left: 0;
		border-left: 0;
		border-right-color: #808080;
	}

	.arrowTopRight::after, .arrowBottomRight::after
	{
		right: -29px;
		border-right: 0;
		border-left-color: #808080;
	}

	.arrowTopRight::before, .arrowTopLeft::before, .arrowBottomRight::before, .arrowBottomLeft::before,
	.arrowTopRight::after, .arrowTopLeft::after, .arrowBottomRight::after, .arrowBottomLeft::after
	{
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}

	/* The :after selector is for the border of the arrow. */
	.arrowBottomLeft::after, .arrowBottomRight::after
	{
		bottom: 20px;
		border-bottom: 0;
		margin-top: 0px;
	}

	.arrowTopLeft::after, .arrowTopRight::after
	{
		top: 20px;
		border-top: 0;
		margin-top: 0px;
	}
	/* The :before selector is for the background of the arrow. */
	.arrowBottomLeft::before, .arrowBottomRight::before
	{
		bottom: 21px;
		border-bottom: 0;
	}

	.arrowTopLeft::before, .arrowTopRight::before
	{
		top: 21px;
		border-top: 0;
	}

	.arrowAboveCenter:after,
	.arrowAboveCenter:before
	{
		bottom: 270px;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
	}

	.arrowAboveCenter:after
	{
		border-bottom-color: white;
		border-width: 27px;
		margin-left: -27px;
	}

	.arrowAboveCenter:before
	{
		border-bottom-color: #808080;
		border-width: 28px;
		margin-left: -28px;
	}

	.arrowBelowCenter:after,
	.arrowBelowCenter:before
	{
		top: 270px;
		left: 50%;
		border: solid transparent;
		content: " ";
		height: 0;
		width: 0;
		position: absolute;
	}

	.arrowBelowCenter:after
	{
		border-top-color: white;
		border-width: 27px;
		margin-left: -27px;
	}

	.arrowBelowCenter:before
	{
		border-top-color: #808080;
		border-width: 28px;
		margin-left: -28px;
	}
</style>