import { CallServer } from "tdsAppRoot/API/apibase.js";

// Mirrors TitleInfoRequest on server
var TitleInfoRequest = function ()
{
	var fxid;
};
Object.seal(TitleInfoRequest);

export function GetTitleInfo(store, fxid)
{
	try
	{
		let myargs = new TitleInfoRequest();
		myargs.fxid = fxid;
		return CallServer("TitleInfo", null, myargs, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}