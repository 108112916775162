import { CallServer } from "tdsAppRoot/API/apibase.js";

var GetAuthOptions = function (store)
{
	try
	{

		return CallServer("LinkGen", "AuthOptions", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

var GetUserGroupId = function (store)
{
	try
	{

		return CallServer("LinkGen", "GetUserGroupId", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

var GetDictInfo = function (store)
{
	try
	{

		return CallServer("LinkGen", "GetDictInfo", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

export { GetAuthOptions, GetUserGroupId, GetDictInfo };