/**
 * Constructor for the Queue class, based on code.stephenmorley.org. A queue is a first-in-first-out (FIFO) data structure - items are added to the end of the queue and removed from the front.
 */
export function Queue()
{
	var queue = [];
	var offset = 0;

	/**
	 * Returns the length of the queue.
	 * @returns {Number} The length of the queue.
	 */
	this.getLength = function ()
	{
		return queue.length - offset;
	};

	/**
	 * Returns true if the queue is empty, and false otherwise.
	 * @returns {Boolean} true if the queue is empty, and false otherwise.
	 */
	this.isEmpty = function ()
	{
		return (queue.length === 0);
	};

	/**
	 * Enqueues the specified item.
	 * @param {Object} item the item to enqueue
	 */
	this.enqueue = function (item)
	{
		queue.push(item);
	};

	/**
	 * Dequeues an item and returns it. If the queue is empty, undefined is returned.
	 * @returns {Object} the dequeued object or undefined
	 */
	this.dequeue = function ()
	{
		if (queue.length === 0)
			return undefined;

		// store the item at the front of the queue
		var item = queue[offset];

		// increment the offset and remove the free space if necessary
		if (++offset * 2 >= queue.length)
		{
			queue = queue.slice(offset);
			offset = 0;
		}

		return item;
	};

	/**
	 * Returns the item at the front of the queue (without dequeuing it). If the queue is empty then undefined is returned.
	 * @returns {Object} the item at the front of the queue (without dequeuing it). If the queue is empty then undefined is returned.
	 */
	this.peek = function ()
	{
		return queue.length > 0 ? queue[offset] : undefined;
	};

	/**
	 * Replaces the item at the front of the queue with a different item. If the queue is empty then this method behaves like [enqueue].
	 * @param {Object} newFront The item which should be the new front of the queue.
	 */
	this.replaceFront = function (newFront)
	{
		if (queue.length > 0)
			queue[offset] = newFront;
		else
			queue.push(newFront);
	};

	/**
	 * Returns the contents of the queue as an array where the first item in the queue is the first item in the array.
	 * @returns {Array} the contents of the queue as an array where the first item in the queue is the first item in the array.
	 */
	this.toArray = function ()
	{
		var len = (queue.length - offset);
		var arr = new Array(len);
		var n = 0;
		for (var i = offset; i < queue.length; i++)
			arr[n++] = queue[i];
		return arr;
	};

	/**
	 * Passes each queued item to the specified function and returns the first item where the function returns true.
	 * @param {Function} where The function to call, passing a queue item as the argument
	 * @returns {Object} the item which caused the function to return true
	 */
	this.find = function (where)
	{
		var len = queue.length - offset;
		for (var i = 0, n = offset; i < len; i++ , n++)
		{
			if (n >= len)
				n = 0;
			if (where(queue[n]))
				return queue[n];
		}
	};
}