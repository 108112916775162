<template>
	<router-link v-if="to" :to="to" :class="{ ieInsetOutlineFV:insetOutline, ieOutlineFV:!insetOutline, basicFocusOutline: true }"><slot /></router-link>
	<a v-else-if="href" :href="href" :class="{ ieInsetOutlineFV:insetOutline, ieOutlineFV:!insetOutline, basicFocusOutline: true }" target="_blank"><slot /></a>
	<div v-else-if="div" class="ieInsetOutlineFV basicFocusOutline"><slot /></div> <!-- Making these focusable causes a redundant tab focus item in the welcome splash panel -->
	<span v-else class="ieInsetOutlineFV basicFocusOutline"><slot /></span>
</template>

<script>
	// This component exists either as a <router-link> or as an <a target="_blank">, depending on provided props.
	export default {
		props:
		{
			to: {
				default: null
			},
			href: {
				type: String,
				default: ""
			},
			div: {
				type: Boolean,
				default: false
			},
			insetOutline: {
				type: Boolean,
				default: true
			}
		}
	}
</script>

<style scoped>
</style>