var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "contactUsFormRoot",
        role: "dialog",
        "aria-label": "Contact Us.  Press escape to close.",
        "aria-describedby": "contactUsFormDescription"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "titleBar",
          attrs: {
            id: "contactUsFormTitleBar",
            role: "heading",
            "aria-level": "1"
          }
        },
        [_vm._v("Contact Us")]
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "contactUsMsg",
        attrs: { id: "contactUsFormDescription" },
        domProps: { innerHTML: _vm._s(_vm.htmlMessage) }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "validateForm",
          staticClass: "contactUsInputs",
          attrs: { role: "form" }
        },
        [
          _c("div", { staticClass: "emailBox" }, [
            _c("div", { staticClass: "labelAndValidator" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "contactUsEmail" } },
                [_vm._v("Your Email")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("email"),
                      expression: "validator.has('email')"
                    }
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "contactUsYourEmailValid" }
                },
                [_vm._v(_vm._s(_vm.validator.first("email").msg))]
              )
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              ref: "emailBox",
              staticClass: "box",
              attrs: {
                id: "contactUsEmail",
                "aria-required": "true",
                role: "textbox",
                "aria-label": "Your email",
                name: "email",
                "data-vv-as": "Email",
                type: "text",
                tabindex: "0",
                validatespec: "required|email",
                maxlength: "250",
                autocomplete: "email",
                "aria-describedby": _vm.validator.has("email")
                  ? "contactUsYourEmailValid"
                  : null,
                "aria-invalid": _vm.validator.has("email") ? "true" : null
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sideBySideInputs" }, [
            _c("div", { staticClass: "leftInputField nameBox" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "contactUsName" } },
                [_vm._v("Name")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("name"),
                      expression: "validator.has('name')"
                    }
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "contactUsNameValid" }
                },
                [_vm._v(_vm._s(_vm.validator.first("name").msg))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.name,
                    expression: "name"
                  }
                ],
                ref: "nameBox",
                staticClass: "box",
                attrs: {
                  id: "contactUsName",
                  "aria-required": "true",
                  role: "textbox",
                  "aria-label": "Name",
                  name: "name",
                  "data-vv-as": "Name",
                  type: "text",
                  tabindex: "0",
                  validatespec: "required",
                  maxlength: "100",
                  autocomplete: "name",
                  "aria-describedby": _vm.validator.has("name")
                    ? "contactUsNameValid"
                    : null,
                  "aria-invalid": _vm.validator.has("name") ? "true" : null
                },
                domProps: { value: _vm.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.name = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "rightInputField phoneBox" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "contactUsPhone" } },
                [_vm._v("Phone")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("phone"),
                      expression: "validator.has('phone')"
                    }
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "contactUsPhoneValid" }
                },
                [_vm._v(_vm._s(_vm.validator.first("phone").msg))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.phone,
                    expression: "phone"
                  }
                ],
                ref: "phoneBox",
                staticClass: "box",
                attrs: {
                  id: "contactUsPhone",
                  "aria-required": "true",
                  role: "textbox",
                  "aria-label": "phone number",
                  name: "phone",
                  "data-vv-as": "Phone",
                  type: "text",
                  tabindex: "0",
                  validatespec: "required",
                  maxlength: "100",
                  autocomplete: "tel",
                  "aria-describedby": _vm.validator.has("phone")
                    ? "contactUsPhoneValid"
                    : null,
                  "aria-invalid": _vm.validator.has("phone") ? "true" : null
                },
                domProps: { value: _vm.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.phone = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "sideBySideInputs" }, [
            _c("div", { staticClass: "leftInputField subjectBox" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "contactUsSubject" } },
                [_vm._v("Subject")]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.validator.has("subject"),
                      expression: "validator.has('subject')"
                    }
                  ],
                  staticClass: "validationWarning",
                  attrs: { id: "contactUsSubjectValid" }
                },
                [_vm._v(_vm._s(_vm.validator.first("subject").msg))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.subject,
                    expression: "subject"
                  }
                ],
                ref: "subjectBox",
                staticClass: "box",
                attrs: {
                  id: "contactUsSubject",
                  "aria-required": "true",
                  role: "textbox",
                  "aria-label": "Subject of message",
                  name: "subject",
                  "data-vv-as": "Subject",
                  type: "text",
                  tabindex: "0",
                  validatespec: "required",
                  maxlength: "120",
                  "aria-describedby": _vm.validator.has("subject")
                    ? "contactUsSubjectValid"
                    : null,
                  "aria-invalid": _vm.validator.has("subject") ? "true" : null
                },
                domProps: { value: _vm.subject },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.subject = $event.target.value
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "rightInputField groupInstBox" }, [
              _c(
                "label",
                { staticClass: "label", attrs: { for: "contactUsGroup" } },
                [_vm._v("Group/Institution (optional)")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.groupInst,
                    expression: "groupInst"
                  }
                ],
                staticClass: "box",
                attrs: {
                  id: "contactUsGroup",
                  name: "groupInst",
                  role: "textbox",
                  "aria-label": "Group or institution name",
                  "data-vv-as": "Your group/institution",
                  type: "text",
                  tabindex: "0",
                  maxlength: "120"
                },
                domProps: { value: _vm.groupInst },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.groupInst = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c(
            "label",
            { staticClass: "commentsLabel label", attrs: { for: "comments" } },
            [_vm._v("Comments")]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.validator.has("comments"),
                  expression: "validator.has('comments')"
                }
              ],
              staticClass: "commentsValidation validationWarning",
              attrs: { id: "contactUsCommentsValid" }
            },
            [_vm._v(_vm._s(_vm.validator.first("comments").msg))]
          ),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.comments,
                expression: "comments"
              }
            ],
            staticClass: "commentBox",
            attrs: {
              role: "textbox",
              maxlength: "50000",
              "aria-label": "Comments",
              id: "comments",
              name: "comments",
              "data-vv-as": "Comments",
              validatespec: "required",
              tabindex: "0",
              "aria-describedby": _vm.validator.has("comments")
                ? "contactUsCommentsValid"
                : null,
              "aria-invalid": _vm.validator.has("comments") ? "true" : null
            },
            domProps: { value: _vm.comments },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.comments = $event.target.value
              }
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "buttons" }, [
            _c(
              "div",
              {
                staticClass: "ctrlButton",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.submitClick,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.submitClick.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Submit")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "ctrlButton",
                attrs: { role: "button", tabindex: "0" },
                on: {
                  click: _vm.cancelClick,
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ]) &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.cancelClick.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      ),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.sendingEmail, msg: "Sending…" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }