import Vue from 'vue';
import { ReportError } from 'tdsAppRoot/library/ErrorReporter.js';

/**
 * Call setFormRoot on this Validation instance in your component's mounted() handler.
 * */
export function Validation()
{
	var me = this;
	var formRootEle = null;
	me._errors = new Object();
	me.ClearErrors = function ()
	{
		me._errors = new Object();
	};
	me.HasAnyErrors = function ()
	{
		return Object.keys(me._errors).length > 0;
	};
	me.has = function (field)
	{
		if (typeof me._errors[field] !== 'undefined' && me._errors[field] && me._errors[field].length > 0
			&& me._errors[field][0].msg && me._errors[field][0].msg.length > 0)
			return true;
		return false;
	};
	me.first = function (field)
	{
		if (!field && me.HasAnyErrors())
			return me._errors[Object.keys(me._errors)[0]][0];
		else if (me.has(field))
			return me._errors[field][0];
		return { msg: null, id: null };
	};
	me.AddError = function (field, error)
	{
		if (!me.has(field))
			Vue.set(me._errors, field, new Array());
		me._errors[field].push(error);
	};
	me.RemoveErrors = function (field)
	{
		if (me.has(field))
		{
			Vue.set(me._errors, field, new Array());
		}

	};
	me.ValidateField = function (fieldName, friendlyName, fieldVal, fieldDomObj, rules)
	{
		if (!formRootEle)
			throw new Error("ValidationHelper used without setting form root element");
		let ruleList = rules.split("|");
		me.RemoveErrors(fieldName);
		for (let i = 0; i < ruleList.length; i++)
		{
			let ruleDat = ruleList[i];
			let ruleOpts = ruleDat.split(":");
			let rule = ruleOpts[0];
			if (rule === "required")
			{
				if (!DataExists(fieldVal))
				{
					me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field is required.", domId: fieldDomObj.id });
				}
			}
			else if (rule === "max")
			{
				if (typeof fieldVal !== "undefined" && fieldVal && fieldVal.length > parseInt(ruleOpts[1]))
				{
					me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field can not contain more than " + ruleOpts[1] + " characters.", domId: fieldDomObj.id });
				}
			}
			else if (rule === "min")
			{
				if (DataExists(fieldVal) && fieldVal.trim().length < parseInt(ruleOpts[1]))
				{
					me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field must contain at least " + ruleOpts[1] + " characters.", domId: fieldDomObj.id });
				}
			}
			else if (rule === "email")
			{
				if (DataExists(fieldVal))
				{
					if (!fieldVal.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
						me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field must be an email address.", domId: fieldDomObj.id });
				}
			}
			else if (rule === "confirmed")
			{ // This field value must match other field value
				let confirmAgainstName = ruleOpts[1];
				let confirmAgainstDomObj = formRootEle.querySelectorAll('[name="'+confirmAgainstName+'"]')[0];
				if (confirmAgainstDomObj && confirmAgainstDomObj.value !== fieldVal)
					me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field does not match the \"" + confirmAgainstDomObj.getAttribute("data-vv-as") + "\" field.", domId: fieldDomObj.id });
			}
			else if (rule === "if") // If other field is specified, then this field is required.
			{
				let ifName = ruleOpts[1];
				let ifDomObj = formRootEle.querySelectorAll('[name="' + ifName + '"]')[0];
				if (ifDomObj && ifDomObj.value !== "")
				{
					if (!DataExists(fieldVal))
					{
						me.AddError(fieldName, { msg: "The \"" + friendlyName + "\" field is required if the \"" + ifDomObj.getAttribute("data-vv-as") + "\" field is specified.", domId: fieldDomObj.id });
					}
				}
			}

		}
	}
	function DataExists(fieldVal)
	{
		if (typeof fieldVal === "undefined" || !fieldVal || fieldVal.trim().length == 0)
			return false;
		return true;
	}
	/**
	 * This must be called before the Validation instance can be used for validation.
	 * @param {any} formRootDomEle An html element that contains all the inputs this Validation instance should look at, and none of the inputs belonging to another Validation instance.
	 */
	me.setFormRoot = function (formRootDomEle)
	{
		formRootEle = formRootDomEle;
	}

	function GetDataForEle(domEle, componentData)
	{
		//let data = null;
		let model;
		let dataFieldName = domEle.getAttribute("validateData");
		if (dataFieldName)
			model = componentData[dataFieldName];
		else
			model = domEle.value;

		//if (!model) {
		//	model = domEle.getAttribute("v-model.trim");
		//	data = model.trim();
		//}
		//else
		//	data = model;
		return model;
	}
	function ValidateFieldInternal(data, fieldDomObj)
	{
		let fval = GetDataForEle(fieldDomObj, data);
		let fname = fieldDomObj.getAttribute("name");
		if (!fname)
			fname = fieldDomObj.getAttribute("data-vv-name");

		// We want to automatically remove the validation error message when the user types something that corrects the validation problem,
		// So we're going to add a hook.
		if (!fieldDomObj.valKeyHookInstalled)
		{
			fieldDomObj.valKeyHookInstalled = true;

			// If the dom object has a validateData attribute, we'll find the data variable that matches and revalidate when that variable changes.
			// If there is no validateData attribute, we will use the dom object's "value" instead.  It would be better if we could somehow find the real
			// data variable associated with the dom object without the help of a validateData attribute, but I don't know how to do that, if it is even
			// possible.
			if (fieldDomObj.getAttribute("validateData") && typeof window !== 'undefined')
			{
				window.appRoot.$watch(
					function ()
					{
						return data[fieldDomObj.getAttribute("validateData")];
					},
					function (newValue, oldValue)
					{
						ValidateFieldInternal(data, fieldDomObj);
					}
				);
			}
			else
			{
				fieldDomObj.addEventListener("input", function ()
				{
					ValidateFieldInternal(data, fieldDomObj);
				});
			}
		}


		me.ValidateField(fname, fieldDomObj.getAttribute("data-vv-as"), fval, fieldDomObj, fieldDomObj.getAttribute("validatespec"));
	}
	me.ValidateAll = function (data)
	{
		return new Promise(function (resolve, reject)
		{
			try
			{
				if (formRootEle)
				{
					me.ClearErrors();
					let validationEles = formRootEle.querySelectorAll('[validatespec]');
					for (let i = 0; i < validationEles.length; i++)
					{
						ValidateFieldInternal(data, validationEles[i]);
					}


					if (me.HasAnyErrors())
					{
						console.log("errors detected.");
						reject(false);
					}
					else
					{
						console.log("no errors detected.");
						resolve(true);

					}
				}
			}
			catch (err)
			{
				if (appContext && appContext.urlRoot)
					ReportError(appContext.urlRoot, err.message, null, err);
			}
		});
	}
}