var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: {
        srCustomCheckboxLabel: true,
        cbContainer: true,
        srCustomCheckboxBlock: _vm.displayBlock
      }
    },
    [
      _c("input", {
        ref: "myInput",
        staticClass: "srCustomCheckbox",
        attrs: {
          "aria-label": _vm.ariaLabel,
          id: _vm.idprefix + _vm.index,
          index: _vm.index,
          type: _vm.inputType,
          role: _vm.roleStr,
          name: _vm.radioName,
          tabindex: _vm.srtabindex
        },
        domProps: { checked: _vm.cbValue, value: _vm.radioValue },
        on: {
          focus: function($event) {
            return _vm.OnFocus()
          },
          keydown: [
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.ToggleMe($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.KeyUp($event)
            },
            function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "down", 40, $event.key, [
                  "Down",
                  "ArrowDown"
                ])
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.KeyDown($event)
            }
          ],
          change: function($event) {
            return _vm.updateValue(
              $event,
              $event.target.checked,
              $event.target.value
            )
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "graphics nowrap" }, [
        _c(
          "span",
          {
            class: {
              srCustomCheckboxSpan: true,
              srCustomCheckboxSpanRound: !_vm.square,
              srCustomCheckboxSpanSquare: _vm.square,
              srCustomCheckboxSpanPrimaryColor: _vm.primaryColor,
              srCustomCheckboxSpanBlock: _vm.displayBlock,
              radio: _vm.radio && !_vm.unselectable
            },
            style: _vm.cbSpanStyle,
            on: {
              mousedown: _vm.stopProp,
              mouseup: _vm.stopProp,
              click: function($event) {
                $event.stopPropagation()
                $event.preventDefault()
                return _vm.ToggleMe($event)
              }
            }
          },
          [
            _c("vsvg", {
              staticClass: "checkmark",
              attrs: {
                sprite: _vm.checkmarkSprite,
                title: "",
                desc: "",
                role: "presentation",
                "aria-hidden": "true"
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.useLabelTag && !_vm.ariaLabelOnly
          ? _c(
              "label",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasSlot,
                    expression: "hasSlot"
                  }
                ],
                staticClass: "srCustomCheckboxLabelContent",
                style: _vm.labelStyle,
                attrs: { for: _vm.idprefix + _vm.index },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.ToggleMe($event)
                  }
                }
              },
              [
                _vm._t("default"),
                !_vm.hasSlot
                  ? _c("span", [_vm._v(_vm._s(_vm.ariaLabel))])
                  : _vm._e()
              ],
              2
            )
          : !_vm.ariaLabelOnly
          ? _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.hasSlot,
                    expression: "hasSlot"
                  }
                ],
                staticClass: "srCustomCheckboxLabelContent",
                style: _vm.labelStyle,
                attrs: {
                  "aria-label": _vm.ariaLabel,
                  for: _vm.idprefix + _vm.index
                }
              },
              [
                _vm._t("default"),
                !_vm.hasSlot
                  ? _c("span", [_vm._v(_vm._s(_vm.ariaLabel))])
                  : _vm._e()
              ],
              2
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }