var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "panelRoot",
      staticClass: "panelRoot",
      attrs: {
        role: "alertdialog",
        tabindex: "0",
        "aria-labelledby": "editGroupDefaultPanelsMsgTitle",
        "aria-describedby": "editGroupDefaultPanelsMsgLabel"
      }
    },
    [
      _c("div", { staticClass: "titleBar" }, [
        _c(
          "div",
          {
            staticClass: "title",
            attrs: { id: "editGroupDefaultPanelsMsgTitle", role: "alert" }
          },
          [
            _vm._v("Edit Default Panels for "),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.groupTitle) } }),
            _vm._v(" "),
            _c("span", { staticClass: "visuallyhidden" }, [
              _vm._v("Press escape to cancel.")
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "dialogBody" }, [
        _c(
          "div",
          {
            ref: "msgLabel",
            staticClass: "messageText",
            attrs: { id: "editGroupDefaultPanelsMsgLabel", role: "document" }
          },
          [
            _c("p", [
              _vm._v(
                "The buttons below allow you to change the default home page panel order for all users of your subscription."
              )
            ]),
            _vm._v(" "),
            _vm.customTitleToolCount === 0
              ? _c("p", [
                  _c("b", [_vm._v("Note:")]),
                  _vm._v(
                    " You can add title panels to your home page, and these will be included when you save your current panel order as default for your subscription."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.customTitleToolCount > 0
              ? _c("p", [
                  _c("b", [_vm._v("Note:")]),
                  _vm._v(" You have added "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.customTitleToolCount) + " title "),
                    _vm.customTitleToolCount === 1
                      ? _c("span", [_vm._v("panel")])
                      : _c("span", [_vm._v("panels")])
                  ]),
                  _vm._v(" to your home page. "),
                  _vm.customTitleToolCount === 1
                    ? _c("span", [_vm._v("This panel")])
                    : _c("span", [_vm._v("These panels")]),
                  _vm._v(
                    " will be included when you save your current panel order as default for your subscription."
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.hiddenToolCount > 0
              ? _c("p", [
                  _c("b", [_vm._v("Note:")]),
                  _vm._v(" You have "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.hiddenToolCount) + " hidden "),
                    _vm.hiddenToolCount === 1
                      ? _c("span", [_vm._v("panel")])
                      : _c("span", [_vm._v("panels")])
                  ]),
                  _vm._v(
                    " configured in your Profile.  Hidden panels are specific to your Profile and will not be hidden in your subscription's defaults."
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "controls" }, [
          _c(
            "div",
            {
              staticClass: "medButton primaryColorButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.SetGroupDefaultToolSettings,
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.SetGroupDefaultToolSettings.apply(null, arguments)
                }
              }
            },
            [
              _vm._v(
                "\n\t\t\t\tSave my current panel order as default for my subscription\n\t\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "medButton primaryColorButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.DeleteGroupDefaultToolSettings,
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.DeleteGroupDefaultToolSettings.apply(
                    null,
                    arguments
                  )
                }
              }
            },
            [
              _vm._v(
                "\n\t\t\t\tReset my subscription's default panel order\n\t\t\t"
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "btnClose",
              staticClass: "medButton primaryColorButton",
              attrs: { role: "button", tabindex: "0" },
              on: {
                click: _vm.DefaultClose,
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar"
                    ])
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.DefaultClose.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n\t\t\t\tcancel\n\t\t\t")]
          )
        ])
      ]),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.busy, msg: "Processing…" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }