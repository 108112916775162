<template>
	<div class="mySRRegistrationRoot" aria-describedby="regdesc" role="dialog" id="mySRRegistrationRoot"
		 aria-label="Personalized Profile Account registration form.  Sign up in seconds.  Press escape to close.">
		<div id="regdesc" style="display: none">
			Personalized Profile Account registration form.  Sign up in seconds.  Press escape to close.
		</div>
		<div class="leftSide">
			<div class="signUpTitle">
				Sign up in seconds
			</div>
			<div class="inputs" ref="validateForm">
				<div class="firstLastNameInputCtrls">
					<div class="inputCtrls firstNameInputCtrls">
						<label for="preg_firstName">* First Name</label>
						<span id="preg_firstNameValid" class="validationWarning" 
							  v-show="validator.has('preg_firstName')">{{ validator.first('preg_firstName').msg }}</span><br />
						<input ref="firstName" @keypress.escape.prevent="DefaultClose" name="preg_firstName" 
							   id="preg_firstName" type="text" data-vv-as="First Name" 
							   :class="{ invalid: validator.has('preg_firstName') }" v-model="firstName" 
							   maxlength="20" validatespec="required|max:20" data-lpignore="true" 
							   autocomplete="given-name" aria-describedby="preg_firstNameValid"
							   :aria-invalid="validator.has('preg_firstName') ? 'true' : null"/>
					</div>
					<div class="inputCtrls lastNameInputCtrls">
						<label for="preg_lastName">Last Name</label>
						<span id="preg_lastNameValid" class="validationWarning" v-show="validator.has('preg_lastName')">{{ validator.first('preg_lastName').msg }}</span><br />
						<input name="preg_lastName" @keypress.escape.prevent="DefaultClose" id="preg_lastName" 
							   type="text" data-vv-as="Last Name" 
							   :class="{ invalid: validator.has('preg_lastName') }" v-model.trim="lastName" 
							   maxlength="20" validatespec="max:20" data-lpignore="true" autocomplete="family-name"
							   aria-describedby="preg_lastNameValid"
							   :aria-invalid="validator.has('preg_lastName') ? 'true' : null"/>
					</div>
				</div>
				<div class="inputCtrls emailInputCtrls">
					<label for="preg_email">* Email</label>
					<span id="preg_emailValid" class="validationWarning" v-show="validator.has('preg_email')">{{ validator.first('preg_email').msg }}</span><br />
					<input name="preg_email" @keypress.escape.prevent="DefaultClose" id="preg_email" type="text" 
						   data-vv-as="Email" :class="{ invalid: validator.has('preg_email') }" v-model.trim="email" 
						   maxlength="250" validatespec="required|email|max:250" autocomplete="username"
						   aria-describedby="preg_emailValid"
						   :aria-invalid="validator.has('preg_email') ? 'true' : null"/>
				</div>
				<div class="inputCtrls passwordInputCtrls">
					<label for="preg_pw">* Password</label>
					<span id="preg_pwValid" class="validationWarning" v-show="validator.has('preg_pw')">{{ validator.first('preg_pw').msg }}</span><br />
					<input name="preg_pw" @keypress.escape.prevent="DefaultClose" id="preg_pw" type="password" 
						   aria-label="Password" placeholder="At least 8 characters" data-vv-as="Password" 
						   :class="{ invalid: validator.has('preg_pw') }" v-model="pw" maxlength="40" 
						   validatespec="required|min:5|max:40" autocomplete="new-password"
						   aria-describedby="preg_pwValid"
						   :aria-invalid="validator.has('preg_pw') ? 'true' : null"/>
				</div>
				<div class="inputCtrls passwordInputCtrls">
					<label for="preg_rpw">* Repeat Password</label>
					<span id="preg_rpwValid" class="validationWarning" v-show="validator.has('preg_rpw')">{{ validator.first('preg_rpw').msg }}</span><br />
					<input name="preg_rpw" @keypress.escape.prevent="DefaultClose" id="preg_rpw" 
						   type="password" aria-label="Repeat Password" placeholder="At least 8 characters" 
						   data-vv-as="Repeat Password" :class="{ invalid: validator.has('preg_rpw') }" 
						   v-model="rpw" maxlength="40" validatespec="required|min:5|confirmed:preg_pw|max:40"
						   autocomplete="new-password" aria-describedby="preg_rpwValid"
						   :aria-invalid="validator.has('preg_rpw') ? 'true' : null" />
				</div>
				<div class="rememberMeBox">
					<SRCustomCheckbox id="rememberCheckBox" idprefix="profileRegCB" v-model="remember" :square="true">Remember me on this computer (uses cookies)</SRCustomCheckbox> 
					<!--<input id="rememberCheckBox" @keypress.escape.prevent="DefaultClose" name="rememberCheckBox" type="checkbox" v-model="remember" />
					<label for="rememberCheckBox">Remember me on this computer (uses cookies)</label>-->
				</div>
				<div class="termsNotice">
					By clicking "Register Now" below, you are agreeing to the TDS <a role="button" tabindex="0" @keypress.escape.prevent="DefaultClose" aria-haspopup="dialog" @click="ShowEulaPopup" @keypress.enter.space.stop.prevent="ShowEulaPopup">Terms and Conditions</a> and <a href="https://www.tetondata.com/privacy.cshtml" target="_blank">Privacy Policy</a>
				</div>
				<div class="RegisterBtnWrapper">
					<invisible-recaptcha sitekey="6LfrjUsUAAAAAIhDbhKQAtBYk4uJ2o8bkpiYIWu3" :validate="OnValidate" :callback="RegisterNow"
										 class="registerBtnBox" type="submit" id="registerBtn" :disabled="false" ref="captchaComponent" aria-label="Register Now">
						Register Now
					</invisible-recaptcha>
				</div>
			</div>
		</div>
		<div class="rightSide">
			<div class="modalDialogCloseButton" tabindex="0" @click="DefaultClose" @keypress.enter.space.stop.prevent="DefaultClose" role="button" aria-label="Close"><vsvg sprite="#x" title="" desc="" role="presentation" aria-hidden="true" /></div>
			<div class="bigLogoBox">
				<vsvg class="bigLogo" sprite="#bookshelf_white" role="presentation" aria-hidden="true" title="" desc="" />
				<div class="bigLogoCaption">Personalized Profile</div>
			</div>
			<div class="whatYouGet">
				<div class="wygTitle">
					What you get
				</div>
				<div class="wygItem">
					Automatically Sign On across all of your favorite {{ appContext.appName }} products
				</div>
				<div class="wygItem">
					Customize views and personalize your homepage
				</div>
				<div class="wygItem">
					Access {{ appContext.appName }} from anywhere
				</div>
				<div class="wygItem">
					Save search history and results
				</div>
				<div class="wygItem">
					Save and annotate specific paragraphs in {{ subProduct }} titles
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import { ReportError, ShowErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	import InvisibleRecaptcha from 'tdsAppRoot/library/3rdParty/InvisibleRecaptcha.vue';
	import { MSRRegister } from 'tdsAppRoot/API/MyStatrefAPI.js';
	import svg1 from 'tdsAppRoot/images/sprite/bookshelf_white.svg';
	import svg2 from "tdsAppRoot/images/sprite/x.svg";
	import { Validation } from 'tdsAppRoot/library/ValidationHelper.js';
	import { ModalEULADialog } from "tdsAppRoot/library/ModalDialog.js";
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";


	export default {
		mixins: [ ThemeMixin ],
		components: { InvisibleRecaptcha, SRCustomCheckbox },
		props:
		{
			initialEmail: {
				type: String,
				default: ""
			},
			initialPass: {
				type: String,
				default: ""
			}
		},
		data()
		{
			return {
				firstName: "",
				lastName: "",
				email: "",
				pw: "",
				rpw: "",
				remember: false,
				validator: new Validation()
			};
		},
		mounted()
		{
			this.validator.setFormRoot(this.$refs.validateForm);
			this.email = this.initialEmail;
			this.pw = this.initialPass;
			this.SetSavedFieldValue("firstName");
			this.SetSavedFieldValue("lastName");
			this.SetSavedFieldValue("email");
			this.SetSavedFieldValue("remember");
			this.$refs.firstName.focus();
		},
		destroyed()
		{
			console.log("Registration dialog destroyed.");
		},
		watch:
		{
			firstName()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "firstName", value: this.firstName });
			},
			lastName()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "lastName", value: this.lastName });
			},
			email()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "email", value: this.email });
			},
			remember()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "remember", value: this.remember });
			}
		},
		methods:
		{
			SetSavedFieldValue(fieldName)
			{
				let value = this.$store.getters.GetProfileRegistrationField(fieldName);
				if (value)
					this.$data[fieldName] = value;
			},
			//SetFocus()
			//{
			//	this.$refs.firstName.focus();
			//},
			DefaultClose()
			{
				this.$emit("close", false);
			},
			ShowEulaPopup()
			{
				ModalEULADialog(false);
			},
			OnValidate(event)
			{
				try
				{
					var vald = this.validator;
					return this.validator.ValidateAll(this.$data).catch(err =>
					{
						if (vald.HasAnyErrors())
						{
							var targetInput = document.getElementById(vald.first().domId);
							targetInput.focus();
						}
					});
				}
				catch (err)
				{
					console.error("Profile account registration validation error: " + err.message);
					ReportError(this.$store.getters.urlRoot, "Profile account registration validation error: " + err.message, null, err, this.$store.state.sid);
					ShowErrorWindow("There was an error validating your registration information.  Technical support has been notified.  We are sorry for the inconvenience.");
				}

				return false;
			},
			RegisterNow(token)
			{
				var defaultErrMsg = "There was an error processing your registration.  Technical support has been notified.  We are sorry for the inconvenience.";
				MSRRegister(this.$data.firstName, this.$data.lastName, this.$data.email, this.$data.pw, token, this.$store)
					.then(data =>
					{
						this.$store.commit("ClearProfileRegistrationFields");
						this.$store.dispatch("ProfileLoggedIn", { profileData: data.profileData, disableAutomaticDocReload: false });
						if (this.$data.remember)
							this.$cookie.set("ProfileLogin", data.profileLoginInfo);
						this.$emit("close", true);
					}).catch(ex =>
					{
						if (ex.name === "ApiError")
						{
							if (ex.data.reCAPTCHAFailure)
							{
								this.$refs.captchaComponent.ResetRecaptcha.apply(this.$refs.captchaComponent);
								ShowErrorWindow(ex.message);
							}
							else if (ex.data.ValidationMsg)
							{
								ShowErrorWindow(ex.data.ValidationMsg);
							}
							else
							{
								ShowErrorWindow(ex.message);
							}
						}
						else
						{
							console.error("Profile account registration error: " + ex.message);
							ReportError(this.$store.getters.urlRoot, "Profile account registration error: " + ex.message, null, ex, this.$store.state.sid);
							ShowErrorWindow(defaultErrMsg);
						}
					});
			}
		}
	}
</script>


<style scoped>

	.mySRRegistrationRoot a,
	.mySRRegistrationRoot a:visited
	{
		color: var(--link-color);
		cursor: pointer;
	}

	.mySRRegistrationRoot
	{
		background-color: #FFFFFF;
		font-family: 'Open Sans', sans-serif;
		font-size: 0px;
		border-radius: 0px;
		display: flex;
		flex-direction: column-reverse;
		align-content: center;
		align-items: stretch;
		z-index: 4010;
	}

	.signUpTitle
	{
		padding: 10px 0px 10px 0px;
		background-color: var(--primary-color);
		color: var(--text-color-on-primary-color);
		font-weight: 100;
		text-transform: uppercase;
		text-align: center;
	}

	.inputs
	{
		padding: 15px 45px 35px 45px;
	}

	.validationWarning
	{
		font-size: 8pt;
		color: #D40202;
		margin-left: 10px;
	}

	.leftSide, .rightSide
	{
		font-size: 16px;
	}

	.rememberMeBox input
	{
		border-radius: 0px;
	}
	.rememberMeBox input:focus
	{
		outline: none;
	}
	.rememberMeBox input:focus-visible
	{
		outline: 1px solid black;
		outline-offset: 0px;
	}
	.rememberMeBox input.focus-visible
	{
		outline: 1px solid black;
		outline-offset: 0px;
	}

	.rememberMeBox,
	.inputCtrls
	{
		margin-top: 22px;
	}


		.inputCtrls label
		{
			color: #666666;
			display: inline-block;
			margin-bottom: 5px;
		}

		.inputCtrls input[type=text], .inputCtrls input[type=password]
		{
			border-radius: 5px;
			padding: 13px 20px 13px 20px;
			border: 1px solid #CECFCE;
			font-size: 16px;
			width: 100%;
		}
		.inputCtrls input:focus-visible
		{
			padding: 12px 19px 12px 19px;
			border: 2px solid black;
		}
		.inputCtrls input.focus-visible
		{
			padding: 12px 19px 12px 19px;
			border: 2px solid black;
		}

	.invalid
	{
		border: 1px solid #FF3860 !important;
	}

	.imaDropDownBox
	{
		margin-top: 23px;
		width: 100%;
		position: relative;
	}

	.imaValidationWarning
	{
		display: block;
		margin-bottom: 5px;
	}

	.rememberMeBox label
	{
		color: #444444;
	}

	.termsNotice
	{
		max-width: 420px;
		width: 100%;
		font-size: 12pt;
		font-weight: 100;
		text-align: center;
		margin: 20px auto 0px auto;
	}

	.RegisterBtnWrapper
	{
		padding-top: 50px;
		text-align: center;
	}

	.registerBtnBox
	{
		padding: 19px;
		box-sizing: border-box;
		margin: 0px auto;
		min-width: 120px;
		max-width: 250px;
		border-radius: 4px;
		background-color: var(--primary-color);
		color: var(--text-color-on-primary-color) !important;
		font-weight: 700;
		font-size: 11pt;
		cursor: pointer;
	}

		.registerBtnBox:hover
		{
			background-color: #4288b3;
		}

	.bigLogoBox
	{
		background-color: #31517B;
		padding: 10px;
		border-top-right-radius: 10px;
		background-color: var(--primary-color-dark2);
		color: var(--text-color-on-primary-color);
	}

	.bigLogo
	{
		width: 24px;
		height: 24px;
		vertical-align: middle;
		padding: 4px;
	}

	.bigLogoCaption
	{
		display: inline-block;
		font-size: 24px;
		text-align: center;
		font-weight: 100;
		text-transform: uppercase;
		vertical-align: middle;
	}

	.whatYouGet
	{
		display: none;
	}

	@media (min-width: 600px)
	{

		.mySRRegistrationRoot
		{
			top: 40px;
			width: auto;
		}

		.signUpTitle
		{
			font-size: 20pt;
		}

		.firstLastNameInputCtrls
		{
			display: flex;
		}

			.firstLastNameInputCtrls > *
			{
				margin-left: 7px;
				margin-right: 7px;
			}

				.firstLastNameInputCtrls > *:first-child
				{
					margin-left: 0px;
				}

				.firstLastNameInputCtrls > *:last-child
				{
					margin-right: 0px;
				}
	}

	@media (min-width: 960px)
	{
		.mySRRegistrationRoot
		{
			flex-direction: row;
		}

		.signUpTitle
		{
			padding: 30px 0px 23px 0px;
			font-size: 23pt;
		}

		.rightSide
		{
			width: 396px;
			flex: 0 0 auto;
			display: flex;
			flex-direction: column;
		}

		.bigLogoBox
		{
			flex: 0 0 auto;
			position: relative;
			height: 312px;
			padding: 0px;
		}

		.bigLogo
		{
			display: block;
			width: 130px;
			height: 130px;
			margin: 0px auto;
			padding: 78px 0px 27px 0px;
		}

		.bigLogoCaption
		{
			display: block;
			font-size: 23pt;
		}

		.whatYouGet
		{
			flex: 1 1 auto;
			display: block;
			background-color: var(--primary-color-dark1);
			color: var(--text-color-on-primary-color);
			padding-top: 38px;
		}

		.wygTitle
		{
			text-align: center;
			font-size: 19pt;
			font-weight: 100;
			text-transform: uppercase;
			margin-bottom: 35px;
		}

		.wygItem:before
		{
			content: '•';
		}

		.wygItem
		{
			padding-left: 36px;
			padding-right: 45px;
			margin-bottom: 15px;
			font-size: 12pt;
			line-height: 26px;
		}
	}
</style>