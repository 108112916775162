<template>
	<div id="supportInfoDialog" class="supportInfoRoot" role="dialog" aria-labelledby="supportInfoTitle" aria-describedby="supportInfoLabel">
		<div class="titleBar">
			<div class="title" id="supportInfoTitle" role="heading">Support Info <span class="visuallyhidden">Press escape to close.</span></div>
		</div>
		<div id="supportInfoLabel" class="dialogBody" role="document">
			<table>
				<tbody>
					<tr v-for="item in info" :key="item.key"><td>{{item.key}}</td><td>{{item.value}}</td></tr>
				</tbody>
			</table>
			<div v-if="allowCauseError" class="debuggingToolsButton" role="button" title="toggle debugging tools" @click="ToggleDebuggingTools" @keydown.space.enter.prevent="ToggleDebuggingTools">
				<vsvg sprite="#ChevronsMatch" title="Debugging Tools" desc="" :isPresentation="false" />
			</div>
			<div class="closeBtnWrapper">
				<div ref="closeBtn" class="smButton primaryColorButton" role="button" tabindex="0" @click="DefaultClose" @keydown.space.enter.prevent="DefaultClose">
					close
				</div>
			</div>
			<div v-if="showDebuggingTools" class="debuggingTools">
				<div class="smButton primaryColorButton" role="button" tabindex="0" @click="ViewStorageInfo" @keydown.space.enter.prevent="ViewStorageInfo">
					Storage Info
				</div>
				<div class="smButton primaryColorButton" role="button" tabindex="0" @click="CauseError" @keydown.space.enter.prevent="CauseError" title="Causes an error (to test error reporting)">
					cause error
				</div>
				<div class="smButton primaryColorButton" role="button" tabindex="0" @click="CauseError2" @keydown.space.enter.prevent="CauseError2" title="Causes an error (to test error reporting)">
					cause error 2
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import svg1 from "tdsAppRoot/images/sprite/ChevronsMatch.svg";
	import { DefaultErrorHandler } from 'tdsAppRoot/library/ErrorReporter.js';
	import { IdentifyBrowser } from 'tdsAppRoot/API/BrowserIdAPI.js';

	export default {
		data()
		{
			return {
				info: GenerateSupportInfo(this.$store, true),
				showDebuggingTools: false
			};
		},
		methods:
		{
			SetFocus()
			{
				this.$refs.closeBtn.focus();
			},
			DefaultClose()
			{
				this.$emit('close');
			},
			CauseError()
			{
				this.ErrorWasCausedByAdminForDebuggingPurposes();
			},
			CauseError2()
			{
				new Promise((resolve, reject) =>
				{
					reject();
				}).then(() =>
				{
					// no catch, will be caught by global handler
				});
			},
			ViewStorageInfo()
			{
				location.href = appContext.appPath + "StorageInfo";
			},
			ToggleDebuggingTools()
			{
				this.showDebuggingTools = !this.showDebuggingTools;
			}
		},
		computed:
		{
			allowCauseError()
			{
				return !!appContext.resultMetadata;
			}
		}
	}
	/**
	 * Generates a support info object.
	 * @param {Object} store The vuex store.
	 * @param {Boolean} loadAsyncData If true, the Browser field will be loaded asynchronously from a server-side user agent parser, then updated later in the previously-returned support info object.  If false, the Browser field will simply be set to the UserAgent string.
	 */
	export function GenerateSupportInfo(store, loadAsyncData)
	{
		let info = [];
		info.push({ key: "Session ID", value: store.getters.sid ? store.getters.sid : "Not logged in" });
		if (store.state.profile && store.state.profile.ProfileEmail)
			info.push({ key: "Profile", value: store.state.profile.ProfileEmail });
		else
			info.push({ key: "Profile", value: "None" });
		info.push({ key: "User Name", value: appContext.userName });
		info.push({ key: "Group Alias", value: appContext.grpAlias });
		info.push({ key: "Group", value: appContext.groupTitle });
		info.push({ key: "Organization", value: appContext.orgName });
		if (appContext.restrictedSessionProductType)
			info.push({ key: "EXPIRED subscription is restricted to", value: appContext.restrictedSessionProductType });
		info.push();
		info.push({ key: "URL", value: location.href });
		info.push({ key: "Client IP", value: appContext.clientIp });
		if (loadAsyncData)
		{
			let browserInfo = { key: "Browser", value: "Identifying browser. Please wait…" };
			IdentifyBrowser(store)
				.then(result =>
				{
					browserInfo.value = result.id;
				})
				.catch(err =>
				{
					browserInfo.value = navigator.userAgent;
					DefaultErrorHandler(err);
				});
			info.push(browserInfo);
		}
		else
			info.push({ key: "Browser", value: navigator.userAgent });
		info.push({ key: "Local Storage", value: CalculateLocalStorageSize() });
		info.push({ key: "Session Storage", value: CalculateSessionStorageSize() });
		info.push({ key: "Load Time", value: appContext.loadTime });
		info.push({ key: "Server", value: appContext.server });
		info.push({ key: "Version", value: appContext.version });
		let buildDateStr = appContext.build.toString();
		try { buildDateStr = new Date(appContext.build).toISOString(); }
		catch (ex) { }
		info.push({ key: "Build", value: buildDateStr });
		return info;
	}
	function CalculateLocalStorageSize()
	{
		try
		{
			return CalculateStorageSize(localStorage);
		}
		catch (ex)
		{
			return "unsupported";
		}
	}
	function CalculateSessionStorageSize()
	{
		try
		{
			return CalculateStorageSize(sessionStorage);
		}
		catch (ex)
		{
			return "unsupported";
		}
	}
	function CalculateStorageSize(storageObj)
	{
		var size = 0;
		for (var key in storageObj)
		{
			if (!storageObj.hasOwnProperty(key))
				continue;
			size += (key.length + storageObj[key].length);
		};
		return (size / 52428.80).toFixed(1) + " % full (" + size + " chars)";
	}
</script>

<style scoped>
	.supportInfoRoot
	{
		max-width: 640px;
		min-width: 200px;
		background-color: #FFFFFF;
	}

	.titleBar
	{
		background-color: #FFFFFF;
		padding: 8px 14px;
		box-sizing: border-box;
	}


	.title
	{
		text-align: center;
		color: black;
		font-weight: bold;
		font-size: 16pt;
	}


	.dialogBody
	{
		padding: 8px 14px;
	}

		.dialogBody table
		{
			border-collapse: collapse;
		}

		.dialogBody td
		{
			padding: 1px 5px;
			border: 1px solid #DDDDDD;
		}

			.dialogBody td:first-child
			{
				text-align: right;
				width: 110px;
			}

			.dialogBody td:last-child
			{
				word-wrap: break-word;
				word-break: break-all;
				white-space: pre-line;
			}

	.closeBtnWrapper
	{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

		.closeBtnWrapper .smButton
		{
			margin-top: 10px;
		}

	.debuggingToolsButton
	{
		float: right;
		cursor: pointer;
		margin-top: 10px;
		border: 1px solid transparent;
	}

		.debuggingToolsButton:hover
		{
			border-color: var(--primary-color);
		}

		.debuggingToolsButton svg
		{
			width: 20px;
			height: 20px;
			fill: var(--primary-color-text);
			transform: rotate(90deg);
		}

	.debuggingTools
	{
		display: flex;
		justify-content: center;
	}

		.debuggingTools .smButton
		{
			margin: 10px 5px 0px 5px;
		}
</style>