import { CallServer } from "tdsAppRoot/API/apibase.js";

export function IdentifyBrowser(store)
{
	try
	{
		return CallServer("BrowserId", null, null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}