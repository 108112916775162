var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "favoriteItems" },
    _vm._l(_vm.favorites, function(fav, idx) {
      return _vm.TypeCheck(fav)
        ? _c(
            "div",
            {
              key: fav.favid,
              staticClass: "favoriteItem ieInsetOutlineFV",
              attrs: {
                id: "MiniFavoriteItem" + idx,
                index: idx,
                role: "link",
                tabindex: "0"
              },
              on: {
                keydown: [
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "up", 38, $event.key, [
                        "Up",
                        "ArrowUp"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.PrevItem.apply(null, arguments)
                  },
                  function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "down", 40, $event.key, [
                        "Down",
                        "ArrowDown"
                      ])
                    ) {
                      return null
                    }
                    $event.preventDefault()
                    return _vm.NextItem.apply(null, arguments)
                  }
                ],
                click: function($event) {
                  return _vm.onItemOpen(fav)
                },
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  $event.preventDefault()
                  return _vm.onItemOpen(fav)
                }
              }
            },
            [
              fav.type.indexOf("srch") !== -1
                ? _c("vsvg", {
                    staticClass: "searchButtonImg",
                    attrs: {
                      sprite: "#magnifying_glass",
                      title: "",
                      desc: "",
                      role: "presentation"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("span", { domProps: { innerHTML: _vm._s(_vm.getText(fav)) } })
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }