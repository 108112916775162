import { CallServer } from "tdsAppRoot/API/apibase.js";

var ContactUsRequest = function () {
	var name;
	var email;
	var groupInst;
	var phone;
	var subject;
	var comments;
};

Object.seal(ContactUsRequest);

var ContactUs = function (store, name, email, groupInst, phone, subject, comments) {
	try {
		let myargs = new ContactUsRequest();
		myargs.name = name;
		myargs.email = email;
		myargs.groupInst = groupInst;
		myargs.phone = phone;
		myargs.subject = subject;
		myargs.comments = comments;
		myargs.referer = location.href;
		return CallServer("SendEmail", null, myargs, store);
	}
	catch (err) {
		return Promise.reject(err);
	}
};

export { ContactUs };