<template>
	<div class="ltpOuter" role="dialog" aria-labelledby="ltpTitle">
		<div class="titleBar">
			<LogoImage :width="59" style="padding-left: 8px" :includeDesc="false" logoType="onPrimaryColor" />
			<div id="ltpTitle" class="ltpTitle">Link To Current Location<span class="visuallyhidden">. Press escape to close this dialog.</span></div>
			<button ref="closeButton" class="closeButton" @click="DefaultClose" tabindex="0" aria-label="Close this dialog box">
				<vsvg sprite="#x" title="Close" desc="Close Dialog" />
			</button>
		</div>
		<div class="linkToPage">
			<div class="top">
				<div class="leftSide">
					<input ref="tbLTPText" type="text" class="linkText" v-bind:value="theLink" readonly aria-label="current location link text" aria-describedby="ltpTitle" />
				</div>
				<div class="rightSide">
					<button class="copyBtn primaryColorButton inputButton" @click="OnCopyClick" tabindex="0">Copy</button>
				</div>
			</div>
			<div class="bottom">
				<div class="previewWarning" v-if="showPreviewWarning">
					Please note that any links created while using this preview version of {{ appContext.appName }} may cease functioning when the preview is officially released.
				</div>
				<div class="authSelectRow">
					<label class="authSelectLabel" for="authSelect">Authentication: </label>
					<select id="authSelect" role="menu" tabindex="0" required class="authSelect" v-model="authTypeIdx" aria-label="Select Authentication Type For Link" aria-describedby="ltpAuthDesc">
						<option v-for="(opt, idx) in flattenedOptions" role="menuitem" :value="idx" aria-describedby="ltpAuthDescText">{{ opt.authOptionText }}</option>
					</select>
				</div>
				<div id="ltpAuthDesc" class="authDescBlock">
					<div id="ltpAuthDescText" class="authDescText">
						{{authDesc}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
	import svg1 from "tdsAppRoot/images/sprite/x.svg";
	import { GetAuthOptions } from "tdsAppRoot/API/LinkGenAPI.js";
	import { BuildLink } from "tdsAppRoot/library/TDSUtil.js";
	import { ShowEmailLinkWindow } from "tdsAppRoot/library/Favorites.js";
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";
	import LogoImage from "tdsAppRoot/components/Controls/LogoImage.vue";
	import { DefaultErrorHandler } from "tdsAppRoot/library/ErrorReporter.js";

	// Kent is set up to, by default, generate the link for this using the current route.
	// To customize the link for this, simply implement the method:
	// ConfigLinkToPage: function (Callback)
	// in the component which acts as the router view for the page you need a customized link.
	// You must call Callback({ path, query }) if valid link info has been made available,
	// otherwise Callback(false).  Note that Callback(false) will result in the user seeing a
	// message window informing them that a link is not currently available for their page.

	export default {
		mixins: [ThemeMixin],
		components: { LogoImage },
		props:
		{
			path: { // path needs to include the app path without a leading forward slash (e.g. "kent/")
				type: String,
				required: true
			},
			query: {
				type: String,
				required: true
			}
		},
		data()
		{
			return {
				options: null,
				hasProxy: false,
				authTypeIdx: 0,
				flattenedOptions: null
			};
		},
		methods:
		{
			DefaultClose()
			{
				this.$emit("close", false);
			},
			OnSelectAllClick()
			{
				if (!this.$refs.tbLTPText)
					return;
				this.$refs.tbLTPText.select();
				if (navigator.userAgent.indexOf("iPhone OS") != -1)
					this.iosSelectAll();
			},
			SetFocus()
			{
				if (!this.$refs.closeButton)
					return;
				this.$refs.closeButton.focus();
			},
			iosSelectAll()
			{
				if (!this.$refs.tbLTPText)
					return;
				let el = this.$refs.tbLTPText;
				var oldContentEditable = el.contentEditable,
					oldReadOnly = el.readOnly,
					range = document.createRange();

				el.contentEditable = true;
				el.readOnly = false;
				range.selectNodeContents(el);

				var s = window.getSelection();
				s.removeAllRanges();
				s.addRange(range);

				el.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.

				el.contentEditable = oldContentEditable;
				el.readOnly = oldReadOnly;
			},
			OnCopyClick()
			{
				if (!this.$refs.tbLTPText)
					return;
				this.$refs.tbLTPText.select();
				if (navigator.userAgent.indexOf("iPhone OS") != -1)
					this.iosSelectAll();

				document.execCommand("copy");
			}
		},
		mounted()
		{
			GetAuthOptions(this.$store)
				.then(response =>
				{
					if (!this.$refs.tbLTPText)
						return;
					let output = new Array();
					this.options = response.options;
					let i = 0;
					for (var k in this.options)
					{
						if (this.options.hasOwnProperty(k))
						{
							let optArr = this.options[k];
							for (let opt in optArr)
							{
								if (opt.AuthType != "UserName")
								{
									output.push(optArr[opt]);
									if (optArr[opt].wasUsedForLogin)
										this.authTypeIdx = i;
									i++;
								}
							}
						}
					}
					this.flattenedOptions = output;
					this.$refs.tbLTPText.focus();
					this.$nextTick(() =>
					{
						this.$refs.tbLTPText.select();
					});
				})
				.catch(DefaultErrorHandler);
		},
		computed:
		{
			authDesc()
			{
				// Description of current auth type.
				if (this.authTypeIdx >= 0 && this.flattenedOptions && this.flattenedOptions.length > this.authTypeIdx)
					return this.flattenedOptions[this.authTypeIdx].authDescription;
				return "";
			},
			theLink()
			{
				let linkTxt = "";
				if (this.flattenedOptions)
				{
					let opt = this.flattenedOptions[this.authTypeIdx];

					linkTxt = BuildLink(this.$store.state.protocol, this.$store.state.host, this.path, this.query, opt);
				}
				return linkTxt;
			},
			showPreviewWarning()
			{
				if (appContext.appPath.length > 1)
					return true;
				return false;
			}
		}
	}
</script>

<style scoped>
	.ltpOuter
	{
		font-family: 'Open Sans', sans-serif;
		background: none;
	}

	.linkToPage
	{
		padding: 30px 3vw;
		overflow: hidden;
		resize: none;
		box-sizing: border-box;
		width: 600px;
		max-width: 80vw;
		text-align: left;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: black;
		font-size: 14px;
		position: relative;
	}

	.titleBar
	{
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: var(--primary-color);
		padding: 10px;
		color: white;
		position: relative;
		height: 57px;
		box-sizing: border-box;
	}


	.previewWarning
	{
		color: red;
	}

	.authSelectRow
	{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		width: 100%;
		margin-top: 10px;
		text-align: right;
	}

	.authSelectLabel
	{
		font-size: 14px;
		padding-right: 10px;
		padding-top: 2px;
	}

	.authSelect
	{
		width: 100%;
		max-width: 260px;
		padding: 3px 10px 3px 10px;
		border-radius: 3px;
		border: 1px solid gray;
	}
	.authSelect:focus
	{
		outline: none;
	}
		.authSelect:focus-visible
		{
			padding: 2px 9px 2px 9px;
			border: 2px solid black;
		}
		.authSelect.focus-visible
		{
			padding: 2px 9px 2px 9px;
			border: 2px solid black;
		}

	.ltpTitle
	{
		width: 100%;
		text-align: center;
		font-weight: bold;
		position: absolute;
		left: 0px;
		right: 0px;
		top: 15px;
		font-size: 20px;
	}

	@media not all and (min-width: 600px)
	{
		.authSelect
		{
			max-width: 60%;
		}
	}

	@media not all and (min-width: 500px)
	{
		.ltpTitle
		{
			font-size: 16px;
		}
	}

	@media not all and (min-width: 430px)
	{
		.ltpTitle
		{
			font-size: 12px;
			width: auto;
			left: 80px;
			right: 60px;
			top: 10px;
		}
	}

	.top
	{
		display: flex;
		flex-direction: row;
		width: 100%;
	}

	.bottom
	{
		width: 100%;
	}

	.tabs
	{
		display: flex;
		flex-direction: row;
		position: relative;
		left: 10px;
		width: 600px;
		max-width: 80vw;
		color: black;
	}


	.tabBtn
	{
		background: none;
		background-color: white;
		border: none;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		border-top: none;
		position: relative;
		top: 0px;
		padding: 0px 3px 0px 3px;
		margin-right: 10px;
		cursor: pointer;
		font-size: 11px;
	}

	.copyBtn
	{
		padding: 9px 9px 11px 9px !important;
		position: relative;
		left: 2px;
	}
		.copyBtn:focus
		{
			outline: none;
		}
		.copyBtn:focus-visible
		{
			padding: 8px 8px 10px 8px !important;
			border: 2px solid black;
		}
		.copyBtn.focus-visible
		{
			padding: 8px 8px 10px 8px !important;
			border: 2px solid black;
		}

	.tabText
	{
		position: relative;
		top: -1px;
	}

	.tabBtn::before
	{
		content: '';
		z-index: -1;
		-webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
		-moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
		box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.75);
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		position: absolute;
		top: 4px;
		left: 0px;
		right: 0px;
		bottom: 0px;
	}

	.leftSide
	{
		flex-grow: 10;
	}

	.rightSide
	{
		flex-shrink: 10;
	}

	.linkText
	{
		resize: none;
		width: 100%;
		margin-bottom: 5px;
		padding: 10px;
		font-size: 16px;
		border-radius: 5px;
		border: 1px solid gray;
	}
		.linkText:focus-visible
		{
			border: 2px solid black;
			padding: 9px;
			outline: none;
		}
		.linkText.focus-visible
		{
			border: 2px solid black;
			padding: 9px;
			outline: none;
		}

	.closeButton
	{
		background: none;
		border: none;
		cursor: pointer;
		padding: 2px;
		position: absolute;
		top: 0px;
		right: 0px;
		width: 57px;
		height: 57px;
		border-top-right-radius: 10px;
	}

		.closeButton:focus-visible
		{
			border: 2px solid black;
			outline: none;
		}
		.closeButton.focus-visible
		{
			border: 2px solid black;
			outline: none;
		}

		.closeButton:hover svg
		{
			fill: gray;
		}

		.closeButton svg
		{
			width: 35px;
			height: 35px;
			position: relative;
			fill: #CCCCCC;
		}


	.authDescBlock
	{
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}

	.authDescText
	{
		margin-top: 10px;
		font-size: 12px;
		width: 100%;
		max-width: 300px;
		text-align: right;
	}
</style>