var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "eulaRoot", role: "dialog", "aria-label": _vm.ariaLabel } },
    [
      _c("div", { staticClass: "eulaContentOuter" }, [
        _vm.eulaContent
          ? _c("div", {
              staticClass: "eulaContent",
              domProps: { innerHTML: _vm._s(_vm.eulaContent) }
            })
          : _vm._e(),
        _vm._v(" "),
        !_vm.eulaContent
          ? _c(
              "div",
              { staticClass: "loadingMsg" },
              [
                _c("ScaleLoader", { staticClass: "animation" }),
                _vm._v("Loading\n\t\t")
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.showButtons
        ? _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                ref: "acceptBtn",
                class: {
                  greenHighButton: true,
                  inputButton: true,
                  disabled: !_vm.eulaLoaded
                },
                attrs: { tabindex: "0", "aria-label": "I Accept" },
                on: {
                  click: _vm.Accept,
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.Accept.apply(null, arguments)
                  }
                }
              },
              [_vm._v("\n\t\t\tI Accept\n\t\t")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "redHighButton inputButton",
                attrs: { tabindex: "0", "aria-label": "I do not accept" },
                on: {
                  click: _vm.Decline,
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.Decline.apply(null, arguments)
                  }
                }
              },
              [_vm._v("\n\t\t\tI do NOT Accept\n\t\t")]
            )
          ])
        : _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                ref: "closeBtn",
                staticClass: "primaryColorButton inputButton",
                attrs: { tabindex: "0", "aria-label": "Close" },
                on: {
                  click: _vm.DefaultClose,
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.DefaultClose.apply(null, arguments)
                  }
                }
              },
              [_vm._v("\n\t\t\tClose\n\t\t")]
            )
          ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }