import { CallServer } from "tdsAppRoot/API/apibase.js";

var SetInactiveToolRequest = function ()
{
	var componentType;
	var inactive; // true or false
};
Object.seal(SetInactiveToolRequest);

export function SetInactiveTool(store, componentKey, inactive)
{
	try
	{
		let req = new SetInactiveToolRequest();
		req.componentKey = componentKey;
		req.inactive = inactive;

		return CallServer("MyStatref", "SetInactiveTool", req, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}


var EditTitleToolRequest = function ()
{
	var fxid;
	var add; // true to add or false to remove
};
Object.seal(EditTitleToolRequest);

export function EditTitleTool(store, fxid, add)
{
	try
	{
		let req = new EditTitleToolRequest();
		req.fxid = fxid;
		req.add = add;

		return CallServer("MyStatref", "EditTitleTool", req, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
