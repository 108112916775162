var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "mtlpRoot",
      attrs: { role: "alertdialog", "aria-labelledby": "mtlpMsgTitle" }
    },
    [
      _c(
        "div",
        {
          staticClass: "heading",
          attrs: { id: "mtlpMsgTitle", role: "alert" }
        },
        [_vm._v("Follow link to")]
      ),
      _vm._v(" "),
      _vm._l(_vm.links, function(link, index) {
        return _c("a", {
          key: index,
          ref: "links",
          refInFor: true,
          attrs: { href: link.href, target: "_blank", tabindex: "0" },
          domProps: { innerHTML: _vm._s(link.html) },
          on: {
            click: function($event) {
              return _vm.$emit("close", true)
            }
          }
        })
      }),
      _vm._v(" "),
      _c("span", { staticClass: "visuallyhidden" }, [
        _vm._v("Press escape to cancel.")
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }