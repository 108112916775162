<template>
	<transition-group name="toast-trans" tag="div" :class="{ toastContainer: true, minorErrorsVisible: minorErrorCount > 0 }">
		<Toast v-for="t in toasts" :key="t.key" :args="t.args" @close="function(toastResult) { closeRequested(t.key, toastResult) }" class="toast-trans-item" />
	</transition-group>
</template>

<script>
	// One instance of this component should exist near the end of the root vue component.  All toasts will be children of this.
	import { RegisterToastContainer, UnregisterToastContainer } from 'tdsAppRoot/library/Toast.js';
	import Toast from 'tdsAppRoot/components/Controls/Toast/Toast.vue';
	import { FPSCounter } from 'tdsAppRoot/library/TDSUtil.js';

	let uniqueToastCounter = 0;
	let maxConcurrentToasts = 10;

	export default {
		components: { Toast },
		data()
		{
			return {
				toasts: [],
				rateLimiter: new FPSCounter()
			};
		},
		created()
		{
			RegisterToastContainer(this);
		},
		beforeDestroy()
		{
			while (this.toasts.length > 0)
				this.toasts.pop().onToastClose(false);
		},
		destroyed()
		{
			UnregisterToastContainer(this);
		},
		methods:
		{
			CreateToast(args, onToastClose)
			{
				if (this.rateLimiter.getFPS() > 5)
				{
					console.error("CreateToast throttle suppressed a toast", args);
					onToastClose(false);
					return;
				}
				this.rateLimiter.addFrame();

				if (this.toasts.length >= maxConcurrentToasts)
				{
					// Close the oldest toast to make room for a new one.
					let removed = this.toasts[0];
					this.toasts.splice(0, 1);
					removed.onToastClose(false);
				}

				if (!args)
					args = { message: "" };
				else if (typeof args === "string")
					args = { message: args };
				if (!args.message)
					args.message = "";

				let t = { args, onToastClose, key: uniqueToastCounter++ };
				this.toasts.push(t);

				return t.key;
			},
			closeRequested(key, toastResult)
			{
				for (let i = 0; i < this.toasts.length; i++)
				{
					if (this.toasts[i].key === key)
					{
						let t = this.toasts[i];
						this.toasts.splice(i, 1);
						t.onToastClose(toastResult);
						return;
					}
				}
			}
		},
		computed:
		{
			minorErrorCount()
			{
				return this.$store.state.minorErrorQueue.length;
			}
		}
	}
</script>

<style scoped>
	.toastContainer
	{
		position: fixed;
		bottom: 0px;
		right: 0px;
		z-index: 5000;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
	}

		.toastContainer.minorErrorsVisible
		{
			bottom: 60px;
		}

	.toast-trans-item
	{
		transition: all 400ms;
	}

	.toast-trans-enter, .toast-trans-leave-to
	/* .list-complete-leave-active below version 2.1.8 */
	{
		opacity: 0;
		transform: translateX(300px);
	}

	.toast-trans-leave-active
	{
		transition: all 200ms;
		position: absolute;
	}
</style>