<template>
	<div class="center">
		<div class="errorBody">
			<div class="logo">
				<router-link :to="rootLink">
					<LogoImage :width="192" class="logoImg" logoType="standalone" :includeDesc="false" />
				</router-link>
			</div>
			<div class="textArea">
				<div class="statusCode">404</div>
				<div class="message">{{ message }}</div>
				<div class="message">
					<router-link :to="rootLink" class="reEnterButton">
						Re-enter {{ appContext.appName }}
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import LogoImage from 'tdsAppRoot/components/Controls/LogoImage.vue';
	import ThemeMixin from 'tdsAppRoot/library/ThemeMixin.js';

	export default {
		mixins: [ThemeMixin],
		components: { LogoImage },
		data()
		{
			return {
				msg: null
			};
		},
		mounted()
		{
			if (this.$route.query.msg)
				this.msg = this.$route.query.msg;
		},
		computed:
		{
			rootLink()
			{
				let location = { name: 'root' };
				if (this.$router.getMatchedComponents(location).length > 0)
					return location;
				location = { name: 'loginRoot' };
				if (this.$router.getMatchedComponents(location).length > 0)
					return location;
				return null;
			},
			message()
			{
				if (this.msg)
					return this.msg;
				else
					return "The requested item was not found.";
			}
		}
	};
</script>

<style scoped>
	.center
	{
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

		.center > *
		{
			margin: 20px 3vw 0px 3vw;
			width: 94vw;
		}

	.errorBody
	{
		font-family: Calibri, sans-serif;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
	}

		.errorBody a
		{
			text-decoration: underline;
		}

	.logo
	{
		margin: 20px;
		font-size: 0px;
	}

		.logo .tpBarLogoImg
		{
			width: 192px;
			height: 122px;
			padding-bottom: 2px;
			fill: var(--primary-color);
		}

	.textArea
	{
		margin: 20px;
	}

	.statusCode
	{
		font-weight: bold;
		font-size: 32pt;
	}

	.message
	{
		font-size: 14pt;
		margin-top: 5px;
	}

	@media (min-width: 600px)
	{
		.errorBody
		{
			flex-direction: row;
			align-items: flex-end;
		}
	}
</style>