import { CallServer } from "tdsAppRoot/API/apibase.js";
import { ArraysSimilar } from 'tdsAppRoot/library/TDSUtil.js';
import { TitleListStringToArray } from "tdsAppRoot/library/TitleListCompressor.js";
import { ReportError } from "tdsAppRoot/library/ErrorReporter.js";

export function SearchArgsEqual(left, right)
{
	return right
		&& CompareStringsWhereNullEqualsEmpty(left.query, right.query)
		&& left.titleCategoryId === right.titleCategoryId
		&& ArraysSimilar(left.titleIds, right.titleIds)
		&& left.yearLimit === right.yearLimit
		&& CompareStringsWhereNullEqualsEmpty(left.intendToUse, right.intendToUse)
		&& left.stemming === right.stemming
		&& left.relatedTerms === right.relatedTerms
		&& left.headingsOnly === right.headingsOnly;
};
// Mirrors SearchArgs (SearchRequest.args) on server
var SearchArgs = function ()
{
	this.query = "";
	this.titleCategoryId = 0;
	this.titleIds = null; // Array of (int) title IDs, to be used by the server only if titleCategoryId is 0, negative, or otherwise does not map to a product category.
	this.yearLimit = 0;
	this.intendToUse = "";
	this.stemming = true;
	this.relatedTerms = false;
	this.headingsOnly = false;

	this.IsEqualTo = function (other)
	{
		return SearchArgsEqual(this, other);
	};
};
Object.seal(SearchArgs);

/**
 * Returns true if the strings are equal, with the special rule that empty strings are considered equal to null.
 * @param {String} a A string
 * @param {String} b A string
 * @returns True if the strings are considered equal.
 */
function CompareStringsWhereNullEqualsEmpty(a, b)
{
	return a === b || (a === "" && b === null) || (a === null && b === "");
}

// Mirrors SearchRequest on server
var SearchRequest = function ()
{
	this.searchid = "";
	this.args = null; // type: SearchArgs
	this.categoryRequests = null; // array of CategoryRequest (class defined on server)
	this.logAs = ""; // "" or "LegacyOpenSearchPlugin" or "OpenSearchAPI"
};
Object.seal(SearchRequest);

// Mirrors ResultSubsetRequest on server
var ResultPageParams = function ()
{
	this.firstResult = 0;
	this.lastResult = 0;
	this.categoryType = "";
};
Object.assign(ResultPageParams.prototype, SearchRequest);
Object.seal(ResultPageParams);

export function Search(request, store)
{
	try
	{
		let p = new SearchRequest();
		p.searchid = request.searchid;
		p.args = request.args;
		p.categoryRequests = request.categoryRequests;
		p.logAs = request.logAs;
		return CallServer("Search", null, p, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
};

export function ResultPage(searchid, args, firstResult, lastResult, categoryType, store)
{
	if (args && typeof args.titleIds === "string")
	{
		console.error("args.titleIds should have been an array, but was a string", args.titleIds);
		ReportError(store.getters.urlRoot, "args.titleIds should have been an array, but was a string: " + args.titleIds, null, null, store.state.sid);
		args.titleIds = TitleListStringToArray(args.titleIds);
	}

	let p = new ResultPageParams();
	p.searchid = searchid;
	p.args = args;
	p.firstResult = firstResult;
	p.lastResult = lastResult;
	p.categoryType = categoryType;

	return CallServer("Search", "GetResultSubset", p, store);
};

export function RelatedConcepts(store, query)
{
	let p = new SearchRequest();
	p.args = new SearchArgs();
	p.args.query = query;
	return CallServer("Search", "RelatedConcepts", p, store);
};

let SearchSuggestionsRequest = function (query)
{
	this.query = query;
};
Object.seal(SearchSuggestionsRequest);

export function GetSearchSuggestionsFromServer(store, query)
{
	return CallServer("Search", "GetSearchSuggestions", new SearchSuggestionsRequest(query), store);
}

let CategoryAccessRequest = function ()
{
	this.categoryType;
	this.searchRequest;
	this.totalResults;
	this.totalMatches;
};
Object.seal(CategoryAccessRequest);

export function CategoryAccess(store, searchRequest, categoryType, totalResults, totalMatches)
{
	try
	{
		let p = new CategoryAccessRequest();
		p.categoryType = categoryType;
		p.searchRequest = searchRequest;
		p.totalResults = totalResults;
		p.totalMatches = totalMatches;

		return CallServer("Search", "CategoryAccess", p, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}

export { SearchArgs, SearchRequest };