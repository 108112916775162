<template>
	<div ref="root" class="emailLinkRoot" role="dialog" aria-label="Email a Link.  Press escape to close.">
		<div class="modalDialogCloseButton" tabindex="0" @click="DefaultClose" :style="dynCloseBtnStyle"
			 @keydown.space.enter.stop.prevent="DefaultClose" role="button" aria-label="Close">
			<vsvg sprite="#x" title="" desc="" role="presentation" aria-hidden="true" />
		</div>
		<div class="topBar">
			<div id="emailLinkTitle" class="title">
				Email a Link
			</div>
			<div class="path" v-html="path"></div>
		</div>
		<div class="emailForm" ref="emailForm">
			<div class="formRow"><span class="label">Sent By:</span><span class="sender formRight">{{ profileName }} ({{ profileEmail }})</span></div>
			<div class="formRow"><label for="emailLinkTo" class="label">Recipient's Address:</label>
			<input ref="to" type="text" id="emailLinkTo" aria-describedby="emailLinkToValid" name="email_to" 
				   v-model="toEmail" validateData="toEmail" class="formRight toInput" data-vv-as="Recipient's Address" 
				   validatespec="required" @keydown.enter.stop.prevent="" @keydown.enter.stop.prevent="OnSendClick" 
				   maxlength="250" :aria-invalid="validator.has('email_to') ? 'true' : null"/></div>
			<div class="formRow"><label for="emailLinkMsg" class="label">Message (optional):</label><textarea rows="5" type="text" id="emailLinkMsg" v-model="message" class="formRight messageInput" maxlength="2000" /></div>
			<div v-bind:class="lastRow">
				<div id="emailLinkToValid" class="validationWarning" v-show="validator.has('email_to')">
					{{ validator.first('email_to').msg }}
				</div>
				<div class="formRight"><button class="sendBtn primaryColorButton inputButton" @click="OnSendClick">Send</button></div>
			</div>
		</div>
		<div class="warnings">
			<div class="warningsHeading">Warnings</div>
			<div>1. Content updates may cause link targets to be lost.</div>
			<div>2. Login authentication requires a valid IP address for this subscription. If used from an invalid IP address, a username and password will be requested.</div>
			<div>3. Maximum of three recipients (separated by semicolons).</div>
			<div>4. If you want to create links that use a proxy server, simply create those links while connected to {{ appContext.appName }} through that proxy server.</div>
		</div>
		<FullScreenLoadingMsg :show="sending" msg="Sending…" />
	</div>
</template>

<script>
	import { EmailLink } from 'tdsAppRoot/API/FavoritesAPI.js';
	import { ModalMessageDialog, ModalConfirmDialog } from "tdsAppRoot/library/ModalDialog.js";
	import { Validation } from 'tdsAppRoot/library/ValidationHelper.js';
	//import { StringBuilder } from 'tdsAppRoot/library/TDSUtil.js';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import ThemeMixin from "tdsAppRoot/library/ThemeMixin.js";

	export default {
		mixins: [ThemeMixin],
		components: { FullScreenLoadingMsg },
		props:
		{
			fav: {
				type: Object,
				required: true
			}
		},
		data()
		{
			return {
				toEmail: "",
				message: "",
				validator: new Validation(),
				busy: false,
				sending: false,
				scrollHeight: 0,
				clientHeight: 0
			};
		},
		mounted()
		{
			this.validator.setFormRoot(this.$refs.emailForm);
			this.toEmail = this.profileEmail;
			setTimeout(() =>
			{
				if (this.$refs.to)
					this.$refs.to.select();
			}, 100);
			if (this.$refs.root)
			{
				this.scrollHeight = this.$refs.root.scrollHeight;
				this.clientHeight = this.$refs.root.clientHeight;

				if (typeof window !== "undefined")
					window.addEventListener("resize", this.onresize);
			}
		},
		beforeDestroy()
		{
			if (typeof window !== "undefined")
				window.removeEventListener("resize", this.onresize);
		},
		computed:
		{
			profileName()
			{
				return this.$store.state.profile.ProfileFirstName + " " + this.$store.state.profile.ProfileLastName;
			},
			profileEmail()
			{
				return this.$store.state.profile.ProfileEmail;
			},
			path()
			{
				let str = this.fav.path.replace(/¦/g, "").replace(/|/g, "").replace(/\\r\\n/g, "|").replace(/\\t/g, "");
				let pathArr = str.split("|");
				//let buffer = new StringBuilder();
				str = this.PathArrayToNestedPath(pathArr, 0);
				//let str = ("<div class=\"linkPathRow\">" + this.fav.path.replace(/\\r\\n/g, "</div>\r\n<div class=\"linkPathRow\">").replace(/¦/g, "").replace(/|/g, "").replace(/\\t/g, "") + "</div>").replace(/<div class=\"linkPathRow\"><\/div>/g, "");
				console.log(str);
				return str;
			},
			lastRow()
			{
				if (this.validator.has("email_to"))
					return "formRow";
				else
					return "formRowNoLeft";
			},
			dynCloseBtnStyle()
			{
				if (this.scrollHeight > this.clientHeight)
				{
					return {
						marginRight: "20px"
					}
				}
			}
		},
		methods:
		{
			onresize(event)
			{
				if (this.$refs.root)
				{
					this.scrollHeight = this.$refs.root.scrollHeight;
					this.clientHeight = this.$refs.root.clientHeight;
				}
			},
			PathArrayToNestedPath(pathArr, i)
			{
				if (i < pathArr.length)
				{
					let item = pathArr[i];
					if (item && item.length > 0)
						return (i > 0 ? " " : "") + "<div class=\"emailLinkPathItem\" level=\"" + i + "\">" + item + this.PathArrayToNestedPath(pathArr, i + 1) + "</div>";
					return "";
				}
				else
					return "";
			},
			DefaultClose()
			{
				if (this.message || this.busy)
				{
					ModalConfirmDialog({
						message: "Your message has not been sent yet.",
						title: "Message Not Sent",
						yesText: "Stay Open",
						noText: "Discard Form",
						cancelMeansYes: true
					}).then(stayOpen =>
					{
						if (!stayOpen)
							this.CloseNow();
					});
				}
				else
				{
					this.CloseNow();
				}
			},
			CloseNow()
			{
				this.$emit("close", false);
			},
			SetFocus()
			{
				if (this.$refs.to)
					this.$refs.to.focus();
			},
			OnSendClick()
			{
				if (this.$data.busy)
					return;
				this.$data.busy = true;
				try
				{
					var vald = this.validator;
					this.validator.ValidateAll(this.$data).then(result =>
					{
						if (result)
						{
							this.sending = true;
							EmailLink(this.$store, this.fav.id, this.toEmail, this.message).then(response =>
							{
								this.$data.busy = false;
								this.sending = false;
								console.log(response.result);
								ModalMessageDialog("The link has been sent.");
								this.CloseNow();
							}
							).catch(err =>
							{
								if (err.data)
									console.log(err.data.result);
								else
									console.error(err);
								/*
									Success,
									InvalidEmail,
									UnknownSender,
									BadRequest,
									TooManyRecipients, // Email is still sent, but only to a max of three recipients.
									Abuse,
									Error
								*/
								ModalMessageDialog(err.data.errMsg, null).then(dialogResult =>
								{
									this.$data.busy = false;
									this.sending = false;
								}
								).catch(err =>
								{
									this.$data.busy = false;
									this.sending = false;
								});
								if (err.data)
								{
									if (err.data.result == "InvalidEmail")
									{
									}
									else if (err.data.result == "UnknownSender")
									{
									}
									else if (err.data.result == "BadRequest")
									{
									}
									else if (err.data.result == "TooManyRecipients")
									{
									}
									else if (err.data.result == "Abuse")
									{
									}
									else if (err.data.result == "Error")
									{
									}
								}
							});
						}
						else
						{
							this.$data.busy = false;
							this.sending = false;
						}
					}
					).catch(err =>
					{
						if (vald.HasAnyErrors())
						{
							var targetInput = document.getElementById(vald.first().domId);
							targetInput.focus();
						}
						this.$data.busy = false;
						this.sending = false;
						console.log(err);
					});
				}
				catch (ex)
				{
					this.$data.busy = false;
					this.sending = false;
				}
			}

		}
	}
</script>

<style>
	.emailLinkPathItem
	{
		margin-left: 10px;
		text-indent: -10px;
	}
</style>

<style scoped>
	.emailLinkRoot
	{
		width: 100%;
		max-width: 100%;
		background-color: #FFFFFF;
		overflow: auto;
		max-height: 100%;
		box-sizing: border-box;
	}

	.topBar
	{
		background-color: var(--primary-color);
		color: var(--text-color-on-primary-color);
		padding: 0px 5px 5px 5px;
	}

	.title
	{
		text-align: center;
		font-size: 18pt;
		margin-bottom: 10px;
	}

	.path
	{
		color: #DDDDDD;
		margin-left: 5px;
	}

	.emailForm
	{
		padding: 10px;
	}

	.warningsHeading
	{
		text-align: center;
		font-style: normal;
		color: #000000;
		font-size: 16pt;
	}

	.warnings
	{
		padding: 5px 10px 10px 10px;
		font-style: italic;
		color: #777777;
	}

	.formRow
	{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 5px;
	}

	.formRowNoLeft
	{
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		margin-bottom: 5px;
	}

	.validationWarning
	{
		color: #FF0000;
		display: flex;
		justify-content: center;
		flex-direction: column;
		box-sizing: border-box;
	}

	.toInput
	{
		width: 210px;
	}

	.messageInput
	{
		width: 210px;
		resize: vertical;
		min-height: 22px;
		max-height: 200px;
	}

	@media (min-width: 417px)
	{
		.emailLinkRoot
		{
			width: 400px;
		}
	}
</style>