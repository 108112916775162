import MySRLoginPopup from 'tdsAppRoot/components/Controls/MySRLoginPopup.vue';
import { ModalDialog } from 'tdsAppRoot/library/ModalDialog.js';

/**
 * Returns a promise which is resolved when the profile login window is closed.  The resolve argument is true if login was successful. Does not reject.
 * @returns {Promise} Returns a promise which is resolved when the profile login window is closed.  The resolve argument is true if login was successful. Does not reject.
 */
export default function ShowMySRLoginWindow(disableAutomaticDocReload)
{
	if (!document.getElementById("mySRLoginRoot"))
		return ModalDialog(MySRLoginPopup, { disableAutomaticDocReload: disableAutomaticDocReload });
	return Promise.resolve(); // This is probably going to result in a bugged state, but the alternative is multiple stacked login dialogs.  Either way, this typically seems to happen if another error happens first.
}