<template>
	<div id="mySRLoginRoot" :class="{ mySRLoginRoot: true, isInPopup: isInPopup, isStandalone: !isInPopup }">
		<div class="titleBar" v-if="isInPopup">
			<div class="loginTitle">PROFILE LOGIN</div>
			
		</div>
		<div class="body">
			<div class="controls" ref="validateForm">
				<div class="userNameBox">
					<label for="profileLoginEmailBox">Your Email</label><br/>
					<input id="profileLoginEmailBox" ref="emailBox" :name="inputName('email')" @keypress.escape.prevent="CloseDialog" class="tb"
						   data-vv-as="Your email" type="email" v-model="email" aria-describedby="profileLoginEmailBoxValid"
						   tabindex="0" validatespec="required" maxlength="250" autocomplete="email"
						   :aria-invalid="validator.has(inputName('email')) ? 'true' : null"/>
					<div id="profileLoginEmailBoxValid" class="validationWarning" v-show="validator.has(inputName('email'))">{{ validator.first(inputName('email')).msg }}</div>
				</div>
				<div class="passwordBox">
					<label for="profilePwEmailBox">Password</label><br/>
					<input id="profilePwEmailBox" :name="inputName('pw')" class="tb" @keypress.escape.prevent="CloseDialog" 
						   data-vv-as="Password" type="password" v-model="pw" tabindex="0" 
						   validatespec="required" v-on:keypress.enter="login" maxlength="120" 
						   autocomplete="current-password" aria-describedby="profilePwEmailBoxValid"
						   :aria-invalid="validator.has(inputName('pw')) ? 'true' : null"/> <!-- keyup doesn't fire on iOS -->
					<div id="profilePwEmailBoxValid" class="validationWarning" v-show="validator.has(inputName('pw'))">{{ validator.first(inputName('pw')).msg }}</div>
				</div>
				<div v-if="!isInPopup">
					<div class="rememberMeBox">
						<SRCustomCheckbox srtabindex="0" idprefix="profileLoginCB" @keypress.escape.prevent="CloseDialog" 
										  v-model="rememberMe">Remember Me (uses cookies)</SRCustomCheckbox>
					</div>
				</div>
				<div v-else>
					<div class="rememberMeBoxAbsPos"><SRCustomCheckbox srtabindex="0" idprefix="profileLoginCB" @keypress.escape.prevent="CloseDialog" v-model="rememberMe">Remember Me (uses cookies)</SRCustomCheckbox></div>
				</div>
				<div class="loginRegisterButtons">
					<div role="button" class="loginButton" v-on:click="login" @keypress.escape.prevent="CloseDialog" 
						 tabindex="0" @keydown.space.enter.stop.prevent="login" >
						Log in
					</div>
					<div ref="registerBtn" role="button" aria-haspopup="dialog" 
						 class="registerButton" v-on:click="register" @keypress.escape.prevent="CloseDialog" 
						 tabindex="0" @keydown.space.enter.stop.prevent="register">
						Register
					</div>
				</div>
			</div>
			<div class="smallLinks"><a role="button" tabindex="0" class="alwaysUnvisited" @click="ForgotPassword" 
									   @keypress.escape.prevent="CloseDialog" aria-haspopup="dialog" 
									   @keypress.enter.space.prevent="ForgotPassword">Forgot Password?</a></div>
		</div>
		<FullScreenLoadingMsg :show="loggingIn" msg="Logging In" backgroundColor="white" :shadow="true" />
	</div>
</template>

<script>
	import { ReportError, ShowModalErrorWindow } from "tdsAppRoot/library/ErrorReporter.js";
	//import VeeValidate from 'vee-validate';
	import FullScreenLoadingMsg from "tdsAppRoot/components/Controls/FullScreenLoadingMsg.vue";
	import SRCustomCheckbox from "tdsAppRoot/components/Controls/SRCustomCheckbox.vue";
	import { MSRLogin } from "tdsAppRoot/API/MyStatrefAPI.js";
	import { Validation } from 'tdsAppRoot/library/ValidationHelper.js';
	import { ModalForgotProfilePasswordDialog } from 'tdsAppRoot/library/ModalDialog.js';
	import { RestrictedSessionCheck } from 'tdsAppRoot/library/Session.js';
	import MySRRegistrationPopup from 'tdsAppRoot/components/Controls/MySRRegistrationPopup.vue';
	import { ModalDialog } from 'tdsAppRoot/library/ModalDialog.js';

	export default {
		components: { SRCustomCheckbox, FullScreenLoadingMsg },
		props:
		{
			isInPopup: {
				type: Boolean,
				default: false
			},
			// Logging in to a profile will normally reload the current document.  Set this to disable that behavior.  Currently
			// used when attempting to save Favorites, as the document must be reloaded after the save, which itself can't happen
			// until after the profile login, and we don't want to reload twice.
			disableAutomaticDocReload: { 
				type: Boolean,
				default: false
			}
		},
		data()
		{
			return {
				email: "",
				pw: "",
				rememberMe: false,
				loggingIn: false,
				validator: new Validation()
			};
		},
		mounted()
		{
			this.validator.setFormRoot(this.$refs.validateForm);
			this.email = this.$store.getters.GetProfileRegistrationField("email");
			this.rememberMe = this.$store.getters.GetProfileRegistrationField("remember");
			
		},
		watch:
		{
			email()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "email", value: this.email });
			},
			rememberMe()
			{
				this.$store.commit("SetProfileRegistrationField", { fieldName: "remember", value: this.rememberMe });
			}
		},
		methods:
		{
			inputName(key)
			{
				return (this.isInPopup ? "popup_" : "static_") + key;
			},
			tabIndex(idx)
			{
				return "" + (this.isInPopup ? idx : 0);
			},
			SetFocus()
			{
				this.$refs.emailBox.focus();
			},
			PreventDefault(event)
			{
				event.preventDefault();
				return false;
			},
			login(event)
			{
				event.preventDefault();
				if (this.$data.loggingIn)
				{
					console.log("Ignoring extra login click.");
					return;
				}
				var vald = this.validator;
				var saveThis = this;
				this.validator.ValidateAll(this.$data).then(result =>
				{
					if (result)
					{
						saveThis.$data.loggingIn = true;
						saveThis.$store.commit("ClearProfileRegistrationFields");
						MSRLogin(saveThis.$data.email, saveThis.$data.pw, saveThis.$store).then(data =>
						{
							saveThis.$data.loggingIn = false;
							try
							{
								console.log("Profile Login Result: " + JSON.stringify(data));
								if (data.profileData)
								{
									console.log("Profile Login success");
									saveThis.$store.dispatch("ProfileLoggedIn", { profileData: data.profileData, disableAutomaticDocReload: saveThis.disableAutomaticDocReload });
									if (saveThis.$data.rememberMe)
									{
										saveThis.$cookie.set("ProfileLogin", data.profileLoginInfo, 90);
									}
									saveThis.$emit("success");
								}
								else
								{
									ReportError(saveThis.$store.getters.urlRoot, "MyStatref login attempt did not return the expected json data.  Instead, we got: " + JSON.stringify(data), null, null, saveThis.$store.state.sid);
									ShowModalErrorWindow("There was an error processing your request.  Please wait a few minutes and try again.");
								}
							}
							catch (err)
							{
								ReportError(saveThis.$store.getters.urlRoot, "MyStatref login error - Response returned, but an exception occurred while processing it: " + err.message, null, err, saveThis.$store.state.sid);
							}
						}).catch(err =>
						{
							saveThis.$data.loggingIn = false;
							if (err.name === "ApiError")
							{
								ShowModalErrorWindow(err.message);
							}
							else
							{
								console.log("Profile login error: " + err.message);
								ReportError(saveThis.$store.getters.urlRoot, "Profile login error: " + err.message, null, err, saveThis.$store.state.sid);
								ShowModalErrorWindow("There was an error logging you in.  Technical support has been notified.  We are sorry for the inconvenience.");
							}
						});
					}
				}).catch(err =>
				{
					if (vald.HasAnyErrors())
					{
						var targetInput = document.getElementById(vald.first().domId);
						targetInput.focus();
					}
					saveThis.$data.loggingIn = false;
				});
				return false;
			},
			register(event)
			{
				if (RestrictedSessionCheck())
					return;
				ModalDialog(MySRRegistrationPopup,
					{ initialEmail: this.email, initialPass: this.pw },
					{ positionAbsolute: true, overflowHidden: true },
					this.$refs.registerBtn).then(result =>
				{
					if (result)
						this.$emit("success");
				});

				return false;
			},
			ForgotPassword()
			{
				ModalForgotProfilePasswordDialog();
			},
			CloseDialog()
			{
				this.$emit("close");
			}
		}
	}
</script>

<style scoped>
	input::-ms-clear,
	input::-ms-reveal
	{
		display: none;
	}

	.isStandalone .mySRLoginRoot
	{
		margin: 20px;
	}

	.isInPopup .mySRLoginRoot
	{
		/* Widening these top radius values prevents a fringe from appearing around the colored title bar */
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
	}

	.validationWarning
	{
		font-size: 8pt;
		color: #D40202;
		overflow: visible;
		margin-left: 3px;
		margin-bottom: 3px;
	}

	.isInPopup .validationWarning
	{
		height: 0px;
		display: block;
		margin-bottom: 0px;
	}

	.isInPopup .titleBar
	{
		background-color: var(--primary-color);
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		box-sizing: border-box;
		padding: 21px 42px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.isInPopup .loginTitle
	{
		color: var(--text-color-on-primary-color);
		font-weight: bold;
		font-size: 18pt;
	}

	.isInPopup .rememberMeBox
	{
		margin-top: 5px;
		flex: 0 0 auto;
		color: var(--text-color-on-primary-color);
	}

	.isStandalone .rememberMeBox
	{
		margin: 20px 0px 25px 0px;
	}

	.rememberMeBoxAbsPos
	{
		right: 42px;
		top: 25px;
		position: absolute;
		color: var(--text-color-on-primary-color);
	}

	.isInPopup .body
	{
		padding: 10px 15px 15px 15px;
	}

	.isInPopup .controls
	{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.isInPopup .smallLinks
	{
		text-align: right;
		margin: 0px 10px;
	}

	.isStandalone .smallLinks
	{
		margin-top: 20px;
	}

	.isStandalone .userNameBox,
	.isStandalone .passwordBox
	{
		margin: 10px 0px;
	}

	.isInPopup .userNameBox,
	.isInPopup .passwordBox
	{
		margin: 0px 10px 10px 10px;
		flex: 1 0 auto;
	}

	.tb
	{
		border-radius: 5px;
		line-height: 18px;
		font-size: 16px;
		color: black;
		border: 2px solid transparent;
	}

		.tb:focus
		{
			font-size: 16px; /* Has to be at least this size or iOS will zoom in when focused. */
		}

	.isInPopup .tb
	{
		box-sizing: border-box;
		height: 46px;
		width: 220px;
		padding: 0px 25px;
	}

	.isStandalone .tb
	{
		padding: 13px 20px 13px 20px;
		border: 1px solid #8E958E;
		width: 240px;
	}
	.tb:focus-visible
	{
		border: 2px solid black;
	}
	.tb.focus-visible
	{
		border: 2px solid black;
	}
	.isStandalone .tb:focus-visible
	{
		padding: 12px 19px 12px 19px;
	}
	.isStandalone .tb.focus-visible
	{
		padding: 12px 19px 12px 19px;
	}


	.isInPopup .loginRegisterButtons
	{
		flex: 1 1 auto;
		font-size: 0px;
		display: flex;
		justify-content: space-between;
		padding: 10px;
	}

	.loginButton, .registerButton
	{
		display: inline-block;
		cursor: pointer;
		color: white;
		border-radius: 5px;
		box-sizing: border-box;
		width: 106px;
		padding: 12px 0px 15px 0px;
		position: relative;
		top: 8px;
		font-size: 16px;
		font-weight: bold;
		text-align: center;
		border: 2px solid transparent;
		outline: none;
	}

	.loginButton
	{
		background-color: #3D8538;
		max-height: 46px;
	}

	.registerButton
	{
		background-color: #A66621;
		margin-left: 16px;
		max-height: 46px;
	}
	.loginRegisterButtons > div:focus-visible
	{
		border: 2px solid black;
	}
	.loginRegisterButtons > div.focus-visible
	{
		border: 2px solid black;
	}

	::placeholder
	{ /* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #A0A0A0;
		opacity: 1; /* Firefox */
		font-size: 11pt;
	}

	:-ms-input-placeholder
	{ /* Internet Explorer 10-11 */
		color: #A0A0A0;
	}

	::-ms-input-placeholder
	{ /* Microsoft Edge */
		color: #A0A0A0;
	}


	@media (max-width: 460px)
	{
		.rememberMeBoxAbsPos
		{
			top: 45px;
		}
	}
</style>