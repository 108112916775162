import { CallServer } from "tdsAppRoot/API/apibase.js";

export function GetBookshelfTitles(store)
{
	try
	{
		return CallServer("BookshelfData", null, null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}
export function GetBookshelfCategories(store)
{
	try
	{
		return CallServer("BookshelfData", "GetCategories", null, store);
	}
	catch (err)
	{
		return Promise.reject(err);
	}
}