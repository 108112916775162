<template>
	<div class="searchResultMetadata" role="dialog" aria-labelledby="searchMetadataPanelTitle">
		<div class="normalBody">
			<div class="heading">
				<div ref="closeBtn" tabindex="0" role="button" aria-label="Close" class="closeButton" @click="readyToClose" @keypress.enter.space.stop.prevent="readyToClose"><vsvg sprite="#x" title="" desc="" role="presentation" aria-hidden="true" /></div>
				<div id="searchMetadataPanelTitle" class="title" role="alert">Metadata for searchid {{searchid}}: {{queryLabel}}</div>
				<div class="subtitle">(This panel and its data requires elevated user permission)</div>
			</div>
			<div class="metadataBody">
				<CategoryMetadata class="category" v-for="cat in allRequestedCategories" :key="cat.CategoryType" :searchid="searchid" :categoryType="cat.CategoryType" :priority="cat.Priority" />
			</div>
			<div class="statRefResultCountsBody">
				<StatRefResultCounts class="category" :searchid="searchid" />
			</div>
		</div>
	</div>
</template>
<script>
	import svg1 from "tdsAppRoot/images/sprite/x.svg";
	import CategoryMetadata from "tdsAppRoot/components/Search/Metadata/CategoryMetadata.vue";
	import StatRefResultCounts from "tdsAppRoot/components/Search/Metadata/StatRefResultCounts.vue";

	export default {
		components: { CategoryMetadata, StatRefResultCounts },
		props:
		{
			searchid: {
				type: String,
				default: "none"
			}
		},
		data: function ()
		{
			return {
			};
		},
		methods:
		{
			readyToClose()
			{
				this.$emit('close', false);
			},
			SetFocus()
			{
				if (this.$refs.closeBtn)
					this.$refs.closeBtn.focus();
			}
		},
		computed:
		{
			myRequest()
			{
				return this.$store.getters.GetSearchRequest(this.searchid);
			},
			queryLabel()
			{
				if (this.myRequest && this.myRequest.args.query && this.myRequest.args.query.length > 0)
					return this.myRequest.args.query;
				return "No search has been performed";
			},
			allRequestedCategories()
			{
				if (this.myRequest && this.myRequest.categoryRequests)
					return this.myRequest.categoryRequests;
				return [];
			}
		}
	};
</script>
<style scoped>
	.searchResultMetadata
	{
		max-width: 90vw;
		max-height: 90vh;
		box-sizing: border-box;
		background-color: #FFFFFF;
		overflow: auto;
	}

	.normalBody
	{
		display: flex;
		flex-direction: column;
	}

	.heading
	{
		background-color: #EDEFF2;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		border-bottom: 1px solid #CDCDCD;
	}

	.closeButton
	{
		float: right;
		cursor: pointer;
		padding: 3px;
		border: 1px solid #BBBBBB;
		margin: 6px;
		background-color: rgba(255,255,255,0.5);
		box-shadow: 0px 0px 2px rgba(0,0,0,0.2);
	}

		.closeButton:focus-visible
		{
			outline: 2px solid black;
		}

		.closeButton.focus-visible
		{
			outline: 2px solid black;
		}

		.closeButton:hover
		{
			background-color: rgba(255,255,255,1);
		}

		.closeButton svg
		{
			width: 32px;
			height: 32px;
		}

	.title
	{
		font-size: 18pt;
		padding: 10px 10px 0px 10px;
		word-break: break-word;
		/*font-weight: bold;*/
	}

	.subtitle
	{
		font-size: 12pt;
		font-style: italic;
		color: #999999;
		padding: 0px 10px 10px 10px;
		word-break: break-word;
	}

	.metadataBody,
	.statRefResultCountsBody
	{
		background-color: #F5F5F5;
		padding: 0px 5px;
	}

	.metadataBody
	{
		box-shadow: inset 0 7px 9px -7px rgba(0,0,0,0.4);
		flex: 0 1 auto;
		max-height: 100%;
		display: flex;
		flex-wrap: wrap;
		align-content: flex-start;
	}

	.category
	{
		flex: 1 1 210px;
		background-color: #FFFFFF;
		margin: 10px 5px;
		border: 1px solid #666666;
		box-shadow: 1px 1px 1px rgba(0,0,0,0.5);
		border-radius: 10px;
		padding: 5px 10px;
	}

	@media (min-width: 600px)
	{
		.searchResultMetadata
		{
			max-width: 80vw;
		}
	}
</style>
