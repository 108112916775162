var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "searchSetEditRoot",
      attrs: {
        role: "dialog",
        "aria-label":
          "Search set editor: " + _vm.searchSetName + ". Press escape to close."
      }
    },
    [
      _c(
        "div",
        { staticClass: "normalBody" },
        [
          _c("div", { staticClass: "heading" }, [
            _c(
              "div",
              {
                ref: "closeBtn",
                staticClass: "closeButton",
                attrs: {
                  tabindex: "0",
                  role: "button",
                  "aria-label": "Save and Close",
                  title: "Save and Close"
                },
                on: {
                  click: _vm.readyToClose,
                  keypress: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k(
                        $event.keyCode,
                        "enter",
                        13,
                        $event.key,
                        "Enter"
                      ) &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar"
                      ])
                    ) {
                      return null
                    }
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.readyToClose.apply(null, arguments)
                  }
                }
              },
              [
                _c("vsvg", {
                  attrs: {
                    sprite: "#save",
                    title: "",
                    desc: "",
                    role: "presentation",
                    "aria-hidden": "true"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.searchSetName))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "headerButtonContainer" }, [
              _c(
                "span",
                {
                  staticClass: "headerButton",
                  attrs: { tabindex: "0", role: "button" },
                  on: {
                    click: _vm.selectAll,
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k(
                          $event.keyCode,
                          "enter",
                          13,
                          $event.key,
                          "Enter"
                        ) &&
                        _vm._k($event.keyCode, "space", 32, $event.key, [
                          " ",
                          "Spacebar"
                        ])
                      ) {
                        return null
                      }
                      $event.preventDefault()
                      return _vm.selectAll.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    "Select " + _vm._s(_vm.selectAllMode ? "All" : "None") + " "
                  ),
                  _c("vsvg", {
                    staticClass: "downArrow",
                    attrs: {
                      sprite: "#arrow_down_thin",
                      title: "",
                      desc: "",
                      role: "presentation",
                      "aria-hidden": "true"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c("span", { staticClass: "saveHelp" }, [
                _vm._v("(Changes are saved when you close this panel)")
              ])
            ])
          ]),
          _vm._v(" "),
          _vm.loading
            ? _c(
                "div",
                { staticClass: "loading" },
                [
                  _c("ScaleLoader", { staticClass: "animation" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "loadingtxt" }, [_vm._v("Loading")])
                ],
                1
              )
            : _vm.error
            ? _c("div", { staticClass: "error" }, [_vm._v(_vm._s(_vm.error))])
            : [
                _vm.allProducts && _vm.allProducts.length > 0
                  ? _c(
                      "div",
                      { staticClass: "allProducts" },
                      _vm._l(_vm.allProducts, function(product, index) {
                        return _c(
                          "div",
                          { key: product.id, staticClass: "product" },
                          [
                            _c("label", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: product.selected,
                                    expression: "product.selected"
                                  }
                                ],
                                staticClass: "searchSetCb",
                                attrs: {
                                  type: "checkbox",
                                  id:
                                    "searchSet" +
                                    _vm.searchSetName +
                                    "_" +
                                    index,
                                  index: index
                                },
                                domProps: {
                                  checked: Array.isArray(product.selected)
                                    ? _vm._i(product.selected, null) > -1
                                    : product.selected
                                },
                                on: {
                                  keydown: [
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "up",
                                          38,
                                          $event.key,
                                          ["Up", "ArrowUp"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.KeyUp($event)
                                    },
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "down",
                                          40,
                                          $event.key,
                                          ["Down", "ArrowDown"]
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.KeyDown($event)
                                    },
                                    function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      product.selected = !product.selected
                                    }
                                  ],
                                  change: function($event) {
                                    var $$a = product.selected,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          _vm.$set(
                                            product,
                                            "selected",
                                            $$a.concat([$$v])
                                          )
                                      } else {
                                        $$i > -1 &&
                                          _vm.$set(
                                            product,
                                            "selected",
                                            $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1))
                                          )
                                      }
                                    } else {
                                      _vm.$set(product, "selected", $$c)
                                    }
                                  }
                                }
                              }),
                              _vm._v(" " + _vm._s(product.name))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  : _c("div", { staticClass: "allProducts" }, [
                      _c("div", { staticClass: "error" }, [
                        _vm._v(
                          "No titles are available to be added to this search set."
                        )
                      ])
                    ])
              ]
        ],
        2
      ),
      _vm._v(" "),
      _c("FullScreenLoadingMsg", {
        attrs: { show: _vm.committingChanges, msg: "Saving Changes" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }